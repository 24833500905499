import React from "react";

export const inputIncludesWord = (word: string, baseStr: string) => {
  const cleanWord = word.replace(/\.|\?|\!|;|,|\n/g, "").toLocaleLowerCase();
  const cleanInput = baseStr
    .replace(/\.|\?|\!|,|;|\n/g, "")
    .toLocaleLowerCase();
  const doesInputIncludeWord = cleanInput
    .split(" ")
    .some((item) => item === cleanWord);
  return doesInputIncludeWord;
};

type Args = {
  baseText: string;
  compareText: string;
  chunkByPhrase?: boolean;
  highlightClassName: string;
};

const splitByPhrase = (text: string) => {
  const wordArray = text.split(" ").filter(Boolean);
  let phrase = "";
  let phrases: string[] = [];
  wordArray.forEach((item, i) => {
    phrase = phrase + " " + item;
    if ((i + 1) % 3 === 0 || i === wordArray.length - 1) {
      phrases = [...phrases, phrase];
      phrase = "";
    }
  });

  return phrases;
};

export const getDiffWords = ({
  baseText,
  compareText,
  chunkByPhrase,
  highlightClassName,
}: Args) => {
  const wordArray = compareText.split(" ").filter(Boolean);
  let chunks: string[] = wordArray;

  if (chunkByPhrase) {
    chunks = splitByPhrase(compareText);
  }

  const spans = chunks.map((phrase) => {
    let isInOriginal = inputIncludesWord(phrase, baseText);
    if (chunkByPhrase) {
      isInOriginal = baseText.toLowerCase().includes(phrase.toLowerCase());
    }
    // generate random Id for key prop
    const randomId = Math.random().toString(36).substring(7);
    return (
      <span key={randomId} className={!isInOriginal ? highlightClassName : ""}>
        {phrase}{" "}
      </span>
    );
  });

  return spans;
};

import React, { ReactNode } from "react";
import Select, { Props as ReactSelectProps } from "react-select";
import ReactFlagsSelect from "react-flags-select";
import { FieldError } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

type Option = {
  value: string | number;
  label: string;
};
type Sizing = "sm" | "md" | "lg";
type Type = "creatable" | "country" | string;

type Props = ReactSelectProps & {
  label?: string | ReactNode;
  name: string;
  options?: Option[];
  placeholder?: string;
  value?: string | null | number | Option[];
  defaultValue?: Option;
  style?: any;
  type?: Type;
  onCountrySelect?: (code: string) => void;
  sizing?: Sizing;
  containerStyle?: any;
  secondary?: boolean;
  setValue?: any;
  error?: FieldError;
  labelStyle?: any;
  hoverStyle?: any;
  menuStyles?: any;
};

const getControlStyle = (sizing: Sizing) => {
  if (sizing === "sm") {
    return {
      fontSize: "12px",
      lineHeight: "24px",
      minHeight: 32,
      padding: 0,
    };
  }
  if (sizing === "md") {
    return {
      fontSize: "14px",
      lineHeight: "24px",
      minHeight: 32,
      padding: 2,
    };
  }
};

const getOptionStyle = (sizing: Sizing) => {
  if (sizing === "sm") {
    return {
      fontSize: "12px",
      lineHeight: "24px",
    };
  }
  if (sizing === "md") {
    return {
      fontSize: "14px",
      lineHeight: "24px",
    };
  }
};

const getLabelClass = (sizing: Sizing) => {
  if (sizing === "sm") {
    return "block mb-2 text5 text-primary-02";
  } else if (sizing === "md") {
    return "block mb-2 text2 text-primary-02";
  } else if (sizing === "lg") {
    return "block mb-2 text2 text-primary-02";
  }
};

const getStyles = ({
  containerStyle,
  style,
  secondary,
  sizing,
  hoverStyle,
  menuStyles,
}: {
  containerStyle: any;
  style: any;
  secondary?: boolean;
  sizing: Sizing;
  hoverStyle?: any;
  menuStyles?: any;
}) => {
  return {
    container: (base: any) => ({
      ...base,
      ...containerStyle,
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 99999 }),
    control: (base: any, state: any) => ({
      ...base,
      padding: !style && "4px",
      border: "1px solid #E5E7EB;",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E5E7EB;",
        ...hoverStyle,
      },
      whiteSpace: "nowrap",
      cursor: "pointer",
      backgroundColor: secondary ? "rgba(229, 231, 235, 0.5)" : "#fff",
      ...getControlStyle(sizing),
      ...style,
    }),
    option: (base: any, state: any) => ({
      ...base,
      cursor: "pointer",
      borderRadius: 8,
      color: state.isSelected ? "#0E1227" : "rgba(100, 102, 114, 0.9)",
      marginBottom: 4,
      ...getOptionStyle(sizing),
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 10000,
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 8,
      zIndex: 100,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#646672",
        borderRadius: 16,
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
};

const SelectInput = React.forwardRef<HTMLSelectElement, Props>(
  ({
    placeholder,
    value,
    label,
    name,
    options,
    defaultValue,
    style,
    type,
    onCountrySelect,
    onChange,
    sizing = "lg",
    containerStyle,
    secondary,
    setValue,
    error,
    labelStyle,
    isSearchable = false,
    hoverStyle,
    menuStyles,
    ...props
  }: Props) => {
    React.useEffect(() => {
      if (defaultValue?.value) {
        setValue?.(name, defaultValue?.value);
      }

      // eslint-disable-next-line
    }, [defaultValue]);

    if (type === "country") {
      return (
        <div className="flex-col">
          <label style={{ ...labelStyle }} className={getLabelClass(sizing)}>
            {label}
          </label>
          <ReactFlagsSelect
            className="country-select"
            selectButtonClassName={
              value ? "country-select-button" : "unselected-select-button"
            }
            selected={String(value) || ""}
            onSelect={(code) => onCountrySelect && onCountrySelect(code)}
          />
        </div>
      );
    } else if (type === "creatable") {
      return (
        <div className="flex-col w-full">
          {label && (
            <label style={{ ...labelStyle }} className={getLabelClass(sizing)}>
              {label}
            </label>
          )}
          <CreatableSelect
            value={value ? { label: value, value: value } : undefined}
            name={name}
            defaultValue={defaultValue}
            // isSearchable={true}
            placeholder={placeholder}
            menuPlacement="auto"
            // menuPosition="fixed"
            options={options}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            styles={getStyles({
              containerStyle,
              style,
              secondary,
              sizing,
              hoverStyle,
              menuStyles,
            })}
            theme={(theme) => ({
              ...theme,
              borderRadius: 8,
              colors: {
                ...theme.colors,
                primary: "rgba(100, 102, 114, 0.1)",
                primary25: "rgba(100, 102, 114, 0.1)",
                primary50: "rgba(100, 102, 114, 0.2)",
                primary75: "rgba(100, 102, 114, 0.1)",
              },
            })}
            className="w-full bg-white rounded cursor-pointer outline-0 text-grey-02"
            onChange={onChange}
            {...props}
          />
          {error && (
            <p className="mt-0 text2 text-red-01 text5">{error.message}</p>
          )}
        </div>
      );
    }

    return (
      <div className="flex-col min-w-[145px]">
        {label && (
          <label style={{ ...labelStyle }} className={getLabelClass(sizing)}>
            {label}
          </label>
        )}
        <Select
          value={
            props.isMulti
              ? value
              : options?.find((item: any) => item.value === value)
          }
          name={name}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          placeholder={placeholder}
          menuPlacement="auto"
          // menuPosition="fixed"
          options={options}
          components={{
            IndicatorSeparator: () => null,
          }}
          styles={getStyles({
            containerStyle,
            style,
            secondary,
            sizing,
            hoverStyle,
            menuStyles,
          })}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: "rgba(100, 102, 114, 0.1)",
              primary25: "rgba(100, 102, 114, 0.1)",
              primary50: "rgba(100, 102, 114, 0.2)",
              primary75: "rgba(100, 102, 114, 0.1)",
            },
          })}
          className="w-full bg-white rounded cursor-pointer outline-0 text-grey-02"
          onChange={onChange}
          {...props}
        />
        {error && (
          <p className="mt-0 text2 text-red-01 text5">{error.message}</p>
        )}
      </div>
    );
  }
);

export default SelectInput;

export const SIGNUP = "SIGNUP";
export const LOGIN = "LOGIN";
export const CREATION_PAGE_ENTERED = "ENTER CREATION PAGE";
export const CREATION = "CREATION";
export const SAVE_CREATION = "SAVE CREATION";
export const FAVORITE = "FAVORITE";
export const EXTEND_TEXT = "EXTEND TEXT";
export const COPY = "COPY CREATION";
export const VIDEO_GUIDE_VISIT = "VISIT VIDEO GUIDE";
export const INVITE_FRIENDS = "INVITE FRIENDS";
export const PRICING_PAGE_VISIT = "PRICING PAGE VISIT";
export const UPGRADE = "UPGRADE";

import React from "react";
import Modal from "react-modal";
import { ReactComponent as CloseButton } from "../assets/icons/close_button.svg";
import useInviteFriends from "../hooks/useInviteFriends";
import { InviteFriendsForm } from "../screens/dashboard/settings/inviteFriends";

type Props = {
  showModal: boolean;
  closeModal: () => void;
};

type IFormInputs = {
  name: string;
  email: string;
};

const InviteFriendsModal = ({ showModal, closeModal }: Props) => {
  const { sendInvite, inviteState } = useInviteFriends();
  const modalStyles = { overlay: { zIndex: 100000 } };

  const sendInviteToFriend = async (data: IFormInputs) => {
    await sendInvite(data);
    closeModal();
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={showModal}
      className="relative z-30 flex items-center w-full h-full m-auto bg-black-rgba"
    >
      <div className="w-[720px] p-6 rounded shadow flex flex-col m-auto bg-white ">
        <div className="flex justify-between items-center">
          <h1 className="h4 text-primary-02">Invite your friends</h1>
          <CloseButton onClick={closeModal} className="cursor-pointer" />
        </div>
        <InviteFriendsForm
          sendInvite={sendInviteToFriend}
          inviteState={inviteState}
        />
      </div>
    </Modal>
  );
};

export default InviteFriendsModal;

import React from "react";
import Paraphrase from "./paraphrase";
import Creative from "../../../assets/icons/creative";
import Expand from "../../../assets/icons/expand";
import Fluency from "../../../assets/icons/fluency";
import Formal from "../../../assets/icons/formal";
import Smile from "../../../assets/icons/smile";
import Star from "../../../assets/icons/star";
import Summarize from "../../../assets/icons/summarize";

const rewriteOptions = [
  {
    icon: Star,
    title: "Rewrite",
    template_name: "paraphrase",
  },
  {
    icon: Fluency,
    title: "Fluency",
    template_name: "fluency",
  },

  {
    icon: Formal,
    title: (
      <span className="flex items-center text-center">
        <p className="text4 text-primary-02 ml-3">Tone Changer</p>
      </span>
    ),
    type: "select",
    options: [
      { value: "Cheerful", label: "🤗   Cheerful" },
      { value: "Decisive", label: "🤔 Decisive" },
      { value: "Casual", label: "👕 Casual" },
      { value: "Encouraging", label: "💪 Encouraging" },
      { value: "Formal", label: "👨🏼‍💼 Formal" },
      { value: "Gentle", label: "😎 Gentle" },
      { value: "Friendly", label: "😀 Friendly" },
      { value: "Detailed", label: "🛥 Detailed" },
      { value: "Respectful", label: "💂‍♀️ Respectful" },
      { value: "Concise", label: "📜 Concise" },
      { value: "Warm", label: "🔥   Warm" },
      { value: "Blunt", label: "🗣 Blunt" },
      { value: "Urgent", label: "🚑 Urgent" },
    ],
    template_name: "change_tone",
  },
  {
    icon: Smile,
    title: "Simple",
    template_name: "simplify",
  },
  // {
  //   icon: Shorten,
  //   title: "Shorten",
  //   template_name: "shorten",
  // },
  {
    icon: Creative,
    title: "Creative",
    template_name: "creative",
  },
  {
    icon: Expand,
    title: "Expand",
    template_name: "expand",
  },
  {
    icon: Summarize,
    title: "Summarize",
    template_name: "summarize",
  },
];

const ToneChanger = () => {
  return <Paraphrase page="change_tone" rewriteTemplates={rewriteOptions} />;
};

export default ToneChanger;

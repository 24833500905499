import { toast } from "react-toastify";
import axiosClient, { getRequest, postRequest } from "../../../axios";
import { Product } from "../../../types";
import _ from "lodash";
import { htmlToText } from "../../../utils/htmlToText";
import { SET_USER } from "../../../context/auth/actions";

export const searchProducts = async (data: {
  query: string;
  first: number;
  after: string | null;
}): Promise<any> => {
  let imageIdObj: any = {};
  try {
    const res = await axiosClient({
      method: "post",
      url: "/shopify/search_products/",
      data: data,
    });
    const products: Product[] = res.data.payload.products.edges.map(
      (item: any) => {
        imageIdObj[item.node.title] = item.node.featuredImage?.url;
        return {
          title: item.node.title,
          id: item.node.id,
          image: item.node.featuredImage?.url,
          productName: item.node.handle,
          description: item.node.description,
          totalInventory: item.node.totalInventory,
        };
      }
    );
    return {
      products,
      pageInfo: {
        ...res.data.payload.products.pageInfo,
        totalItems: res.data.payload.products_count,
      },
    };
  } catch (error) {
    console.log(error);
  }
};

export const generateTextAI = async (data: any, url?: string) => {
  try {
    const res = await axiosClient({
      method: "POST",
      url: url || "/ai_v3/generate_text",
      data,
    });
    if (res.data.generated_text?.length === 0) {
      throw new Error("No text generated");
    }
    return {
      text: res.data.generated_text,
      credits: res.data.remaining_requests,
    };
  } catch (error) {
    if (error) {
      toast.error("Oops! Can you hit create again? I got lost in my thoughts…");
      throw new Error(JSON.stringify(error));
    }
  }
};

export const fetchGenerationPrompts = async (generationId: string) => {
  try {
    const res = await axiosClient({
      method: "POST",
      url: "/history_v2/generated_texts",
      data: {
        generation_id: generationId,
      },
    });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const saveToShopify = async (data: {
  product_id: string;
  description: string;
}) => {
  data.product_id = data.product_id.replace("gid://shopify/Product/", "");
  toast.loading(`Saving description to shopify. \n Please wait...`);
  try {
    const res = await axiosClient({
      method: "POST",
      url: "/shopify/upload_description",
      data,
    });
    toast.dismiss();
    toast("🎉 Description saved to shopify!");
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.error(`Failed to save description. Please try again.`);
  }
};

export const saveDocument = async (
  data: any,
  isUpdate?: boolean,
  updateType?: undefined | "hidden"
) => {
  updateType !== "hidden" &&
    toast.loading(
      `${isUpdate ? "Updating" : "Saving"} Document. Please wait...`
    );

  if (isUpdate && !data.document_name) {
    data = _.omit(data, "document_name");
  }

  if (isUpdate && updateType === "hidden" && data.document_data?.length === 0) {
    return;
  }

  try {
    const res = await axiosClient({
      method: isUpdate ? "PUT" : "POST",
      url: isUpdate
        ? "/documents/update_document"
        : "/documents/save_new_document",
      data: {
        ...data,
        status: isUpdate ? "Ready To Publish" : "Draft",
      },
    });
    toast.dismiss();
    updateType !== "hidden" &&
      toast(`🎉 Document ${isUpdate ? "updated" : "saved"}!`);
    return res;
  } catch (error) {
    console.log(error, "here");
    toast.dismiss();
    updateType !== "hidden" &&
      toast.error(
        `Failed to ${isUpdate ? "update" : "save"} document. Please try again.`
      );
  }
};

export const addToFavorites = async (data: any) => {
  toast.loading(`Adding to favorites. Please wait...`);
  try {
    const res = await axiosClient({
      method: "POST",
      url: "/history/save_to_favourites",
      data,
    });
    toast.dismiss();
    toast("🎉 Document saved to favorites!");
    return res;
  } catch (error) {
    console.log(error);
    toast.dismiss();
    toast.error(`Failed to save document. Please try again.`);
  }
};

export const getDocument = async (document_id: string) => {
  try {
    const res = await getRequest(`documents/view/?document_id=${document_id}`);
    return res;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const fetchReadabilityScore = async (data: any) => {
  try {
    const res = await postRequest("text_analytics/get_readability_score", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const copyOnSave = async (generationId?: string) => {
  if (generationId) {
    try {
      const res = await postRequest("ai_v3/update_generated_text", {
        copied: 1,
        favourite: 1,
        generation_id: generationId,
        good_quality: 1,
        bad_quality: 1,
        offensive: 1,
        doesnt_make_sense: 0,
      });
    } catch (err) {
      console.log(err);
    }
  }
};

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import Button from "../../../components/button";
import Input from "../../../components/input";
import SocialAuthButton from "../../../components/socialAuthButton";
import { retrieveAccount } from "../../../context/auth/actions";
import { useAuth } from "../../../context/auth/authContext";
import useRecaptcha from "../../../hooks/useRecaptcha";

const RetrieveAccount = () => {
  const [email, setEmail] = useState("");
  const { state, dispatch } = useAuth();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { token: captchaToken, handleReCaptchaVerify } = useRecaptcha();

  const submitForm = async () => {
    const token = await handleReCaptchaVerify();
    const isDone = await retrieveAccount(
      { email, token: token || "" },
      dispatch
    );
    isDone && setIsEmailSent(true);
  };

  const openGmail = () => {
    window.open("http://mail.google.com", "_blank");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Retrieve Account</title>
      </Helmet>

      <div className="p-4 md:p-0 m-auto mt-32 max-w-[456px]">
        <h1 className="h1 text-center text-primary-02">
          Retrieve your account
        </h1>
        {!isEmailSent && (
          <p className="mb-6 text-center text2 text-grey-02">
            Please enter your email address to get password reset instructions.{" "}
          </p>
        )}
        {isEmailSent ? (
          <p className="text-center my-4 text-center text2 text-grey-02">
            A password reset link has been sent to your email!
          </p>
        ) : (
          <Input
            type="email"
            label="Email address"
            name="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        )}
        <div className="mt-10">
          {!isEmailSent ? (
            <Button onClick={submitForm} loading={state.submittingForm}>
              Submit
            </Button>
          ) : (
            <div className="flex flex-col items-center">
              <SocialAuthButton
                type="google"
                text={"Open Gmail"}
                onClick={openGmail}
              />
              <p className="cursor-pointer text2 text-grey-02 flex items-center ">
                <RefreshIcon />
                <span onClick={() => setIsEmailSent(false)} className="ml-3">
                  Send code again
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RetrieveAccount;

import { PlanType } from "../types";
import { planType } from "./helpers";

export const TEXT_LENGTH = "TEXT_LENGTH";

export const isUserEligibleForFeature = ({
  feature,
  user,
}: {
  feature: PlanType;
  user: PlanType;
}) => {
  if ((!user || user === planType.Free) && feature === planType.Free)
    return true;
  else if (
    user === planType.Pro &&
    (feature === planType.Free || feature === planType.Pro)
  )
    return true;
  else if (
    user === planType.Business &&
    (feature === planType.Free ||
      feature === planType.Pro ||
      feature === planType.Business)
  )
    return true;

  return false;
};

import { IconProps } from "../../types";
import React from "react";

const Star = (props: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1.44077L9.61201 5.79717C9.7133 6.07089 9.92911 6.2867 10.2028 6.38799L14.5592 8L10.2028 9.61201C9.92911 9.7133 9.7133 9.92911 9.61201 10.2028L8 14.5592L6.38799 10.2028C6.2867 9.92911 6.07089 9.7133 5.79717 9.61201L1.44077 8L5.79717 6.38799C6.07089 6.2867 6.2867 6.07089 6.38799 5.79717L8 1.44077Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
      />
    </svg>
  );
};

export default Star;

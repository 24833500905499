import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../axios";
import { useAuth } from "../context/auth/authContext";
import moment from "moment";
import { analytics } from "../utils/analytics";
import { INVITE_FRIENDS } from "../constants/analytics";

type LoadingState = "loading" | "success" | "error" | "idle";

export type InviteHistory = {
  email: string;
  name: string;
  last_name: string;
  date: string;
  signed_up: boolean;
};

type HistoryState = {
  loadingState: LoadingState;
  history: InviteHistory[];
};

type IFormInputs = {
  name: string;
  email: string;
};

const useInviteFriends = () => {
  const [inviteState, setInviteState] = useState<LoadingState>("idle");
  const {
    state: { user },
  } = useAuth();
  const [inviteHistoryState, setInviteHistory] = useState<HistoryState>({
    history: [],
    loadingState: "loading",
  });

  const [copyToolTip, setCopyToolTip] = useState<{
    show: boolean;
    text?: string;
  }>();

  useEffect(() => {
    fetchInviteHistory();
  }, []);

  const fetchInviteHistory = async () => {
    try {
      setInviteHistory((prev: any) => ({
        ...prev,
        loadingState: "loading",
      }));
      const res = await getRequest("invite/history");
      if (res.data.status === "success") {
        setInviteHistory((prev: any) => ({
          history: res.data.result.reverse(),
          loadingState: "success",
        }));
      }
    } catch (error) {
      setInviteHistory((prev) => ({
        ...prev,
        loadingState: "error",
      }));
      console.log("eeror: ", error);
    }
  };

  const sendInvite = async (data: IFormInputs) => {
    setInviteState("loading");
    try {
      const res = await postRequest("invite/send", {
        email: data.email,
        name: data.name,
        last_name: "",
      });
      if (res.data.status === "success") {
        setInviteState("success");
        toast.dismiss();
        toast.success(
          "Invite sent successfully! You will earn creations when the user signs up!"
        );
        analytics(INVITE_FRIENDS, {
          inviteFrom: data.email,
          user: user?.email,
        });
        setInviteHistory((prev) => ({
          ...prev,
          history: [
            {
              date: moment(new Date()).format("MMM DD,YYYY").toString(),
              email: data.email,
              name: data.name,
              last_name: "Hold up",
              signed_up: false,
            },
            ...prev.history,
          ],
        }));
      } else {
        setInviteState("error");

        toast.error("Something went wrong!");
      }
    } catch (error: any) {
      setInviteState("error");
      if (error.response.data.error === 11) {
        toast.error("You already invited this user before.");
      } else if (error.response.data.error === 406) {
        toast.error("Person you are trying to invite has already registered.");
      } else if (error.response.data.error === 403) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong!");
      }
      console.log("error: ", error);
    }
  };

  const onCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
    } catch (error) {
      console.log("Copy failed:", error);
    }
  };

  return {
    onCopy,
    sendInvite,
    fetchInviteHistory,
    inviteState,
    inviteHistoryState,
    copyToolTip,
    setCopyToolTip,
  };
};

export default useInviteFriends;

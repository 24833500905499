import React, { LegacyRef, useEffect, useReducer, useRef } from "react";
import { useAuth } from "../auth/authContext";
import { ChatHistory, initialState, Message, reducer } from "./reducer";
import {
  fetchChatHistory,
  fetchChatResponse,
  GetUnlimitedButton,
  streamChatResponse,
} from "./utils";

let abortController = new AbortController();

export const useChat = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const auth = useAuth();
  const messageListRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const fetchHistory = async () => {
    try {
      const conversation_id = await localStorage.getItem(
        "cortex_conversation_id"
      );
      if (conversation_id) {
        dispatch({
          type: "SET_LOADING_STATUS",
          payload: { status: "loading_history" },
        });
        const history = await fetchChatHistory({
          signal: abortController.signal,
          conversation_id,
        });
        dispatch({
          type: "SET_HISTORY",
          payload: {
            conversationId: history.data.data.conversationId,
            history: history.data.data.history as ChatHistory[],
          },
        });
        dispatch({
          type: "SET_LOADING_STATUS",
          payload: { status: "idle" },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_LOADING_STATUS",
        payload: { status: "error" },
      });
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [state.messages]);

  useEffect(() => {
    if (state.messages.length === 0) {
      fetchHistory();
    }
  }, [state.messages.length]);

  const scrollToBottom = () => {
    messageListRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });
  };

  const submitForm = (text: string) => {
    const isTyping = state.loadingStatus.status === "typing";
    const isEmpty = text.trim() === "";
    if (!isTyping && !isEmpty) {
      sendMessage(text);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      // dispatch({
      //   type: "SHOW_EXAMPLES",
      //   payload: false,
      // });
    }
  };
  const createNewChat = () => {
    dispatch({
      type: "START_NEW_CHAT",
      payload: {
        messages: [],
      },
    });
  };

  const sendMessage = async (text: string) => {
    // abortController.abort();
    // abortController = new AbortController();
    try {
      dispatch({
        type: "ADD_MESSAGE",
        payload: {
          text: text,
          messageType: "sent",
        },
      });
      // setTimeout(() => {
      dispatch({
        type: "SET_LOADING_STATUS",
        payload: {
          status: "typing",
        },
      });
      // }, 500);
      const res = await fetchChatResponse({
        text,
        conversationId: state.conversation?.id,
        language: auth.language,
        augment: state.enableWebSearch,
      });

      const isSuccess = res?.data?.status === "success";
      if (isSuccess) {
        const textData = res?.data.data.outputs[0];
        dispatch({
          type: "ADD_MESSAGE",
          payload: {
            ...textData,
            messageType: "received",
          },
        });
        if (auth.state.user?.plan_type === "Free")
          auth.dispatch({
            type: "SET_USER",
            payload: {
              user: {
                ...auth.state.user,
                credit:
                  auth.state.user.credit === 0 ? 0 : auth.state.user.credit - 1,
              },
            },
          });
        scrollToBottom();
      }
    } catch (error: any) {
      abortController.abort();
      if (error.message === "canceled") return;
      else {
        dispatch({
          type: "SET_LOADING_STATUS",
          payload: {
            status: "error",
          },
        });
        switch (error.response?.data?.error) {
          case 402: {
            dispatch({
              type: "ADD_MESSAGE",
              payload: {
                text: GetUnlimitedButton,
                messageType: "received",
              },
            });
            break;
          }
          default: {
            dispatch({
              type: "ADD_MESSAGE",
              payload: {
                text: "That went straight over my head. Please check your network and try again!\n\nIf I keep doing this please contact my creators.",
                messageType: "received",
              },
            });
          }
        }
      }
      console.error("error: ", error);
    }
  };

  const setMessageAsCurrentlyPlaying = ({ message }: { message: Message }) => {
    dispatch({
      type: "SET_CURRENTLY_PLAYING_MESSAGE",
      payload: message,
    });
  };

  return {
    state,
    dispatch,
    submitForm,
    setMessageAsCurrentlyPlaying,
    inputRef,
    messageListRef,
    createNewChat,
  };
};

interface DocumentCategory {
  title: string;
  themeColor: string;
  themeBg: string;
}

const documentCategories: {
  [key: string]: DocumentCategory;
} = {
  auto_complete: {
    title: "AI text editor",
    themeColor: "#6775F8",
    themeBg: "rgba(103, 117, 248, 0.12)",
  },
  product_description: {
    title: "Product Description",
    themeColor: "#7B8B95",
    themeBg: "rgba(129, 156, 171, 0.12)",
  },
  paraphrase: {
    title: "Rewrite",
    themeColor: "#FA675C",
    themeBg: "rgba(250, 103, 92, 0.12)",
  },
  blog_body: {
    title: "Blog Writing",
    themeColor: "#0A8BFE",
    themeBg: "rgba(10, 139, 254, 0.12)",
  },
  blog_outline: {
    title: "Blog Wizard",
    themeColor: "#7B8B95",
    themeBg: "rgba(129, 156, 171, 0.12)",
  },
  ads_copy: {
    title: "Marketing Copies",
    themeColor: "#09A5F5",
    themeBg: "rgba(9, 165, 245, 0.12)",
  },
  shopify_description: {
    title: "Shopify Description",
    themeColor: "#7DBA36",
    themeBg: "rgba(125, 186, 54, 0.12)",
  },
  customer_call_request_email: {
    title: "Outreach Emails",
    themeColor: "#FA675C",
    themeBg: "rgba(250, 103, 92, 0.12)",
  },
  customer_support_email: {
    title: "Support Emails",
    themeColor: "#0A8BFE",
    themeBg: "rgba(10, 139, 254, 0.12)",
  },
  youtube_captions: {
    title: "Youtube Description",
    themeColor: "#FA675C",
    themeBg: "rgba(250, 103, 92, 0.12)",
  },
  instagram_caption: {
    title: "Instagram Description",
    themeColor: "#6A67F8",
    themeBg: "rgba(106, 103, 248, 0.12)",
  },
  blog_title: {
    title: "Blog Title",
    themeColor: "#26BFC9",
    themeBg: "rgba(38, 191, 201, 0.12)",
  },
  email_subject: {
    title: "Email Subject",
    themeColor: "rgba(38, 191, 201, 0.9)",
    themeBg: "rgba(38, 191, 201, 0.1)",
  },
  general_email: {
    title: "Email",
    themeColor: "#0A8BFE",
    themeBg: "rgba(10, 139, 254, 0.12)",
  },
};

export const getDocumentCategory = (documentType: string) => {
  return documentCategories[documentType];
};

export const getAllDocumentCategories = () => {
  return Object.keys(documentCategories).map((item) => ({
    label: documentCategories[item].title,
    value: item,
  }));
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./axios";
import App from "./App";

import TagManager from "react-gtm-module";
import { tagManagerArgs } from "./config/config";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://decc20544192488b9ea14c201880db37@o927671.ingest.sentry.io/5877143",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);

import React from "react";
import { ReactComponent as LogoSmall } from "../../assets/icons/logo-small.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-close.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { BannerTypes, ChatBanners } from "../../context/chat/reducer";

const sampleMessages = [
  "Compose a love song in the style of Robert Frost.",
  "Write a Romantic Letter to Gertrude.",
  "Convince me to invest in a Sandcastle Hotel.",
];

type Props = {
  showBanners: ChatBanners;
  toggleBanners: (val: BannerTypes) => void;
  onSend: (text: string) => void;
  sidebar?: boolean;
};

const SampleMessagesBanner = ({
  showBanners,
  toggleBanners,
  onSend,
  sidebar,
}: Props) => {
  if (showBanners.sampleMessages)
    return (
      <div className="w-full bg-white  im:border-[1px] im:p-4  rounded">
        <div className="flex justify-between">
          <div className="flex">
            <LogoSmall />
            {sidebar ? (
              <p className="h2 text-primary-02 ml-4">
                Try these example prompts below.
              </p>
            ) : (
              <p className="h2 text-primary-02 ml-4">
                Hello there, I am Zeno. How can I help you today?
              </p>
            )}
          </div>
          <CloseIcon
            onClick={() => toggleBanners("sampleMessages")}
            className="cursor-pointer hidden im:block"
          />
        </div>
        <div
          className={`flex mt-3 ${
            sidebar ? "flex-col" : "im:flex-row"
          } flex-col  im:mt-4`}
        >
          {sampleMessages.map((message) => (
            <div
              onClick={() => onSend(message)}
              className={`rounded ${
                sidebar && "shadow"
              } border mb-3 im:mr-4 cursor-pointer p-2 flex justify-between items-center`}
            >
              <p className="text text4 text-grey-02 mr-1">{message}</p>
              <ChevronRight />
            </div>
          ))}
        </div>
      </div>
    );
  return null;
};

export default SampleMessagesBanner;

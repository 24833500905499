import React, { useState } from "react";
import PricingCard from "./pricingCard";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MarketingImg } from "../assets/icons/marketing.svg";
import { ReactComponent as Icons } from "../assets/icons/icons-box.svg";
import { useMediaQuery } from "react-responsive";

const pricingOptions = [
  {
    title: "BUSINESS",
    description: "For power users who work with content all day, every day.",
    creations: "Unlimited creations at",
    perkTitle: "Everything in PRO, plus:",
    perks: [
      "Unlimited creations",
      "up to 300 words creations at a time",
      "Creator Suite: 60+ Creation Templates",
      "Zeno Mode - personal writing assistant",
      "WebApp AI text editor",
      "12+ more Features",
    ],
    buttons: ["Buy Now"],
    guarantee: "Money back guarantee for 5 days",
    value: "Business",
  },
  {
    title: "PRO",
    description: "For individuals and businesses who create content regularly.",
    creations: "Unlimited creations at",
    perkTitle: "Everything in Free, plus:",
    perks: [
      "Unlimited creations",
      "Creation and translation in 10+ Languages",
      "up to 200 word creations at a time",
      "Summarizer",
      "Tone changer",
      "5+ more Features",
    ],
    buttons: ["Buy Now"],
    guarantee: "Money back guarantee for 5 days",
    value: "Pro",
  },
  // {
  //   title: "FREE",
  //   description:
  //     "Use all of our core features on a daily limit. Ideal for creators, marketers and students.",
  //   creations: "10 Creations per day at",
  //   perkTitle: "What you get with FREE:",
  //   perks: [
  //     "10 Creations per day",
  //     "ZenoChat Feature",
  //     "Rewriting & Rephrasing",
  //     "Extend your text",
  //     "Email writer",
  //     "No credit card required",
  //   ],
  //   buttons: ["Start For Free"],
  //   guarantee: "Money back guarantee for 5 days",
  //   value: "Free",
  //   extraContentType: "earnMore",
  // },
];

type Props = {
  onSelect: (val: "Free" | "Business" | "Pro") => void;
  currentPlan?: "Free" | "Business" | "Pro";
  planDuration?: "monthly" | "yearly";
  handlePlanDurationChange: () => void;
  plans?: any;
  currency: string;
};

const Pricings = ({
  planDuration,
  handlePlanDurationChange,
  onSelect,
  currentPlan,
  plans,
  currency,
}: Props) => {
  const [isToggled, setIsToggled] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 940px)" });

  const handleToggleClick = () => {
    setIsToggled(!isToggled);
    handlePlanDurationChange();
  };
  const navigate = useNavigate();

  return (
    <div className="flex flex-col text-center mx-auto w-full px-[3%]">
      <div className="flex grid-cols-2 gap-[30px] m-auto justify-center  flex-wrap md:h-[90px]">
        <div className="flex items-end">
          <MarketingImg className="w-[250px] h-[54px] bottom-0" />
        </div>
        <Icons className="w-[259px] h-[82px] flex items-end" />
      </div>
      <div>
        <div className="flex w-fit sm:max-w-[368px] h-12 mx-auto gap-2 text-center justify-center items-center mt-[98px] bg-[#f2f3f5] py-3 px-6 rounded text-sm-SemiBold text-grey-002">
          <span className={`${!isToggled && "text-grey-003"}`}>Monthly</span>

          <button
            className={`w-[36px] h-[20px] min-w-[36px] rounded-full border border-gray-500 flex items-center  ${
              isToggled ? "bg-brand-02" : "bg-grey-07"
            } relative`}
            onClick={handleToggleClick}
          >
            <span
              className={`bg-white rounded-full w-[16px] h-[16px] mr-0.5 absolute transition-left-300 duration-300 ${
                isToggled ? "right-0" : "left-0"
              }`}
            />
          </button>

          <span className={`${isToggled && "text-grey-003"}`}>Yearly</span>
          <div className="bg-pink-gradient-2 bg-contain p-0.5 rounded-xl min-w-max ">
            <div className="bg-white rounded-xl">
              <p className="text-sm-Medium w-full  brand-gradient-text px-4">
                Get 40% off
              </p>
            </div>
          </div>
        </div>
        <div
          className={`flex w-full m-auto justify-center gap-2 my-12 max-w-[1360px] p-6 ${
            isMobile && "flex-wrap "
          }`}
        >
          {pricingOptions.map((item: any) => {
            const itemPlanName = item.value.toLowerCase();
            const plan = plans[itemPlanName];

            return (
              <div
                className={`flex rounded-xl min-w-[288px] h-auto im:w-1/2 md:w-[48%]  ${
                  item.title === "BUSINESS" ? "bg-brand p-0.5 " : "border "
                } ${isMobile ? "border-2 w-screen  " : "w-auto"}`}
              >
                <PricingCard
                  planDuration={planDuration}
                  currency={currency}
                  price={planDuration && plan ? plan[planDuration] : 0}
                  onSelect={async () => {
                    if (item.value === "Free") navigate("/user/dashboard");
                    else {
                      await onSelect(item.value as any);
                    }
                  }}
                  isSelected={item.value === currentPlan}
                  key={item.title}
                  {...item}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Pricings;

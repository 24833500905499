import React from "react";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as MessageIcon } from "../../assets/icons/message.svg";
import { ChatHistory, LoadingStatus } from "../../context/chat/reducer";

type Props = {
  status: LoadingStatus;
  history: { conversationId: string; history: ChatHistory[] };
  dispatch: any;
};

const History = ({ status, history, dispatch }: Props) => {
  const onSelect = (message: ChatHistory) => {
    dispatch({
      type: "ADD_MESSAGE",
      payload: {
        text: message.input,
        messageType: "sent",
        conversation_id: history.conversationId,
      },
    });
    dispatch({
      type: "ADD_MESSAGE",
      payload: {
        text: message.output,
        messageType: "received",
        conversation_id: history.conversationId,
      },
    });
  };

  return (
    <div className="flex flex-col my-4 ml-4 pb-8">
      <div className="flex flex-col mt-4">
        {status.status === "loading_history" ? (
          <Skeleton
            style={{ marginBottom: 10, marginRight: 32, borderRadius: 10 }}
            containerClassName="overflow-hidden flex  w-full max-w-[100%] flex-wrap"
            width={300}
            height={30}
            count={8}
            direction="ltr"
          />
        ) : (
          history.history.map((item: ChatHistory) => {
            return (
              <div
                onClick={() => onSelect(item)}
                className="flex justify-start  hover:bg-grey-06 w-[94%] max-w-full rounded px-2 py-1 items-center mb-4 cursor-pointer"
              >
                <MessageIcon className="mr-2 left-0" />
                <p className="text2 text-grey-02 w-full truncate pr-2">
                  {item.input}
                </p>
              </div>
            );
          })
        )}
        {/* {status.status === "idle" && history.history.length > 10 && (
          <div className="flex items-center justify-center">
            <button className="bg-white py-1 rounded border text2 px-2 text-grey-02">
              Show more
            </button>
          </div>
        )} */}
        {history.history.length === 0 &&
          status.status !== "loading_history" && (
            <p className="top-0 text-center text2 text-grey-02 max-w-full pr-2">
              No chat history
            </p>
          )}
      </div>
    </div>
  );
};

export default History;

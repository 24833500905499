import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/spinner";
import { ENTER_DASHBOARD, fetchUser } from "../../context/auth/actions";
import { useAuth } from "../../context/auth/authContext";
import { defaultEditorRoute } from "../../utils/helpers";
import { setAccessToken } from "../../utils/setAccessToken";

const AuthVerify = () => {
  const { dispatch } = useAuth();
  const { accessToken, extension } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUrlAndRedirect = async () => {
      await setAccessToken({ accessToken: accessToken || "" });
      const user = await fetchUser();
      if (user && user.onboarded === false) {
        await localStorage.removeItem("cortexUnverifiedUser");
        user.registration_source === "chrome_extension"
          ? navigate("/extension/onboarding")
          : navigate("/user/onboarding");
        return;
      }
      if (user && user.onboarded === true) {
        dispatch({
          type: ENTER_DASHBOARD,
          payload: { user },
        });
      }
      navigate(defaultEditorRoute);
    };
    checkUrlAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, extension, navigate]);

  return (
    <div className="w-[100vw] h-[100vh] m-auto flex justify-center items-center">
      <Spinner primaryColor />
    </div>
  );
};

export default AuthVerify;

import React, { ReactNode } from "react";
import Spinner from "./spinner";
import { ReactComponent as SaveIcon } from "../assets/icons/file-text.svg";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children?: React.ReactNode;
  loading?: boolean;
  size?: "large" | "medium" | "small";
  type?: "submit" | "button";
};

const Button: React.FC<Props> = ({
  children,
  size = "large",
  className,
  loading = false,
  ...props
}) => {
  return (
    <button
      disabled={loading}
      {...props}
      className={`h2 text-white outline-none bg-brand-02 w-full px-4 rounded whitespace-nowrap ${
        size === "large" ? "max-w-[456px] py-3" : "max-w-[200px] py-2"
      } ${className}`}
    >
      {loading ? <Spinner small /> : children}
    </button>
  );
};

export const ButtonSecondary: React.FC<Props> = ({
  size = "small",
  ...props
}) => {
  return (
    <button
      {...props}
      className={`text2 text-grey-01 border border-grey-03 rounded  whitespace-nowrap px-[11.5px] bg-white  $${
        size === "large" ? "max-w-[456px] py-3" : "max-w-[200px] py-1"
      } ${props.className}`}
    >
      {props.loading ? <Spinner small /> : props.children}
    </button>
  );
};

type SaveBtnProps = Props & {
  title: string;
};

export const SaveButton = ({ title, ...props }: SaveBtnProps) => {
  return (
    <Button
      {...props}
      className={`mr-6 flex items-center h-[42px] px-4 bg-primary-03 whitespace-nowrap border-[0.2px] border-primary-04 rounded justify-center ${
        props.className
      } ${
        props.disabled
          ? "opacity-60 cursor-default"
          : "opacity-100 cursor-pointer"
      }`}
    >
      <SaveIcon className="block" />
      <p className="ml-2 text2 text-primary-01">{title}</p>
    </Button>
  );
};

type FileInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  children: ReactNode;
};

export const UploadButton = ({
  containerProps,
  children,
  ...props
}: FileInputProps) => {
  return (
    <div
      {...containerProps}
      className={`relative inline-block mb-4 overflow-hidden whitespace-nowrap cursor-pointer ${props.className}`}
    >
      <input
        type="file"
        {...props}
        className="absolute top-0 bottom-0 left-0 h-full text-4xl opacity-0 cursor-pointer"
      />
      <button className="px-2 py-1 border rounded text2 text-grey-02">
        {children}
      </button>
    </div>
  );
};

export default Button;

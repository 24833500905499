import React from "react";
import { IconProps } from "../../types";

const Creative = (props: IconProps) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5898 8.79638C13.7 8.31283 13.7535 7.81809 13.7492 7.32216C13.7226 3.80927 10.814 0.967085 7.30779 1.00029C5.80989 1.01608 4.36539 1.55886 3.22773 2.53338C2.09007 3.50789 1.33192 4.85191 1.0863 6.32962C0.840672 7.80733 1.12326 9.32435 1.88449 10.6145C2.64572 11.9046 3.83695 12.8855 5.2492 13.3851C5.48943 13.4716 5.747 13.4989 6.00004 13.4646C6.25308 13.4304 6.49411 13.3356 6.70269 13.1883C6.91126 13.041 7.08121 12.8455 7.19812 12.6185C7.31502 12.3915 7.37542 12.1396 7.3742 11.8843V11.6253C7.37332 11.4157 7.41395 11.2081 7.49373 11.0143C7.57351 10.8206 7.69087 10.6445 7.83904 10.4964C7.98721 10.3482 8.16325 10.2308 8.35701 10.1511C8.55077 10.0713 8.75841 10.0307 8.96795 10.0315H12.0359C12.3965 10.0318 12.7464 9.90975 13.0286 9.68541C13.3109 9.46107 13.5087 9.14767 13.5898 8.79638V8.79638Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.1084 3.92215C8.1084 4.32773 7.77961 4.65652 7.37402 4.65652C6.96844 4.65652 6.63965 4.32773 6.63965 3.92215C6.63965 3.51657 6.96844 3.18777 7.37402 3.18777C7.77961 3.18777 8.1084 3.51657 8.1084 3.92215Z"
        fill={props.stroke ? props.stroke : "#5B6772"}
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.125"
      />
      <path
        d="M5.12012 5.64871C5.12012 6.0543 4.79133 6.38309 4.38574 6.38309C3.98016 6.38309 3.65137 6.0543 3.65137 5.64871C3.65137 5.24313 3.98016 4.91434 4.38574 4.91434C4.79133 4.91434 5.12012 5.24313 5.12012 5.64871Z"
        fill={props.stroke ? props.stroke : "#5B6772"}
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.125"
      />
      <path
        d="M5.12012 9.10184C5.12012 9.50742 4.79133 9.83621 4.38574 9.83621C3.98016 9.83621 3.65137 9.50742 3.65137 9.10184C3.65137 8.69625 3.98016 8.36746 4.38574 8.36746C4.79133 8.36746 5.12012 8.69625 5.12012 9.10184Z"
        fill={props.stroke ? props.stroke : "#5B6772"}
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.125"
      />
      <path
        d="M11.0967 5.64871C11.0967 6.0543 10.7679 6.38309 10.3623 6.38309C9.95672 6.38309 9.62793 6.0543 9.62793 5.64871C9.62793 5.24313 9.95672 4.91434 10.3623 4.91434C10.7679 4.91434 11.0967 5.24313 11.0967 5.64871Z"
        fill={props.stroke ? props.stroke : "#5B6772"}
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.125"
      />
    </svg>
  );
};

export default Creative;

import React, { useEffect, useState } from "react";
import BarChart from "../../components/barChart";
import LineChart from "../../components/lineChart";
import { useAuth } from "../../context/auth/authContext";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-up-right.svg";
import ReactPlayer from "react-player/lazy";
import SelectInput from "../../components/selectDropdown";
import DocumentList from "../../components/documentList";
import TemplatesList from "../../components/templatesList";
import { getRequest } from "../../axios";
import useDocuments from "../../hooks/useDocuments";
import SaveDocumentModal from "../../components/saveDocumentModal";
import DeleteConfirmationModal from "../../components/deleteConfirmationModal";
import moment from "moment";
import { getDateFiller, getDatasetFiller } from "../../utils/getDataFiller";
import { extensionLink } from "../../utils/helpers";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

import DashboardCtaCard from "../../components/dashboardCtaCard";
import PricingModal from "../../components/pricingModal";
import { toast } from "react-toastify";

const Dashboard = () => {
  const { state, isSidebarOpen, pricingModal, setPricingModalData } = useAuth();
  const [pageState, setPageState] = useState<
    "idle" | "loading" | "success" | "error"
  >("loading");
  const [currentPlan] = useState("5 days");
  const [graphData, setGraphData] = useState<any>({
    wordsGenerated: {
      stats: {},
      totalWordsGenerated: 0,
      percentage: 0,
    },
    savings: {
      fund: 0,
      hours: 0,
      stats: {},
    },
  });

  const {
    documents,
    documentType,
    setDocumentType,
    newDocument,
    setNewDocument,
    showSaveDocumentModal,
    toggleSaveDocumentModal,
    saveNewDocument,
    showDeleteModal,
    toggleDeleteModal,
    handleDeleteDocument,
    setDocumentToBeDeleted,
  } = useDocuments();
  const { user } = state;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setPageState("loading");
        const res = await getRequest(`accounts/stats/total_requests`);
        const stats = res.data.monthly_detailed_generated_words;
        const chartLabels = stats
          .map((item: any) => moment(new Date(item.date)).format("MMM DD"))
          .slice(-5);
        let dateLabels =
          chartLabels.length < 5 ? getDateFiller(chartLabels) : chartLabels;

        const datasets = stats.map((item: any) => item.word_count).slice(-5);
        const data =
          datasets.length < 5 ? getDatasetFiller(datasets).data : datasets;
        const backgroundColors =
          datasets.length < 5 && getDatasetFiller(datasets).backgroundColors;
        const chartData = {
          wordsGenerated: {
            totalWordsGenerated: res.data.total_generated_words,
            percentage: Math.floor(res.data.daily_change_percent),
            stats: { labels: dateLabels, datasets: [{ label: "", data }] },
          },
          savings: {
            fund: Math.floor(res.data.saved_funds),
            hours: res.data.total_saved_time, //TODO: hours not getting from api, change later.
            stats: {
              labels: dateLabels,
              datasets: [
                {
                  label: "",
                  data,
                  backgroundColors: backgroundColors
                    ? [...backgroundColors]
                    : null,
                },
              ],
            },
          },
        };
        setGraphData({ ...chartData });
        setPageState("success");
      } catch (err) {
        toast.error(
          "Something went wrong. Please check your internet connection and refresh the page"
        );
        console.log(err);
      }
    };
    fetchStats();
  }, []);

  const documentsToShow = documents.filteredDocuments
    ? documents.filteredDocuments
    : documents.allDocuments;

  if (pageState === "loading") {
    return (
      <div className="overflow-hidden page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            TextCortex AI - Generate Blog Posts, Product Descriptions, Ad Copies
            and more
          </title>
        </Helmet>
        <h1 className="h3 text-primary-02">Dashboard</h1>
        <p className="text2 text-grey-02">
          Welcome to TextCortex, {user?.data?.first_name}.
        </p>
        <Skeleton
          style={{
            marginBottom: 40,
            marginTop: 32,
            marginRight: 32,
            borderRadius: 10,
          }}
          containerClassName="overflow-hidden flex flex  w-full max-w-[100%] flex-wrap"
          width={"31%"}
          height={300}
          count={3}
        />
        <Skeleton
          style={{ marginBottom: 20, marginRight: 40, borderRadius: 10 }}
          containerClassName="overflow-hidden flex  w-full max-w-[100%] flex-wrap"
          width={"22.1%"}
          height={300}
          count={4}
        />
      </div>
    );
  }

  return (
    <div className="w-full h-full  px-6 page pb-20 overflow-scroll">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          TextCortex AI - Generate Blog Posts, Product Descriptions, Ad Copies
          and more
        </title>
      </Helmet>
      <h1
        className={`h1 ${
          !isSidebarOpen ? "text-center" : "text-center md:text-left"
        } text-primary-02 `}
      >
        Dashboard
      </h1>
      <p
        className={`text2 text-grey-02 ${
          !isSidebarOpen ? "text-center" : "text-center md:text-left"
        }`}
      >
        Welcome to TextCortex, {user?.data?.first_name}.
      </p>

      {/************Top Section***********/}
      <section className="flex flex-col-reverse max-w-screen  im:grid w-full dashboard-top-grid im:gap-3 mt-4">
        <DashboardCtaCard />
        <div className="flex flex-col mt-8 im:mt-0  max-w-[100%] im:flex-row justify-between px-6 pt-5 border rounded shadow">
          <div className="h-[320px] im:w-[100%]">
            <p className="text2 text-grey-02">Words generated</p>
            <div className="flex items-center mt-3">
              <h1 className="mr-2 h1 text-primary-02">
                {graphData.wordsGenerated.totalWordsGenerated}
              </h1>
              <span className="flex items-center w-auto h-6 px-1 py-2 rounded bg-green-02 text2 text-green-01">
                <ArrowIcon />
                <p className="ml-1">{`${graphData.wordsGenerated.percentage}%`}</p>
              </span>
            </div>
            <div className="mt-10 flex-1">
              <LineChart chartData={graphData.wordsGenerated.stats} />
            </div>
          </div>
        </div>
        <div className="flex flex-col p-4 border rounded shadow">
          <div className="relative p-8 im:p-0 min-h-[300px] im:h-[80%] im:min-h-0 im:rounded">
            <ReactPlayer
              style={{ borderRadius: 8 }}
              className="cursor-pointer react-player"
              height={"100%"}
              width={"100%"}
              autoPlay
              // playIcon={VideoPlayIcon}
              url="https://www.youtube.com/watch?v=usk6XK6AjO0"
            />
          </div>
          <div className="flex justify-between px-4 py-6 mt-4 border rounded shadow bg-[rgba(100, 102, 114, 0.03)]">
            <div className="flex items-center">
              <img
                alt="google-chrome"
                src={require("../../assets/icons/chrome.png")}
              />
              <span className="ml-3">
                <p className="h2 text-primary-02">Chrome extension</p>
                <p className="text2 text-grey-02">Add to Chrome it's free</p>
              </span>
            </div>
            <a href={extensionLink} target="_blank" rel="noreferrer noopener">
              <button
                // onClick={() => {
                //   window.open(extensionLink, "_blank");
                // }}
                className="mt-1 bg-white h2 text-grey-02 shadow border rounded outline-0 link px-4 pt-[2px] h-[40px]"
              >
                Install
              </button>
            </a>
          </div>
        </div>
      </section>

      {/**  Templates section */}
      <section className="max-w-[98vw]">
        <TemplatesList />
      </section>
      {/*Documents Sections*/}
      <DocumentList
        deleteDocument={(id: string) => {
          toggleDeleteModal(true);
          setDocumentToBeDeleted(id);
        }}
        documentType={documentType}
        setDocumentType={setDocumentType}
        documents={documentsToShow}
        openSaveModal={() => {
          toggleSaveDocumentModal(true);
        }}
      />
      <SaveDocumentModal
        onDocumentTypeSelect={(value) => {
          setNewDocument({
            ...newDocument,
            documentType: value,
          });
        }}
        documentType={newDocument.documentType}
        selectDisabled={false}
        closeModal={() => toggleSaveDocumentModal(false)}
        documentName={newDocument.documentName}
        onSave={(documentName) => {
          saveNewDocument({
            document_name: documentName,
            category: newDocument.documentType,
            status: "Draft",
          });
        }}
        showModal={showSaveDocumentModal}
      />
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        closeModal={() => {
          toggleDeleteModal(false);
        }}
        onDelete={handleDeleteDocument}
      />
      <PricingModal
        user={user}
        onClose={() => {
          setPricingModalData({ ...pricingModal, isOpen: false });
        }}
        featurePlanType={pricingModal.featureUserAttempted || "Free"}
        isOpen={pricingModal.isOpen}
      />
    </div>
  );
};

export default Dashboard;

import React, { useState } from "react";

import Typewriter from "typewriter-effect";

import { ButtonSecondary } from "../button";
import Tippy from "@tippyjs/react";
import { links, planType } from "../../utils/helpers";
import { PlanType } from "../../types";

type Props = {
  showCtaButton?: boolean;
  userPlanType?: PlanType;
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const messages = {
  entertainment: [
    "Tailoring the right wording...",
    "Activating creative superpowers...",
    "Finding the inner hemingw.ai...",
    "Masterminding a plot...",
    "Releasing the inner Pulitzer...",
    "Contributing to the encyclopedia...",
    "Writing the next big hit on Netflix...",
    "Sharing apples with Agathe Christie for creative inspiration...",
  ],
  info: [
    "Saving you 70% of your time...",
    "Find best practices in our help center...",
    "Invite your friends and earn free daily creations...",
    "Give us a review and earn free daily creations...",
    "Hyper charge any textbox with our chrome extension...",
  ],
  factainment: [
    "Sharing apples with Agatha Christie for creative inspiration...",
    "Thinking about Tolkien’s polar bear appearances...",
    "Avoid writer’s block the un-Victor-Hugo-ian way",
    "Admiring Theodore Geisel for coining the word nerd...",
    "Finishing Kafka’s work...",
    "Borrowing Joyce’s large blue pencils...",
    "Typing on McCarthy’s magic typewriter...",
    "Reading Tolstoy’s diary...",
    "Trying to text Harari...",
  ],
};

export const ctaButtonsForBusinessUsers = [
  {
    title: "Join Discord",
    desc: "Join our community to learn how to make the best of TextCortex",
    url: links.discord,
  },
  {
    title: "Customize your AI",
    desc: "Share your writing style with us and get your personalized AI companion",
    url: "https://tell.textcortex.com/customise-your-ai-companion",
  },
];

export const ctaButtonsForProUsers = [
  {
    title: "Go Business",
    desc: "Unlock full the full power of TextCortex",
    url: "https://app.textcortex.com/pricing",
  },
  ...ctaButtonsForBusinessUsers,
];

export const ctaButtonsForFreeUsers = [
  {
    title: "Claim $50 reward",
    desc: "Get a $50 discount for referring friends",
    url: "https://tell.textcortex.com/refer-your-friends",
  },
  {
    title: "Send a gift",
    desc: "Gift 1-month Pro to a friend and get a little treat for yourself",
    url: "https://tell.textcortex.com/refer-your-friends",
  },
  {
    title: "Go Unlimited",
    desc: "Create as much as you like without limit",
    url: "https://app.textcortex.com/pricing",
  },
  ...ctaButtonsForProUsers,
];

const TypewriterLoading = ({ showCtaButton, userPlanType }: Props) => {
  const hasNoPlanOrHasFreePlan =
    userPlanType === planType.Free || !userPlanType;
  const getCTAButtons = () => {
    if (hasNoPlanOrHasFreePlan) return ctaButtonsForFreeUsers;
    else if (userPlanType === planType.Pro) return ctaButtonsForProUsers;
    else if (userPlanType === planType.Business)
      return ctaButtonsForBusinessUsers;
  };

  const ctaButtons = getCTAButtons();
  const [cta] = useState(() => {
    if (ctaButtons && ctaButtons.length > 0) {
      const i = getRandomInt(0, ctaButtons.length - 1);
      return ctaButtons[i];
    }
  });

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <h2 className="h3 neutral-04">Loading your content...</h2>
      <p className="text-center mt-[-4px] w-[70%] mx-auto text-neutral-04 body2">
        <Typewriter
          options={{
            strings: [
              "Your creation takes less than 10 seconds...",
              messages.entertainment[
                getRandomInt(0, messages.entertainment.length - 1)
              ],
              messages.info[getRandomInt(0, messages.info.length - 1)],
              messages.factainment[
                getRandomInt(0, messages.factainment.length - 1)
              ],
              messages.factainment[
                getRandomInt(0, messages.factainment.length - 1)
              ],
              messages.info[getRandomInt(0, messages.info.length - 1)],
              messages.factainment[
                getRandomInt(0, messages.factainment.length - 1)
              ],
              messages.factainment[
                getRandomInt(0, messages.factainment.length - 1)
              ],
              messages.entertainment[
                getRandomInt(0, messages.entertainment.length - 1)
              ],
            ],
            autoStart: true,
            loop: true,
            delay: 30,
            deleteSpeed: 2,
          }}
        />
      </p>
      {showCtaButton && (
        <div className="flex flex-col items-center justify-center w-[70%]">
          <Tippy
            content={
              <p className="text-center text2 text-primary-02 w-[70%] m-auto">
                {cta?.desc}
              </p>
            }
            offset={[0, 4]}
            delay={[300, 0]}
            placement="bottom"
          >
            <a target={"_blank"} href={cta?.url}>
              <div className="bg-brand rounded p-[1px] mt-4 mb-4">
                <ButtonSecondary className="w-[200px]" style={{ padding: 0 }}>
                  <span className="w-full px-2 py-1 bg-primary-09  h-full rounded-md  flex items-center justify-center">
                    <p className="brand-gradient-text-glow h5BoldRegular">
                      {cta?.title}
                    </p>
                  </span>
                </ButtonSecondary>
              </div>
            </a>
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default React.memo(TypewriterLoading);

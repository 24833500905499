import React from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  initial: string;
  onClick?: () => void;
  size?: "large" | "small";
  color?: string;
};

const colors = ["#FC636B"];

const UserIcon = ({
  size = "large",
  initial,
  onClick,
  color,
  ...props
}: Props) => {
  const randomColor = colors[(colors.length * Math.random()) | 0];
  return (
    <div
      style={{ backgroundColor: color ? color : randomColor }}
      // onClick={onClick}
      {...props}
      className={`cursor-pointer flex items-center justify-center shadow h-8 w-8 rounded-[50%] ${
        size === "small" && "h-[22px] w-6 text-[8px]"
      } ${props.className}`}
    >
      <p className="p-2 text-white text2">{initial}</p>
    </div>
  );
};

export default UserIcon;

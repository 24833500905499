import axiosClient from "../../axios";
import React from "react";
import { Link } from "react-router-dom";
import { analytics } from "../../utils/analytics";
import { GET_PREMIUM, LINK_LOCATION } from "../../constants";
import axios from "axios";

export const fetchChatResponse = async ({
  text,
  conversationId,
  signal,
  language,
  augment,
}: {
  text: string;
  conversationId?: string;
  signal?: AbortSignal;
  language: string;
  augment: boolean;
}) => {
  const res = await axiosClient({
    method: "post",
    data: {
      input: text, // This is what the user has typed in
      source_lang: language === "auto" ? "en" : language, // Optional, defaults to "en"
      conversation_id: conversationId,
      augment: augment,
      response_type: "text/markdown",
    },
    url: "/internal/v1/chats",
    signal,
  });
  return res;
};

export const streamChatResponse = async ({
  text,
  conversationId,
  language,
  augment,
}: {
  text: string;
  conversationId?: string;
  language: string;
  augment: boolean;
}) => {
  const data = {
    input: text, // This is what the user has typed in
    source_lang: language, // Optional, defaults to "en"
    conversation_id: conversationId,
    augment: augment,
    response_type: "text/markdown",
  };
  try {
    const response = await axiosClient({
      method: "post",
      url: "/internal-stream/v1/chats",
      data,
      responseType: "stream",
    });
    response.data.on("data", (chunk: any) => {
      process.stdout.write(chunk.toString());
    });
    return response;
  } catch (err) {
    console.log("error here", err);
  }
};

export const fetchChatHistory = async ({
  signal,
  conversation_id,
}: {
  signal?: AbortSignal;
  conversation_id: string;
}) => {
  const res = await axiosClient({
    method: "get",
    url: `/internal/v1/chats?id=${conversation_id}`,
    signal,
  });
  return res;
};

export const sendChatFeedback = async ({
  signal,
  data,
}: {
  signal?: AbortSignal;
  data: any;
}) => {
  const res = await axiosClient({
    method: "post",
    url: `/internal/v1/output-feedback`,
    data,
    signal,
  });
  return res;
};

export const messageTextClassName = "whitespace-pre-wrap body8 mt-1";

export const GetUnlimitedButton = (
  <>
    <p className={messageTextClassName}>
      You have finished your creations. Go unlimited to continue working with
      Zeno.
    </p>
    <Link
      className="cursor-pointer "
      to={`/pricing`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        analytics(GET_PREMIUM, {
          [LINK_LOCATION]: "chat-message",
        });
      }}
    >
      <button className="mt-2 bg-primary-01 border-none outline-none px-2 py-1 text-white text2">
        Go unlimited
      </button>
    </Link>
  </>
);

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect } from "react";
import { State } from "../useEditor";

type Props = {
  state: State;
  dispatch: any;
};

export const StateHandlerPlugin = ({ state, dispatch }: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {});
  return null;
};

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthPagesContainer from "../../../components/authPageContainer";
import {
  AUTH_LOADING,
  googleAuth,
  login,
  REMOVE_ERROR,
  shopifyAuth,
  SUBMITTING_FORM,
} from "../../../context/auth/actions";
import { useAuth } from "../../../context/auth/authContext";
import { AuthPageData } from "../../../types";
import _ from "lodash";
import { sendMessageToExtension } from "../../../utils/extension";
import { Helmet } from "react-helmet";
import useRecaptcha from "../../../hooks/useRecaptcha";

const signInPageData: AuthPageData = {
  type: "signIn",
  title: "Sign in",
  checkboxText: "Remember me",
  footer: "© 2022 TextCortex, All right Reserved",
  buttonText: "Sign in",
  changePage: {
    text: "Don't have an account?",
    linkText: "Sign up now",
    link: "/user/signup",
  },
  forms: [
    {
      type: "email",
      name: "email",
      label: "Email address",
      placeholder: "textcortex@gmail.com",
      rules: { required: "This is a required field" },
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Enter 8 characters or more",
      rules: { required: "This is a required field" },
      helperLink: {
        text: "Forgot Password?",
        link: "/user/retrieve-account",
      },
    },
  ],
};

type FormData = {
  email: string;
  password: string;
  remember_me: boolean;
};

const SignIn = () => {
  const { dispatch, state } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<FormData>();
  const { token: captchaToken, handleReCaptchaVerify } = useRecaptcha();

  useEffect(() => {
    localStorage.removeItem("cortexAccessToken");
    sendMessageToExtension({
      type: "REMOVE_TOKEN",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckbox = (value: boolean) => {
    setValue("remember_me", value);
  };

  const submitForm = async (data: FormData) => {
    dispatch({
      type: SUBMITTING_FORM,
      payload: true,
    });
    const token = await handleReCaptchaVerify();
    login(
      dispatch,
      _.omit({ ...data, captcha_response: token }, "remember_me")
    );
  };

  const googleAuthResponse = async (response: any) => {
    // fetching userinfo can be done on the client or the server
    await googleAuth(
      dispatch,
      {
        google_access_token: response.access_token,
      },
      "login"
    );
  };

  const authWithShopify = async (storeUrl: string) => {
    await shopifyAuth(storeUrl);
  };

  return (
    <div className="w-full h-[100vh] bg-red">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Login To Generate Amazing Text</title>
      </Helmet>
      <AuthPagesContainer
        googleAuth={googleAuthResponse}
        shopifyAuth={authWithShopify}
        watch={watch}
        handleCheckbox={handleCheckbox}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        authError={state.error}
        submitForm={submitForm}
        loading={state.submittingForm}
        pageData={signInPageData}
        control={control}
      />
    </div>
  );
};

export default React.memo(SignIn);

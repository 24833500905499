import { IconProps } from "../../types";
import React from "react";

const Summarize = (props: IconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.35896 2.28712L9.48071 8.39016L15.6904 8.50982L14.1075 6.95415L16.8978 4.21177C16.9651 4.14565 17.0018 4.05492 16.9999 3.95954C16.998 3.86416 16.9576 3.77195 16.8877 3.70318L14.2496 1.11039C14.1796 1.04162 14.0858 1.00194 13.9887 1.00007C13.8917 0.998199 13.7994 1.03429 13.7321 1.10041L10.9418 3.84279L9.35896 2.28712Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.64104 15.7129L8.51929 9.60984L2.30961 9.49018L3.89247 11.0459L1.10217 13.7882C1.03489 13.8544 0.998168 13.9451 1.00007 14.0405C1.00197 14.1358 1.04235 14.2281 1.11232 14.2968L3.75041 16.8896C3.82038 16.9584 3.91421 16.9981 4.01125 16.9999C4.1083 17.0018 4.20061 16.9657 4.26789 16.8996L7.05818 14.1572L8.64104 15.7129Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Summarize;

import React, { useEffect, useState } from "react";
import { ReactComponent as FolderIcon } from "../../../assets/icons/folder_sidebar.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import Intro from "./intro";
import Textarea from "../../../components/textarea";
import useCustomizeAI from "./useCustomizeAI";
import Spinner from "../../../components/spinner";
import DeleteConfirmationModal from "../../../components/deleteConfirmationModal";
import { defaultEditorRoute } from "../../../utils/helpers";
import { useAuth } from "../../../context/auth/authContext";

export const personaData = {
  customization_id: "cf4b415f-1be0-423b-b9e4-790701d4e6e8",
  updated_date: null,
  text_samples: {
    text_1:
      "To learn more about the command-line tools for App Engine, Compute Engine, Cloud Storage, BigQuery, Cloud SQL, and Cloud DNS (which are all bundled with the gcloud CLI), see Accessing services with the gcloud CLI.",
    text_2:
      "If you're a client application developer and want to find out more about accessing Google Cloud services with a programming language or framework, see Client Libraries Explained.",
    text_3:
      "Here are some links to help you get started with Google Cloud services:",
  },
  created_date: "2023-03-08T09:12:22",
  customization_name: "My everyday writing persona",
  style: {
    voice: "active",
    tonality: { friendly: 0.1, persuasive: 0.3, informative: 0.6 },
    reading_ease: "easy",
    sentence_length: "medium",
    writers_intention: "informative",
  },
  category: "general",
  default: true,
};

const readingEaseValues = ["Easy", "Medium", "Hard"];

const sentenceLengthValue = ["Short", "Medium", "Long"];

const voiceValues = ["Active", "Passive"];

type ProgressBarProps = {
  value: number;
  label: string;
};

const ProgressBar = ({ value, label }: ProgressBarProps) => {
  return (
    <div>
      <div className="relative bg-[#EAECF0] w-full h-2 rounded">
        <div
          style={{ width: `${value}%` }}
          className="absolute top-0 bottom-0 rounded left-0 bg-brand-02"
        />
      </div>
      <div className="flex mt-2 justify-between">
        <p className="text-primary-02 capitalize">{label}</p>
        <p className="text-primary-02">{value}%</p>
      </div>
    </div>
  );
};

const Result = () => {
  const auth = useAuth();
  const {
    state,
    dispatch,
    setAsDefault,
    deletePersona,
    toggleModal,
    deleteModal,
  } = useCustomizeAI();
  const navigate = useNavigate();
  const params = useParams();

  if (state.status !== "success") {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  const persona = state.currentPersona;
  return (
    <div className="mt-4 m-6">
      <div className="flex justify-between">
        <div
          className={`flex flex-col ${
            !auth.isSidebarOpen && "items-center"
          } w-full`}
        >
          <h1 className={`h1 text-primary-02`}>Customize your AI</h1>
          <p className="text2 text-grey-02">
            Create your digital twin and your apprentice
          </p>
        </div>
        <Link to={defaultEditorRoute}>
          <Button
            onClick={() => {}}
            className="text-white h-[40px] w-fit justify-center mt-4 bg-brand-02 border-[1px] h5BoldRegular flex items-center"
            style={{ padding: "6px 16px", borderRadius: 8 }}
          >
            <div className="flex justify-center items-center h-full">
              <div className="flex mr-2 p-[2px]  rounded-full border-[1px] items-center justify-center border-white">
                <PlusIcon className="stroke-white w-3 h-3" />
              </div>
              New Doc
            </div>
          </Button>
        </Link>
      </div>
      <div className=" my-8 p-8 max-w-[660px] rounded-lg shadow-3xl bg-white">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="h3Bold">
              Persona Details ({persona?.persona_details.name})
            </h3>
            <p className="text2 text-grey-02">
              Set your persona as default for specific writing
            </p>
          </div>
          <FolderIcon className="stroke-primary-02 mt-[-20px]" />
        </div>
        <div className="mt-8">
          {Object.keys(persona.style.tonality).map((item: any) => (
            <div className="mb-6">
              <ProgressBar
                label={item.replace("_", " ")}
                value={(persona.style.tonality as any)[item] * 100}
              />
            </div>
          ))}
          <div className="mb-6">
            {/* <ProgressBar
              label={"Reading Ease"}
              value={(progressBarValues as any)[persona.style.reading_ease]}
            /> */}
          </div>
          <div className="mb-6">
            <p className="text-primary-02 capitalize">Reading Ease</p>
            <div className="grid grid-cols-3 gap-x-4 mt-2">
              {readingEaseValues.map((item) => (
                <div
                  className={`${
                    item.toLowerCase() ===
                    persona.style.reading_ease.toLowerCase()
                      ? "bg-brand-02 text-white"
                      : "bg-brand-01"
                  } rounded h5BoldRegular py-[6px] flex justify-center items-center`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <p className="text-primary-02 capitalize">Sentence Length</p>
            <div className="grid grid-cols-3 gap-x-4 mt-2">
              {sentenceLengthValue.map((item) => (
                <div
                  className={`${
                    item.toLowerCase() ===
                    persona.style.sentence_length.toLowerCase()
                      ? "bg-brand-02 text-white"
                      : "bg-brand-01"
                  } rounded h5BoldRegular py-[6px] flex justify-center items-center`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <p className="text-primary-02 capitalize">Voice</p>
            <div className="grid grid-cols-2 gap-x-4 mt-2">
              {voiceValues.map((item) => (
                <div
                  className={`${
                    item.toLowerCase() === persona.style.voice.toLowerCase()
                      ? "bg-brand-02 text-white"
                      : "bg-brand-01"
                  } rounded h5BoldRegular py-[6px] flex justify-center items-center`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className=" my-8 p-8 max-w-[660px] rounded-lg shadow-3xl bg-white">
        <div>
          <h3 className="h3Bold">Writer's Intention</h3>
          <p className="text-brand-02 capitalize">
            {persona.style.writers_intention}
          </p>
        </div>
      </div>
      <div className=" my-8 p-8 max-w-[660px] rounded-lg shadow-3xl bg-white">
        <div>
          <h3 className="h3Bold">Persona Bio</h3>
          <p className="text-grey-02 capitalize">
            {persona.persona_details.bio}
          </p>
        </div>
      </div>
      <div className=" my-8 p-8 max-w-[660px] rounded-lg shadow-3xl bg-white">
        <h3 className="h3Bold mb-5">Text Samples</h3>
        <p className="text2Bold">Text Sample #1</p>
        <Textarea
          disabled
          className="mb-4 h-[100px]"
          value={persona.text_samples.text_1}
        />
        <p className="text2Bold">Text Sample #2</p>
        <Textarea
          disabled
          className="mb-4 h-[100px]"
          value={persona.text_samples.text_2}
        />

        <p className="text2Bold">Text Sample #3</p>
        <Textarea
          disabled
          className="mb-4 h-[100px]"
          value={persona.text_samples.text_3}
        />

        <Button
          onClick={() => setAsDefault(persona.id, persona.default)}
          className="min-w-full mt-6 h-[44px] flex items-center justify-center"
        >
          <span className="text2Bold">
            {persona.default
              ? "Remove default AI persona"
              : "Set as default AI persona"}
          </span>
        </Button>
      </div>
      <p
        onClick={() =>
          toggleModal({ show: true, id: persona.customization_id })
        }
        className="text-red-01 cursor-pointer"
      >
        Delete AI Persona
      </p>
      <DeleteConfirmationModal
        showModal={deleteModal.show}
        description={"Are you sure you want to delete this AI persona?"}
        closeModal={() => {
          toggleModal({ id: null, show: false });
        }}
        onDelete={deletePersona}
      />
    </div>
  );
  return null;
};

export default Result;

import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(...registerables);

export const options = {
  responsive: true,
  scales: {
    y: {
      // ticks: { display: false },
      grid: { display: false },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 6,
      },
    },
    x: {
      grid: { display: true, drawOnChartArea: false, drawBorder: true },
      gridLines: { display: false },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 6,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

type Props = {
  chartData: any;
};

function BarChart({ chartData }: Props) {
  const data = {
    labels: [...chartData.labels],
    datasets: chartData?.datasets?.map((item: any) => ({
      label: item.label,
      data: [...item.data].map((data: any) => data),
      backgroundColor: "#0A8BFE",
      barThickness: 16,
      minBarLength: 20,
      fillColor: "#0A8BFE",
      strokeColor: "rgba(151,187,205,1)",
      pointColor: "rgba(151,187,205,1)",
      maxBarThickness: 30,
    })),
  };

  return <Bar options={options} data={data} />;
}

export default BarChart;

import React, { useEffect } from "react";
import { useAuth } from "../../context/auth/authContext";

const offBoardingUrl = "https://tell.textcortex.com/sorry-to-see-you-go";

const OffBoarding = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.state.user) {
      window.open(`${offBoardingUrl}?email=${auth.state.user.email}`, "_self");
    } else {
      window.open(`${offBoardingUrl}`, "_self");
    }
  }, []);
  return null;
};

export default OffBoarding;

import { ReactNode } from "react";

export type LoadingStatus = {
  status: "idle" | "typing" | "error" | "success" | "loading_history";
  message?: string;
};

export type ChatBanners = {
  limitations: boolean;
  capabilities: boolean;
  sampleMessages: boolean;
};

export type ChatHistory = {
  input: string;
  output: string;
};

export type BannerTypes = "limitations" | "capabilities" | "sampleMessages";

export type Message = {
  text: string | ReactNode;
  id?: string;
  index?: number;
  conversation_id?: string;
  messageType?: "sent" | "received";
  references?: any;
};

export type State = {
  messages: Message[];
  loadingStatus: LoadingStatus;
  conversation?: { id: string };
  showBanners: ChatBanners;
  messageText: string;
  history: { conversationId: string; history: ChatHistory[] };
  // language: string;
  enableWebSearch: boolean;
  currentlyPlayingMessage?: Message;
};

export type Action =
  | {
      type: "TOGGLE_BANNER";
      payload: BannerTypes;
    }
  | {
      type: "TOGGLE_WEB_SEARCH";
    }
  | { type: "SET_MESSAGE_TEXT"; payload: string }
  | {
      type: "ADD_MESSAGE";
      payload: Message;
    }
  | { type: "SET_LOADING_STATUS"; payload: LoadingStatus }
  | {
      type: "SET_HISTORY";
      payload: { conversationId: string; history: ChatHistory[] };
    }
  // | {
  //     type: "SET_LANGUAGE";
  //     payload: string;
  //   }
  | {
      type: "START_NEW_CHAT";
      payload: any;
    }
  | {
      type: "SET_LANGUAGE";
      payload: string;
    }
  | {
      type: "SET_CURRENTLY_PLAYING_MESSAGE";
      payload: Message;
    };

export const initialState: State = {
  history: { conversationId: "", history: [] },
  loadingStatus: { status: "idle" },
  messages: [],
  enableWebSearch: false,
  showBanners: {
    limitations: true,
    capabilities: true,
    sampleMessages: true,
  },
  messageText: "",
  // language: localStorage.getItem("default_language") || "en",
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "TOGGLE_BANNER":
      return {
        ...state,
        showBanners: {
          ...state.showBanners,
          [action.payload]: !state.showBanners[action.payload],
        },
      };
    case "TOGGLE_WEB_SEARCH":
      return {
        ...state,
        enableWebSearch: !state.enableWebSearch,
      };
    case "SET_HISTORY":
      return {
        ...state,
        history: action.payload,
      };
    // case "SET_LANGUAGE":

    //   return {
    //     ...state,
    //     language: action.payload,
    //   };
    case "SET_LOADING_STATUS":
      return {
        ...state,
        loadingStatus: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case "SET_CURRENTLY_PLAYING_MESSAGE":
      return { ...state, currentlyPlayingMessage: action.payload };
    case "SET_MESSAGE_TEXT":
      return {
        ...state,
        messageText: action.payload,
      };
    case "START_NEW_CHAT":
      return {
        ...state,
        messageText: "",
        messages: [],
        showBanners: {
          capabilities: true,
          limitations: true,
          sampleMessages: true,
        },
      };
    case "ADD_MESSAGE": {
      const conversationId = action.payload.conversation_id;
      const stateData: State = {
        ...state,
        messageText: "",
        showBanners: {
          capabilities: false,
          limitations: false,
          sampleMessages: false,
        },
        messages: [...state.messages, action.payload],
      };
      if (conversationId) {
        localStorage.setItem("cortex_conversation_id", conversationId);
        Object.assign(stateData, {
          conversation: { id: conversationId },
        });
      }
      if (action.payload.messageType === "received") {
        Object.assign(stateData, {
          loadingStatus: { status: "success" },
        });
      }
      return stateData;
    }
    default:
      return state;
  }
};

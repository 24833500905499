import React, { useState } from "react";
import {
  AUTH_SUCCESS,
  confirmPasswordReset,
} from "../../../context/auth/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/button";
import Input from "../../../components/input";
import { useAuth } from "../../../context/auth/authContext";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

// add params check
const ResetPassword = () => {
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
    error: "",
  });
  const { dispatch, state } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const validateForm = () => {
    if (password.new !== password.confirm) {
      setPassword({
        ...password,
        error: "Password does not match.",
      });
      return;
    } else {
      if (password.new.length < 8) {
        setPassword({
          ...password,
          error: "Password must be more 8 or more characters.",
        });
        return;
      }
      submitForm();
    }
  };

  const submitForm = async () => {
    try {
      const token = searchParams.get("reset_token");
      const res = await confirmPasswordReset(dispatch, {
        token,
        password: password.new,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Reset Password</title>
      </Helmet>
      <div className="p-6 md:p-0 m-auto mt-32 max-w-[456px]">
        <h1 className="text-center h1 text-primary-02">Reset your password</h1>
        <p className="mb-6 text-center text2 text-grey-02">
          Enter a new password to reset the password on your account:{" "}
        </p>
        <Input
          placeholder={"Password must be at least 8 characters"}
          type="password"
          label="New Password"
          name="newPassword"
          value={password.new}
          onChange={({ target }) =>
            setPassword({ ...password, error: "", new: target.value })
          }
        />
        <Input
          type="password"
          label="Confirm Password"
          name="confirm"
          value={password.confirm}
          onChange={({ target }) =>
            setPassword({ ...password, error: "", confirm: target.value })
          }
        />
        {password.error && (
          <p className="my-3 text2 text-red-01">{password.error}</p>
        )}
        <div className="mt-12">
          <Button loading={state.submittingForm} onClick={validateForm}>
            Save new password{" "}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

import React from "react";
import { BannerTypes, Message } from "../../context/chat/reducer";
import { useChat } from "../../context/chat/useChat";
import Banners from "../zenoChat/chatBanners";
import ChatInput from "../zenoChat/chatInput";
import MessageComponent from "../zenoChat/message";
import SampleMessagesBanner from "../zenoChat/sampleMessagesBanner";

type Props = {
  chatData: any;
};

const ZenoSidebar = ({ chatData }: Props) => {
  const {
    inputRef,
    state,
    dispatch,
    submitForm,
    messageListRef,
    setMessageAsCurrentlyPlaying,
    createNewChat,
  } = chatData;
  return (
    <div className="mt-[15%]  flex flex-col justify-end relative">
      <div className="flex h-[100%]  max-h-full  w-full flex-col justify-between">
        <div>
          <Banners
            showBanners={state.showBanners}
            toggleBanners={(val: BannerTypes) =>
              dispatch({
                type: "TOGGLE_BANNER",
                payload: val,
              })
            }
          />
        </div>
        <div className="flex h-full mb-[170px] flex-col w-full justify-end max-w-full max-h-full pb-5">
          <div className="flex flex-col justify-end  h-full">
            {state.messages.map((message: Message) => (
              <div className="my-2">
                <MessageComponent
                  onPlayIconClick={() => {
                    setMessageAsCurrentlyPlaying({ message });
                  }}
                  isAudioActive={
                    state.currentlyPlayingMessage &&
                    state.currentlyPlayingMessage?.id === message.id
                  }
                  canBePlayed={Boolean(message.id)}
                  dispatch={dispatch}
                  data={message}
                />
              </div>
            ))}

            {state.loadingStatus.status === "typing" && (
              <div className="mb-2">
                <MessageComponent state={state} status={state.loadingStatus} />
              </div>
            )}
          </div>
          <SampleMessagesBanner
            sidebar={true}
            showBanners={state.showBanners}
            toggleBanners={(val) =>
              dispatch({
                type: "TOGGLE_BANNER",
                payload: val,
              })
            }
            onSend={(text) => {
              submitForm(text);
            }}
          />
        </div>
        <div ref={messageListRef} />

        <div className="fixed bottom-[7%] pt-2 w-[90%] bg-white">
          <ChatInput
            onLanguageChange={() => {}}
            hideLanguage={true}
            inputRef={inputRef}
            message={state.messageText}
            onSend={() => {
              submitForm(state.messageText);
            }}
            setMessage={(text: string) => {
              dispatch({
                type: "SET_MESSAGE_TEXT",
                payload: text,
              });
            }}
            toggleWebSearch={() => {
              dispatch({ type: "TOGGLE_WEB_SEARCH" });
            }}
            state={state}
          />
        </div>
      </div>
    </div>
  );
};

export default ZenoSidebar;

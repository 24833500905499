import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

const DropdownItem = ({ onClick, children, className }: Props) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer mb-6 flex items-center text-2 text-grey-02 ${className}`}
    >
      {children}
    </div>
  );
};

export default DropdownItem;

import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import UsageGraph from "../../../components/usageGraph";
// import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";
// import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { useAuth } from "../../../context/auth/authContext";

import moment from "moment";
import { getRequest } from "../../../axios";
import { LoadingState } from "../../../types";
import { Helmet } from "react-helmet";

// const monthly_detailed_generated_words = [
//   { date: "2022-04-29", word_count: 8257 },
//   { date: "2022-05-01", word_count: 7590 },
//   { date: "2022-05-02", word_count: 9151 },
//   { date: "2022-05-03", word_count: 18726 },
//   { date: "2022-05-04", word_count: 2960 },
//   { date: "2022-05-05", word_count: 10507 },
//   { date: "2022-05-06", word_count: 96 },
//   { date: "2022-05-09", word_count: 3540 },
//   { date: "2022-05-11", word_count: 22 },
//   { date: "2022-05-12", word_count: 329 },
//   { date: "2022-05-15", word_count: 11 },
//   { date: "2022-05-23", word_count: 378 },
//   { date: "2022-05-24", word_count: 12830 },
//   { date: "2022-05-25", word_count: 4042 },
//   { date: "2022-05-26", word_count: 664 },
// ];

// const data = {
//   labels: monthly_detailed_generated_words.map((item) => item.date),
//   datasets: [
//     {
//       barPercentage: 0.5,
//       barThickness: 6,
//       maxBarThickness: 8,
//       minBarLength: 2,
//       data: monthly_detailed_generated_words.map((item) => item.word_count),
//     },
//   ],
// };

type CreditHistoryItem = {
  Action: string;
  Credit: number;
  Date: string;
};

type DailyWords = {
  date: string;
  word_count: number;
};

type RequestStat = {
  current_month_requests?: number;
  plan_requests_daily_limit?: number;
  remaining_daily_requests?: number;
  total_generated_words?: number;
  monthly_detailed_generated_words?: DailyWords[];
};

type State = {
  loadingState: LoadingState;
  creditHistory: CreditHistoryItem[];
  requestStat: RequestStat;
  searchTerm: string;
};

const getLabels = (
  monthly_detailed_generated_words: DailyWords[] | undefined
) => {
  if (monthly_detailed_generated_words) {
    return monthly_detailed_generated_words.map((item) =>
      moment(item.date).format("DD MMM")
    );
  } else {
    return [];
  }
};

const getData = (
  monthly_detailed_generated_words: DailyWords[] | undefined
) => {
  if (monthly_detailed_generated_words) {
    return monthly_detailed_generated_words.map((item) => item.word_count);
  } else {
    return [];
  }
};

const getHistory = (
  items: CreditHistoryItem[],
  query: string
): CreditHistoryItem[] => {
  const filtered = items.filter((item) =>
    item.Action.toLowerCase().includes(query.toLowerCase())
  );
  return filtered;
};

const Usage = () => {
  // const [currentPage, setCurrentPage] = useState(0);
  const auth = useAuth();
  const [state, setState] = useState<State>({
    loadingState: "loading",
    creditHistory: [],
    requestStat: {},
    searchTerm: "",
  });

  // useEffect(() => {
  //   const data = usageData.slice    const filtered = state.creditHistory.filter((item) =>
  //  (currentPage, currentPage + 3);
  //   setUsageHistory(data);
  // }, [currentPage]);

  // const nextPage = () => {
  //   if (usageHistory.length - 3 <= currentPage) {
  //     setCurrentPage(currentPage + 3);
  //   }
  // };

  // const prevPage = () => {
  //   if (currentPage !== 0) {
  //     setCurrentPage(currentPage - 3);
  //   }
  // };

  useEffect(() => {
    fetchCreditHistory();
    fetchGenerationStats();
  }, []);

  const fetchGenerationStats = async () => {
    try {
      const res = await getRequest("accounts/stats/total_requests");
      if (res.data.status === "success") {
        setState((prev) => ({
          ...prev,
          loadingState: "success",
          requestStat: res.data,
        }));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const fetchCreditHistory = async () => {
    try {
      const res = await getRequest("accounts/credit_history");
      if (res.data.status === "success") {
        setState((prev) => ({
          ...prev,
          loadingState: "success",
          creditHistory: res.data.usage_history,
        }));
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        state: "error",
      }));
      console.log("error: ", error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Usage Settings</title>
      </Helmet>
      <div className="flex page flex-col mt-2">
        <div>
          <div>
            <h1 className="h3 text-primary-02">Usage</h1>
            <p className="text2 mt-[-8px] text-grey-02">
              Subscription Plan:{" "}
              <span className="text-primary-02">
                {auth.state.user?.plan_type}
              </span>
            </p>
          </div>
          <div className="mt-4 relative flex flex-col py-4 px-6 max-h-[260px] border rounded">
            <p className="text2 text-grey-02">Total words created</p>
            <h1 className="h1 text-primary-02">
              {state.requestStat.total_generated_words}
            </h1>
            <div className="flex">
              <UsageGraph
                chartData={{
                  labels: getLabels(
                    state.requestStat.monthly_detailed_generated_words
                  ),
                  datasets: [
                    {
                      barPercentage: 0.5,
                      barThickness: 6,
                      maxBarThickness: 8,
                      minBarLength: 2,
                      data: getData(
                        state.requestStat.monthly_detailed_generated_words
                      ),
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-9">
          {/* <div>
            <div className="px-6 py-4 border rounded">
              <p className="pb-3 mb-3 text2 text-primary-02 border-bottom">
                Today’s Remaining Text Generation Requests
              </p>
              <div className="flex justify-between pb-2 mb-2 border-bottom text-grey-02 text2">
                <p className="">Total text generation requests this month:</p>
                <p className="text-primary-02 h2">
                  {state.requestStat.current_month_requests}
                </p>
              </div>
              <div className="flex justify-between pb-2 mb-2 border-bottom text-grey-02 text2">
                <p className="">Remaining platform requests for today</p>
                <p className="text-primary-02 h2">
                  {state.requestStat.remaining_daily_requests}
                </p>
              </div>
              <div className="flex justify-between pb-2 mb-2 border-bottom text-grey-02 text2">
                <p className="">Daily plan limit:</p>
                <p className="text-primary-02 h2">
                  {state.requestStat.plan_requests_daily_limit}
                </p>
              </div>
            </div>
          </div> */}
          <div className="col-span-2 px-6 py-4 border rounded">
            <div className="flex items-center justify-between pb-4 border-bottom">
              <p className="pb-3 text2 text-primary-02 ">
                Detailed usage History
              </p>
              <div className="bg-grey-07 shadow mt-1 flex items-center relative ml-4 outline-0 h-9 border rounded w-[260px] pl-4 py-3">
                <SearchIcon className="absolute w-4 h-4 transform -translate-y-1/2 pointer-events-none top-1/2 left-3" />
                <input
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      searchTerm: e.target.value,
                    }));
                  }}
                  className="pl-5 pr-1 bg-transparent border-0 text- outline-0 text2 text-primary-02"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="flex justify-between mt-7">
              <div className="flex flex-col items-start">
                <p className="mb-6 text3 text-grey-02">Type</p>
                {getHistory(state.creditHistory, state.searchTerm).map(
                  (item) => (
                    <p className="mb-4 text-primary-02 text2">{item.Action}</p>
                  )
                )}
              </div>
              <div className="flex flex-col items-center">
                <p className="mb-6 text3 text-grey-02">Requests</p>
                {getHistory(state.creditHistory, state.searchTerm).map(
                  (item) => (
                    <p className="mb-4 text-primary-02 text2">{item.Credit}</p>
                  )
                )}
              </div>
              <div className="flex flex-col">
                <p className="mb-6 text3 text-grey-02">Date</p>
                {getHistory(state.creditHistory, state.searchTerm).map(
                  (item) => (
                    <p className="mb-4 text-primary-02 text2">{item.Date}</p>
                  )
                )}
                {/* <div className="flex items-center">
                <div
                  onClick={prevPage}
                  className="p-2 border rounded cursor-pointer"
                >
                  <ChevronLeft />
                </div>
                <div
                  onClick={nextPage}
                  className="p-2 ml-4 border rounded cursor-pointer"
                >
                  <ChevronRight />
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Usage;

import React from "react";
import Button from "../../../components/button";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import useCustomizeAI, { CustomizeTab, steps } from "./useCustomizeAI";
import Intro from "./intro";
import ExampleText from "./exampleText";
import Card from "../../../components/customizeAICard";
import TypeWriterLoading from "../../../components/DPU/typeWriterLoading";
import Spinner from "../../../components/spinner";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
import { defaultEditorRoute } from "../../../utils/helpers";
import { useAuth } from "../../../context/auth/authContext";

const CustomizeYourAI = () => {
  const { state, dispatch, submitForm } = useCustomizeAI();
  const auth = useAuth();
  const isActiveTab = (tab: CustomizeTab) => {
    return state.completedSteps[tab] === true;
  };

  const handleChangeTab = (step: CustomizeTab) => {
    if (isActiveTab(step)) {
      dispatch({
        type: "CHANGE_TAB",
        payload: {
          tab: step,
          isOpen:
            step === state.currentTab.tab ? !state.currentTab.isOpen : true,
        },
      });
    }
  };

  return (
    <div className="px-8 relative pt-4 pb-12 w-full min-h-[100vh] bg-[#FCFCFD]">
      <div className="full flex justify-between items-start">
        <div
          className={`flex flex-col ${
            !auth.isSidebarOpen && "items-center"
          } w-full`}
        >
          <h1 className={`h1 text-primary-02`}>Customize your AI</h1>
          <p className="text2 text-grey-02">
            Create your digital twin and your apprentice
          </p>
        </div>
        <Link to={defaultEditorRoute}>
          <Button
            onClick={() => {}}
            className="text-white w-fit justify-center mt-4 bg-brand-02 border-[1px] h5BoldRegular flex items-center"
            style={{ padding: "6px 16px", borderRadius: 8 }}
          >
            <div className="flex justify-center items-center h-full">
              <div className="flex mr-2 p-[2px]  rounded-full border-[1px] items-center justify-center border-white">
                <PlusIcon className="stroke-white w-3 h-3" />
              </div>
              New Doc
            </div>
          </Button>
        </Link>
      </div>
      <div className="max-w-[660px] ">
        <div className="flex items-center mt-8 mb-4">
          {steps.map((step) => {
            return (
              <p
                onClick={() => {
                  handleChangeTab(step.value as CustomizeTab);
                }}
                className={`mr-4 cursor-pointer hover:border-brand-02 hover:border-b-[1px] pb-2 ${
                  state.currentTab.tab === step.value
                    ? "border-brand-02 border-b-[1px] text-brand-02"
                    : "text-grey-02"
                }`}
              >
                {step.step}
              </p>
            );
          })}
        </div>
        <div className="mb-8 py-8 rounded-lg shadow-3xl bg-white px-4">
          {steps.slice(0, steps.length - 1).map((step) => (
            <Card
              onCollapse={() => {
                handleChangeTab(step.value);
              }}
              isOpen={
                state.currentTab.tab === step.value && state.currentTab.isOpen
              }
              title={step.title}
              isCompleted={state.completedSteps[step.value]}
            >
              {state.currentTab.tab === "intro" && state.currentTab.isOpen && (
                <Intro nextStep="step_1" dispatch={dispatch} state={state} />
              )}
              {state.currentTab.tab === "step_1" && state.currentTab.isOpen && (
                <ExampleText
                  nextStep="step_2"
                  currentStep="step_1"
                  dispatch={dispatch}
                  name="text_1"
                  placeholder="Paste or type your first text example here, keep it below 8000 characters."
                  state={state}
                />
              )}
              {state.currentTab.tab === "step_2" && state.currentTab.isOpen && (
                <ExampleText
                  nextStep="step_3"
                  currentStep="step_2"
                  dispatch={dispatch}
                  name="text_2"
                  placeholder="Paste or type your second text example here, keep it below 8000 characters."
                  state={state}
                />
              )}
              {state.currentTab.tab === "step_3" && state.currentTab.isOpen && (
                <ExampleText
                  nextStep="results"
                  currentStep="step_3"
                  dispatch={dispatch}
                  name="text_3"
                  placeholder="Paste or type your third text example here, keep it below 8000 characters."
                  state={state}
                  buttonText="Get your writing style results"
                  submitForm={submitForm}
                />
              )}
            </Card>
          ))}
        </div>
      </div>
      {state.status === "submitting" && (
        <div className="w-full left-[10%] fixed top-0  z-[1000] h-full bg-black-rgba flex justify-center items-center">
          <div className=" w-[300px] flex-col h-[300px] flex justify-center items-center bg-white shadow-3xl top-[25%] left-[35%] z-[100]">
            <Spinner />
            <div className="w-[80%] text-center mt-2">
              <Typewriter
                options={{
                  strings: [
                    "Analyzing your sample texts...",
                    "Creating your customized AI persona...",
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 30,
                  deleteSpeed: 2,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizeYourAI;

import { Base64 } from "js-base64";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postRequest } from "../../axios";
import Logo from "../../components/logo";
import Spinner from "../../components/spinner";
import {
  AUTH_SUCCESS,
  ENTER_DASHBOARD,
  SET_USER,
} from "../../context/auth/actions";
import { useAuth } from "../../context/auth/authContext";
import { defaultEditorRoute } from "../../utils/helpers";
import { setAccessToken } from "../../utils/setAccessToken";

const DiscordRedirect = () => {
  const [state, setState] = useState<"loading" | "idle" | "success" | "error">(
    "loading"
  );
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleDiscordAuth = async () => {
      setState("loading");
      const code = searchParams.get("code");
      const state = searchParams.get("state");
      const decoded = JSON.parse(Base64.decode(state || ""));
      const res = await postRequest("auth/discord", {
        ...decoded,
        registration_source: decoded.registration_source || "webapp",
        reference_id: decoded.reference_id || "unknown",
        code,
      });
      await setAccessToken({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });
      if (res.data.onboarded) {
        auth.dispatch({ type: ENTER_DASHBOARD, payload: { user: res.data } });
        navigate(defaultEditorRoute);
      } else {
        auth.dispatch({ type: AUTH_SUCCESS, payload: { user: res.data } });
        navigate("/user/onboarding");
      }
    };

    handleDiscordAuth();
  }, []);

  return (
    <div className="p-[5%] w-screen h-screen bg-light-pink-gradient">
      <Logo />
      <div className="flex h-full w-full justify-center items-center">
        <div className="flex flex-col mt-[-5%] justify-center items-center">
          <p className="text-primary-02 h3 mb-4">
            Hold tight! We're signing you in.
          </p>
          <Spinner primaryColor />
        </div>
      </div>
    </div>
  );
};

export default DiscordRedirect;

import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { cleanUpAuthFromStorage, logout } from "./context/auth/actions";
import { history } from "./router";
import { storageKeys } from "./utils/helpers";

const axiosClient = axios.create();

axiosClient.defaults.headers.post["Content-Type"] = "application/json";
axiosClient.defaults.headers.common["X-Client-Type"] = "webapp";

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 402) {
      history.push("/user/dashboard/earn-more?out_of_credit=true");
    }
    if (error?.response?.status === 422) {
      localStorage.removeItem("cortexAccessToken");
      history.push("/");
    }
    if (error?.response?.status === 416) {
      toast.error(
        "It seems there is not much to extend for this text. Please add more content and hit create again."
      );
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    const isUnAuthStatusCode = error?.response?.status === 401;
    const isTokenExpired = error?.response?.data?.error === 23;
    const isFirstTry = !config?.hasRetried;
    const shouldRefreshToken =
      isUnAuthStatusCode && isTokenExpired && isFirstTry;

    if (shouldRefreshToken) {
      config.hasRetried = true;
      const data = await refreshToken();
      const accessToken = data?.access_token;
      if (accessToken) {
        Object.assign(config.headers, {
          Authorization: "Bearer " + accessToken,
        });
        localStorage.setItem("cortexAccessToken", accessToken);
      }
      return axios(config);
    }
    const isTokenRevoked = error?.response?.data?.error === 24;
    if (isTokenRevoked) {
      logout();
    }
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  const key = storageKeys.REFRESH_TOKEN;
  try {
    const refreshToken = await localStorage.getItem(key);
    const config: AxiosRequestConfig = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT + "auth/token/refresh",
      headers: {
        "Content-Type": "application/json",
        Requester: "webapp",
        Authorization: "Bearer " + refreshToken,
      },
    };
    const response = await axios(config);
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      logout();
    }
    console.error("error: ", error);
  }
};

axiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (config.headers === undefined) {
    config.headers = {};
  } else {
    let token = localStorage.getItem("cortexAccessToken");
    token = JSON.parse(token as any);
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});

axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export function getRequest(URL: string, payload?: { [key: string]: any }) {
  return axiosClient.get(`/${URL}`, payload).then((response) => response);
}

export function postRequest(URL: string, payload?: { [key: string]: any }) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function putRequest(URL: string, payload?: { [key: string]: any }) {
  return axiosClient.put(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL: string, payload?: { [key: string]: any }) {
  return axiosClient.delete(`/${URL}`, payload).then((response) => response);
}

export default axiosClient;

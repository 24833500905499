import React from "react";
import { IconProps } from "../../types";

const Smile = (props: IconProps) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.4375 5.75C5.4375 6.1297 5.1297 6.4375 4.75 6.4375C4.3703 6.4375 4.0625 6.1297 4.0625 5.75C4.0625 5.3703 4.3703 5.0625 4.75 5.0625C5.1297 5.0625 5.4375 5.3703 5.4375 5.75Z"
        fill={props.stroke ? props.stroke : "#5B6772"}
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.125"
      />
      <path
        d="M9.9375 5.75C9.9375 6.1297 9.6297 6.4375 9.25 6.4375C8.8703 6.4375 8.5625 6.1297 8.5625 5.75C8.5625 5.3703 8.8703 5.0625 9.25 5.0625C9.6297 5.0625 9.9375 5.3703 9.9375 5.75Z"
        fill={props.stroke ? props.stroke : "#5B6772"}
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.125"
      />
      <path
        d="M9.5999 8.5C9.33558 8.95537 8.9563 9.33334 8.50001 9.59608C8.04372 9.85881 7.52643 9.9971 6.9999 9.9971C6.47338 9.9971 5.95608 9.85881 5.49979 9.59608C5.0435 9.33334 4.66422 8.95537 4.3999 8.5"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Smile;

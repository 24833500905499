import { getTemplateListData } from "../../utils/getTemplateListData";
import { planType } from "../../utils/helpers";

const languages = [
  {
    name: "Auto-detect",
    emoji: "💡",
    nativeName: "Automatic detection",
    content: {},
    bcp47: "auto",
    native: "Auto",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "English",
    emoji: "🇬🇧",
    nativeName: "English",
    content: {},
    bcp47: "en",
    native: "English",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Spanish",
    emoji: "🇪🇸",
    nativeName: "español, castellano",
    content: {},
    bcp47: "es",
    native: "español, castellano",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "French",
    emoji: "🇫🇷",
    nativeName: "français, langue française",
    content: {},
    bcp47: "fr",
    native: "français, langue française",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "German",
    nativeName: "Deutsch",
    emoji: "🇩🇪",
    content: {},
    bcp47: "de",
    native: "Deutsch",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Korean",
    emoji: "🇰🇷",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
    content: {},
    bcp47: "ko",
    native: "한국어 (韓國語), 조선말 (朝鮮語)",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Portuguese",
    emoji: "🇵🇹",
    nativeName: "Português",
    content: {},
    bcp47: "pt",
    native: "Português",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Dutch",
    emoji: "🇳🇱",
    nativeName: "Nederlands, Vlaams",
    content: {},
    bcp47: "nl",
    native: "Nederlands, Vlaams",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Italian",
    emoji: "🇮🇹",
    nativeName: "Italiano",
    content: {},
    bcp47: "it",
    native: "Italiano",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Chinese",
    emoji: "🇨🇳",
    nativeName: "中文 (Zhōngwén)",
    content: {},
    bcp47: "zh",
    native: "中文 (Zhōngwén)",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Turkish",
    emoji: "🇹🇷",
    nativeName: "Türkçe",
    content: {},
    bcp47: "tr",
    native: "Türkçe",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Norwegian",
    emoji: "🇳🇴",
    nativeName: "Norsk",
    content: {},
    bcp47: "no",
    native: "Norsk",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Swedish",
    emoji: "🇸🇪",
    nativeName: "svenska",
    content: {},
    bcp47: "sv",
    native: "svenska",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Japanese",
    emoji: "🇯🇵",
    nativeName: "日本語 (にほんご)",
    content: {},
    bcp47: "ja",
    native: "日本語 (にほんご)",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Polish",
    emoji: "🇵🇱",
    nativeName: "polski",
    content: {},
    bcp47: "pl",
    native: "polski",
    planTypeToUnlock: planType.Free,
  },

  {
    name: "Greek",
    emoji: "🇬🇷",
    nativeName: "Ελληνικά",
    content: {},
    bcp47: "el",
    native: "Ελληνικά",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Finnish",
    emoji: "🇫🇮",
    nativeName: "suomi, suomen kieli",
    content: {},
    bcp47: "fi",
    native: "suomi, suomen kieli",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Hungarian",
    emoji: "🇭🇺",
    nativeName: "Magyar",
    content: {},
    bcp47: "hu",
    native: "Magyar",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Indonesian",
    emoji: "🇮🇩",
    nativeName: "Bahasa Indonesia",
    content: {},
    bcp47: "id",
    native: "Bahasa Indonesia",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Bulgarian",
    emoji: "🇧🇬",
    nativeName: "български език",
    content: {},
    bcp47: "bg",
    native: "български език",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Czech",
    nativeName: "česky, čeština",
    emoji: "🇨🇿",
    content: {},
    bcp47: "cs",
    native: "česky, čeština",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Danish",
    nativeName: "dansk",
    emoji: "🇩🇰",
    content: {},
    bcp47: "da",
    native: "dansk",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Lithuanian",
    emoji: "🇱🇹",
    nativeName: "lietuvių kalba",
    content: {},
    bcp47: "lt",
    native: "lietuvių kalba",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Latvian",
    emoji: "🇱🇻",
    nativeName: "latviešu valoda",
    content: {},
    bcp47: "lv",
    native: "latviešu valoda",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Slovak",
    emoji: "🇸🇰",
    nativeName: "slovenčina",
    content: {},
    bcp47: "sk",
    native: "slovenčina",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Slovene",
    emoji: "🇸🇮",
    nativeName: "slovenščina",
    content: {},
    bcp47: "sl",
    native: "slovenščina",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Ukrainian",
    emoji: "🇺🇦",
    nativeName: "українська",
    content: {},
    bcp47: "uk",
    native: "українська",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Romanian",
    emoji: "🇷🇴",
    nativeName: "română",
    content: {},
    bcp47: "ro",
    native: "română",
    planTypeToUnlock: planType.Free,
  },
  {
    name: "Russian",
    emoji: "🇷🇺",
    nativeName: "русский",
    content: {},
    bcp47: "ru",
    native: "русский",
    planTypeToUnlock: planType.Free,
  }
];

export const languageOptions = languages.map((item) => ({
  value: item.bcp47,
  label: `${item.emoji} ${item.name}`,
  planTypeToUnlock: item.planTypeToUnlock,
  emoji: item.emoji,
}));

export const templateOptions = getTemplateListData().map((item) => ({
  label: item.title,
  value: item.value,
}));

export const productCategoryOptions = [
  { label: "Home & Kitchen", value: "Home & Kitchen" },
  { label: "Clothing", value: "Clothing" },
  { label: "Jewellery", value: "Jewellery" },
  { label: "Shoes & Bags", value: "Shoes & Bags" },
  { label: "Pet Supplies", value: "Pet Supplies" },
  { label: "Beauty", value: "Beauty" },
  { label: "Sports & Outdoors", value: "Sports & Outdoors" },
  { label: "Electronics", value: "Electronics" },
  { label: "Cell Phones & Accessories", value: "Cell Phones & Accessories" },
  { label: "Health & Personal Care", value: "Health & Personal Care" },
  { label: "Toys & Games", value: "Toys & Games" },
  { label: "Tools & Home Improvement", value: "Tools & Home Improvement" },
  { label: "Grocery & Gourmet Food", value: "Grocery & Gourmet Food" },
  { label: "Patio, Lawn & Garden", value: "Patio, Lawn & Garden" },
  { label: "Baby", value: "Baby" },
  { label: "Musical Instruments", value: "Musical Instruments" },
];

export const creativityOptions = [
  {
    label: "Low",
    value: 0.5,
    planTypeToUnlock: planType.Business,
  },
  {
    label: "Medium",
    value: 0.6,
    planTypeToUnlock: planType.Business,
  },
  {
    label: "High",
    value: 0.8,
    planTypeToUnlock: planType.Business,
  },
  {
    label: "Default",
    value: 0.65,
    planTypeToUnlock: planType.Free,
  },
];

export const textLengthOptions = [
  {
    value: "short",
    icon: () => "S",
    label: "Short - 100 words",
    planTypeToUnlock: planType.Free,
  },
  {
    value: "medium",
    icon: () => "M",
    label: "Medium - 200 words",
    planTypeToUnlock: planType.Pro,
  },
  {
    value: "long",
    icon: () => "L",
    label: "Long - 300 words",
    planTypeToUnlock: planType.Business,
  },
];

export const creationCountOptions = [
  {
    value: 1,
    label: "1x",
  },
  {
    value: 2,
    label: "2x",
  },
  {
    value: 3,
    label: "3x",
  },
];

export const reachoutReasonOptions = [
  {
    value: "I saw you are part of LinkedIn group {{Name}}",
    label: "I saw you are part of LinkedIn group {{Name}}",
  },
  {
    value: "I saw you are team member of {{Company}}",
    label: "I saw you are team member of {{Company}}",
  },
  {
    value: "We met each other at conference {{Name}}",
    label: "We met each other at conference {{Name}}",
  },
  {
    value: "We were working on the same project {{Name}}",
    label: "We were working on the same project {{Name}}",
  },
  {
    value: "We had a call last week about {{Topic}}",
    label: "We had a call last week about {{Topic}}",
  },
];

export const valuePropositionOptions = [
  {
    value: "Increase your traffic by 24x",
    label: "Increase your traffic by 24x",
  },
  {
    value: "Grow your revenue by X%",
    label: "Grow your revenue by X%",
  },
  {
    value: "Increase number of closed deals by X%",
    label: "Increase number of closed deals by X%",
  },
  {
    value: "We had a call last week about {{Topic}}",
    label: "We had a call last week about {{Topic}}",
  },
];

export const callToActionOptions = [
  {
    value: "Book a demo with me from the link below",
    label: "Book a demo with me from the link below",
  },
  {
    value: "Let's get on a short call if you're free at {{DateAndTime}}",
    label: "Let's get on a short call if you're free at {{DateAndTime}}",
  },
  {
    value:
      "We could discuss if you're interested in hearing more about {{topic}}",
    label:
      "We could discuss if you're interested in hearing more about {{topic}}",
  },
];

export const targetAudience = [
  {
    value: "Freelance writers",
    label: "Freelance writers",
  },
  {
    value: "Graphic designers",
    label: "Graphic designers",
  },
  {
    value: "Java developers",
    label: "Java developers",
  },
  {
    value: "Content writers",
    label: "Content writers",
  },
  {
    value: "Executive assistants",
    label: "Executive assistants",
  },
  {
    value: "Founders",
    label: "Founders",
  },
  {
    value: "College students",
    label: "College students",
  },
];

export const emailPurposeOptions = [
  { value: "Cold sales outreach", label: "Cold sales outreach" },
  { value: "Customer support answer", label: "Customer support answer" },
  {
    value: "Candidate interview invitation",
    label: "Candidate interview invitation",
  },
  { value: "Candidate outreach", label: "Candidate outreach" },
  {
    value: "Candidate reference call request",
    label: "Candidate reference call request",
  },
  { value: "Candidate rejection", label: "Candidate rejection" },
  { value: "Candidate to next round", label: "Candidate to next round" },
  { value: "Customer call request", label: "Customer call request" },
  { value: "Generic meeting scheduler", label: "Generic meeting scheduler" },
  { value: "Generic request", label: "Generic request" },
  { value: "I will get back to you", label: "I will get back to you" },
  { value: "Link building outreach", label: "Link building outreach" },
  {
    value: "Overdue payment notification",
    label: "Overdue payment notification",
  },
  { value: "PR outreach", label: "PR outreach" },
  { value: "Sales email rejection", label: "Sales email rejection" },
  { value: "Sales prospect follow-up", label: "Sales prospect follow-up" },
  { value: "Testimonial request", label: "Testimonial request" },
  {
    value: "Thank customer for feedback",
    label: "Thank customer for feedback",
  },
  { value: "Feedback request", label: "Feedback request" },
  { value: "Founder introduction", label: "Founder introduction" },
  { value: "Invitation to event", label: "Invitation to event" },
  { value: "Job application outreach", label: "Job application outreach" },
  { value: "Negative review reply", label: "Negative review reply" },
  { value: "Positive review reply", label: "Positive review reply" },
  { value: "Re-engagement email", label: "Re-engagement email" },
  { value: "Self-introduction", label: "Self-introduction" },
  { value: "Answering a job offer", label: "Answering a job offer" },
  {
    value: "Breaking up with a sales prospect",
    label: "Breaking up with a sales prospect",
  },
  { value: "Client project update", label: "Client project update" },
  { value: "️Founder acceptance", label: "️Founder acceptance" },
  { value: "Founder reject", label: "Founder reject" },
  { value: "Fundraising outreach", label: "Fundraising outreach" },
  { value: "General business inquiry", label: "General business inquiry" },
  { value: "Give feedback on task", label: "Give feedback on task" },
  { value: "Influencer outreach", label: "Influencer outreach" },
  { value: "Internal inquiry", label: "Internal inquiry" },
  { value: "Investor update", label: "Investor update" },
  { value: "Leave request", label: "Leave request" },
  { value: "Out of office", label: "Out of office" },
  { value: "Outreach to a founder", label: "Outreach to a founder" },
  {
    value: "Product update to customers",
    label: "Product update to customers",
  },
  { value: "Product update to team", label: "Product update to team" },
  { value: "Reply to a feature request", label: "Reply to a feature request" },
  { value: "Salary increment request", label: "Salary increment request" },
  { value: "Sales email acceptance", label: "Sales email acceptance" },
  { value: "Team update", label: "Team update" },
];

export const videoCategoryOptions = [
  { label: "Film & Animation", value: "Film & Animation" },
  { label: "Autos & Vehicles", value: "Autos & Vehicles" },
  { label: "Music", value: "Music" },
  { label: "Pets & Animals", value: "Pets & Animals" },
  { label: "Sports", value: "Sports" },
  { label: "Travel & Events", value: "Travel & Events" },
  { label: "Gaming", value: "Gaming" },
  { label: "People & Blogs", value: "People & Blogs" },
  { label: "Comedy", value: "Comedy" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "News & Politics", value: "News & Politics" },
  { label: "Howto & Style", value: "Howto & Style" },
  { label: "Education", value: "Education" },
  { label: "Science & Technology", value: "Science & Technology" },
  { label: "Nonprofits & Activism", value: "Nonprofits & Activism" },
];

export const adTypeOptions = [
  {
    value: "Explainer/Informational",
    label: "Explainer/Informational",
  },
  {
    value: "Retargeting Ad",
    label: "Retargeting Ad",
  },
  {
    value: "Sales offer",
    label: "Sales offer",
  },
  {
    value: "Long description",
    label: "Long description",
  },
  {
    value: "AIDA(Attention-Interest-Desire -Action)",
    label: "AIDA(Attention-Interest-Desire -Action)",
  },
  {
    value: "PAS(Pain-Agitate-Solution)",
    label: "PAS(Pain-Agitate-Solution)",
  },
  {
    value: "BAB(Before-After-Bridge)",
    label: "BAB(Before-After-Bridge)",
  },
  {
    value: "FAB(Feature-Advantage-Benefit)",
    label: "FAB(Feature-Advantage-Benefit)",
  },
  {
    value: "4P(Problem-Promise-Proof-Proposal)",
    label: "4P(Problem-Promise-Proof-Proposal)",
  },
];

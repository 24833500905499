import { SVGProps } from "react";

export type AuthFormTypes = {
  type: string;
  name: string;
  label?: string;
  placeholder?: string;
  helperLink?: {
    text: string;
    link?: string;
  };
  rules?: any;
};

type FeedbackRating =
  | "bad_quality"
  | "good_quality"
  | "favorite"
  | "copied"
  | "offensive"
  | "doesnt_make_sense"
  | "thumbs_up"
  | "thumbs_down"
  | "tab"
  | "ctrl_c";

type FeedbackChannel =
  | "extension_creation_window"
  | "inline_autocomplete"
  | "extension_chat"
  | "extension_sidebar_text_generator"
  | "webapp_rewrite_editor";

export type Creation = {
  text: string;
  id: string;
  audioSrc?: string;
};

export type OutputFeedback = {
  output_id: string;
  rating: FeedbackRating;
  channel: FeedbackChannel;
  metadata?: any;
};

export type CreationStatus =
  | "loading"
  | "loading_more"
  | "idle"
  | "error"
  | "success"
  | "creation_up_no_auth"
  | "creation_up"
  | "language_not_supported"
  | "fetching_templates";

export type AuthPageData = {
  type: "signIn" | "signup";
  title: string;
  subtitle?: string;
  forms?: AuthFormTypes[];
  checkboxText: React.ReactNode | string;
  buttonText: string;
  footer?: string;
  changePage?: {
    text: string;
    linkText: string;
    link: string;
  };
};

export enum OnboardingStep {
  howDidYouHearAboutUs = "HOW_DID_YOU_HEAR_ABOUT_US",
  pricing = "PRICING",
  demo = "DEMO",
  success = "SUCCESS",
}

export type AppError = {
  type: number | string;
  errorMessage: string | null;
};

export type PlanType = "Free" | "Pro" | "Business";

export type User = {
  email: string;
  status: "not-verified" | "success";
  affiliate_link: string;
  credit: number;
  plan_type: "Free" | "Business" | "Pro";
  shopify: boolean;
  shopify_url?: string;
  registration_source?: string;
  data: {
    first_name: string;
    last_name: string;
    company_description: string;
    id: number;
    postal_code: string;
    company_name: string;
    country: string;
    street_address_1: string;
    billing_name: string;
    billing_address: string;
    city: string;
    profile_image: string;
  };
};

export type Product = {
  id: string;
  image: string;
  title: string;
  productName: string;
  description: string;
  totalInventory: number;
};

export type LoadingState = "loading" | "success" | "error" | "idle";

export type IconProps = Partial<SVGProps<SVGSVGElement>> & {
  pathFill?: string;
};

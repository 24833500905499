import React from "react";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { ReactComponent as HeartIcon } from "../assets/icons/heart.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/share.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import DropdownItem from "./dropdownItem";

type Props = {
  onFavorite: () => void;
  onDelete: () => void;
  onEdit?: () => void;
};

const DocumentMenuDropdown = ({ onFavorite, onDelete, onEdit }: Props) => {
  return (
    <div className="shadow-md w-[256px] bg-white py-6 pb-0 px-4 bg-white border rounded shadow">
      <DropdownItem onClick={onEdit}>
        <EditIcon />
        <p className="mt-1 ml-3 text2 text-grey-02">Edit</p>
      </DropdownItem>
      <DropdownItem>
        <CopyIcon />
        <p className="mt-1 ml-3 text2 text-grey-02">Copy to clipboard</p>
      </DropdownItem>
      <DropdownItem onClick={onFavorite}>
        <HeartIcon />
        <p className="mt-1 ml-3 text2 text-grey-02">Add to favorite</p>
      </DropdownItem>
      <DropdownItem>
        <ShareIcon />
        <p className="mt-1 ml-3 text2 text-grey-02">Share</p>
      </DropdownItem>
      <DropdownItem onClick={onDelete}>
        <TrashIcon />
        <p className="mt-1 ml-3 text2 text-grey-02">Delete</p>
      </DropdownItem>
    </div>
  );
};

export default DocumentMenuDropdown;

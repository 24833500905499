import React from "react";
import SocialAuthButton from "../../../components/socialAuthButton";
import { ReactComponent as ExternalLinkIcon } from "../../../assets/icons/external-link.svg";
// import Input from "../../../components/input";
// import Button from "../../../components/button";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import { useAuth } from "../../../context/auth/authContext";
import { logout, sendVerificationEmail } from "../../../context/auth/actions";
import { Helmet } from "react-helmet";
import useRecaptcha from "../../../hooks/useRecaptcha";

const VerifyEmail = () => {
  const { state, dispatch } = useAuth();
  const { token: captchaToken, handleReCaptchaVerify } = useRecaptcha();

  const openGmail = () => {
    window.open("http://mail.google.com", "_blank");
  };

  const verifyEmail = async () => {
    const token = await handleReCaptchaVerify();
    sendVerificationEmail(dispatch, {
      email: state.user?.email,
      captcha_response: token,
    });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Verify Email</title>
      </Helmet>
      <p
        onClick={() => logout(dispatch)}
        className="pt-4 pr-8 text-right underline cursor-pointer text2 text-grey-02"
      >
        Logout
      </p>
      <div className="p-6 md:p-0 m-auto mt-32 max-w-[456px]">
        <h1 className="text-center h1 text-primary-02">Verify your email</h1>
        <p className="mt-2 mb-4 text-center text-grey-02 text2">
          Please check your inbox to verify your email.
        </p>
        <SocialAuthButton
          type="google"
          onClick={openGmail}
          text={
            <div className="flex items-center">
              Open Gmail inbox <ExternalLinkIcon className="ml-2" />
            </div>
          }
        />
        <p className="text-center text2 text-grey-02">
          Email sent to
          <span className="text-primary-02"> {state.user?.email}</span>
        </p>
        {/* <Input
        type="password"
        name="code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        label={
          <div className="flex flex-col items-start text2 text-grey-02 md:flex-row md:items-center">
            Code sent to{" "}
            <p className="md:ml-2 h2 text-primary-02"> {state.user?.email}</p>
          </div>
        }
      /> */}
        <div className="mt-4">
          {/* <Button>Continue</Button> */}
          <p
            onClick={() => !state.submittingForm && verifyEmail()}
            className="flex items-center justify-center mt-5 text-center cursor-pointer text2 text-grey-02"
          >
            <RefreshIcon className="mr-2" />
            {state.submittingForm ? "Sending email..." : "Send email again"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;

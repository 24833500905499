import React, { useRef, useEffect } from "react";

// Hook that detects clicks outside of the passed ref

type HookParams = {
  onClickOutside: () => void;
  ref: React.MutableRefObject<any>;
};
function useOutsideClickDetector({ ref, onClickOutside }: HookParams) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

type Props = {
  children: React.ReactNode;
  onClickOutside: () => void;
};

function OutsideClickDetector({ children, onClickOutside }: Props) {
  const wrapperRef = useRef(null);
  useOutsideClickDetector({
    ref: wrapperRef,
    onClickOutside,
  });

  return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideClickDetector;

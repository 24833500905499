import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import axiosClient from "../../axios";

const fetchAudio = async ({
  text,
  language,
  signal,
  voice,
}: {
  text: string;
  language: string;
  voice?: string;
  signal: AbortSignal;
}) => {
  let requestData: any = {
    text,
    gender: voice ? voice : "female",
    language,
  };

  //TODO: remove extension key and use Bearer token.

  const res = await axiosClient({
    method: "post",
    url: `/internal/v1/accessibility/voiceover`,
    data: requestData,
    signal,
  });

  return res;
};

type AudioState = "playing" | "paused" | "error" | "idle" | "loading";

export type Action =
  | {
      type: "SET_AUDIO_STATE";
      payload: AudioState;
    }
  | {
      type: "RESET_STATE";
    };

export type State = {
  audioState: AudioState;
};

export const initialState: State = {
  audioState: "idle",
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_AUDIO_STATE": {
      return { ...state, audioState: action.payload };
    }

    case "RESET_STATE": {
      return initialState;
    }
  }
};

type AudioPlayerProps = {
  text: string;
  onPlayClick?: () => void;
  shouldDestroyAudio?: boolean;
};

const preventKeyboardFromControllingAudio = () => {
  // next two lines prevent the audio from going to the chrome's player (to prevent keyboard control)
  if (navigator?.mediaSession && !navigator?.mediaSession?.metadata) {
    navigator?.mediaSession?.setActionHandler?.("play", function () {});
    navigator?.mediaSession?.setActionHandler?.("pause", function () {});
  }
};

const useAudioPlayer = (props: AudioPlayerProps) => {
  const [audio] = useState<HTMLAudioElement>(new Audio());
  const [abortController, setAC] = useState<AbortController>(
    new AbortController()
  );
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const abortFetchAudioSrc = () => {
    abortController.abort();
    setAC(new AbortController());
  };

  const cleanupAudio = () => {
    abortFetchAudioSrc();
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      audio.src = "";
    }
    dispatch({ type: "RESET_STATE" });
  };

  useEffect(() => {
    if (props.shouldDestroyAudio) {
      cleanupAudio();
    }
    const handlePlayEvent = () => {
      preventKeyboardFromControllingAudio();
    };

    const handleCanPlayEvent = () => {
      dispatch({ type: "SET_AUDIO_STATE", payload: "playing" });
    };

    const handleCanPlayThroughEvent = () => {
      dispatch({ type: "SET_AUDIO_STATE", payload: "playing" });
    };

    const handlePauseEvent = () => {
      dispatch({ type: "SET_AUDIO_STATE", payload: "paused" });
    };

    const handledEndedEvent = () => {
      dispatch({ type: "SET_AUDIO_STATE", payload: "paused" });
    };

    audio.addEventListener("play", handlePlayEvent);
    audio.addEventListener("pause", handlePauseEvent);
    document.addEventListener("visibilitychange", handlePauseEvent);
    audio.addEventListener("canplay", handleCanPlayEvent);
    audio.addEventListener("canplaythrough", handleCanPlayThroughEvent);
    audio.addEventListener("ended", handledEndedEvent);

    return () => {
      audio.removeEventListener("play", handlePlayEvent);
      audio.removeEventListener("pause", handlePauseEvent);
      audio.removeEventListener("canplay", handleCanPlayEvent);
      audio.removeEventListener("canplaythrough", handleCanPlayThroughEvent);
      document.removeEventListener("visibilitychange", handlePauseEvent);
    };
  }, [props.text, props.shouldDestroyAudio]);

  const handlePlay = async () => {
    try {
      dispatch({ type: "SET_AUDIO_STATE", payload: "loading" });
      //todo: check cached url first
      const res = await fetchAudio({
        text: props.text,
        language: "en",
        signal: abortController.signal,
      });
      console.log(res, "res here");

      if (res.data) {
        playAudio({ src: res.data.data.voice_over });
      }
    } catch (error: any) {
      const isAborted = error.message === "canceled";
      if (isAborted) {
        dispatch({ type: "SET_AUDIO_STATE", payload: "paused" });
      } else {
        dispatch({ type: "SET_AUDIO_STATE", payload: "error" });
        console.error("handle play error: ", error);
      }
    }
  };

  const handlePause = () => {
    abortFetchAudioSrc();
    if (audio) {
      audio.pause();
    }
  };

  const playAudio = ({ src }: { src: string }) => {
    audio.src = src;
    audio.play();
  };

  const isAudioLoadingOrPlaying = () =>
    state.audioState === "playing" || state.audioState === "loading";

  return {
    state,
    isAudioLoadingOrPlaying,
    dispatch,
    handlePlay,
    handlePause,
  };
};

export default useAudioPlayer;

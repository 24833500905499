import { ChartOptions } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

type Props = {
  chartData: any;
};

function UsageGraph({ chartData }: Props) {
  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },

    scales: {
      y: {
        offset: false,
        beginAtZero: true,
        afterFit: function (scale) {
          scale.height = 0; //<-- set value as you wish
        },
        grid: { drawOnChartArea: true, drawBorder: false, borderDash: [8, 8] },
        // ticks: {
        //   stepSize: 2000,
        // },
      },
      x: {
        grid: { display: true, drawOnChartArea: false, drawBorder: false },
      },
    },
  };
  return <Line options={options as any} data={chartData} />;
}

export default UsageGraph;

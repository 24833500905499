import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { Placement } from "@popperjs/core";
import { ChipDark } from "./chip";

type CopyProps = {
  text: string;
  onCopy?: () => void;
  tippyPlacement?: Placement;
};

const Copy = (props: CopyProps) => {
  const [copyToolTip, setCopyToolTip] = useState<{
    show: boolean;
    text?: string;
  }>();

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(props.text);
    setCopyToolTip({
      show: true,
      text: "Copied!",
    });
    props.onCopy?.();
  };

  return (
    <div>
      <Tippy
        visible={copyToolTip?.show}
        offset={[0, 4]}
        content={<ChipDark text={copyToolTip?.text || "Copy"} />}
        delay={[300, 0]}
        moveTransition={"none"}
        placement={props.tippyPlacement ? props.tippyPlacement : "left"}
      >
        <div
          onClick={handleClick}
          className="flex justify-end float-right w-5 cursor-pointer"
          onMouseEnter={() => {
            setCopyToolTip({
              show: true,
              text: "Copy",
            });
          }}
          onMouseLeave={() => {
            setCopyToolTip({
              show: false,
              text: copyToolTip?.text || "Copy",
            });
          }}
        >
          <CopyIcon />
        </div>
      </Tippy>
    </div>
  );
};

export default Copy;

import React from "react";
import { UseFormRegister, UseFormRegisterReturn } from "react-hook-form";

type Props = {
  size?: "large" | "small";
  label?: string | React.ReactNode;
  name: UseFormRegisterReturn["name"];
  value?: boolean;
  required?: boolean | string;
  handleCheckbox?: (val: boolean, name?: string) => void;
  register?: UseFormRegister<UseFormRegisterReturn>;
  errors?: { [name: string]: any };
  className?: string;
  containerStyle?: any;
};

const Checkbox = ({
  size = "small",
  label,
  name,
  value = false,
  handleCheckbox,
  register,
  errors,
  required,
  className,
  containerStyle,
}: Props) => {
  if (size === "large") {
    return (
      <button
        onClick={() =>
          handleCheckbox && handleCheckbox(value === true ? false : true, name)
        }
        style={containerStyle}
        className={
          "cursor-pointer mt-4 flex justify-between px-4 py-3 bg-white border rounded w-full max-w-[456px]"
        }
      >
        <div className={`text2 text-grey-02`}>{label}</div>
        <input
          onChange={() =>
            handleCheckbox &&
            handleCheckbox(value === true ? false : true, name)
          }
          name={name}
          checked={value}
          type="checkbox"
          className="block cursor-pointer checkbox"
        />
      </button>
    );
  }

  return (
    <div>
      <div className="flex items-start mb-6 cursor-pointer">
        <label
          style={containerStyle}
          className="text-primary-02 text2 mt-[0.5px] ml-2 flex items-center cursor-pointer select-none"
        >
          <input
            {...register?.(name as "name", { required })}
            onChange={(e) => {
              let val = e.target.checked;
              handleCheckbox?.(val, name);
            }}
            value={value ? "checked" : "unchecked"}
            checked={value}
            name={name}
            className={`cursor-pointer checkbox block mr-2 ${className}`}
            type="checkbox"
            id={name}
          />
          {label}
        </label>
      </div>
      <p className="text2 text-red-01 mt-[-16px] mb-4">
        {errors?.[name] && errors[name].message}
      </p>
    </div>
  );
};

export default Checkbox;

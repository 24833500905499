import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import PricingPlans from "../../components/pricingPlans";
import { ReactComponent as CloseButton } from "../../assets/icons/close_button.svg";
import { useAuth } from "../../context/auth/authContext";
import PaymentRedirectModal from "../../components/paymentRedirectModal";
import NotificationModal from "../../components/notificationModal";
import { getRequest, postRequest } from "../../axios";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

import { ReactComponent as CheersIcon } from "../../assets/icons/cheers.svg";
import { ReactComponent as FaceEmoji } from "../../assets/icons/face_emoji.svg";

import _ from "lodash";
import { Helmet } from "react-helmet";
import axios from "axios";
import { analytics } from "../../utils/analytics";
import { PRICING_PAGE_VISIT, UPGRADE } from "../../constants/analytics";
import { openTallyPopup } from "../../utils/tallyPopup";
import Button from "../../components/button";
import Spinner from "../../components/spinner";

const Pricing = () => {
  const modalStyles = { overlay: { zIndex: 100000 } };
  const [planDuration, setPlanDuration] = useState<"monthly" | "yearly">(
    "yearly"
  );
  const { pathname } = useLocation();
  const [plans, setPlans] = useState({
    business: { monthly: 0, yearly: 0 },
    pro: { monthly: 0, yearly: 0 },
  });
  const auth = useAuth();
  const currentPlan = auth.state.user?.plan_type;
  const [selectedPlan, setSelectedPlan] = useState({
    plan: currentPlan,
    price: 0,
    duration: "monthly",
    discountPercentage: 0,
  });
  const [currency, setCurrency] = useState("$");
  const [appliedDiscountCode, setAppliedDiscountCode] = useState("");
  const [discountCodeState, setDiscountCodeState] = useState<
    "idle" | "success" | "error" | "loading"
  >("idle");
  const [state, setState] = useState<"idle" | "success" | "error" | "loading">(
    "idle"
  );
  const [showPaymentModal, togglePaymentModal] = useState(false);
  const [showSuccessModal, toggleSuccessModal] = useState(false);
  const [showFailureModal, toggleFailureModal] = useState(false);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    if (pathname === "/payment/success") {
      analytics(UPGRADE, {
        email: auth.state.user?.email,
      });
      window.location.href = `/tally.html?email=${auth.state.user?.email}&plan_type=${auth.state.user?.plan_type}`;
    } else if (pathname === "/payment/failure") {
      toggleFailureModal(true);
    }
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      setState("loading");
      const res = await getRequest("payment/configuration");
      setCurrency(res.data.currency);
      setPlans({
        business: {
          monthly: res.data.products.Business.price.monthly,
          yearly: res.data.products.Business.price.yearly / 12,
        },
        pro: {
          monthly: res.data.products.Pro.price.monthly,
          yearly: res.data.products.Pro.price.yearly / 12,
        },
      });
      analytics(PRICING_PAGE_VISIT, {
        email: auth.state.user?.email,
      });
      setState("success");
    } catch (err) {
      setState("error");
      console.log(err);
    }
  };

  const openDiscountCodeModal = (val: any) => {
    const allPlans = plans as any;
    const selectedPrice = allPlans[val.toLowerCase()][planDuration];
    setSelectedPlan({
      ...selectedPlan,
      price: selectedPrice,
      duration: planDuration,
      plan: val,
    });
    togglePaymentModal(true);
  };

  const createStripeSession = async (plan: any) => {
    try {
      toast.loading("Redirecting you to stripe.com.");
      let coupon = appliedDiscountCode;
      coupon = discountCodeState === "error" ? "" : coupon;
      !coupon && setAppliedDiscountCode("");
      let data: any = {
        product: plan,
        quantity: "1",
        payment: ["card"],
        frequency: planDuration,
        coupon: "",
      };
      const res = await postRequest("payment/create_session", data);
      redirectToStripe(res.data);
    } catch (err) {
      console.log(err);
      toast.dismiss();
      toast.error("Something went wrong. Please try again");
    }
  };

  const redirectToStripe = async (data: any) => {
    loadStripe(data.publicKey)
      .then((stripe: any) => {
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
      })
      .then(() => {
        toast.dismiss();
      })
      .catch(() => {
        toast.dismiss();
      });
  };

  const applyDiscountCode = async (discountCode: string) => {
    try {
      toast.loading("Applying discount code...");
      const res = await postRequest("payment/discount", {
        coupon: discountCode,
      });
      if (res.data.status === "success") {
        setSelectedPlan({
          ...selectedPlan,
          price: selectedPlan.price * res.data.discount_amount,
          discountPercentage: res.data.discount_amount,
        });
        setAppliedDiscountCode(discountCode);
        setDiscountCodeState("success");
        toast.dismiss();
      } else {
        toast.dismiss();
        setDiscountCodeState("error");
      }
    } catch (err) {
      setDiscountCodeState("error");
      console.log(err);
    }
  };

  const handlePlanDurationChange = () => {
    if (planDuration === "monthly") {
      setPlanDuration("yearly");
    } else {
      setPlanDuration("monthly");
    }
  };

  const closePaymentModal = () => {
    setAppliedDiscountCode("");
    setDiscountCodeState("idle");
    setSelectedPlan({
      plan: currentPlan,
      price: 0,
      duration: "monthly",
      discountPercentage: 0,
    });
    togglePaymentModal(false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Pricing</title>
      </Helmet>
      <Modal
        style={modalStyles}
        isOpen={true}
        className="relative z-30 flex items-center w-full h-full m-auto bg-black-rgba"
      >
        <div className="flex flex-col w-full h-full m-auto overflow-auto bg-white rounded shadow ">
          <div className="flex justify-end px-6 pt-2">
            <CloseButton className="cursor-pointer" onClick={() => goBack()} />
          </div>
          {state === "loading" && (
            <div className="mt-10 text-center m-auto justify-center p-6">
              <h1 className="h1 text-primary-02">
                Create more with Friends or Get Business
              </h1>
              <p className="text2 text-grey-02 mb-20">
                Earn 10 extra creations per day per referred friend. Use without
                payments required.
              </p>
              <Spinner primaryColor />
            </div>
          )}
          {state === "success" && (
            <PricingPlans
              currency={currency}
              plans={plans}
              planDuration={planDuration}
              handlePlanDurationChange={handlePlanDurationChange}
              currentPlan={currentPlan}
              onSelect={createStripeSession}
            />
          )}
        </div>
        {/* <PaymentRedirectModal
          discountCodeState={discountCodeState}
          applyDiscountCode={applyDiscountCode}
          plan={selectedPlan}
          onContinue={createStripeSession}
          showModal={showPaymentModal}
          closeModal={closePaymentModal}
        /> */}
        <NotificationModal
          link={"/user/dashboard/documents"}
          icon={() => <CheersIcon />}
          title={"Your payment was successful"}
          description={"What will you do with all this creative power?"}
          buttonText={"Create text"}
          closeModal={() => toggleSuccessModal(false)}
          showModal={showSuccessModal}
        />
        <NotificationModal
          icon={() => <FaceEmoji />}
          title={"Something went wrong"}
          description={
            "Try again or reach out to us to payments@textcortex.com"
          }
          buttonText={"Try again"}
          closeModal={() => toggleFailureModal(false)}
          showModal={showFailureModal}
        />
      </Modal>
    </>
  );
};

export default Pricing;

import React from "react";
import OnboardingRouter from "./onboardingRouter";
import DashboardRouter from "./dashboardRouter";
import { useAuth } from "../context/auth/authContext";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const Router = () => {
  const {
    state: { isLoggedIn },
  } = useAuth();
  return isLoggedIn ? <DashboardRouter /> : <OnboardingRouter />;
};

export default Router;

import React from "react";
import PricingPlans from "../../../components/pricingPlans";

type Props = {
  onNext: () => void;
};

const Pricing = ({ onNext }: Props) => {
  return (
    <PricingPlans
      currency={"$"}
      handlePlanDurationChange={() => {}}
      onSelect={onNext}
    />
  );
};

export default Pricing;

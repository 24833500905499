import React, { ReactNode } from "react";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { ChipDark as Label } from "./chip";

type Item = {
  value: string | number;
  icon?: any;
  label: string;
};

type Props = {
  value: string | number;
  onChange?: (value: string | number) => void;
  items: Item[];
  control?: ReactNode;
  showIcon?: boolean;
  showLabel?: boolean;
  showLabelOnControl?: boolean;
  showHelperForOptions?: boolean;
  hint?: string;
};

export const hideOnInnerButtonPress = {
  name: "hideOnInnerButtonPress",
  defaultValue: true,
  fn(instance: any) {
    return {
      onCreate() {
        instance.popper.addEventListener("click", (event: any) => {
          if (
            instance.props.hideOnInnerButtonPress &&
            event.target.getAttribute("data-hide-menu-on-press") !== "false"
          ) {
            setTimeout(() => instance.hide(), 50);
            return event;
          }
        });
      },
    };
  },
};

export const hideOnMouseLeave = {
  name: "hideOnMouseLeave",
  defaultValue: true,
  fn(instance: any) {
    return {
      onCreate() {
        let timeoutId: any;
        instance.popper.addEventListener("mouseleave", (event: any) => {
          // if (
          //   instance.props.hideOnMouseLeave &&
          //   event.target.getAttribute("data-hide-menu-on-press") !== "false"
          // ) {
          timeoutId = setTimeout(() => instance.hide(), 550);
          return event;
          // }
        });

        instance.popper.addEventListener("mouseenter", (event: any) => {
          // if (
          //   instance.props.hideOnMouseLeave &&
          //   event.target.getAttribute("data-hide-menu-on-press") !== "false"
          // ) {
          clearTimeout(timeoutId);
          return event;
          // }
        });
      },
    };
  },
};

type OptionsProps = {
  value: string | number;
  onChange?: (value: string | number) => void;
  items: Item[];
  showIcon?: boolean;
  showLabel?: boolean;
  maxHeight?: number;
};

export const Options = ({
  items,
  onChange,
  value,
  showIcon,
  showLabel,
  maxHeight,
}: OptionsProps) => {
  return (
    <div className="">
      <div
        className={`rounded flex flex-col min-h-[110px] justify-between  shadow-dropdown border bg-white scrollbar-small overflow-y-auto`}
        style={{
          maxHeight: maxHeight ? maxHeight : 200,
        }}
      >
        {items.map((item) => (
          <div
            onClick={() => {
              onChange && onChange(item.value);
            }}
            className={classNames({
              "cursor-pointer px-[10px] py-1 hover:bg-primary-07 text-neutral-03 text-center h5BoldRegular":
                true,
              "bg-primary-07": value === item.value,
            })}
          >
            {!showIcon ? (
              <p className="text2">{item.label}</p>
            ) : showLabel ? (
              <div className="flex items-center">
                {item.icon && <item.icon />}
                <p className="ml-2 text2">{item.label}</p>
              </div>
            ) : (
              item.icon && <item.icon />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ParagraphRadio = ({
  value,
  onChange,
  items,
  control,
  showIcon = true,
  showLabel = false,
  showLabelOnControl = false,
  ...props
}: Props) => {
  const getControlIcon = () => {
    const item = items.find((item: any) => item.value === value);
    const icon = item ? item.icon : "";
    return icon;
  };

  const ControlIcon = showIcon ? getControlIcon() : null;

  const item = items.find((item: any) => item.value === value);

  return (
    <div>
      <Tippy
        zIndex={1000}
        content={
          <Options
            items={items}
            value={value}
            onChange={(value) => {
              onChange && onChange(value);
            }}
            showIcon={showIcon}
            showLabel={showLabel}
          />
        }
        delay={[100, 500]}
        trigger="click"
        interactive={true}
        plugins={[hideOnInnerButtonPress, hideOnMouseLeave]}
      >
        <Tippy
          zIndex={1000}
          content={<Label text={props.hint || (item ? item.label : "")} />}
          delay={[300, 0]}
          placement="top"
        >
          <div className="h-6 flex items-center  cursor-pointer px-[10px] py-[5px] rounded hover:bg-primary-07 ">
            {control ? (
              control
            ) : (
              <div className="flex items-center">
                <ControlIcon />
                {showLabelOnControl && (
                  <p className="ml-2 caption2">{item ? item.label : ""}</p>
                )}
              </div>
            )}
          </div>
        </Tippy>
      </Tippy>
    </div>
  );
};

export default ParagraphRadio;

const serverVars = {
  apiUrl: "https://api.textcortex.com",
};

const localVars = {
  apiUrl: "http://127.0.0.1:5000",
};

const stagingVars = {
  apiUrl: "https://staging-s647te7twq-ez.a.run.app",
};

export const tagManagerArgs = {
  gtmId: "GTM-TL69PMP",
  dataLayer: {
    accessToken: localStorage.getItem("cortexAccessToken"),
    // email: `${localStorage.getItem("EMAIL")}`,
    // firstName: `${localStorage.getItem("FIRSTNAME")}`,
    // lastName: `${localStorage.getItem("LASTNAME")}`,
    // subscription: `${localStorage.getItem("SUBSCRIPTION")}`,
    // isShopify: `${localStorage.getItem("SHOPIFY")}`,
    // shopifyUrl: `${localStorage.getItem("SHOPIFY_URL")}`,
  },
};

export function getConfiguration(enviroment) {
  if (enviroment === "prod") {
    return serverVars;
  }

  if (enviroment === "staging") {
    return stagingVars;
  }
  return localVars;
}

export function getTracking(platform) {
  if (platform === "analytics") {
    return "UA-183452635-1";
  }
  if (platform === "facebook") {
    return "461316745016859";
  }
  if (platform === "tagmanager") {
    return (tagManagerArgs = {
      gtmId: "GTM-000000",
    });
  }
}

export function getLoginCred(platform) {
  if (platform === "google") {
    return "404842714684-h7gu5bv5huhv50tv9444opqipnvsfaam.apps.googleusercontent.com";
  }
  if (platform === "facebook") {
    return "";
  }
}

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardContainer from "../screens/dashboard";
import Creator from "../screens/dashboard/creator";
import Dashboard from "../screens/dashboard/dashboard";
import Documents from "../screens/dashboard/documents";
import Account from "../screens/dashboard/settings/account";
import ApiKey from "../screens/dashboard/settings/apiKey";
import Billing from "../screens/dashboard/settings/billing";
import InviteFriends from "../screens/dashboard/settings/inviteFriends";
// import Teams from "../screens/dashboard/settings/teams";
import Usage from "../screens/dashboard/settings/usage";
import EarnMore from "../screens/dashboard/earnMore";
import ResetPassword from "../screens/onboarding/webapp/resetPassword";
import SuggestTemplate from "../screens/dashboard/suggestTemplate";
import Pricing from "../screens/dashboard/pricing";
import ZenoChat from "../screens/dashboard/zenoChat";
import FastCheckout from "../screens/onboarding/fastCheckout";
import OffBoarding from "../screens/onboarding/offboarding";
import CustomizeYourAI from "../screens/dashboard/customizeAI/create";
import AiPersonas from "../screens/dashboard/customizeAI";
import Result from "../screens/dashboard/customizeAI/result";
import { defaultEditorRoute } from "../utils/helpers";

// import Checkout from "../screens/dashboard/checkout";

const DashboardRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={defaultEditorRoute} />} />
      <Route path="/user/forgot-password" element={<ResetPassword />} />
      <Route path="/fast-checkout" element={<FastCheckout />} />
      <Route path="/offboarding" element={<OffBoarding />} />
      <Route path="/user/reset-password-confirm" element={<ResetPassword />} />
      <Route path="/user/dashboard" element={<DashboardContainer />}>
        <Route path="chat" element={<ZenoChat />} />
        <Route path="customize-your-ai">
          <Route index element={<AiPersonas />} />
          <Route path="create" element={<CustomizeYourAI />} />
          <Route path="persona/:personaId" element={<Result />} />
        </Route>

        <Route index element={<Dashboard />} />
        <Route path="creator">
          <Route index element={<Creator />} />
          <Route path="template" element={<Creator />} />
          <Route path="template/:templateName" element={<Creator />} />
          <Route path="document" element={<Creator />} />
        </Route>
        <Route path="documents">
          <Route index element={<Documents />} />
          <Route path=":documentType" element={<Documents />} />
        </Route>
        <Route
          path="settings"
          element={<Navigate to="/user/dashboard/settings/account" />}
        />
        <Route path="suggest-template" element={<SuggestTemplate />} />

        <Route path="settings">
          <Route path="account" element={<Account />} />
          <Route path="usage" element={<Usage />} />
          <Route path="billing" element={<Billing />} />
          {/* <Route path="teams" element={<Teams />} /> */}
          <Route path="invite-friends" element={<InviteFriends />} />
          <Route path="api-key" element={<ApiKey />} />
        </Route>
        <Route path="earn-more" element={<EarnMore />} />
      </Route>
      {/* <Route path="/user/checkout">
        <Route index element={<Navigate to="/user/dashboard/pricing" />} />
        <Route path=":planType" element={<Checkout />} />
      </Route> */}
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/payment/success" element={<Pricing />} />
      <Route path="/payment/failure" element={<Pricing />} />
      <Route path="/user/login" element={<Navigate to="/user/dashboard" />} />
      {/* 404 page should be built */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default DashboardRouter;

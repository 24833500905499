import React from "react";
import { ReactComponent as GoogleIcon } from "../assets/icons/google.svg";
import { ReactComponent as ShopifyIcon } from "../assets/icons/shopify.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as DiscordIcon } from "../assets/icons/discord.svg";

const Icons: { [key: string]: any } = {
  google: GoogleIcon,
  shopify: ShopifyIcon,
  facebook: FacebookIcon,
  mail: MailIcon,
  discord: DiscordIcon,
};

type Props = {
  type: string;
  onClick?: () => void;
  text: string | React.ReactNode;
};

const SocialAuthButton = ({ type, text, onClick }: Props) => {
  const Icon = Icons[type];
  return (
    <button
      onClick={onClick}
      className="max-w-[456px] mb-4 border justify-center rounded  py-3 flex shadow w-full bg-white"
    >
      <span className=" block mr-2">
        <Icon />
      </span>
      <div className="h2 text-grey-02">{text}</div>
    </button>
  );
};

export default SocialAuthButton;

import React, { useEffect, useState } from "react";
import Input from "../../../components/input";
import moment from "moment";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import SelectInput from "../../../components/selectDropdown";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../context/auth/authContext";
import { countriesOptions } from "../../../utils/countries";
import { getRequest, postRequest } from "../../../axios";
import { toast } from "react-toastify";
import Button, { ButtonSecondary } from "../../../components/button";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import NotificationModal from "../../../components/notificationModal";
import { ReactComponent as WarningIcon } from "../../../assets/icons/info.svg";
import DeleteConfirmationModal from "../../../components/deleteConfirmationModal";
import { SET_USER } from "../../../context/auth/actions";
import Spinner from "../../../components/spinner";

const formSchema = [
  {
    type: "text",
    name: "billing_name",
    placeholder: "Billing name",
    label: "Billing Name",
  },
  {
    type: "text",
    name: "billing_address",
    placeholder: "Billing address",
    label: "Billing Address",
  },
  {
    type: "country",
    name: "country",
    placeholder: "",
    label: "Country",
  },
  {
    type: "text",
    name: "city",
    placeholder: "Eg. Berlin",
    label: "City",
  },
  {
    type: "text",
    name: "street_address_1",
    placeholder: "Address",
    label: "Street Address",
  },
  {
    type: "text",
    name: "postal_code",
    placeholder: "Postal code",
    label: "Postal Code",
  },
] as const;

type IFormInputs = {
  billing_name: string;
  billing_address: string;
  country: string;
  city: string;
  street_address_1: string;
  postal_code: string;
};

type Invoice = {
  invoice_date: string;
  invoice_link: string;
  status: string;
  amount: number;
  product: any;
};

const Billing = () => {
  const [state, setState] = useState<"loading" | "idle" | "success" | "error">(
    "idle"
  );
  const [billingUrl, setBillingUrl] = useState("");
  const [showCancelSubModal, toggleWarningModal] = useState(false);
  const [invoices, setInvoices] = useState<{
    items: Invoice[];
    state: "loading" | "idle" | "success" | "error";
  }>({ items: [], state: "loading" });

  const auth = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>();

  // console.log("auth.user: ", auth.state.user);

  const fetchInvoices = async () => {
    try {
      setInvoices({ ...invoices, state: "loading" });
      const res = await getRequest("payment/invoice/");
      if (res.data.message === "You don't have any invoice/payment") {
        setInvoices({
          items: [],
          state: "success",
        });
      } else if (res.data.invoices) {
        setInvoices({
          items: res.data.invoices,
          state: "success",
        });
      }
    } catch (error) {
      setInvoices((prev) => ({
        items: [],
        state: "error",
      }));
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    // fetchInvoices();
    fetchBillingUrl();
  }, []);

  const fetchBillingUrl = async () => {
    try {
      setState("loading");
      const res = await getRequest("payment/billing_portal/");
      const url = res.data.url;
      window.open(url);
      setBillingUrl(url);
      setState("success");
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data: IFormInputs) => {
    setState("loading");
    try {
      const { billing_address, ...rest } = data;
      const res = await postRequest("accounts/user_details", rest);
      setState("success");
      toast.success("Billing setting successfully updated!");
    } catch (error) {
      setState("error");
      toast.success("Something went wrong :(");
      console.log("error:  ", error);
    }
  };

  const cancelSubscription = async () => {
    try {
      toast.loading("Canceling your subscription. Please wait...");
      const res = await getRequest("payment/cancel");
      if (res.data.status === "success") {
        toast.success("Subscription cancelled!");
        toggleWarningModal(false);
        auth.dispatch({
          type: SET_USER,
          payload: { user: { ...auth.state.user, plan_type: "Free" } },
        });
      }
    } catch (err: any) {
      if (err.response.data.message) {
        toast.dismiss();
        toast.error(`Failed! ${err.response.data.message}`);
        toggleWarningModal(false);
      }
    }
  };

  if (state === "loading")
    return (
      <div className="w-full h-[80vh] flex items-center justify-center flex-col">
        <Spinner />
        <p className="text3 mt-2">Redirecting you to stripe. Please wait...</p>
      </div>
    );
  if (state === "success") {
    return (
      <div className="w-full flex items-center justify-center h-[80vh]">
        <a href={billingUrl} target="_blank">
          <Button className="w-[200px] bg-primary-01">Open billing page</Button>
        </a>
      </div>
    );
  }
  return null;

  // return (
  //   <>
  //     <Helmet>
  //       <meta charSet="utf-8" />
  //       <title>TextCortex AI - Billing</title>
  //     </Helmet>
  //     <div className="mt-2">
  //       <h1 className="h3 text-primary-02">Billing {"&"} Invoices</h1>
  //       <p className="text2 text-grey-02">
  //         Your billing information is incomplete
  //       </p>
  //       <div className="flex flex-row w-full mt-4">
  //         <form className="w-full max-w-[504px] border rounded px-6 py-4 mr-4">
  //           {formSchema.map((form) =>
  //             form.type === "country" ? (
  //               <SelectInput
  //                 label="Country"
  //                 placeholder="Choose country"
  //                 defaultValue={countriesOptions.find(
  //                   (item) => item.value === auth.state.user?.data.country
  //                 )}
  //                 options={countriesOptions}
  //                 {...register("country", {
  //                   required: "this is a required field ",
  //                 })}
  //                 onChange={(newValue: any) => {
  //                   setValue("country", newValue.value);
  //                 }}
  //                 // sizing={"sm"}
  //                 setValue={setValue}
  //                 isSearchable
  //                 className="mb-6"
  //               />
  //             ) : (
  //               <Input
  //                 {...form}
  //                 {...register(form.name, {
  //                   required:
  //                     form.name === "billing_address"
  //                       ? false
  //                       : "This is a required required",
  //                 })}
  //                 error={errors[form.name]}
  //                 defaultValue={auth.state.user?.data[form.name]}
  //               />
  //             )
  //           )}
  //           <div className="flex justify-between items-center">
  //             <ButtonSecondary
  //               onClick={handleSubmit((data) => {
  //                 onSubmit(data);
  //               })}
  //               loading={state === "loading"}
  //               className="h-10 px-4 bg-white border rounded text2 text-grey-02"
  //             >
  //               Save changes
  //             </ButtonSecondary>
  //             {/* {auth.state.user?.plan_type.toLowerCase() !== "free" && ( */}
  //             <p
  //               onClick={() => toggleWarningModal(true)}
  //               className="text3 underline text-red-500 cursor-pointer"
  //             >
  //               Cancel Subscription
  //             </p>
  //             {/* )} */}
  //           </div>
  //         </form>

  //         <div className="w-full max-w-[620px] border rounded px-6 py-4">
  //           {invoices.state === "loading" && (
  //             <div>
  //               <h2 className="pb-4 text2 text-primary-02 border-bottom">
  //                 Invoices
  //               </h2>
  //               <div className="mt-4">
  //                 <Skeleton
  //                   style={{
  //                     marginRight: 32,
  //                     borderRadius: 10,
  //                   }}
  //                   containerClassName="overflow-hidden flex flex  w-full max-w-[100%] flex-wrap"
  //                   width={"100%%"}
  //                   height={40}
  //                   count={3}
  //                 />
  //               </div>
  //             </div>
  //           )}
  //           {invoices.items.length > 0 && invoices.state === "success" && (
  //             <>
  //               <h2 className="pb-4 text2 text-primary-02 border-bottom">
  //                 Invoices
  //               </h2>

  //               <div className="">
  //                 <div className="grid grid-cols-5">
  //                   <div>
  //                     <p className="h4   text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
  //                       ID
  //                     </p>
  //                     {invoices.items.map((item, index) => (
  //                       <p
  //                         className={`text4 py-4 text-grey-02  border-[##E5E7EB] ${
  //                           invoices.items.length - 1 !== index &&
  //                           "border-b-[1px]"
  //                         }`}
  //                       >
  //                         {" "}
  //                         {index + 1}
  //                       </p>
  //                     ))}
  //                   </div>
  //                   <div className="">
  //                     <p className="h4  text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
  //                       Amount
  //                     </p>
  //                     {invoices.items.map((item, index) => (
  //                       <p
  //                         className={`text4 py-4 text-grey-02  border-[##E5E7EB] ${
  //                           invoices.items.length - 1 !== index &&
  //                           "border-b-[1px]"
  //                         }`}
  //                       >
  //                         {" "}
  //                         {item.amount}
  //                       </p>
  //                     ))}
  //                   </div>
  //                   <div className="col-span-3">
  //                     <p className=" h4 text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
  //                       Date
  //                     </p>
  //                     {invoices.items.map((item, index) => (
  //                       <div
  //                         className={`py-[13px] flex justify-between items-center text4 text-grey-02 border-[##E5E7EB] ${
  //                           invoices.items.length - 1 !== index &&
  //                           "border-b-[1px]"
  //                         }`}
  //                       >
  //                         <p>
  //                           {moment(item.invoice_date).format(
  //                             "MMM DD YYYY, HH:MM"
  //                           )}
  //                         </p>
  //                         <div className="flex items-center">
  //                           <a
  //                             href={item.invoice_link}
  //                             target="_blank"
  //                             rel="noopener noreferrer"
  //                           >
  //                             <Button className="h-[30px] w-[120px] text5 flex items-center justify-center mr-3 cursor-pointer">
  //                               Download Invoice
  //                             </Button>{" "}
  //                           </a>
  //                           {/* <DownloadIcon className="cursor-pointer" /> */}
  //                         </div>
  //                       </div>
  //                     ))}
  //                   </div>
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //           {invoices.items.length === 0 && invoices.state === "success" && (
  //             <div className="flex items-center justify-center w-full h-full">
  //               <h2>No invoices</h2>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //     {showCancelSubModal && (
  //       <DeleteConfirmationModal
  //         showModal={showCancelSubModal}
  //         closeModal={() => toggleWarningModal(false)}
  //         title={"Cancel Subscription?"}
  //         description={
  //           "Are you sure you want to cancel your subscription plan?"
  //         }
  //         buttonText={"Continue"}
  //         onDelete={cancelSubscription}
  //       />
  //     )}
  //   </>
  // );
};

export default Billing;

import React from "react";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-circle.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x-close.svg";
import { ReactComponent as CloseIconGreen } from "../../assets/icons/x-close-green.svg";
import { BannerTypes, ChatBanners } from "../../context/chat/reducer";

const limitations = [
  "Do not use me for biased or offensive content.",
  "Check my facts",
];

const capabilities = [
  "Remember what you said",
  "Correct and build upon messages",
];

const Limitations = ({ onClose }: any) => {
  return (
    <div className="mb-8">
      <div className="flex items-center mb-4 im:hidden">
        <InfoIcon />
        <p className="h2 ml-2 text-neutral-800">
          My limitations you need to be aware of are:
        </p>
      </div>

      <div className="w-full p-4 bg-grey-09 flex flex-col rounded border-[1px] border-grey-07">
        <div className="im:flex hidden im:justify-between">
          <div className="flex items-center">
            <InfoIcon />
            <p className="h2 ml-2 text-neutral-800">
              My limitations you need to be aware of are:
            </p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="flex flex-col im:ml-7 mt-1">
            {limitations.map((limitation) => (
              <p className="text4 text-neutral-600">
                <li>{limitation}</li>
              </p>
            ))}
          </div>
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

const Capabilities = ({ onClose }: any) => {
  return (
    <div>
      <div className="flex items-center mb-4 im:hidden">
        <CheckIcon />
        <p className="h2 ml-2 text-green-05">
          My capabilities you need to be aware of are:
        </p>
      </div>

      <div className="w-full flex-col  justify-between bg-green-04 p-4 border-[1px] rounded border-green-07">
        <div className="im:flex hidden im:justify-between">
          <div className="flex items-center">
            <CheckIcon />
            <p className="h2 ml-2 text-green-05">
              My capabilities you need to be aware of are:
            </p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="flex flex-col im:ml-7 mt-1">
            {capabilities.map((capability) => (
              <p className="text4 text-green-06">
                <li>{capability}</li>
              </p>
            ))}
          </div>
          <CloseIconGreen
            onClick={onClose}
            className="cursor-pointer mt-1 fill-green-200"
          />
        </div>
      </div>
    </div>
  );
};

type Props = {
  showBanners: ChatBanners;
  toggleBanners: (val: BannerTypes) => void;
};

const Banners = ({ showBanners, toggleBanners }: Props) => {
  return (
    <div className="my-3">
      {showBanners.capabilities && (
        <div className="mb-8 im:mb-4">
          <Capabilities onClose={() => toggleBanners("capabilities")} />
        </div>
      )}
      {showBanners.limitations && (
        <div className="mb-8 im:mb-4">
          <Limitations onClose={() => toggleBanners("limitations")} />
        </div>
      )}
    </div>
  );
};

export default Banners;

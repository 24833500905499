import { IconProps } from "../../types";
import React from "react";

const Fluency = (props: IconProps) => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.63641 8.72222C9.84139 8.72222 10.8182 7.72729 10.8182 6.49999C10.8182 5.27269 9.84139 4.27777 8.63641 4.27777C7.43142 4.27777 6.45459 5.27269 6.45459 6.49999C6.45459 7.72729 7.43142 8.72222 8.63641 8.72222Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.16582 10.0347C4.71788 9.57899 4.36126 9.03886 4.11582 8.44444C3.85927 7.82948 3.72705 7.16816 3.72705 6.49999C3.72705 5.83182 3.85927 5.17051 4.11582 4.55555C4.36126 3.96112 4.71788 3.421 5.16582 2.96527"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1069 2.96527C12.5549 3.421 12.9115 3.96112 13.1569 4.55555C13.4135 5.17051 13.5457 5.83182 13.5457 6.49999C13.5457 7.16816 13.4135 7.82948 13.1569 8.44444C12.9115 9.03886 12.5549 9.57899 12.1069 10.0347"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.23642 12C2.54072 11.2901 1.98523 10.4509 1.60005 9.52778C1.20401 8.56931 1 7.53989 1 6.5C1 5.46011 1.20401 4.43069 1.60005 3.47222C1.98523 2.54913 2.54072 1.70988 3.23642 1"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0364 1C14.7321 1.70988 15.2876 2.54913 15.6727 3.47222C16.0688 4.43069 16.2728 5.46011 16.2728 6.5C16.2728 7.53989 16.0688 8.56931 15.6727 9.52778C15.2876 10.4509 14.7321 11.2901 14.0364 12"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Fluency;

import React from "react";
import useHorizontalScroll from "../hooks/useHorizontalScroll";
import { ReactComponent as PlusButton } from "../assets/icons/plus_button.svg";
import SelectInput from "./selectDropdown";
import DocumentCard from "./documentCard";
import { ReactComponent as LeftIcon } from "../assets/icons/chevron-left.svg";
import { ReactComponent as RightIcon } from "../assets/icons/chevron-right.svg";
import { getAllDocumentCategories } from "../utils/getDocumentCategory";
import { useAuth } from "../context/auth/authContext";
import { Link } from "react-router-dom";
import { defaultEditorRoute } from "../utils/helpers";

type Props = {
  documents: any;
  documentType: string;
  setDocumentType: (type: string) => void;
  deleteDocument: (id: string) => void;
  openSaveModal?: () => void;
};

const DocumentList = ({
  documents,
  documentType,
  setDocumentType,
  deleteDocument,
  openSaveModal,
}: Props) => {
  const { onNext, onPrev, scrollRef } = useHorizontalScroll();
  const categories = getAllDocumentCategories();
  const auth = useAuth();

  return (
    <section className="h-auto mt-4 max-w-[98vw]">
      <div className="flex items-center flex-wrap justify-between">
        <div>
          <h1
            className={`h3 text-primary-02 ${
              !auth.isSidebarOpen && "text-center"
            }`}
          >
            Documents
          </h1>
          <p
            className={`text2 text-grey-02 ${
              !auth.isSidebarOpen && "text-center"
            }`}
          >
            Browse existing or create new documents.
          </p>
        </div>
        <div className="flex items-center">
          <Link to={defaultEditorRoute}>
            <div>
              <PlusButton className="mr-4 cursor-pointer w-14 -14 fill-brand-02 stroke-brand-02" />
            </div>
          </Link>
          <SelectInput
            name="plan"
            placeholder={"Category"}
            style={{
              minWidth: "200px",
              height: "24px",
              cursor: "pointer",
              fontSize: 14,
              background: " rgba(4, 6, 19, 0.05)",
            }}
            onChange={(value: any) => {
              setDocumentType(value.value);
            }}
            value={documentType}
            options={[{ label: "All", value: "all" }, ...categories]}
          />
          <div className="flex items-center flex-1">
            <button
              onClick={onPrev}
              className="w-[32px] ml-4 h-[32px] flex items-center justify-center mr-2 border rounded cursor-pinter"
            >
              <LeftIcon />
            </button>
            <button
              onClick={onNext}
              className="w-[32px] mr-4 h-[32px] flex items-center justify-center mr-2 border rounded cursor-pinter"
            >
              <RightIcon />
            </button>
          </div>
        </div>
      </div>
      <div className="flex mt-4">
        <div
          ref={scrollRef}
          className="flex justify-between w-full overflow-hidden overflow-x-auto hide-scrollbar"
        >
          {documents?.length > 0 &&
            documents.map((item: any, index: any) => {
              return (
                <div className="relative flex-1 mr-4">
                  <DocumentCard
                    isLastCard={documents.length - 1 === index}
                    document={item}
                    setDocumentToBeDeleted={deleteDocument}
                    category={item.category}
                    key={index}
                    title={item.document_name}
                    description={item.document_data}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default DocumentList;

import * as React from "react";

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function useHorizontalScroll() {
  const scrollRef = React.useRef(null);

  const onNext = () => {
    sideScroll(scrollRef.current as any, 1, 300, 10);
  };

  const onPrev = () => {
    sideScroll(scrollRef.current as any, 1, 300, -10);
  };

  return {
    onNext,
    scrollRef,
    onPrev,
  };
}

export default useHorizontalScroll;

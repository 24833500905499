import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/button";
import SelectInput from "../../components/selectDropdown";
import {
  AUTH_FAILURE,
  ENTER_DASHBOARD,
  logout,
  submitOnboardingQuestions,
} from "../../context/auth/actions";
import { useAuth } from "../../context/auth/authContext";
import { defaultEditorRoute } from "../../utils/helpers";

const industrySelectOptions = [
  { value: "ecommerce", label: "Ecommerce" },
  { value: "agency", label: "Agency" },
  { value: "software", label: "Software" },
  { value: "dropshipping", label: "Dropshipping" },
  { value: "freelance", label: "Freelance" },
];

const rolesSelectOptions = [
  { value: "entrepreneur", label: "Entrepreneur" },
  { value: "marketer", label: "Marketer" },
  { value: "copywriter", label: "Copywriter" },
  { value: "designer", label: "Designer" },
  { value: "developer", label: "Developer" },
  { value: "manager", label: "Manager" },
];

const hearFromUsOptions = [
  { value: "twitter", label: "Twitter" },
  { value: "youtube", label: "Youtube" },
  { value: "linkedin", label: "Linkedin" },
  { value: "reddit", label: "Reddit" },
  { value: "onlineCommunity", label: "Online Community" },
  { value: "fromSomeone", label: "From Someone" },
  { value: "facebook", label: "Facebook Post/Group" },
  { value: "instagram", label: "Instagram" },
  { value: "tiktok", label: "TikTok" },
  { value: "ProductHunt", label: "ProductHunt" },
  { value: "googleAds", label: "Google Ads" },
  { value: "email", label: "Email" },
  { value: "friendsAndFamily", label: "Friends & Family" },
  { value: "onlineSearch", label: "Online Search" },
  { value: "blogArticle", label: "Blog Article" },
  { label: "Influencer", value: "Influencer" },
  { value: "videoContent", label: "Video Content" },
];

type Props = {
  onNext?: () => void;
};

const OnboardingQuestions = ({ onNext }: Props) => {
  const { dispatch, state } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    industry: "",
    role: "",
    working_in_team: "",
    website: "",
    heard_us_from: [],
  });

  // useEffect(() => {
  //   if (!state.user) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleChange = (newOption: any) => {
    setFormData({ ...formData, [newOption.name]: newOption.value });
  };

  const submitForm = async () => {
    const submitted = await submitOnboardingQuestions(formData, dispatch);
    if (submitted) {
      dispatch({
        type: ENTER_DASHBOARD,
        payload: {
          user: {
            ...state.user,
            onboarded: true,
          },
        },
      });

      if (
        state.user?.registration_source === "icortex" ||
        state.user?.registration_source === "api-landing"
      ) {
        navigate("/user/dashboard/settings/api-key");
        dispatch({
          type: ENTER_DASHBOARD,
          payload: { user: { ...state.user, onboarded: true } },
        });
        return;
      }
      navigate(defaultEditorRoute);
    } else {
      toast.error("Something went wrong. Please try again.");
      dispatch({
        type: AUTH_FAILURE,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Onboarding</title>
      </Helmet>
      <p
        onClick={() => logout(dispatch)}
        className="pt-4 pr-8 text-right underline cursor-pointer text2 text-grey-02"
      >
        Logout
      </p>
      <div className="p-6 md:p-0 m-auto mt-14 max-w-[456px]">
        <h1 className="py-2 text-center h1">What is your role?</h1>
        <p className="mt-2 mb-4 text-center text2 text-grey-02">
          Select all that apply. You can change this later at any time.
        </p>
        <div className="mb-4">
          <SelectInput
            value={formData.role}
            onChange={({ value }: any) => handleChange({ name: "role", value })}
            name="role"
            placeholder={"Where do you work?"}
            options={rolesSelectOptions}
            label={"Role"}
          />
        </div>
        <div className="mb-4">
          <SelectInput
            placeholder={"Select one"}
            value={formData.industry}
            onChange={({ value }: any) =>
              handleChange({ name: "heard_us_from", value })
            }
            name="industry"
            options={hearFromUsOptions}
            label={"How did you hear about us?"}
          />
        </div>

        <div className="mb-4">
          <SelectInput
            placeholder={"Select one"}
            value={formData.industry}
            onChange={({ value }: any) =>
              handleChange({ name: "industry", value })
            }
            name="industry"
            options={industrySelectOptions}
            label={"Industry"}
          />
        </div>
        <div className="mb-4">
          <SelectInput
            value={formData.working_in_team}
            onChange={(value: any) =>
              handleChange({ name: "working_in_team", value: value.value })
            }
            name="working_in_team"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            placeholder={"Working with a team?"}
            label={"Working with a team?"}
          />
          <p className="mt-2 text2 text-grey-02">
            Invite teammates later{" &"} receive free creations 🎉{" "}
          </p>
        </div>
        <div className="url-input">
          <p className="mb-2 text2 text-primary-02">
            Website - <span className="text-grey-02">Optional</span>
          </p>
          <div className="flex h-12 border rounded">
            <p className="flex items-center block px-3 align-middle border-r-2 text-grey-02 border-r-1 text2 text-grey-2 border-text-grey-02">
              www.
            </p>
            <input
              placeholder={"example.com"}
              value={formData.website}
              name={"website"}
              onChange={(e) =>
                setFormData({ ...formData, website: e.target.value })
              }
              className="w-full px-3 outline-none text-primary-02 text2"
            />
          </div>
        </div>
        <div className="my-12">
          <Button loading={state.submittingForm} onClick={submitForm}>
            Continue
          </Button>
          <p
            onClick={submitForm}
            className="mt-4 text-center cursor-pointer text2 text-grey-02"
          >
            Skip
          </p>
        </div>
      </div>
    </div>
  );
};

export default OnboardingQuestions;

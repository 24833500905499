import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import TemplatesList from "../../../components/templatesList";
import { useAuth } from "../../../context/auth/authContext";
import { analytics } from "../../../utils/analytics";
import { CREATION_PAGE_ENTERED } from "../../../constants/analytics";
import Paraphrase from "./paraphrase";
import ToneChange from "./toneChange";
import Editor from "./editor";
import { fetchTemplates } from "../../../components/DPU/useDpu";
import TemplateCard from "../../../components/templateCard";
import { ReactComponent as Logo } from "../../../assets/icons/logo-small.svg";
import Skeleton from "react-loading-skeleton";
import PricingModal from "../../../components/pricingModal";
import Input from "../../../components/input";
import { FaSearch } from "react-icons/fa";

let defaultTemplate = "auto_complete";

export type GenerationResultType = {
  prompt: any;
  generatedText: any;
  document_id?: string;
  isSaved: boolean;
  isFavorite?: boolean;
} | null;

export type SaveToFavoriteArgs = {
  prompt?: string;
  generated_text?: string;
  category?: string;
  language?: string;
  word_count?: number;
  parameters?: string;
};

export type SaveDocumentArgs = {
  category?: string;
  status?: string;
  document_id?: string;
  document_name?: string;
  document_data?: string;
  language?: string;
  generation_ids?: string[];
  isSaved?: boolean;
  favourite?: number | null;
};

type Params = {
  templateName: string;
  documentId: string;
};

const TemplatesHeader = ({
  templates,
  setSelectedCategory,
  selectedCategory,
  searchValue,
  setSearchValue,
}: any) => {
  const auth = useAuth();
  return (
    <>
      <div className={`mb-2 flex w-full`}>
        <div></div>
        <div
          className={`flex ${
            !auth.isSidebarOpen ? "items-center text-center" : "items-start"
          } flex-col w-full`}
        >
          <h1 className={`h1 text-primary-02`}>Select a template</h1>
          <p className="text2 text-grey-02">Start writing with AI today </p>
        </div>
        <div
          className={`absolute border-bottom pb-1 right-0 flex top-[15%] items-center mr-[4%]`}
        >
          <FaSearch />
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="ml-4 outline-none border-none"
            placeholder="Search Templates..."
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div className=" mx-auto overflow-x-scroll scroll-mb-10 pb-3 flex items-center">
          {templates.categories.length > 0 && (
            <div
              onClick={() => setSelectedCategory("all")}
              className={`cursor-pointer ${
                selectedCategory === "all" ? "bg-light-pink-gradient" : "border"
              } bg-white w-auto rounded flex items-center px-2 py-[4px] mr-4`}
            >
              <p className="text2 text-primary-02 whitespace-nowrap flex items-center">
                <Logo className="w-4 h-4 mr-1" />
                All
              </p>
            </div>
          )}
          {templates.categories.length > 0 &&
            templates.categories.map((category: any) => (
              <div
                onClick={() => setSelectedCategory(category.value)}
                className={`cursor-pointer ${
                  selectedCategory === category.value
                    ? "bg-light-pink-gradient"
                    : "border"
                } bg-white w-auto  rounded flex items-center px-2 py-[6px] mr-4`}
              >
                <p className="text2 text-primary-02 whitespace-nowrap">
                  {category.icon} {category.display_name}
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const Loading = () => {
  return (
    <div className="page">
      <Skeleton
        style={{
          marginBottom: 40,
          marginTop: 32,
          marginRight: 32,
          borderRadius: 10,
        }}
        containerClassName="overflow-hidden flex flex  w-full max-w-[100%] flex-wrap"
        width={"100%"}
        height={200}
        count={1}
      />
      <Skeleton
        style={{
          marginBottom: 40,
          marginTop: 32,
          marginRight: 32,
          borderRadius: 10,
        }}
        containerClassName="overflow-hidden flex flex  w-full max-w-[100%] flex-wrap"
        width={"20%"}
        height={220}
        count={4}
      />
      <Skeleton
        style={{ marginBottom: 20, marginRight: 40, borderRadius: 10 }}
        containerClassName="overflow-hidden flex  w-full max-w-[100%] flex-wrap"
        width={"25%"}
        height={220}
        count={4}
      />
    </div>
  );
};

const Creator = () => {
  const { pathname } = useLocation();
  const auth = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const {
    state: { user },
  } = auth;
  const params = useParams<Params>();
  const [template, setTemplate] = useState<string>(defaultTemplate);
  const [templates, setTemplates] = useState({
    categories: [],
    templates: [],
    allTemplates: [],
  });
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");

  useEffect(() => {
    setTemplate(template);
    analytics(CREATION_PAGE_ENTERED, {
      template,
    });
    const handleTemplates = async () => {
      setStatus("loading");
      const res = await fetchTemplates();
      const categories = _.omit(res.data.categories, "code");
      const templates = res.data.templates;
      const templatesArray = Object.keys(templates)
        .map((key) => ({
          value: key,
          icon:
            categories[templates[key].category] &&
            categories[templates[key].category].icon,
          ...templates[key],
          category: templates[key].category,
        }))
        .filter(
          (template) => !template.value.toLowerCase().includes("code")
        ) as any;
      setTemplates({
        categories: Object.keys(categories).map((key) => ({
          value: key,
          ...categories[key],
        })) as any,
        templates: templatesArray,
        allTemplates: templatesArray,
      });
      setStatus("success");
    };
    if (!params.templateName) {
      handleTemplates();
    }
  }, [pathname]);

  useEffect(() => {
    if (params.templateName) {
      setTemplate(params.templateName || defaultTemplate);
    }
  }, [pathname]);

  useEffect(() => {
    if (selectedCategory === "all") {
      setTemplates({
        ...templates,
        templates: templates.allTemplates,
      });
    } else {
      const categorizedTemplates = templates.allTemplates.filter(
        (item: any) => item.category === selectedCategory
      );
      setTemplates({
        ...templates,
        templates: categorizedTemplates,
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (!searchValue) {
      setTemplates({
        ...templates,
        templates: templates.allTemplates,
      });
    } else {
      const result = templates.allTemplates.filter((template: any) =>
        template.display_name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setTemplates({
        ...templates,
        templates: result,
      });
    }
  }, [searchValue]);

  let DocumentCreator = () => <Editor />;
  switch (params.templateName) {
    case "paraphrase":
      DocumentCreator = () => <Paraphrase />;
      break;
    case "change_tone":
      DocumentCreator = () => <ToneChange />;
      break;
    default:
      DocumentCreator = () => <Editor />;
  }

  return useMemo(() => {
    if (status === "loading") {
      return <Loading />;
    }
    return pathname === "/user/dashboard/creator" ? (
      <div className="page relative w-full bg-white mx-auto">
        <div className="sticky pb-3 w-full top-0 bg-white z-10">
          <TemplatesHeader
            search={searchValue}
            setSearchValue={setSearchValue}
            templates={templates}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        </div>
        <div
          className={`mt-6 flex flex-wrap ${
            auth.isSidebarOpen ? "justify-start" : "justify-center"
          }`}
        >
          {templates.templates.length > 0 &&
            templates.templates.map((template: any) => (
              <div className="mr-8 mb-5">
                <TemplateCard
                  plan={template.plan_to_unlock}
                  icon={() => <span className="h1">{template.icon}</span>}
                  description={
                    template.description || "Start writing with TextCortex..."
                  }
                  value={template.value}
                  title={template.display_name}
                />
              </div>
            ))}
        </div>
        <PricingModal
          user={auth.state.user}
          onClose={() => {
            auth.setPricingModalData({
              ...auth.pricingModal,
              isOpen: false,
            });
          }}
          featurePlanType={auth.pricingModal.featureUserAttempted || "Free"}
          isOpen={auth.pricingModal.isOpen}
        />
      </div>
    ) : (
      <div className="relative flex w-full bg-white">
        <DocumentCreator />
      </div>
    );
  }, [pathname, selectedCategory, templates, auth.pricingModal]);
};

export default Creator;

import React, { useEffect, useState } from "react";
import { ReactComponent as GetStartedIcon } from "../../../assets/icons/get_started.svg";
import { ReactComponent as DiscordIcon } from "../../../assets/icons/discord.svg";
import { ReactComponent as SendIcon } from "../../../assets/icons/send.svg";

import { ReactComponent as CreditCard } from "../../../assets/icons/credit_card.svg";
import Button, { ButtonSecondary } from "../../../components/button";
import axiosClient, { getRequest } from "../../../axios";
import moment, { months } from "moment";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-up-right.svg";
import { LoadingState } from "../../../types";
import { InputWithCopy } from "../../../components/input";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { FaDollarSign } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { getUsageDataWithFiller } from "../../../utils/getDataFiller";
import Skeleton from "react-loading-skeleton";
import SelectInput from "../../../components/selectDropdown";
import LineChart from "../../../components/lineChart";
import BarChart from "../../../components/barChart";
import { Chart } from "chart.js";

import { postRequest } from "../../../axios";
import { loadStripe } from "@stripe/stripe-js";
import MonthSelector from "../../../components/monthSelector";
import ApiUsageHistoryModal from "../../../components/apiUsageHistoryModal";
import { useAuth } from "../../../context/auth/authContext";

Chart.defaults.responsive = true;
Chart.defaults.maintainAspectRatio = false;

const Send = () => (
  <div className="items-center flex justify-center p-[6px] rounded bg-primary-01">
    <SendIcon />
  </div>
);

const createTopUpSession = async (plan: any) => {
  try {
    toast.loading("Redirecting you to stripe.com.");
    let data: any = {
      product: plan,
      payment: ["card"],
      currency: "usd",
    };
    const res = await postRequest("payment/create_session", data);
    redirectToStripe(res.data);
  } catch (err) {
    console.log(err);
    toast.dismiss();
    toast.error("Something went wrong. Please try again");
  }
};

const redirectToStripe = async (data: any) => {
  loadStripe(data.publicKey)
    .then((stripe: any) => {
      stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });
    })
    .then(() => {
      toast.dismiss();
    })
    .catch(() => {
      toast.dismiss();
    });
};

type Props = {
  title: string;
  description: string;
  buttonText: string;
  url?: string;
  primaryButton?: boolean;
  onClick?: () => void;
  icon?: any;
};

const CtaCard = ({
  title,
  description,
  buttonText,
  url,
  onClick,
  primaryButton,
  icon: Icon,
}: Props) => {
  const CtaIcon = () => (Icon ? <Icon /> : <div />);

  return (
    <div className="w-full h-full px-6 py-4 border rounded">
      <div className="">
        <div className="flex items-center">
          <CtaIcon />
          <h1 className="ml-2 text2 text-primary-02">{title}</h1>
        </div>
        <p className="text2 text-grey-02 mt-3 max-w-[524px]">{description}</p>
        <a href={url} target="_blank" rel="noreferrer noopener">
          {/* {primaryButton ? (
            <Button onClick={onClick}>{buttonText}</Button>
          ) : (
            <ButtonSecondary onClick={onClick}>{buttonText}</ButtonSecondary>
          )} */}
          <button
            onClick={onClick}
            className={`px-6 py-2 mt-6  rounded h2 ${
              primaryButton ? "bg-primary-01 text-white" : "text-grey-02 border"
            } `}
          >
            {buttonText}
          </button>
        </a>
      </div>
    </div>
  );
};

type IApiKey = {
  state: LoadingState;
  api_key?: string;
  remaining_credits?: number;
  hasKey?: boolean;
};

type GraphData = {
  graphView: "daily" | "cumulative";
  graphDate: {
    month: number;
    year: number;
  };
  graphInfo: {
    labels: any;
    datasets: any;
  };
  cumulative: any;
  detailedUsage: any;
  dailyUsage: any;
  percentageIncrease: number;
  totalCost: number;
  totalWords: number;
};

const ApiKey = () => {
  const [apiKey, setApiKey] = useState<IApiKey>({
    state: "loading",
  });
  const [pageState, setPageState] = useState<
    "idle" | "loading" | "success" | "error"
  >("loading");
  const [showUsageModal, toggleModal] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>({
    graphView: "daily",
    detailedUsage: [],
    dailyUsage: [],
    graphDate: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
    graphInfo: { labels: [], datasets: [] },
    cumulative: [],
    percentageIncrease: 0,
    totalCost: 0,
    totalWords: 0,
  });
  const navigate = useNavigate();

  const auth = useAuth();
  const user = auth.state.user;

  const pageData = [
    {
      title: "API documentation",
      icon: GetStartedIcon,
      description:
        "Sign in to start generating amazing Content. If you do not have an account,you can register at the button below.",
      buttonText: " Documentation",
      url: "https://docs.textcortex.com/api",
    },

    {
      icon: GetStartedIcon,
      title: "Help center",
      description:
        "Find more help on best practices and how to get started with our API in our help center.",
      buttonText: "Help center",
      url: "https://help.textcortex.com/help/enterprise-text-generation-api-bdd5b775",
    },
    {
      icon: Send,
      title: "Contact us to upgrade your access to the next level",
      description:
        "Do you have a question for generating content on a niche subject? Looking for a priority hosting solution? Or are you looking for a  customization.",
      buttonText: "Upgrade",
      primaryButton: true,
      url: `https://tell.textcortex.com/upgrade-your-api?email=${user?.email}&plan_type=${user?.plan_type}`,
    },
    {
      icon: DiscordIcon,
      title: "Discord Community",
      description:
        "Check out our Discord community channel where we share valuable information and meet like minded people!",
      buttonText: "Join Discord",
      url: "https://discord.textcortex.com",
    },
  ];

  const fetchApiKey = async () => {
    try {
      const res = await getRequest("hemingwai/get_api_key_v2");
      if (res.data.status === "fail") {
        setApiKey({
          state: "success",
          hasKey: false,
        });
      } else if (res.data.status === "success") {
        setApiKey({
          state: "success",
          api_key: res.data.api_key,
          remaining_credits: res.data.remaining_credits,
          hasKey: true,
        });
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    fetchApiKey();
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setPageState("loading");
        const res = await axiosClient({
          method: "post",
          url: "/hemingwai/usage_history",
          data: graphData.graphDate,
        });

        const cumulative = res.data.cumulative_api_usage;
        const dailyUsage = res.data.daily_api_usage;
        const percentageIncrease = res.data.percent_increase_from_last_month;
        const totalWords = res.data.total_tokens;
        const detailedUsage = res.data.detailed_api_usage;
        const totalCost = res.data.total_cost.toFixed(3);

        const stats = res.data.daily_api_usage;
        const { graphLabels, datasets } = getGraphData(stats);
        setGraphData({
          ...graphData,
          cumulative,
          dailyUsage,
          graphView: "daily",
          graphInfo: {
            labels: graphLabels,
            datasets,
          },
          percentageIncrease,
          detailedUsage,
          totalCost,
          totalWords,
        });

        setPageState("success");
      } catch (err: any) {
        if (err.response.status === 404) {
          toast.dismiss();
          toast.warn(
            `You do not have API usage history for ${months(
              graphData.graphDate.month - 1
            )}.`
          );
          const stats = {
            "1": { total_cost: 0 },
          };
          const { graphLabels, datasets } = getGraphData(stats);
          setGraphData({
            ...graphData,
            graphInfo: {
              labels: graphLabels,
              datasets,
            },
            graphDate: {
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            },
            cumulative: stats,
            dailyUsage: stats,
          });
        }
        setPageState("success");
        console.log(err);
      }
    };
    fetchStats();
  }, [graphData.graphDate.month]);

  useEffect(() => {
    if (graphData.graphView === "cumulative") {
      const { graphLabels, datasets } = getGraphData(graphData.cumulative);
      setGraphData({
        ...graphData,
        graphInfo: { labels: graphLabels, datasets },
      });
    }
    if (graphData.graphView === "daily") {
      const { graphLabels, datasets } = getGraphData(graphData.dailyUsage);
      setGraphData({
        ...graphData,
        graphInfo: { labels: graphLabels, datasets },
      });
    }
  }, [graphData.graphView]);

  const getGraphData = (stats: any) => {
    const dailyChartData = Object.keys(stats).map((item) => ({
      day: moment(
        new Date(
          graphData.graphDate.year,
          graphData.graphDate.month - 1,
          Number(item)
        )
      ).format("MMM DD"),
      cost: stats[item].total_cost,
    }));

    const graphInfo = getUsageDataWithFiller(dailyChartData);

    const graphLabels = [...graphInfo].map((item: any) => {
      return item.day;
    });

    const datasets = [
      {
        label: "",
        data: graphInfo.map((item: any) => item.cost),
        backgroundColors: [
          ...Array(graphInfo.length).fill("#0A8BFE"),
          ...Array(graphInfo).fill("#E5E7EB"),
        ],
      },
    ];

    return {
      graphLabels,
      datasets,
    };
  };

  const generateApiKey = async () => {
    try {
      setApiKey((prev) => ({ ...prev, state: "loading" }));
      const res = await getRequest("hemingwai/generate_api_key_v2");
      if (res.data.status === "success") {
        setApiKey({
          state: "success",
          api_key: res.data.api_key,
          remaining_credits: res.data.remaining_credits,
          hasKey: true,
        });
        toast.success("API successfully generated!");
      } else {
        toast.dismiss();
        toast.error("Something went wrong generating your API key!");
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong generating your API key!");

      setApiKey((prev) => ({ ...prev, state: "error" }));
      console.log("error: ", error);
    }
  };

  const remainCreditCardData = {
    title: `Remaining Credits $${apiKey.remaining_credits}`,
    icon: CreditCard,
    description: "Get more API credits and continue creating.",
    buttonText: "Get more API credits",
    primaryButton: true,
    onClick: () => createTopUpSession("API"),
  };

  const Loading = () => (
    <div className="overflow-hidden">
      <Skeleton
        direction="rtl"
        inline
        style={{
          marginBottom: 40,
          marginTop: 32,
          marginRight: 32,
          borderRadius: 10,
        }}
        containerClassName="overflow-hidden flex flex  w-full max-w-[100%] flex-wrap"
        width={"46%"}
        height={300}
        count={2}
      />
    </div>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - API Key</title>
      </Helmet>
      <div className="mt-2 w-[98%] page pb-10">
        <div className="mb-8 relative">
          <h1 className="h3 text-primary-02">Usage</h1>
          <p className="text2 text-grey-02">
            Below you’ll find a summary of API usage for your organization.{" "}
          </p>
          <div className="mt-4 flex justify-between">
            <div>
              <MonthSelector
                value={graphData.graphDate.month}
                onChange={(val) => {
                  setGraphData({
                    ...graphData,
                    graphDate: {
                      ...graphData.graphDate,
                      month: val.number,
                    },
                  });
                }}
              />
            </div>
            <div className="flex items-end">
              <p
                onClick={() => toggleModal(true)}
                className="text3 underline mr-4 cursor-pointer"
              >
                View detailed usage
              </p>
              <div className="cursor-pointer flex border rounded h-10">
                <p
                  onClick={() =>
                    setGraphData({
                      ...graphData,
                      graphView: "daily",
                    })
                  }
                  className={`flex items-center h-full  p1 rounded-l-md px-3 flex-1 ${
                    graphData.graphView === "daily"
                      ? "bg-primary-01 text-white"
                      : "text-primary-02"
                  }`}
                >
                  Daily
                </p>
                <p
                  onClick={() =>
                    setGraphData({
                      ...graphData,
                      graphView: "cumulative",
                    })
                  }
                  className={`flex items-center h-full  p1 rounded-r-md px-3 flex-1 ${
                    graphData.graphView === "cumulative"
                      ? "bg-primary-01 text-white"
                      : "text-primary-02"
                  }`}
                >
                  Cumulative
                </p>
              </div>
            </div>
          </div>
          {pageState === "loading" ? (
            <Loading />
          ) : (
            <div className="flex  flex-col mt-4 im:flex-row justify-between">
              <div className="p-6 relative w-[49%] mr-4 border rounded shadow pr-6">
                <p className="text2 text-grey-02">Tokens generated</p>
                <div className="flex items-center mt-3">
                  <h1 className="mr-2 h1 text-primary-02">
                    {graphData.totalWords}
                  </h1>
                  <span className="flex items-center w-auto h-6 px-1 py-2 rounded bg-green-02 text2 text-green-01">
                    <ArrowIcon />
                    <p className="ml-1">{`${graphData.percentageIncrease}%`}</p>
                  </span>
                </div>
                <div className="mt-10">
                  <LineChart chartData={graphData.graphInfo} />
                </div>
              </div>
              <div className="flex flex-col border w-[50%]   shadow rounded flex-1 p-6 divider">
                <div className="relative flex flex-col justify-between w-[46%]">
                  <p className="flex-1">Cost</p>
                  <h1 className="h1 text-primary-02">${graphData.totalCost}</h1>
                </div>

                <div className="flex-1">
                  <BarChart chartData={graphData.graphInfo} />
                </div>
              </div>
            </div>
          )}
        </div>

        <h1 className="h3 text-primary-02">API Key</h1>
        <p className="text2 text-grey-02">
          Generate an API key to start using the TextCortex Enterprise API.
        </p>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="w-full h-full px-6 py-4 border rounded">
            <p className="text2 text-primary-02">Your API key</p>
            <InputWithCopy
              showLinkIcon={false}
              containerProps={{
                className: "mt-4",
              }}
              value={apiKey.api_key}
            />
            <div className="w-[100%] mt-4 flex justify-between">
              <Button
                className="h-[42px] flex p-0 items-center justify-center w-[148px]"
                onClick={generateApiKey}
                loading={apiKey.state === "loading"}
              >
                Generate API key
              </Button>
              {apiKey.hasKey && (
                <p className="text2 text-grey-02">
                  Remaining credits: {apiKey.remaining_credits}
                </p>
              )}
            </div>
          </div>
          <CtaCard {...remainCreditCardData} />
          {pageData.map((item) => (
            <CtaCard {...item} />
          ))}
        </div>
        {showUsageModal && (
          <ApiUsageHistoryModal
            data={graphData.detailedUsage}
            closeModal={() => toggleModal(false)}
            showModal={showUsageModal}
          />
        )}
      </div>
    </>
  );
};

export default ApiKey;

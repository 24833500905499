import React from "react";
import Button from "../../../components/button";

type Props = {
  onNext: () => void;
};

const Demo = ({ onNext }: Props) => {
  return (
    <div className="p-6 md:p-0 mt-32 max-w-[456px] m-auto">
      <h1 className="text-center h1 text-primary-02 mb-2">
        All you need to do is highlight your text
      </h1>
      <p className="text2 text-grey-02 text-center">
        Rephrase a sentence, write a full email, or extend your thoughts. All
        with one click.
      </p>
      <textarea className="text2 text-primary-02 p-4 outline-0 mt-4 bg-grey-04 rounded w-full h-[307px]" />
      <div className="mt-4">
        <Button onClick={onNext}>Continue</Button>
        <p className="cursor-pointer mt-4 text2 text-grey-02 text-center">
          Skip
        </p>
      </div>
    </div>
  );
};

export default Demo;

import React from "react";

import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../assets/icons/chevron-up.svg";
import { ReactComponent as CheckCircle } from "../assets/icons/check_circle.svg";

const Card = ({
  children,
  title,
  isOpen,
  onCollapse,
  isCompleted,
  className,
}: {
  children: React.ReactNode;
  title?: string;
  isOpen?: boolean;
  onCollapse?: () => void;
  isCompleted?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={`${className} ${
        isOpen ? "bg-white" : "bg-[#F9FAFB] shadow-lg pt-6"
      } relative z-10  px-8 min-h-[76px] w-full rounded mb-8`}
    >
      <div
        onClick={onCollapse}
        className="flex cursor-pointer items-center justify-between"
      >
        <h3 className="mb-5 flex items-center">
          {isCompleted && !isOpen && <CheckCircle className="mr-2" />} {title}
        </h3>
        {onCollapse && <ChevronDown className="mt-[-12px]" />}
      </div>
      <div
        className={`card-collapse-transition ${
          isOpen
            ? "max-h-[100vh] h-auto overflow-auto card-open-transition"
            : "max-h-0 overflow-hidden card-collapse-transition"
        } `}
      >
        {children}
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import {
  GroupHeadingProps,
  components,
  GroupProps,
  InputProps,
  ValueContainerProps,
} from "react-select";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import axiosClient from "../../axios";
import { OutputFeedback } from "../../types";
import { storageKeys } from "../../utils/helpers";
import { Tab } from "./useDpu";

export const templateSelectStyles = {
  width: "fit-content",
  height: "24px",
  cursor: "pointer",
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: 600,
  fontFamily: "Inter-Medium",
  outline: "none",
  marginTop: 12,
  letterSpacing: 0.4,
  border: "none",
  background: "transparent",
};

export const getTemplatesOfCategory = (templates: any, category: string) => {
  let templatesOfCategory: any = [];
  Object.keys(templates).forEach((template) => {
    if (templates[template].category === category) {
      templatesOfCategory = [
        ...templatesOfCategory,
        { ...templates[template], value: template },
      ];
    }
  });
  return templatesOfCategory;
};

export const getCategorizedTemplates = ({ templates, categories }: any) => {
  let categorizedTemplates: any = [];
  Object.keys(categories).forEach((category) => {
    categorizedTemplates = [
      ...categorizedTemplates,
      {
        ...categories[category],
        category: category,
        templates: getTemplatesOfCategory(templates, category),
      },
    ];
  });
  return categorizedTemplates;
};

export const getTemplateOptions = ({ templates, categories }: any) => {
  //format of select option
  // [{ label: "", options: [{ value: "", label: "" }] }];
  const categorizedTemplates = getCategorizedTemplates({
    templates,
    categories,
  });

  const selectOptions = categorizedTemplates.map((category: any) => {
    return {
      icon: category.icon,
      label: category.display_name,
      options: category.templates.map((template: any) => ({
        ...template,
        label: template.display_name,
        value: template.display_name.toLowerCase().replaceAll(" ", "_"),
      })),
    };
  });
  return selectOptions;
};

export const Group: React.FC<GroupProps> = ({ children, ...props }) => {
  return (
    <components.Group {...props} className="py-0 group-container h5BoldRegular">
      {children}
    </components.Group>
  );
};

export const GroupHeading: React.FC<GroupHeadingProps> = ({
  children,
  ...props
}) => {
  return (
    components.GroupHeading && (
      <div className="py-1 group-heading-wrapper hover:bg-none">
        <components.GroupHeading {...props}>
          <div id={props.id} className="flex items-center justify-between">
            <div className="flex items-center h-full py-1 cursor-pointer ">
              <p className="mr-2">
                {(props.data as any).icon ? (props.data as any).icon : null}
              </p>
              <div className=" flex items-center capitalize text-brand-02 h5BoldRegular">
                {children}
              </div>
            </div>
            {/* {isOpen ? <ChevronUp /> : <ChevronDown />} */}
          </div>
        </components.GroupHeading>
      </div>
    )
  );
};

export const ValueContainer = ({ children, ...props }: ValueContainerProps) => (
  <div className="flex items-center h-6 w-auto">
    <SearchIcon className="flex items-center w-4 h-4" />
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </div>
);

export const setDefaultValue = ({
  templates,
  categories,
  tab,
  defaultValue,
}: any) => {
  const categorizedTemplates = getCategorizedTemplates({
    templates,
    categories,
  });
  // const codeTemplates = categorizedTemplates.find(
  //   (item: any) => item.category === "code"
  // );
  const allTemplates = Object.keys(templates).map((key) => ({
    value: key,
    ...templates[key],
  }));

  const chosenTemplate = allTemplates.find(
    (item: any) => item.value === defaultValue
  );

  // if (codeTemplates && tab === "developer_mode") {
  //   const template = codeTemplates.templates[0];
  //   return {
  //     ...template,
  //     label: template.display_name,
  //     value: template.display_name.toLowerCase().replaceAll(" ", "_"),
  //   };
  // }
  if (chosenTemplate && tab === "templates") {
    return {
      ...chosenTemplate,
      label: chosenTemplate.display_name,
    };
  }
};

export const filterOption = (
  { label, value }: any,
  string: string,
  templateOptions: any
) => {
  if (
    label.toLowerCase().includes(string) ||
    value.toLowerCase().includes(string)
  )
    return true;
  const groupOptions = templateOptions.filter((group: any) =>
    group.label.toLocaleLowerCase().includes(string)
  );
  if (groupOptions) {
    for (const groupOption of groupOptions) {
      // Check if current option is in group
      const option = groupOption.options.find(
        (opt: any) => opt.value === value
      );
      if (option) {
        return true;
      }
    }
  }
  return false;
};

export const sendOutputFeedback = async (data: OutputFeedback) => {
  try {
    await axiosClient({
      method: "post",
      url: "/internal/v1/output-feedback",
      data,
    });
  } catch (error) {
    console.error("error: ", error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;

function processChunk(chunk: any, callback?: any) {
  callback(chunk);
}

export async function fetchTokenStreams(url: string, data: any, callback: any) {
  try {
    const accessToken = await localStorage
      .getItem(storageKeys.ACCESS_TOKEN)
      ?.replace(/"/g, "");
    const headers = new Headers({
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
      "X-Client-Type": "webapp",
    });

    const response: any = await fetch(baseURL + url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = "";

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        // Process the remaining buffer when the stream ends
        if (buffer) {
          processChunk(buffer);
        }
        break;
      }

      buffer += decoder.decode(value, { stream: true });
      // Split the buffer by the delimiter
      const parts = buffer.split("\n\n");
      // Process each part except for the last one (which might be incomplete)
      for (let i = 0; i < parts.length - 1; i++) {
        processChunk(parts[i], callback);
      }
      // Keep the last part in the buffer for future processing
      buffer = parts[parts.length - 1];
    }
  } catch (error) {
    console.error(error);
  }
}

import { IconProps } from "../../types";
import React from "react";

const Lightning = (props: IconProps) => {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.375 15.9375L5.4375 10.625L1.1875 9.03125L8.625 1.0625L7.5625 6.375L11.8125 7.96875L4.375 15.9375Z"
        stroke="#8E99A5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Lightning;

import React, { useState } from "react";
import Modal from "react-modal";
import { ReactComponent as CloseButton } from "../assets/icons/close_button.svg";
import { ReactComponent as UploadIcon } from "../assets/icons/upload.svg";
import axiosClient from "../axios";
import DragAndDropUpload from "./dragAndDropUpload";
import { toast } from "react-toastify";
import { LoadingState } from "../types";
import { EarnItem, ModalText } from "../screens/dashboard/earnMore";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  item?: EarnItem;
  onSuccess?: () => void;
  textData?: ModalText;
};

const WriteReviewModal = ({
  showModal,
  closeModal,
  item,
  onSuccess,
  textData = {
    mainTitle: "Write a review",
    stepOne: {
      title: "Write a review following this link",
      buttonText: "Write a review",
    },
  },
}: Props) => {
  const modalStyles = { overlay: { zIndex: 100000 } };
  const [loadingState, setLoadingState] = useState<LoadingState>("idle");
  const [file, setFile] = useState();

  const sendReview = async () => {
    if (!file) {
      return;
    }
    toast.loading("Uploading screenshot...");
    setLoadingState("loading");

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", item?.earnType || "");

      const res = await axiosClient({
        method: "post",
        url: "/accounts/upload_screenshot",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.data.status === "success" && res.data.error === 0) {
        setLoadingState("success");
        toast.dismiss();
        toast.success(res.data.message);
        onSuccess?.();
      } else {
        toast.dismiss();
        toast.error(
          "Something went wrong :(. Please try again or refresh the page."
        );
      }
    } catch (error: any) {
      setLoadingState("error");
      toast.dismiss();
      if (error.response.status === 413) {
        toast.error(
          "Something is wrong with your screenshot :(. Please try again with a valid screenshot."
        );
        return;
      }
      switch (error?.response?.data?.error) {
        case 406:
        case 405:
          toast.error(error?.response?.data?.message);
          break;
        default:
          toast.error(
            "Something went wrong :(. Please try again or refresh the page."
          );
      }

      console.log("error: ", error);
      console.log("error response: ", error.response);
    }
  };

  const handleUpload = (file: any) => {
    setFile(file);
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={showModal}
      className="relative z-30 flex items-center w-full h-full m-auto bg-black-rgba"
    >
      <div className="w-[490px] p-6 h-auto  rounded shadow flex flex-col m-auto bg-white ">
        <div className="flex justify-between">
          <h2 className="">{textData.mainTitle}</h2>
          <CloseButton className="cursor-pointer" onClick={closeModal} />
        </div>
        <div className="">
          <p className="text4 text-primary-02">
            Step 1:{" "}
            <span className="text4 text-grey-02">{textData.stepOne.title}</span>
          </p>
          <a href={item?.url} target="_blank" rel="noreferrer noopener">
            <button className="px-12 py-2 mt-4 text-white border rounded h2 bg-primary-01">
              {textData.stepOne.buttonText}
            </button>
          </a>
        </div>
        <p className="mt-10 mb-4 text4 text-primary-02">
          Step 2: <span className="text4 text-grey-02">Upload screenshot</span>
        </p>
        <div className="border rounded ">
          <DragAndDropUpload
            label={
              <div className="flex flex-col items-center justify-center w-full h-full mt-6 text2 text-grey-02">
                <UploadIcon />
                <p className="mt-2">
                  Already wrote a review? Upload it here for processing
                </p>
              </div>
            }
            uploadImage={handleUpload}
          />
        </div>

        <button
          onClick={sendReview}
          className={`w-[40%] mt-4 h2 bg-primary-01 text-white py-2 px-5 border rounded ${
            !file ? "opacity-70" : "opacity-100"
          }`}
          disabled={!file || loadingState === "loading"}
        >
          Send screenshot
        </button>
      </div>
    </Modal>
  );
};

export default WriteReviewModal;

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import TextLengthProgress from "./textLengthProgress";

export type TextareaProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  error?: FieldError;
  label?: string;
  sizing?: "sm" | "lg";
  containerClassName?: string;
  showLengthProgress?: boolean;
  maxProgLength?: number;
  value?: string;
  labelClassName?: string;
  disabled?: boolean;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      sizing = "lg",
      error,
      showLengthProgress,
      maxProgLength,
      labelClassName,
      value,
      disabled,
      ...props
    },
    ref
  ) => {
    const [chars, setChars] = useState("");

    const handleChange = (e: any) => {
      setChars((e.target as any)?.value || "");
    };
    useEffect(() => {
      const textarea = document.querySelector(
        `[name=${(props as any).name}]`
      ) as HTMLElement;
      textarea?.addEventListener("keydown", handleChange);
      return () => {
        textarea?.removeEventListener("keydown", handleChange);
      };

      // eslint-disable-next-line
    }, []);

    return (
      <div className={props.containerClassName}>
        <label
          className={classNames({
            "flex flex-col  text-primary-02 ": true,
            text2: sizing === "lg",
            text5: sizing === "sm",
            labelClassName: Boolean(labelClassName),
          })}
        >
          {props.label}
          <textarea
            disabled={disabled}
            ref={ref}
            {...props}
            className={classNames({
              [`w-full h-full  bg-white pl-3 pr-7 py-1  placeholder-grey-02  text-primary-02 max-w-xl  disabled:bg-white disabled:opacity-70 outline-0 disabled:cursor-not-allowed focus:outline-none  resize-none rounded ${
                error
                  ? "border-[1px] border-red-01 outline-0"
                  : "outline-0 border-[1px] focus:border-[1px] focus:border-grey-02"
              }`]: true,
              "text2 mt-2": sizing === "lg",
              "text5 mt-1": sizing === "sm",
              [`${props.className}`]: true,
            })}
            value={value}
          />
        </label>
        {showLengthProgress && (
          <TextLengthProgress
            characters={chars.trim().length}
            maxChars={maxProgLength ? maxProgLength : 20 * 3}
          />
        )}
      </div>
    );
  }
);

export default Textarea;

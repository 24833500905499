import React from "react";
import { ReactComponent as ProductDescription } from "../../../assets/icons/product-description.svg";
import { ReactComponent as ShopifyProducts } from "../../../assets/icons/shopify-products.svg";
import { ReactComponent as BlogPosts } from "../../../assets/icons/blog-posts.svg";
import { ReactComponent as OutreachEmails } from "../../../assets/icons/outreach-emails.svg";
import { ReactComponent as InstagramCaptions } from "../../../assets/icons/instagram-captions.svg";
import { ReactComponent as FacebookAds } from "../../../assets/icons/facebook-ad.svg";
import Button from "../../../components/button";

type Props = {
  onNext: () => void;
};

const productFeatures = [
  {
    title: "Product Description",
    icon: ProductDescription,
    link: "/user/dashboard/product-description",
  },
  {
    title: "Shopify Products",
    icon: ShopifyProducts,
    link: "/user/dashboard/shopify-products",
  },
  {
    title: "Blog Posts",
    icon: BlogPosts,
    link: "/user/dashboard/blog-posts",
  },
  {
    title: "Outreach Emails",
    icon: OutreachEmails,
    link: "/user/dashboard/outreach-emails",
  },
  {
    title: "Instagram Captions",
    icon: InstagramCaptions,
    link: "/user/dashboard/product-description",
  },
  {
    title: "Facebook Ad",
    icon: FacebookAds,
    link: "/user/dashboard/shopify-products",
  },
];

const OnboardingSuccess = ({ onNext }: Props) => {
  return (
    <div className="p-6 md:p-0 flex flex-col items-center mt-32 m-auto">
      <h1 className="h1 text-primary-02 text-center">
        Your AI partner is ready to write
      </h1>
      <p className="text-center text2 text-grey-02">
        You're all set, start creating
      </p>
      <div className="mt-4 items-start grid grid-cols-2 md:grid-cols-3 gap-4 content-center m-auto justify-items-center">
        {productFeatures.map((item) => {
          const Icon = item.icon;
          return (
            <div className="w-full cursor-pointer mr-4 rounded border flex justify-center items-center flex-col py-5 px-4">
              <Icon />
              <p className="mt-1 text2 text-center text-primary-02">
                {item.title}
              </p>
            </div>
          );
        })}
      </div>
      <Button onClick={onNext} className="mt-12">
        Start with other template!
      </Button>
    </div>
  );
};

export default OnboardingSuccess;

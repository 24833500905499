import React from "react";

type Props = {
  primaryColor?: boolean;
  small?: boolean;
};

const Spinner = ({ small, primaryColor }: Props) => {
  return (
    <div
      style={{
        marginTop: 4,
        width: small ? 24 : 40,
        height: small ? 24 : 40,
      }}
      className={`loader`}
    ></div>
  );
};

export default Spinner;

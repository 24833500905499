import React from "react";
import Modal from "react-modal";
import Input from "../components/input";
import { ReactComponent as CloseButton } from "../assets/icons/close_button.svg";
import moment from "moment";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
};

const ApiUsageHistoryModal = ({ data, showModal, closeModal }: Props) => {
  const modalStyles = { overlay: { zIndex: 100000, backgroundColor: "unset" } };

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={{
        ...modalStyles,
      }}
      isOpen={showModal}
      className="relative z-30 flex items-center justify-end h-full bg-black-rgba"
    >
      <div className="overflow-y-auto relative w-[720px] h-[100vh] p-6  rounded shadow-md flex flex-col bg-white ">
        <div className="  top-4 flex justify-between">
          <div className="w-10" />
          <CloseButton className="cursor-pointer" onClick={closeModal} />
        </div>
        <h1 className="h4">API Usage History</h1>
        <div className="grid grid-cols-8">
          <div>
            <p className="h4 text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
              ID
            </p>
            {data.map((item: any, index: any) => (
              <p
                className={`text4 py-4 text-grey-02  border-[##E5E7EB] ${
                  data.length - 1 !== index && "border-b-[1px]"
                }`}
              >
                {" "}
                {index + 1}
              </p>
            ))}
          </div>
          <div className="col-span-1">
            <p className=" h4 text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
              Template
            </p>
            {data.map((item: any, index: any) => (
              <div
                className={`py-4 flex justify-between items-center  text4 text-grey-02 border-[##E5E7EB] ${
                  data.length - 1 !== index && "border-b-[1px]"
                }`}
              >
                <p>{item.template_name}</p>
              </div>
            ))}
          </div>
          <div className="col-span-2">
            <p className="h4  text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB] pl-8">
              Cost
            </p>
            {data.map((item: any, index: any) => (
              <p
                className={`pl-8 text4 py-4 text-grey-02  border-[##E5E7EB] ${
                  data.length - 1 !== index && "border-b-[1px]"
                }`}
              >
                {" "}
                {item.total_cost}
                {item.currency}
              </p>
            ))}
          </div>
          <div className="col-span-2">
            <p className="h4  text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
              Remaining credits
            </p>
            {data.map((item: any, index: any) => (
              <p
                className={`text4 py-4 text-grey-02  border-[##E5E7EB] ${
                  data.length - 1 !== index && "border-b-[1px]"
                }`}
              >
                {" "}
                {item.remaining_credits}
                {item.currency}
              </p>
            ))}
          </div>
          <div className="col-span-2">
            <p className=" h4 text-grey-02 pt-1 border-b-[1px] border-[##E5E7EB]">
              Date
            </p>
            {data.map((item: any, index: any) => (
              <div
                className={`py-4 flex justify-between items-center  text4 text-grey-02 border-[##E5E7EB] ${
                  data.length - 1 !== index && "border-b-[1px]"
                }`}
              >
                <p>{moment(item.created_at).format("MMM DD YYYY, HH:MM")}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ApiUsageHistoryModal;

import { IconProps } from "../../types";
import React from "react";

const Formal = (props: IconProps) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3H1C0.723858 3 0.5 3.22386 0.5 3.5V11.5C0.5 11.7761 0.723858 12 1 12H12C12.2761 12 12.5 11.7761 12.5 11.5V3.5C12.5 3.22386 12.2761 3 12 3Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.5 4V1.27273C9.5 1.2004 9.37357 1.13103 9.14853 1.07988C8.92348 1.02873 8.61826 1 8.3 1H4.7C4.38174 1 4.07652 1.02873 3.85147 1.07988C3.62643 1.13103 3.5 1.2004 3.5 1.27273V4"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Formal;

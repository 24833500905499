import { User } from "../../types";
import {
  AUTH_LOADING,
  SET_USER,
  REMOVE_ERROR,
  SUBMITTING_FORM,
  AUTH_FAILURE,
  AUTH_SUCCESS,
  ENTER_DASHBOARD,
  LOGOUT,
  // LOGOUT,
} from "./actions";

export type State = {
  user: User | null;
  isLoggedIn: boolean;
  token?: string;
  loading: boolean;
  submittingForm?: boolean;
  error: any; //TODO: figure out what's wrong with this
};

type Action = {
  type: string;
  payload?: any;
};

export const initialState: State = {
  user: null,
  isLoggedIn: false,
  token: "",
  loading: true,
  error: null,
  submittingForm: false,
};

export const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUBMITTING_FORM:
      return {
        ...state,
        error: null,
        submittingForm: action.payload,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        error: null,
        user: payload.user,
        loading: false,
        submittingForm: false,
      };
    case AUTH_FAILURE:
      return {
        ...state,
        user: null,
        loading: false,
        submittingForm: false,
        error: {
          type: payload?.error?.type,
          message: payload?.error?.message,
        },
      };
    case REMOVE_ERROR:
      return {
        ...state,
        error: null,
      };
    case SET_USER:
      return {
        ...state,
        user: payload.user ? { ...payload.user } : payload.user,
        loading: false,
      };
    case ENTER_DASHBOARD:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        submittingForm: false,
        loading: false,
        error: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        loading: false,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

import React from "react";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Chip = ({ ...props }: Props) => {
  return (
    <button
      {...props}
      className={`px-1 rounded-[6px] bg-grey-04 whitespace-nowrap text-grey-01 caption1 h-4 flex items-center justify-center cursor-default ${props.className}`}
    >
      {props.children}
    </button>
  );
};

type Props2 = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  text: string;
};

export const ChipDark = ({ text }: Props2) => {
  return (
    <span className="px-3 py-1 text-white rounded-md bg-primary-02 text3">
      {text}
    </span>
  );
};

export default Chip;

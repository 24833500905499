import React from "react";
import { ReactComponent as CloseIcon } from "../assets/icons/close_button.svg";
import Button from "./button";
import Modal from "react-modal";
import { Link } from "react-router-dom";

// type Data = {
//   creditsWon?: number;
// };

type Props = {
  showModal: boolean;
  closeModal: () => void;
  icon?: () => any;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  buttonText?: string | React.ReactNode;
  data?: any;
  link?: string;
  titleClassName?: string;
  buttonAction?: () => void;
  footer?: React.ReactNode;
};

const NotificationModal = ({
  showModal,
  closeModal,
  title,
  icon,
  description,
  buttonText,
  data,
  link,
  titleClassName,
  buttonAction,
  footer,
}: Props) => {
  const modalStyles = { overlay: { zIndex: 10000 } };

  const Icon = () => icon?.();

  return (
    <Modal
      style={modalStyles}
      isOpen={showModal}
      className="relative z-30 flex items-center justify-center w-full h-full m-auto bg-black-rgba"
    >
      <div className="relative py-6 w-[492px] border shadow rounded bg-white flex flex-col items-center justify-center">
        <CloseIcon
          onClick={closeModal}
          className="absolute z-10 cursor-pointer top-4 right-3"
        />
        {icon && <Icon />}
        <div
          className={`${
            titleClassName
              ? titleClassName
              : "flex items-center mt-4 h3 text-primary-02"
          } `}
        >
          {title}
        </div>
        <p className="mb-1 h2 w-[80%] text-center text-grey-02">
          {description}
        </p>
        <Link to={link || ""} className="flex justify-center w-full">
          <Button
            style={{ fontSize: 15 }}
            onClick={() => closeModal()}
            className="mt-4 bg-brand-02 h5BoldRegular max-w-[246px]"
          >
            {buttonText && buttonText}
          </Button>
        </Link>
        {footer && <div>{footer}</div>}
      </div>
    </Modal>
  );
};

export default NotificationModal;

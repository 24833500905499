import React, { useState } from "react";

import { ReactComponent as InviteFriendsIcon } from "../assets/icons/invite_friends.svg";
import { ReactComponent as JoinExpertsIcon } from "../assets/icons/join_experts.svg";
import { ReactComponent as ReviewUsIcon } from "../assets/icons/review_us.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";

import { useAuth } from "../context/auth/authContext";
import Tippy from "@tippyjs/react";
import { ChipDark } from "./chip";

export const InviteFriends = () => {
  const {
    state: { user },
  } = useAuth();
  const [copyToolTip, setCopyToolTip] = useState<{
    show: boolean;
    text?: string;
  }>();

  const onCopy = async (value: string) => {
    try {
      setCopyToolTip({
        show: true,
        text: "Copied!",
      });
      await navigator.clipboard.writeText(value);
    } catch (error) {
      console.log("Copy failed:", error);
    }
  };

  return (
    <CTACard
      title={"Invite Friends, Get Free Creations"}
      description={
        "Copy and send your invitation link to a friend for extra daily creations! Each accepted invite will give 5 creations per day."
      }
      icon={InviteFriendsIcon}
    >
      <div className="flex flex-col">
        <p className="my-1">Your invitation link</p>
        <Tippy
          visible={copyToolTip?.show}
          offset={[0, 8]}
          content={
            copyToolTip?.show && <ChipDark text={copyToolTip?.text || ""} />
          }
          delay={[300, 0]}
          moveTransition={"none"}
        >
          <button className="mt-1 w-full flex items-center justify-between text-left bg-white h2 text-grey-02 shadow border rounded outline-0 link px-4 pt-[2px] h-[40px]">
            {user?.affiliate_link.slice(0, 25)}...
            <CopyIcon
              onClick={() => onCopy(user?.affiliate_link || "")}
              className="mr-3 outline-none cursor-pointer"
            />
          </button>
        </Tippy>
      </div>
    </CTACard>
  );
};

export const ReviewCard = () => {
  const {
    state: { user },
  } = useAuth();
  return (
    <CTACard
      title={"Get 14-days Business for free"}
      description={
        "Get a trial of TextCortex most popular plan for a Review on G2."
      }
      icon={ReviewUsIcon}
    >
      <a
        target={"_blank"}
        href={`https://tell.textcortex.com/write-g2-review-get-14-days-free?email=${user?.email}&plan_type=${user?.plan_type}`}
      >
        <button className="mt-1 w-full bg-white h2 text-grey-02 shadow border rounded outline-0 link px-4 pt-[2px] h-[40px]">
          Give Review
        </button>
      </a>
    </CTACard>
  );
};

export const JoinExperts = () => {
  const {
    state: { user },
  } = useAuth();
  return (
    <CTACard
      title={"Join Community of Experts"}
      description={
        "Learn new tips and tricks to from industry experts. Ask questions and become a better writer together with the TextCortex Community."
      }
      icon={JoinExpertsIcon}
    >
      <a
        href={`https://tell.textcortex.com/introduce-yourself?email=${user?.email}&plan_type=${user?.plan_type}`}
        target="_blank"
      >
        <button className="mt-1 w-full bg-white h2 text-grey-02 shadow border rounded outline-0 link px-4 pt-[2px] h-[40px]">
          Join Community
        </button>
      </a>
    </CTACard>
  );
};

const CTACard = ({ icon, title, description, children }: any) => {
  const Icon = icon;
  return (
    <div className="border rounded shadow p-4 flex flex-col justify-between">
      <div>
        <Icon />
        <h2 className="text-primary-02 h4 mt-2">{title}</h2>
        <p className="text-grey-02 text2">{description}</p>
      </div>
      {children}
    </div>
  );
};

const DashboardCtaCard = () => {
  const {
    state: { user },
  } = useAuth();
  const cards =
    user?.plan_type !== "Free"
      ? [ReviewCard, JoinExperts]
      : [ReviewCard, InviteFriends];
  const CTA = cards[Math.floor(Math.random() * cards.length)];
  return <CTA />;
};

export default DashboardCtaCard;

import _ from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/button";
import Textarea from "../../../components/textarea";
import { CustomizeTab, State } from "./useCustomizeAI";

type Props = {
  state: State;
  placeholder: string;
  name: string;
  dispatch: any;
  nextStep: CustomizeTab;
  currentStep: "step_1" | "step_2" | "step_3";
  buttonText?: string;
  submitForm?: (data: any) => void;
};

const ExampleText = ({
  state,
  placeholder,
  name,
  nextStep,
  dispatch,
  currentStep,
  buttonText,
  submitForm,
}: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [name]: state.formValues[name as "text_1" | "text_2" | "text_3"],
    },
  });

  const handleSubmitForm = (data: any) => {
    dispatch({
      type: "SUBMIT_FORM",
      payload: {
        completedStep: currentStep,
        nextTab: nextStep,
        formValues: data,
      },
    });
    if (currentStep === "step_3" && submitForm) {
      submitForm({ ...state.formValues, [name]: data[name] });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit && handleSubmit(handleSubmitForm)();
  };

  return (
    <>
      <form onSubmit={onSubmit} className="">
        <Textarea
          error={errors[name]}
          {...register(name, {
            required: true,
            validate: (value) => _.words(value).length > 3,
          })}
          placeholder={placeholder}
          className="min-w-full max-w-full min-h-[100px]"
        />
        {errors[name] && (
          <p className="text-red-500 mt-1 text3">
            You need to add at least 4 words
          </p>
        )}
        <Button
          type="submit"
          className="h-[40px] mt-4 mb-4 min-w-full max-w-full flex items-center justify-center"
        >
          {buttonText || "Continue"}
        </Button>
      </form>
    </>
  );
};

export default ExampleText;

import { ReactComponent as ProductDescription } from "../assets/icons/product_description.svg";
import { ReactComponent as BlogWriting } from "../assets/icons/blog_writing.svg";
import { ReactComponent as MarketingCopies } from "../assets/icons/marketing_copies.svg";
import { ReactComponent as ShopifyDescription } from "../assets/icons/shopify_description.svg";
import { ReactComponent as OutreachEmails } from "../assets/icons/outreach_emails.svg";
import { ReactComponent as SupportEmails } from "../assets/icons/support_emails.svg";
import { ReactComponent as YoutubeDescription } from "../assets/icons/youtube_description.svg";
import { ReactComponent as InstagramDescription } from "../assets/icons/instagram_description.svg";
import { ReactComponent as TitleGenerator } from "../assets/icons/title_generator.svg";
import { ReactComponent as FreestyleWriting } from "../assets/icons/pen.svg";

import { ReactComponent as FreestyleWritingOutline } from "../assets/icons/writing.svg";
import { ReactComponent as ProductDescriptionOutline } from "../assets/icons/product_description_outline.svg";
import { ReactComponent as BlogWritingOutline } from "../assets/icons/blog_writing_outline.svg";
import { ReactComponent as MarketingCopiesOutline } from "../assets/icons/marketing_copies_outline.svg";
import { ReactComponent as ShopifyOutline } from "../assets/icons/shopify.svg";
import { ReactComponent as OutreachEmailOutline } from "../assets/icons/outreach_email_outline.svg";
import { ReactComponent as SupportEmailOutline } from "../assets/icons/support_email_outline.svg";
import { ReactComponent as YoutubeOutline } from "../assets/icons/youtube_outline.svg";
import { ReactComponent as InstagramOutline } from "../assets/icons/instagram_outline.svg";
import { ReactComponent as TitleGeneratorOutline } from "../assets/icons/title_generator_outline.svg";

export const getTemplateListData = () => {
  return [
    {
      outline: TitleGeneratorOutline,
      icon: TitleGenerator,
      title: "Rewrite",
      description: "Let's rewrite your text together.... ",
      link: "",
      value: "paraphrase",
      plan_to_unlock: "Free",
    },
    {
      icon: FreestyleWritingOutline,
      outline: FreestyleWriting,
      title: "Auto Complete",
      plan_to_unlock: "Free",
      description:
        "AI writing assistant helps you with any type of content writing.",
      link: "",
      value: "auto_complete",
    },
    {
      plan_to_unlock: "Business",
      outline: ProductDescriptionOutline,
      icon: ProductDescription,
      title: "Product Descriptions",
      description: "Start creating product descriptions that convert.",
      link: "",
      value: "product_description",
    },
    {
      outline: BlogWritingOutline,
      icon: BlogWriting,
      plan_to_unlock: "Free",
      title: "Blog writing",
      description: "Create high-quality blog posts within a click of a button.",
      link: "",
      value: "blog_body",
    },
    // {
    //   outline: BlogWritingOutline,
    //   icon: BlogWriting,
    //   title: "Blog Wizard",
    //   description: "Create a well structured blog post using keywords.",
    //   link: "",
    //   value: "blog_outline",
    // },
    {
      outline: YoutubeOutline,
      icon: YoutubeDescription,
      title: "Youtube Captions",
      description:
        "Create catchy & SEO-optimized  captions for your youtube videos ",
      link: "",
      value: "youtube_captions",
      plan_to_unlock: "Business",
    },
    {
      outline: InstagramOutline,
      icon: InstagramDescription,
      title: "Instagram Descriptions",
      description:
        "Create unique & engaging content for your Instagram profile within seconds.",
      link: "",
      value: "instagram_caption",
      plan_to_unlock: "Business",
    },
    {
      outline: MarketingCopiesOutline,
      icon: MarketingCopies,
      title: "Marketing Copies",
      description:
        "AI marketing copy generator to cut 70% of the time you spend on writing copies.",
      link: "",
      value: "ads_copy",
      plan_to_unlock: "Business",
    },
    {
      outline: TitleGeneratorOutline,
      icon: TitleGenerator,
      title: "Email body",
      description: "Start creating email body .... ",
      link: "",
      value: "general_email",
      plan_to_unlock: "Business",
    },
    {
      outline: TitleGeneratorOutline,
      icon: TitleGenerator,
      title: "Tone Change",
      description: "Let's rewrite your text together.... ",
      link: "",
      value: "change_tone",
      plan_to_unlock: "Pro",
    },
    // {
    //   outline: ShopifyOutline,
    //   icon: ShopifyDescription,
    //   title: "Shopify Descriptions",
    //   description:
    //     "Creating SEO-optimized Shopify product descriptions is as easy.",
    //   link: "",
    //   value: "product_description_shopify",
    // },
    {
      outline: OutreachEmailOutline,
      icon: OutreachEmails,
      title: "Outreach Emails",
      description:
        "TextCortex helps you create concise & unique emails that engages with readers.",
      link: "",
      value: "customer_support_email",
      plan_to_unlock: "Business",
    },
    // {
    //   outline: SupportEmailOutline,
    //   icon: SupportEmails,
    //   title: "Support Emails",
    //   description:
    //     "Turn your bullet points into well phrased emails in seconds.",
    //   link: "",
    //   value: "customer_support_email",
    // },
    // {
    //   outline: YoutubeOutline,
    //   icon: YoutubeDescription,
    //   title: "Youtube Descriptions",
    //   description:
    //     "Create catchy & SEO-optimized description for your YouTube channel.",
    //   link: "",
    //   value: "youtube_description",
    // },

    {
      outline: TitleGeneratorOutline,
      icon: TitleGenerator,
      title: "Title Generator",
      description: "Start creating titles that attract visitors.",
      link: "",
      value: "blog_title",
      plan_to_unlock: "Business",
    },
    {
      outline: TitleGeneratorOutline,
      icon: TitleGenerator,
      title: "Subject Line",
      description: "Start creating subject lines .... ",
      link: "",
      value: "email_subject",
      plan_to_unlock: "Business",
    },
  ];
};

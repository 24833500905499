import React from "react";
import Input, { InputWithCopy } from "../../../components/input";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import Button from "../../../components/button";
import UserIcon from "../../../components/userIcon";
// import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg";
// import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg";
import { useAuth } from "../../../context/auth/authContext";
import colors from "../../../utils/colors";
import { useForm } from "react-hook-form";
import useInviteFriends from "../../../hooks/useInviteFriends";
import { LoadingState } from "../../../types";
import { Helmet } from "react-helmet";

type IFormInputs = {
  name: string;
  email: string;
};

type InviteFriendsFormProps = {
  sendInvite: (data: IFormInputs) => Promise<void>;
  inviteState: LoadingState;
};

export const InviteFriendsForm = ({
  sendInvite,
  inviteState,
}: InviteFriendsFormProps) => {
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();
  return (
    <div className="p-4 mt-5 border rounded">
      <p className="text2 text-grey-02">
        For each active user referred you earn 10 extra recurring creations per
        day
      </p>
      <div className="flex justify-between mt-10">
        <div className="flex-1 mr-2">
          <Input
            inputStyle={{ height: 42 }}
            label={"Name"}
            placeholder={"Micheal Scott"}
            {...register("name", { required: "This fields is required" })}
            hasError={Boolean(errors.name)}
          />
          <Input
            inputStyle={{ height: 42 }}
            label={"Email Address"}
            placeholder={"user@textcortex.com"}
            type="email"
            {...register("email", { required: "This fields is required" })}
            hasError={Boolean(errors.email)}
          />
        </div>
        <div className="flex flex-col flex-1 ml-2">
          <div className="flex items-center mb-2">
            <p className="mr-2 text2 text-primary-02">Private link</p>
            <InfoIcon className="w-4 h-4 cursor-pointer" />
          </div>
          <InputWithCopy value={auth.state.user?.affiliate_link} />
        </div>
      </div>
      <Button
        className="w-[148px] flex pt-3 items-center justify-center h-[42px]"
        loading={inviteState === "loading"}
        onClick={handleSubmit((data) => sendInvite(data))}
      >
        Send Invite
      </Button>
    </div>
  );
};

const InviteFriends = () => {
  const { sendInvite, inviteState, inviteHistoryState } = useInviteFriends();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Invite Friends</title>
      </Helmet>
      <div className="mt-2 w-full page">
        <h1 className="h3 text-primary-02">Invite Friends</h1>
        <p className="text2 text-grey-02">Invite Friends, get free creations</p>
        <InviteFriendsForm sendInvite={sendInvite} inviteState={inviteState} />
        <div className="py-4 pl-6 mt-6 border rounded w-full">
          <h2 className="mb-8 text2 text-primary-02">Previous Invites</h2>
          <div className="grid grid-cols-7 w-full">
            <div className="col-span-2">
              <p className="text2 text-grey-02">Name</p>
              <div className="flex flex-col mt-4">
                {inviteHistoryState.history.map((item, i) => (
                  <div className="flex items-center h-12">
                    <UserIcon initial={item.name[0]} color={colors[i]} />
                    <p className="ml-2 text2 text-primary-02">{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-2">
              <p className="text2 text-grey-02">Email</p>
              <div className="flex flex-col mt-4">
                {inviteHistoryState.history.map((item) => (
                  <div className="flex items-center h-12">
                    <p className="text2 text-primary-02">{item.email}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-2">
              <p className="text2 text-grey-02">Status</p>
              <div className="flex flex-col mt-4">
                {inviteHistoryState.history.map((item, index) => {
                  const textColor = item.signed_up
                    ? "text-green-01"
                    : "text-yellow-01";
                  const background = item.signed_up
                    ? "bg-green-02"
                    : "bg-yellow-02";
                  return (
                    <div key={index} className={`flex items-center h-12 `}>
                      <p
                        className={`text2 px-3 ${textColor} ${background} rounded `}
                      >
                        {item.signed_up ? "accepted" : "pending"}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="">
              <p className="text2 text-grey-02">Date</p>
              <div className="mt-4">
                {inviteHistoryState.history.map((item) => (
                  <div className="flex items-center h-12">
                    <p className="text2 text-primary-02">{item.date}</p>
                  </div>
                ))}
              </div>
              {/* <div className="flex items-center mt-4">
              <div className="p-2 border rounded cursor-pointer">
                <ChevronLeft />
              </div>
              <div className="p-2 ml-4 border rounded cursor-pointer">
                <ChevronRight />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteFriends;

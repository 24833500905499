import React from "react";
import { useForm } from "react-hook-form";
import Card from "../../../components/customizeAICard";
import Button from "../../../components/button";
import Input from "../../../components/input";
import SelectInput from "../../../components/selectDropdown";
import Textarea from "../../../components/textarea";
import { Category, CustomizeTab, State } from "./useCustomizeAI";

type IntroInputs = {
  customization_name: string;
  description: string;
  category: Category;
};

const categories = [
  { label: "General", value: "general" },
  { label: "Academic life", value: "academic_life" },
  { label: "Ads And Marketing", value: "ads_and_marketing" },
  { label: "Blogging", value: "blogging" },
  { label: "Business", value: "business" },
  { label: "Ecommerce", value: "ecommerce" },
  { label: "Email Writing", value: "email_writing" },
  { label: "Website Copy", value: "website_copy" },
  { label: "Youtube And Social Media", value: "youtube_and_social_media" },
];

type Props = {
  state: State;
  dispatch: any;
  nextStep: CustomizeTab;
};

const Intro = ({ state, dispatch, nextStep }: Props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,

    formState: { errors },
  } = useForm<IntroInputs>({
    defaultValues: {
      category: state.formValues.category || "general",
      description: state.formValues.description,
      customization_name: state.formValues.customization_name,
    },
  });

  const handleSubmitForm = (data: any) => {
    dispatch({
      type: "SUBMIT_FORM",
      payload: {
        completedStep: "intro",
        nextTab: nextStep,
        formValues: data,
      },
    });
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit && handleSubmit(handleSubmitForm)();
  };

  return (
    <form onSubmit={onSubmit} className="">
      <div className="mb-6 w-full">
        <p className="text2">What is the name?</p>
        <Input
          error={errors.customization_name}
          {...register("customization_name", {
            required: "this is a required field",
          })}
          placeholder="Ceyhun Derinbogaz"
          style={{ height: 40 }}
          className="min-w-full max-w-full h-[40px] mb-0"
        />
        {errors.customization_name && (
          <p className="text-red-500 text3 mt-3">This is a required field</p>
        )}
      </div>
      <div className="mb-6">
        <p className="text2">Write few words about the persona</p>
        <Textarea
          error={errors["description"]}
          {...register("description", {
            required: "this is a required field ",
          })}
          placeholder="Deep tech entrepreneur and software engineer Ceyhun Derinbogaz is a tech professional, author, and Co-Founder of TextCortex AI. His latest venture, TextCortex AI, has developed an AI-based platform that can help companies and individuals to help their content creation using NLG."
          className="min-w-full max-w-full min-h-[100px]"
        />
        {errors["description"] && (
          <p className="text-red-500 mt-2 text3">This is a required field</p>
        )}
      </div>
      <div className="mb-6 h-auto relative z-100 ">
        <p className="text2 mb-2">Where do you want to use this persona for?</p>
        <SelectInput
          menuPortalTarget={document.body}
          error={errors.category}
          {...register("category", {
            required: "this is a required field ",
          })}
          onChange={(value: any) => {
            setValue("category", value.value);
          }}
          defaultValue={{
            label:
              watch().category.replace("_", " ").toUpperCase() || "General",
            value: watch().category || "general",
          }}
          style={{ height: 40, fontSize: 14, textTransform: "capitalize" }}
          className="w-full min-h-[40px]"
          name="category"
          options={categories}
        />
      </div>
      <Button
        type="submit"
        className="h-[40px] mb-4 min-w-full max-w-full flex items-center justify-center"
      >
        Continue
      </Button>
    </form>
  );
};

export default Intro;

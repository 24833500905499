import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import DocumentCard from "../../components/documentCard";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import SelectInput from "../../components/selectDropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllDocumentCategories } from "../../utils/getDocumentCategory";
import useDocuments from "../../hooks/useDocuments";
import DeleteConfirmationModal from "../../components/deleteConfirmationModal";
import SaveDocumentModal from "../../components/saveDocumentModal";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAuth } from "../../context/auth/authContext";
import NotificationModal from "../../components/notificationModal";
import { defaultEditorRoute } from "../../utils/helpers";

const Documents = () => {
  const params = useParams();
  const documentParam = params.documentType;
  const isFavorite = documentParam === "favorites";
  const categories = getAllDocumentCategories();
  const [documentModal, toggleDocumentModal] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const {
    state,
    documents,
    setDocuments,
    documentType,
    setDocumentType,
    sortByAlphabet,
    newDocument,
    setNewDocument,
    showSaveDocumentModal,
    toggleSaveDocumentModal,
    saveNewDocument,
    handleDeleteDocument,
    documentToBeDeleted,
    setDocumentToBeDeleted,
    showDeleteModal,
    toggleDeleteModal,
  } = useDocuments(isFavorite);

  useEffect(() => {
    return () => {
      setDocuments({
        filteredDocuments: [],
        allDocuments: [],
      });
    };
  }, []);

  const documentsToShow = documents.filteredDocuments
    ? documents.filteredDocuments
    : documents.allDocuments;
  // console.group("documentsToShow: ", documentsToShow);
  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Documents</title>
      </Helmet>
      <div className={`${!!auth.isSidebarOpen ? "w-100vw" : "w-full"}`}>
        <div className="flex w-full justify-between mb-4">
          <div className={`${!auth.isSidebarOpen ? "w-[20%]" : "w-0"}`}></div>
          <div className="w-full">
            <h1
              className={`h1 text-primary-02 ${
                !auth.isSidebarOpen ? "text-center" : "text-center md:text-left"
              }`}
            >
              {isFavorite ? "Favorite Texts" : "Documents"}
            </h1>
            <p
              className={`text2 text-grey-02 ${
                !auth.isSidebarOpen ? "text-center" : "text-center md:text-left"
              }`}
            >
              {isFavorite
                ? "You can save your favorite texts in the 'Favorite Texts' page"
                : "Browse existing or create new documents."}
            </p>
          </div>
          <div className="flex items-center">
            <button
              onClick={sortByAlphabet}
              className="outline-none cursor-pointer w-[180px] justify-center flex mr-4 p-[6px] px-2 rounded border text2 text-grey-02"
            >
              Sort by A-Z
            </button>
            {/* <SelectInput
              name="plan"
              placeholder={"Category"}
              style={{
                minWidth: "200px",
                height: "24px",
                cursor: "pointer",
                fontSize: 14,
                background: " rgba(4, 6, 19, 0.05)",
              }}
              onChange={(value: any) => {
                setDocumentType(value.value);
              }}
              value={documentType}
              options={[{ label: "All", value: "all" }, ...categories]}
            /> */}

            <Button
              onClick={() => toggleDocumentModal(true)}
              className="text-white w-[180px] justify-center  bg-brand-02 border-[1px] h5BoldRegular flex items-center"
              style={{ padding: 4 }}
            >
              <div className="flex justify-center items-center h-full">
                <div className="flex mr-2 p-[2px]  rounded-full border-[1px] items-center justify-center border-white">
                  <PlusIcon className="stroke-white w-3 h-3" />
                </div>
                New Doc
              </div>
            </Button>
          </div>
        </div>
        <div
          className={`flex flex-wrap ${
            auth.isSidebarOpen ? "justify-start" : "justify-center"
          }  flow-wrap`}
        >
          {state === "loading" ? (
            <div className="ml-10">
              <Skeleton
                style={{ marginBottom: 20, marginRight: 32, borderRadius: 10 }}
                containerClassName="overflow-hidden flex  w-full max-w-[100%] flex-wrap"
                width={300}
                height={300}
                count={8}
              />
            </div>
          ) : (
            documentsToShow.map((item: any) => {
              return (
                <div className="mb-6 mr-6 w-[280px]">
                  <DocumentCard
                    document={item}
                    title={item.document_name}
                    category={item.category}
                    setDocumentToBeDeleted={(id) => {
                      toggleDeleteModal(true);
                      setDocumentToBeDeleted(id);
                    }}
                    description={item.document_data}
                    cardType={documentParam ? "type-2" : "type-1"}
                  />
                </div>
              );
            })
          )}
        </div>
        <SaveDocumentModal
          onDocumentTypeSelect={(value) => {
            setNewDocument({
              ...newDocument,
              documentType: value,
            });
          }}
          documentType={newDocument.documentType}
          selectDisabled={false}
          closeModal={() => toggleSaveDocumentModal(false)}
          documentName={newDocument.documentName}
          onSave={(documentName) => {
            saveNewDocument({
              document_name: documentName,
              category: newDocument.documentType,
              status: "Draft",
            });
          }}
          showModal={showSaveDocumentModal}
        />
        <DeleteConfirmationModal
          showModal={showDeleteModal}
          closeModal={() => {
            toggleDeleteModal(false);
          }}
          onDelete={handleDeleteDocument}
        />
        <NotificationModal
          showModal={documentModal}
          buttonText={"Create Document"}
          closeModal={() => toggleDocumentModal(false)}
          title="Do you want to create a new document?"
          description={
            "Create a blank document and start writing with TextCortex"
          }
          link={defaultEditorRoute}
        />
      </div>
    </div>
  );
};

export default Documents;

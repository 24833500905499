import { browserName } from "react-device-detect";

export const extensionLink =
  "https://chrome.google.com/webstore/detail/textcortex-ai-writing-ass/hahkojdegblcccihngmgndhdfheheofe";

export const defaultEditorRoute =
  "/user/dashboard/creator/template/auto_complete";

export const planType = {
  Business: "Business",
  Pro: "Pro",
  Free: "Free",
} as const;

export const links = {
  discord: "https://discord.textcortex.com",
  landingPage: "https://textcortex.com",
};

export const sizeToCount = {
  short: 100,
  medium: 200,
  long: 300,
};

export const edgeLink =
  "https://microsoftedge.microsoft.com/addons/detail/textcortex-ai-powered-w/iaociiajffacjhhmleclkjdchjhdmjpb";

export const isUserEdgeUser = () => {
  if (browserName === "Edge") {
    return true;
  }
  return false;
};

export const storageKeys = {
  ACCESS_TOKEN: "cortexAccessToken",
  REFRESH_TOKEN: "cortexRefreshToken",
};

export const redirectBrowser = () => {
  if (browserName === "Edge") {
    return "https://microsoftedge.microsoft.com/addons/detail/textcortex-zeno-ai-writi/iaociiajffacjhhmleclkjdchjhdmjpb";
  } else {
    return extensionLink;
  }
};

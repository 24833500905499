import React, { useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as TickIcon } from "../assets/icons/tick.svg";
import { ReactComponent as CloseSVG } from "../assets/icons/close_button.svg";
import Button from "./button";
import { GET_PREMIUM, LINK_LOCATION } from "../constants";
import { Link } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  featurePlanType: "Free" | "Pro" | "Business";
};

const pricingData = {
  Business: {
    title: "BUSINESS",
    desc: "For power users who work with text all day, every day.",
    textColor: "text-primary-01",
    strokeColor: "stroke-primary-01",
    items: [
      {
        title: "All features in PRO",
      },
      {
        title: "300+ words creations",
      },

      {
        title: "Creator Suite: 60+ AI Templates",
      },
      {
        title: "Email Copy Templates",
      },
      {
        title: "Website Copy Templates",
      },
      {
        title: "Social Meda Templates",
      },
      {
        title: "Modify Creativity",
      },
      {
        title: "Zeno mode",
      },
    ],
  },
  Pro: {
    title: "PRO",
    desc: "For individuals and businesses who create regularly.",
    textColor: "text-green-500",
    strokeColor: "stroke-green-500",
    items: [
      {
        title: "Unlimited creations",
      },
      {
        title: "200+ words creations",
      },
      {
        title: "Creation and translation in 10+ Languages",
      },
      {
        title: "Tone changer",
      },
      {
        title: "Summarizer",
      },
    ],
  },
};

const modalStyles = { overlay: { zIndex: 1000000 } };

const PricingModal = (props: Props) => {
  //   useEffect(() => {
  //     if (props.isOpen)
  //     //   analytics(constants.mixpanelEventNames.OPEN_PRICING_MODAL_OPEN);
  //   }, [props.isOpen]);

  return (
    <Modal
      style={modalStyles}
      className="relative z-[1000] flex justify-center items-center w-full h-full m-auto bg-black-rgba"
      isOpen={props.isOpen}
      //   onRequestClose={() => {
      //     analytics(constants.mixpanelEventNames.CLOSE_PRICING_MODAL_CLOSE);
      //     props.onClose();
      //   }}
    >
      <div className="bg-brand  from-brand-01 via-brand-02 to-brand-03 rounded-[10px] p-4 w-[674px]">
        <div className="bg-white relative rounded-[10px] py-[48px] px-[60px]">
          <div
            className="absolute z-[1000] top-0 right-0 px-4 py-4 cursor-pointer"
            onClick={() => {
              //   analytics(constants.mixpanelEventNames.CLOSE_PRICING_MODAL_CLOSE);
              props.onClose();
            }}
          >
            <CloseSVG />
          </div>
          <h1 className="mb-10 text-center h1 text-neutral-01 ">
            You have discovered a
            <span
              className={`mx-1 ${
                (pricingData as any)[props.featurePlanType]?.textColor
              }
                `}
            >
              {props.featurePlanType ? props.featurePlanType : "Paid"}
            </span>
            feature
          </h1>

          <div className="grid grid-cols-2 mb-12 gap-x-12">
            {Object.entries(pricingData).map((plan) => {
              const name = plan[1].title;
              const desc = plan[1].desc;
              const items = plan[1].items;
              const textColor = plan[1].textColor;
              const strokeColor = plan[1].strokeColor;

              return (
                <div>
                  <h2 className={`${textColor} mb-2 h7`}>{name}</h2>
                  <p className="mb-6 body3 text-neutral-04">{desc}</p>
                  <div>
                    {items.map((item) => (
                      <div className="flex mb-2 ">
                        <TickIcon className={`${strokeColor}`} />
                        <p className="ml-2 text-neutral-03 sub4 w-[184px]">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <Link to={"/pricing"}>
            <Button className="w-full ml-8 mt-12 justify-center mx-auto">
              Upgrade Now
            </Button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default PricingModal;

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/authContext";
import Sidebar from "./sidebar";

type Props = {
  children: React.ReactNode;
};

const HAS_USER_WATCHED_ONBOARDING = "hasUserWatchedOnboarding";

const hasUserWatchedOnboardingModal = () => {
  const res = localStorage.getItem(HAS_USER_WATCHED_ONBOARDING);
  return res;
};

const Layout = ({ children }: Props) => {
  const auth = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1180px)" });
  const { toggleSidebar, isSidebarOpen } = auth;

  const markUserAsOnboarded = () => {
    localStorage.setItem(HAS_USER_WATCHED_ONBOARDING, "true");
  };

  useEffect(() => {
    isTabletOrMobile ? auth.toggleSidebar(false) : auth.toggleSidebar(true);
  }, [isTabletOrMobile]);
  // const specialOfferButton = {
  //   buttonTitle: "Get Valentine's Deal ❤️",
  //   buttonColor: "bg-brand",
  //   link: "https://textcortex.com/valentines-day-deal-by-textcortex",
  // };

  return (
    <div className="h-full max-h-[100dvh] w-full flex max-w-screen relative bg-white z-[1000]">
      <div
        className={`grid grid-cols-layout h-fit max-h-screen ${
          isSidebarOpen ? "z-[1000000]" : "z-[100]"
        }  fixed left-0 top-0`}
      >
        <Sidebar
          toggleSidebar={() => toggleSidebar(!isSidebarOpen)}
          isOpened={isSidebarOpen}
        />
      </div>
      <div
        className={`md:z-10 relative overflow-y-auto ${
          !isSidebarOpen || isTabletOrMobile
            ? "layout-content-width-full"
            : "layout-content-width-desktop"
        }`}
        // onClick={() => toggleSidebar(false)}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;

import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  LexicalEditor,
} from "lexical";
import { useEffect, useReducer, useRef } from "react";
import _ from "lodash";
import { useLexicalIsTextContentEmpty } from "@lexical/react/useLexicalIsTextContentEmpty";
import { markdownToString } from "./utils/helpers";
import axiosClient from "../../axios";
import { getDocument } from "../../screens/dashboard/creator/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { $convertFromMarkdownString } from "@lexical/markdown";
import { defaultEditorRoute } from "../../utils/helpers";

type FOCUS_INPUT = "TITLE_EDITOR" | "MAIN_EDITOR";

export type State = {
  currentEditorFocus: FOCUS_INPUT;
  document: {
    name: string;
    id: string;
  };
  editorValue: string;
  saveStatus: {
    documentStatus: "new" | "saved";
    editorStatus: "idle" | "saving" | "saved";
  };
};

type Action =
  | {
      type: "CHANGE_EDITOR_FOCUS";
      payload: FOCUS_INPUT;
    }
  | {
      type: "UPDATE_DOCUMENT";
      payload: {
        name: string;
        id: string;
      };
    }
  | {
      type: "SET_EDITOR_VALUE";
      payload: string;
    }
  | {
      type: "SET_SAVE_STATUS";
      payload: {
        documentStatus: "new" | "saved";
        editorStatus: "idle" | "saving" | "saved";
      };
    }
  | {
      type: "SET_STATE";
      payload: State;
    };

const initialState: State = {
  currentEditorFocus: "TITLE_EDITOR",
  document: {
    name: "",
    id: "",
  },
  editorValue: "",
  saveStatus: {
    documentStatus: "new",
    editorStatus: "idle",
  },
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CHANGE_EDITOR_FOCUS":
      return {
        ...state,
        currentEditorFocus: action.payload,
      };
    case "UPDATE_DOCUMENT":
      return {
        ...state,
        document: action.payload,
      };
    case "SET_EDITOR_VALUE":
      return {
        ...state,
        editorValue: action.payload,
        saveStatus: {
          ...state.saveStatus,
          editorStatus: "idle",
        },
      };
    case "SET_SAVE_STATUS":
      return {
        ...state,
        saveStatus: action.payload,
      };
    case "SET_STATE":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export const saveDocument = async ({
  data,
  isUpdate,
  signal,
}: {
  data: any;
  isUpdate: boolean;
  signal?: AbortSignal;
}) => {
  const res = await axiosClient({
    method: isUpdate ? "put" : "post",
    url: isUpdate
      ? "/documents/update_document"
      : "/documents/save_new_document",
    data: {
      ...data,
      status: "Published",
    },
    signal,
  });
  return res;
};

let abortController = new AbortController();

const useEditor = (editor: LexicalEditor) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isEmpty = useLexicalIsTextContentEmpty(editor);
  const params = useParams();
  const titleRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchDocument = async (documentId: string) => {
    const res = await getDocument(documentId || "");
    if (res?.data) {
      const editorValue = res.data.data.document_data;
      dispatch({
        type: "SET_STATE",
        payload: {
          ...state,
          editorValue,
          saveStatus: {
            ...state.saveStatus,
            documentStatus: "saved",
          },
          document: {
            name: res.data.data.document_name,
            id: res.data.data.document_id,
          },
        },
      });
      (titleRef.current as any).innerText = res.data.data.document_name;
      editor.update(() => {
        $convertFromMarkdownString(editorValue);
      });
    }
  };

  useEffect(() => {
    const documentId = params.documentId
      ? params.documentId
      : searchParams.get("documentId");
    if (documentId) {
      fetchDocument(documentId);
    }
  }, []);

  const checkTimeForAutoSave = (currentText: string) => {
    const currentPlainText = markdownToString(currentText);
    const wordCountCurrent = _.words(currentPlainText).length;
    if (
      wordCountCurrent % 5 === 0 &&
      currentPlainText[currentPlainText.length - 1] === " "
    ) {
      return true;
    } else return false;
  };

  const resetDocument = async () => {
    await saveEditorState(state.editorValue, true);
    editor.update(() => {
      $getRoot().clear();
    });
    if (titleRef.current) (titleRef.current as any).innerText = null;
    navigate(defaultEditorRoute);
  };

  const handleDocumentSaving = async (editorValue: string) => {
    try {
      dispatch({
        type: "SET_SAVE_STATUS",
        payload: {
          ...state.saveStatus,
          editorStatus: "saving",
        },
      });
      if (state.saveStatus.documentStatus === "new") {
        const res = await saveDocument({
          data: {
            status: "draft",
            document_name: state.document.name || "Untitled",
            document_data: editorValue,
            generation_ids: [],
          },
          signal: abortController.signal,
          isUpdate: false,
        });
        setSearchParams({ documentId: res.data.document_id });
        dispatch({
          type: "SET_SAVE_STATUS",
          payload: {
            ...state.saveStatus,
            documentStatus: "saved",
          },
        });
        dispatch({
          type: "UPDATE_DOCUMENT",
          payload: {
            ...state.document,
            id: res.data.document_id,
          },
        });
      }
      if (state.saveStatus.documentStatus === "saved") {
        await saveDocument({
          data: {
            status: "draft",
            document_name: state.document.name || "Untitled",
            document_data: editorValue,
            document_id: state.document.id,
          },
          signal: abortController.signal,
          isUpdate: true,
        });
        dispatch({
          type: "SET_SAVE_STATUS",
          payload: {
            ...state.saveStatus,
            documentStatus: "saved",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "SET_SAVE_STATUS",
        payload: {
          documentStatus: "saved",
          editorStatus: "idle",
        },
      });
      console.log(err);
    }
  };

  const saveEditorState = async (
    editorValue: string,
    shouldTriggerSave?: boolean
  ) => {
    const canAutoSave = isEmpty === false && checkTimeForAutoSave(editorValue);
    dispatch({
      type: "SET_EDITOR_VALUE",
      payload: editorValue,
    });
    if (
      canAutoSave ||
      (shouldTriggerSave && (!isEmpty || state.document.name.length > 3))
    ) {
      await handleDocumentSaving(editorValue);
      dispatch({
        type: "SET_SAVE_STATUS",
        payload: {
          documentStatus: "saved",
          editorStatus: "saved",
        },
      });
    }
  };

  const dumpTextToEditor = (text: string) => {
    editor.update(() => {
      const root = $getRoot();
      const paragraphNode = $createParagraphNode();
      const textNode = $createTextNode(text);
      if (isEmpty) {
        textNode.__text = textNode.__text.replace("\n\n", "");
        paragraphNode.append(textNode);
      } else paragraphNode.append(textNode);
      root.append(paragraphNode);
    });
  };

  const saveDocumentTitle = (title: string) => {
    const titleLength = title.length;
    dispatch({
      type: "UPDATE_DOCUMENT",
      payload: {
        ...state.document,
        name: title,
      },
    });
    if (titleLength && titleLength % 3 === 0) {
      saveEditorState(state.editorValue, true);
    }
  };

  return {
    state,
    dispatch,
    saveEditorState,
    dumpTextToEditor,
    saveDocumentTitle,
    titleRef,
    resetDocument,
  };
};

export default useEditor;

import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import Button, {
  ButtonSecondary,
  UploadButton,
} from "../../../components/button";
import Input from "../../../components/input";
import { useAuth } from "../../../context/auth/authContext";
import { uploadToServer } from "../../../utils/uploadImage";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axiosClient, { postRequest } from "../../../axios";
import {
  getShopifyCharge,
  logout,
  retrieveAccount,
  SET_USER,
} from "../../../context/auth/actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import useRecaptcha from "../../../hooks/useRecaptcha";
import DeleteConfirmationModal from "../../../components/deleteConfirmationModal";

const defaultProfileImage =
  "https://media.istockphoto.com/vectors/avatar-5-vector-id1131164548?k=20&m=1131164548&s=612x612&w=0&h=ODVFrdVqpWMNA1_uAHX_WJu2Xj3HLikEnbof6M_lccA=";

type ImageState = {
  state: "uploading" | "idle" | "error" | "success";
  url: string | undefined;
};

type FromI = {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  website: string;
  company_description: string;
};

const Account = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [imageState, setImageState] = useState<ImageState>({
    state: "idle",
    url: auth.state.user?.data?.profile_image,
  });
  const [showDeleteConfirmationModal, toggleDeleteConfirmationModal] =
    useState(false);
  const { token: captchaToken, handleReCaptchaVerify } = useRecaptcha();
  const [confirmationEmail, setConfirmationEmail] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FromI>();

  useEffect(() => {
    setValue("email", auth.state.user?.email || "");
  }, []);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        setImageState((prev) => ({ ...prev, state: "uploading" }));
        toast.loading("Uploading image...", {
          closeOnClick: true,
          // close
        });
        const res = await uploadToServer(e.target.files[0]);
        if (res) {
          toast.dismiss();
          toast.success("Image uploaded successfully!");
          setImageState({ state: "success", url: res });
        }
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong uploading your image!");
      setImageState((prev) => ({ ...prev, state: "error" }));
    }
  };

  const onSubmit = async (data: FromI) => {
    try {
      toast.loading("Updating profile...", {
        closeOnClick: true,
      });
      const { website, ...formattedData } = {
        ..._.omit(data, "email", "first_name", "last_name"),
        profile_image: imageState.url,
      };
      await postRequest("accounts/user_details", formattedData);
      if (imageState.url) {
        auth.dispatch({
          type: SET_USER,
          payload: {
            user: {
              ...auth.state.user,
              data: { ...auth.state.user?.data, profile_image: imageState.url },
            },
          },
        });
      }
      toast.dismiss();
      toast.success("Profile updated!", {
        closeOnClick: true,
      });
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to update profile. Please try again.", {
        closeOnClick: true,
      });
      console.log("error:", error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      toast.loading("Sending reset link to your email!");
      const token = await handleReCaptchaVerify();
      const res = await retrieveAccount(
        { email: auth.state.user?.email as string, token: token || "" },
        auth.dispatch
      );
      if (res) {
        toast.dismiss();
        toast.success("Password reset link sent to your email");
      }
      toast.dismiss();
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
    }
  };

  const deleteAccount = async () => {
    try {
      toast.loading("Deleting your account. Please wait...", {
        bodyClassName: "z-[100000]",
      });
      const res = await axiosClient({
        method: "post",
        url: "/accounts/delete",
        data: {
          email: auth.state.user?.email,
        },
      });
      toast.success("Your account has been deleted", {
        bodyClassName: "z-[100000]",
      });
      if (res) {
        logout(auth.dispatch);
      }
      toast.dismiss();
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Account Settings</title>
      </Helmet>
      <h1 className="h3 text-primary-02">Account Settings</h1>
      <div className="pl-4 py-4 max-w-[687px]">
        {/*** Personal Information ***/}
        <p className="mb-8 h2 text-primary-02">Personal Information</p>
        <div className="flex items-start justify-between">
          <div className="flex">
            <img
              alt="avatar"
              className="object-center object-cover rounded border contain w-[75px] h-[75px]"
              src={imageState.url ? imageState.url : defaultProfileImage}
            />

            <div className="flex flex-col ml-5">
              <UploadButton
                containerProps={{ className: "mb-4" }}
                accept="image/png, image/gif, image/jpeg, image/svg, image/jpg"
                onChange={(e) => {
                  handleImageUpload(e);
                }}
              >
                Upload photo
              </UploadButton>
              {imageState.url && (
                <button
                  onClick={() => {
                    setImageState((prev) => ({ ...prev, url: "" }));
                  }}
                  className="flex justify-center py-1 rounded item-center bg-red-02 text2 text-red-03 border-red-03 text-grey-02"
                >
                  <DeleteIcon className="mr-2 mt-[2px]" /> Delete
                </button>
              )}
            </div>
          </div>
          <div className="rounded-[32px] cursor-pointer h-10 flex items-start border px-4 py-2">
            <p className="text2 text-grey-02">Pricing plan:</p>
            <p className="mx-2 h2 text-primary-02">
              {auth?.state?.user?.plan_type}
            </p>
            <button
              onClick={() =>
                auth?.state?.user?.shopify
                  ? getShopifyCharge()
                  : auth.state.user?.plan_type === "Free"
                  ? navigate("/pricing")
                  : navigate("/user/dashboard/settings/billing")
              }
              className="px-2 rounded h2 text-green-01 bg-green-02"
            >
              Upgrade
            </button>
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="grid grid-cols-2 gap-4">
            <Input
              disabled
              type="text"
              placeholder="Dom"
              label="First Name"
              {...register("first_name", {
                required: "This is a required field",
              })}
              defaultValue={auth.state.user?.data?.first_name}
              error={errors?.first_name}
            />
            <Input
              disabled
              type="text"
              defaultValue={auth.state.user?.data.last_name}
              placeholder="Last name"
              label="Last Name"
              {...register("last_name", {
                required: "This is a required field",
              })}
              error={errors.last_name}
            />
          </div>
          <Input
            type="email"
            defaultValue={auth.state.user?.email}
            placeholder="user@textcortex.com"
            label="Email"
            className="max-w-[100%]"
            {...register("email", { required: "This is a required field" })}
            error={errors.email}
            disabled
          />
        </div>

        {/*** Company Information ****/}
        <div className="pt-6">
          <p className="mb-8 h2 text-primary-02">Company Information</p>

          <div className="w-full mt-4">
            <div className="grid grid-cols-2 gap-4">
              <Input
                type="text"
                defaultValue={auth.state.user?.data.company_name}
                placeholder="TextCortex"
                label="Company Name"
                {...register("company_name")}
              />
              <Input
                type="url"
                placeholder="textcortex.com"
                label="Website"
                {...register("website")}
              />
            </div>
            <Input
              type="text"
              defaultValue={auth.state.user?.data.company_description}
              placeholder="One AI Tool To Write All Your Content."
              label="Company Description"
              className="max-w-[100%]"
              {...register("company_description")}
            />
          </div>
        </div>

        {/*********Password**********/}
        <div className="pt-6">
          <ButtonSecondary
            size="large"
            onClick={handlePasswordReset}
            className="w-[150px]"
          >
            Change password
          </ButtonSecondary>
          {/* <div className="grid grid-cols-2 gap-4">
            <Input
              type="password"
              value=""
              placeholder="Enter 8 characters or more"
              label="Password"
              name="password"
              onChange={() => {}}
            />
            <Input
              type="url"
              value=""
              placeholder="Confirm password"
              label="Confirm Password"
              name="confirm_password"
              onChange={() => {}}
            />
          </div> */}
        </div>
        <p
          onClick={() => toggleDeleteConfirmationModal(true)}
          className="text-red-01 mt-5 ml-1 underline cursor-pointer"
        >
          Delete Account
        </p>
        <Button
          onClick={handleSubmit(onSubmit)}
          className="h2 mt-8 h-12 max-w-[150px]"
        >
          Confirm changes
        </Button>
      </div>
      <DeleteConfirmationModal
        title="Delete Account?"
        description={
          <div>
            <p>
              This action cannot be undone. This will permanently delete your
              account.
              <b> Please enter your email address to confirm.</b>
            </p>
            <Input
              value={confirmationEmail}
              onChange={(e) => {
                setConfirmationEmail(e.target.value);
              }}
              className="h-10"
            />
          </div>
        }
        disableDeleteButton={
          auth.state.user?.email.toLowerCase() !==
          confirmationEmail.toLowerCase()
        }
        onDelete={deleteAccount}
        showModal={showDeleteConfirmationModal}
        closeModal={() => toggleDeleteConfirmationModal(false)}
      />
    </div>
  );
};

export default Account;

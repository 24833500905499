import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/authContext";
import { isUserEligibleForFeature } from "../utils/isUserEligableForFeature";
import PricingModal from "./pricingModal";
import { ReactComponent as LockIcon } from "../assets/icons/Locker.svg";

type Props = {
  icon: any;
  title: string;
  description: string;
  link?: string;
  value: string;
  plan: "Free" | "Business" | "Pro";
};

const TemplateCard = ({
  icon,
  title,
  description,
  link,
  value,
  plan,
}: Props) => {
  const Icon = icon;
  const navigate = useNavigate();
  const auth = useAuth();
  return (
    <div
      onClick={() => {
        if (
          isUserEligibleForFeature({
            feature: plan,
            user: auth.state.user?.plan_type || "Free",
          })
        ) {
          navigate(`/user/dashboard/creator/template/${value}`);
        } else {
          auth.setPricingModalData({
            featureUserAttempted: plan,
            isOpen: true,
          });
        }
      }}
    >
      <div className="cursor-pointer  w-[280px] md:w-[256px] shadow-2xl h-[220px] relative flex flex-col rounded border p-4">
        <div className="flex justify-between w-full">
          {icon ? <Icon className="w-[100] mb-4" /> : <span />}
          {isUserEligibleForFeature({
            feature: plan,
            user: auth.state.user?.plan_type || "Free",
          }) ? (
            <span />
          ) : (
            <LockIcon />
          )}
        </div>
        <h2 className="h2 text-4 text-primary-02 mb-2">{title}</h2>
        <p className="mb-5 text3 text-grey-02">{description}</p>
        <button className="cursor-pointer w-full py-1 mt-auto border rounded shadow h2 text-grey-02">
          Open
        </button>
      </div>
    </div>
  );
};

export default TemplateCard;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ExtensionOnboarding from "../screens/onboarding/extension";
import ExtensionSignup from "../screens/onboarding/extension/signup";
import ResetPassword from "../screens/onboarding/webapp/resetPassword";
import RetrieveAccount from "../screens/onboarding/webapp/retrieveAccount";
import SignIn from "../screens/onboarding/webapp/signin";
import Signup from "../screens/onboarding/webapp/signup";
import OnboardingQuestions from "../screens/onboarding/onboardingQuestions";
import VerifyEmail from "../screens/onboarding/webapp/verifyEmail";
import AuthVerify from "../screens/onboarding/authVerify";
import DiscordAuth from "../screens/onboarding/discordAuth";
import FastCheckout from "../screens/onboarding/fastCheckout";
import OffBoarding from "../screens/onboarding/offboarding";

const OnboardingRouter = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/user/login" replace />} />
      <Route path="/fast-checkout" element={<FastCheckout />} />
      <Route path="/offboarding" element={<OffBoarding />} />

      <Route path="/auth/redirect/:accessToken" element={<AuthVerify />} />
      <Route path="user">
        <Route path="login" element={<SignIn />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signup/:affiliateCode" element={<Signup />} />
        <Route path="signup/discord" element={<DiscordAuth />} />

        <Route path="onboarding" element={<OnboardingQuestions />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="retrieve-account" element={<RetrieveAccount />} />
        <Route path="forgot-password" element={<ResetPassword />} />
      </Route>
      <Route path="extension">
        <Route path="signup" element={<ExtensionSignup />} />
        <Route path="onboarding" element={<ExtensionOnboarding />} />
      </Route>
      <Route path="*" element={<Navigate to="/user/login" />} />

      {/* <Route path="*"  /> */}
    </Routes>
  );
};

export default OnboardingRouter;

import React, { useEffect } from "react";
import TagsInput, { ReactTagsInputProps } from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { FieldError } from "react-hook-form";
// If using WebPack and style-loader.

type Props = ReactTagsInputProps & {
  onChange?: (tags: string[]) => void;
  label?: string;
  value?: string[];
  error?: FieldError;
};

const TagInput = ({ onChange, label, error, ...props }: Props) => {
  const [tags, setTags] = React.useState(props.value || []);

  useEffect(() => {
    setTags(props.value || []);
  }, [props.value]);

  return (
    <div className={props.className}>
      <div className="mb-1">
        <label className="text5">{label}</label>
      </div>
      <TagsInput
        addOnBlur={true}
        value={tags}
        onChange={(tags) => {
          setTags(tags);
          onChange(tags);
        }}
        tagProps={{
          className:
            "react-tagsinput-tag !bg-grey-08 !border-none !text-grey-02 !h-6 h5 !rounded-[6px]",
        }}
        inputProps={{
          placeholder: "Write a keyword and press enter",
          className: "react-tagsinput-input",
        }}
      />
      <p className="mt-1 text3 text-grey-01">
        Hint: Type and <b>press enter</b>
      </p>
      {error && <p className="mt-0 text2 text-red-01 text5">{error.message}</p>}
    </div>
  );
};

export default TagInput;

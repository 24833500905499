import React from "react";
import classNames from "classnames";

type Props = {
  characters: number;
  maxChars: number;
  hideDetails?: boolean;
};

const TextLengthProgress = ({
  hideDetails = false,
  characters,
  maxChars,
  ...props
}: Props) => {
  const barLength = Math.round(maxChars / 3);
  const isShort = () => characters > 0 && characters < barLength + 1;
  const isMedium = () =>
    characters > barLength && characters < barLength * 2 + 1;
  const isLong = () => characters > barLength * 2;

  const getWidth = () => {
    let width = 0;
    const bl = barLength;

    if (isShort()) {
      width = (characters / bl) * 100;
    } else if (isMedium()) {
      width = ((characters - bl) / bl) * 100;
    } else if (isLong()) {
      width = ((characters - bl * 2) / bl) * 100;
    }

    if (width > 100) {
      return 100;
    }
    return width;
  };

  return (
    <div>
      <div className="flex items-center w-full">
        <div className="w-full bg-grey-03 rounded-[18px]">
          <div
            className={classNames({
              "rounded-[18px] h-1 ": true,
              "bg-red-01": isShort(),
              "bg-yellow-01": isMedium(),
              "bg-green-01": isLong(),
            })}
            style={{
              width: isMedium() || isLong() ? "100%" : `${getWidth()}%`,
            }}
          />
        </div>
        <div className="w-full bg-grey-03 rounded-[18px]">
          <div
            className={classNames({
              "rounded-[18px] h-1 ": true,
              "bg-yellow-01": isMedium(),
              "bg-green-01": isLong(),
            })}
            style={{
              width: isLong() ? "100%" : `${getWidth()}%`,
            }}
          />
        </div>
        <div className="w-full bg-grey-03 rounded-[18px]">
          <div
            className={classNames({
              "rounded-[18px] h-1 ": true,
              "bg-green-01": isLong(),
            })}
            style={{
              width: `${getWidth()}%`,
            }}
          />
        </div>
      </div>
      {!hideDetails && (
        <div className="flex items-center justify-between">
          {isShort() && (
            <p className="caption1 text-grey-02">
              Enter at least <b> {barLength + 1 - characters} characters </b>
            </p>
          )}
          {isMedium() && (
            <p className="caption1 text-grey-02">
              Extend your text for better results
            </p>
          )}
          {isLong() && <p className="caption1 text-grey-02">Awesome!</p>}
          <p className="caption1 text-grey-02">
            {characters}/ {maxChars}
          </p>
        </div>
      )}
    </div>
  );
};

export default TextLengthProgress;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Input from "./input";
import { ReactComponent as CloseIcon } from "../assets/icons/close_button.svg";
import Button from "./button";
import SelectInput from "./selectDropdown";
import { getAllDocumentCategories } from "../utils/getDocumentCategory";

type Props = {
  showModal: boolean;
  onSave: (document: string) => void;
  closeModal: () => void;
  documentName?: string;
  onDocumentTypeSelect?: (value: string) => void;
  documentType?: string;
  selectDisabled?: boolean;
  isSaved?: boolean;
};

const SaveDocumentModal = ({
  documentName,
  closeModal,
  showModal,
  onSave,
  onDocumentTypeSelect,
  documentType,
  selectDisabled = true,
  isSaved,
}: Props) => {
  const categories = getAllDocumentCategories();
  const [document, setDocumentName] = useState("");

  useEffect(() => {
    setDocumentName(documentName || "");
  }, [documentName]);

  return (
    <Modal
      style={{ overlay: { zIndex: 100 } }}
      isOpen={showModal}
      className="w-full relative flex items-center h-full m-auto bg-black-rgba"
    >
      <div className="w-[490px] p-6  rounded shadow flex flex-col m-auto bg-white ">
        <div className="mb-2 flex justify-between items-center">
          <h1 className="h3 text-primary-02">
            {isSaved ? "Update Document" : "Save document"}
          </h1>
          <CloseIcon onClick={closeModal} className="cursor-pointer" />
        </div>
        <SelectInput
          name="plan"
          isDisabled={selectDisabled}
          defaultValue={categories[0]}
          placeholder={"Category"}
          style={{
            cursor: "pointer",
            fontSize: 14,
            background: " rgba(4, 6, 19, 0.05)",
          }}
          onChange={(value: any) => {
            onDocumentTypeSelect?.(value.value);
          }}
          value={documentType}
          options={[{ label: "All", value: "all" }, ...categories]}
        />
        <Input
          className="mt-4 h-9"
          onChange={(e) => setDocumentName(e.target.value)}
          placeholder={"Document name"}
          value={document}
          name="documentName"
        />

        <Button
          onClick={
            documentName || (document && document.length > 0)
              ? () => onSave(document || "")
              : () => {}
          }
          className="mt-4 max-w-[150px] h-11 flex items-center justify-center self-end"
        >
          {isSaved ? "Update" : "Save"}
        </Button>
      </div>
    </Modal>
  );
};

export default SaveDocumentModal;

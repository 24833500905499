import React, { useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "../../../components/button";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import Card from "../../../components/customizeAICard";
import { personaData } from "./result";
import useCustomizeAI from "./useCustomizeAI";
import axiosClient from "../../../axios";
import Spinner from "../../../components/spinner";
import { useAuth } from "../../../context/auth/authContext";
import Tippy from "@tippyjs/react";
import { ReactComponent as MenuIcon } from "../../../assets/icons/kebab_menu.svg";

import DeleteConfirmationModal from "../../../components/deleteConfirmationModal";
import { defaultEditorRoute } from "../../../utils/helpers";

const AiPersonas = () => {
  const {
    state,
    fetchPersonas,
    dispatch,
    deleteModal,
    toggleModal,
    deletePersona,
    setAsDefault,
  } = useCustomizeAI();
  const auth = useAuth();

  if (state.status === "loading")
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Spinner />
      </div>
    );

  return (
    <div className="mt-4 m-6">
      <div className="flex justify-between">
        <div
          className={`flex flex-col ${
            !auth.isSidebarOpen && "items-center"
          } w-full`}
        >
          <h1 className={`h1 text-primary-02`}>Customize your AI</h1>
          <p className="text2 text-grey-02">
            Create your digital twin and your apprentice
          </p>
        </div>
        <Link to={defaultEditorRoute}>
          <Button
            onClick={() => {}}
            className="text-white h-[40px] w-fit justify-center mt-4 bg-brand-02 border-[1px] h5BoldRegular flex items-center"
            style={{ padding: "6px 16px", borderRadius: 8 }}
          >
            <div className="flex justify-center items-center h-full">
              <div className="flex mr-2 p-[2px]  rounded-full border-[1px] items-center justify-center border-white">
                <PlusIcon className="stroke-white w-3 h-3" />
              </div>
              New Doc
            </div>
          </Button>
        </Link>
      </div>
      <div className=" my-8 p-8 max-w-[660px] rounded-lg shadow-3xl bg-white">
        <h2>Your Customized AI Persona Library</h2>
        <p className="mt-2 text2 text-grey-02">
          Create your digital twin and your apprentice
        </p>

        {state.customizations.map((customization: any) => {
          return (
            <div className="flex w-full justify-between bg-[#F9FAFB] px-6 pb-5 pt-4 mt-6">
              <Link
                className="w-full"
                to={`/user/dashboard/customize-your-ai/persona/${customization.customization_id}`}
              >
                <div className="relative  cursor-pointer shadow-lg items-center shadow-lg  rounded">
                  <div className="flex w-full justify-between">
                    <p className="h3 flex items-center">
                      {customization.persona_details.name}
                      {/* <FaChevronRight className="ml-2" color={"#98A2B3"} /> */}
                    </p>
                  </div>
                  <div className="flex">
                    {customization.default && (
                      <span className="mr-4 shadow-md bg-light-pink-gradient rounded-[12px] px-2 py-[3px] flex items-center">
                        <span className="text3 brand-gradient-text-glow ">
                          Default
                        </span>
                      </span>
                    )}
                    {Object.keys(customization.style.tonality).map(
                      (item: any) => (
                        <span className="bg-[#F2F4F7] shadow-md rounded-[12px] mr-4 px-2 py-[3px] flex items-center">
                          <span className="text3Bold text-primary-02 capitalize">
                            {item.replace("_", " ")}
                          </span>
                        </span>
                      )
                    )}
                  </div>
                </div>
              </Link>
              <div className="flex items-center">
                <Tippy
                  offset={[0, 8]}
                  interactive
                  popperOptions={{
                    strategy: "fixed",
                  }}
                  zIndex={99}
                  content={
                    <div className="shadow-md w-fit bg-white  py-1 border rounded shadow">
                      <p
                        onClick={() =>
                          setAsDefault(
                            customization.customization_id,
                            customization.default
                          )
                        }
                        className="p-2 cursor-pointer px-3  text3 border-bottom w-full"
                      >
                        {customization.default
                          ? "Remove default Persona"
                          : "Set as default"}
                      </p>
                      <p
                        onClick={() => {
                          toggleModal({
                            show: true,
                            id: customization.customization_id,
                          });
                        }}
                        className="cursor-pointer p-2 px-3 text-red-01 text3 w-full"
                      >
                        Delete
                      </p>
                    </div>
                  }
                  delay={[100, 0]}
                  moveTransition={"none"}
                  trigger={"click"}
                  placement={"left-start"}
                >
                  <MenuIcon className="cursor-pointer" />
                </Tippy>
              </div>
            </div>
          );
        })}
        {(auth.state.user?.plan_type === "Business" ||
          state.customizations.length < 1) && (
          <Link to="/user/dashboard/customize-your-ai/create">
            <div className="flex cursor-pointer shadow-lg justify-between items-center bg-[#F9FAFB] shadow-lg p-6 mt-6 rounded w-full">
              <p className="h3">
                Unused AI Persona Slot - Click to start customization
              </p>
              <FaChevronRight color={"#98A2B3"} />
            </div>
          </Link>
        )}
        {auth.state.user?.plan_type !== "Business" && (
          <Link to="/pricing">
            <Button
              className={`min-w-full h-[44px] flex justify-center items-center mt-6 h5BoldRegular`}
            >
              <span className="h4">
                Upgrade to customize additional AI persona
              </span>
            </Button>
          </Link>
        )}
      </div>
      <DeleteConfirmationModal
        showModal={deleteModal.show}
        description={"Are you sure you want to delete this AI persona?"}
        closeModal={() => {
          toggleModal({ id: null, show: false });
        }}
        onDelete={deletePersona}
      />
    </div>
  );
};

export default AiPersonas;

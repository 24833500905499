import React, { useState } from "react";
import { ReactComponent as CheckIcon } from "../assets/icons/check-black.svg";
import { ReactComponent as Money } from "../assets/icons/money.svg";
import { ReactComponent as Bussiness } from "../assets/icons/business.svg";
import { useNavigate } from "react-router-dom";
import Spinner from "./spinner";
type Props = {
  title: string;
  price?: number;
  description: string;
  perkTitle: string;
  perks: string[];
  buttons: string[];
  tag?: string;
  isSelected?: boolean;
  value: string;
  onSelect: () => Promise<void>;
  currency: string;
  extraContentType?: string;
  creations: string;
  yearlySavePercentage?: string;
  planDuration: "yearly" | "monthly";
};

const PricingCard = ({
  title,
  price,
  description,
  perks,
  perkTitle,
  buttons,
  tag,
  value,
  onSelect,
  currency,
  extraContentType,
  creations,
  planDuration,
  yearlySavePercentage,
  isSelected = false,
}: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <div
      className={`flex flex-col flex-grow h-full w-full  pt-8 text-left  md:min-w-285px rounded-xl bg-white hover:drop-shadow-xl transition-shadow duration-500 ease-in-out`}
    >
      <div className="flex flex-col gap-y-4 px-6">
        <div className="mb-4 flex justify-between items-center">
          {title === "BUSINESS" ? (
            <Bussiness />
          ) : (
            <h1 className="text-lg-SemiBold">{title}</h1>
          )}

          {tag && (
            <h2 className="mt-[-8px]  mr-2 h2 bg-primary-03 text-brand-02 rounded-[32px] px-3 py-1">
              {tag}
            </h2>
          )}
        </div>
        <div className="flex md:min-h-[80px] ">
          <p className="text-sm-Regular text-grey-02 pr-4 flex-grow">
            {description}
          </p>
        </div>

        <div className="w-full min-h-[90px]">
          <p className="mt-5 md:mt-2 text-grey-02">{creations}</p>
          <h1 className="text-3xl-Bold md:mb-3">
            {currency}
            {price}/<span className="text-base-SemiBold">month</span>
          </h1>

          {planDuration === "yearly" && (
            <p className="mr-2 text2 mt-[-6px] text-orange-01 bg-orange-02 rounded px-2">
              {yearlySavePercentage && `Save ${yearlySavePercentage}`}
            </p>
          )}
        </div>
        <div className="flex w-full gap-3  justify-between">
          {buttons.map((buttonText) => (
            <button
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                await onSelect();
                setLoading(false);
              }}
              className={`flex flex-grow text-base whitespace-nowrap h2   rounded-xl justify-center px-6 py-4 items-center w-full   text-center ${
                title === "BUSINESS"
                  ? buttonText === "Start For Free"
                    ? "border-[1px] border-brand-02 text-brand-02"
                    : "text-white bg-brand-02 hover:bg-[#9562FA]"
                  : "border"
              } `}
            >
              {loading ? <Spinner small /> : buttonText}
            </button>
          ))}
        </div>
        <div className="min-h-[16px]">
          {value === "Free" && (
            <p className="text3 text-grey-02 text-center">
              No credit card required
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-col flex-grow h-auto mt-8 gap-y-4 px-6 py-6 bg-primary-05 rounded-b-lg">
        <p className="text-sm-SemiBold">{perkTitle}</p>
        <div>
          {perks.map((item) => (
            <p className="items-center text-grey-001 mb-3 flex">
              <CheckIcon /> <span className="ml-3 text-Regular ">{item}</span>
            </p>
          ))}
          {value !== "Free" && (
            <div className="flex">
              <a
                href="https://help.textcortex.com/help/refund-policy"
                className="flex items-center"
              >
                <Money />
                <p className="ml-3 text-sm-SemiBold">
                  Money back guarantee for 5 days
                </p>
              </a>
            </div>
          )}
        </div>

        {extraContentType === "earnMore" && (
          <div className=" flex flex-col gap-y-4 ">
            <p className="rounded-xl text-green-01 bg-green-02 px-[9px] mr-auto py-[3px] text2 uppercase ">
              Earn more
            </p>

            <p className="text-sm-SemiBold text-grey-001">
              Refer friends for more creations and premium feature unlocks!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;

import { sendMessageToExtension } from "./extension";
import { storageKeys } from "./helpers";

export const setAccessToken = async ({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken?: string;
}) => {
  try {
    if (!accessToken) return;
    const token = await JSON.stringify(accessToken);
    const ref_token = await JSON.stringify(refreshToken);
    await localStorage.setItem(storageKeys.ACCESS_TOKEN, token);
    if (refreshToken) {
      await localStorage.setItem(storageKeys.REFRESH_TOKEN, ref_token);
    }
    sendMessageToExtension({
      payload: { accessToken, refreshToken, token: accessToken },
      type: "SET_TOKEN",
    });
    return true;
  } catch (err) {
    console.log("Failed to set access token");
  }
};

import React, { useState } from "react";
import { useAuth } from "../../context/auth/authContext";
import UserIcon from "../userIcon";
import { ReactComponent as LogoSmall } from "../../assets/icons/logo-small.svg";
import { ReactComponent as LikeButton } from "../../assets/icons/ThumbsUp.svg";
import { ReactComponent as UnlikeButton } from "../../assets/icons/unlike.svg";
import { ReactComponent as CopyButton } from "../../assets/icons/copy_zeno.svg";

import { ReactComponent as Microphone } from "../../assets/icons/Microphone.svg";
import { ReactComponent as VocalWaveIcon } from "../../assets/icons/vocal-wave.svg";

import { LoadingStatus, Message } from "../../context/chat/reducer";
import Tippy from "@tippyjs/react";
import { ChipDark } from "../chip";
import { onCopy } from "../input";
import { sendChatFeedback } from "../../context/chat/utils";
import useAudioPlayer from "./textToSpeach";

import { marked } from "marked";
import * as DOMPurify from "dompurify";
import Typewriter from "typewriter-effect";

type Props = {
  data?: Message;
  status?: LoadingStatus;
  dispatch?: any;
  state?: any;
  onPlayIconClick?: () => void;
  isAudioActive?: boolean;
  canBePlayed?: boolean;
};

type ActionProps = {
  triggerAction: (action: "like" | "unlike" | "copy" | "voice") => void;
  toolTipData:
    | { type: "copy" | "like" | "unlike"; show: boolean; text?: string }
    | undefined;
  onPlayIconClick?: () => void;
  isAudioActive?: boolean;
  canBePlayed?: boolean;
  data?: Message;
};

type AudioPlayerProps = {
  text: string;
  onPlayClick?: () => void;
  shouldDestroyAudio?: boolean;
};

export type Feedback =
  | "bad_quality"
  | "good_quality"
  | "favorite"
  | "copied"
  | "offensive"
  | "doesnt_make_sense"
  | "thumbs_up"
  | "thumbs_down"
  | "tab";

let abortController = new AbortController();

var renderer = new marked.Renderer();
renderer.link = function (href, title, text) {
  var link = marked.Renderer.prototype.link.apply(this, [href, title, text]);
  return link.replace("<a", "<a target='_blank'");
};

DOMPurify.setConfig({ ADD_ATTR: ["target"] });

marked.setOptions({
  renderer: renderer,
});

const TextToSpeech = (props: AudioPlayerProps) => {
  const { isAudioLoadingOrPlaying, handlePause, state, handlePlay } =
    useAudioPlayer({
      text: props.text,
      onPlayClick: props.onPlayClick,
      shouldDestroyAudio: props.shouldDestroyAudio,
    });

  return (
    <div>
      <Tippy
        offset={[4, 4]}
        content={
          <ChipDark text={isAudioLoadingOrPlaying() ? "Pause" : "Play"} />
        }
        delay={[300, 0]}
        moveTransition={"none"}
        placement="left"
      >
        <div>
          {isAudioLoadingOrPlaying() ? (
            <div
              onClick={(e) => {
                handlePause();
              }}
              className="cursor-pointer"
            >
              <div className="relative">
                <VocalWaveIcon className={"stroke-primary-01"} />
                {state.audioState === "loading" && (
                  <div className="absolute top-[8.5px] left-[8.5px]">
                    <div className="spinner-circle spinner-circle_lg"></div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              onClick={(e) => {
                handlePlay();
                props.onPlayClick?.();
              }}
              className="cursor-pointer"
            >
              <VocalWaveIcon
                className={"stroke-grey-02 hover:stroke-primary-01"}
              />
            </div>
          )}
        </div>
      </Tippy>
    </div>
  );
};

const ActionButtons = ({
  triggerAction,
  toolTipData,
  onPlayIconClick,
  canBePlayed,
  isAudioActive,
  data,
}: ActionProps) => {
  return (
    <div className="relative flex items-center justify-around  bg-white  mb-[-50px] border z-[10]  shadow-sm">
      <Tippy
        visible={toolTipData?.type === "like" && toolTipData?.show}
        offset={[0, 8]}
        content={
          toolTipData?.show && <ChipDark text={toolTipData?.text || ""} />
        }
        delay={[300, 0]}
        moveTransition={"none"}
      >
        <div
          onClick={() => triggerAction("like")}
          className=" px-1 h-full py-1 mr-1 hover:bg-primary-08"
        >
          <LikeButton className="cursor-pointer" />
        </div>
      </Tippy>
      <Tippy
        visible={toolTipData?.type === "unlike" && toolTipData?.show}
        offset={[0, 8]}
        content={
          toolTipData?.show && <ChipDark text={toolTipData?.text || ""} />
        }
        delay={[300, 0]}
        moveTransition={"none"}
      >
        <div
          onClick={() => triggerAction("unlike")}
          className=" px-1 py-1 mr-1 hover:bg-primary-08"
        >
          <UnlikeButton className="cursor-pointer" />
        </div>
      </Tippy>
      {canBePlayed && (
        <div className=" px-1 py-1 mr-1 hover:bg-primary-08">
          <TextToSpeech
            text={(data?.text as string) || ""}
            onPlayClick={onPlayIconClick}
            shouldDestroyAudio={isAudioActive ? false : true}
          />
        </div>
      )}
      <Tippy
        visible={toolTipData?.type === "copy" && toolTipData?.show}
        offset={[0, 8]}
        content={
          toolTipData?.show && <ChipDark text={toolTipData?.text || ""} />
        }
        delay={[300, 0]}
        moveTransition={"none"}
      >
        <div
          onClick={() => triggerAction("copy")}
          className="px-1 py-1 mr-1 hover:bg-primary-08"
        >
          <CopyButton className="cursor-pointer" />
        </div>
      </Tippy>
    </div>
  );
};

const MessageComponent = ({
  data,
  state,
  status,
  dispatch,
  onPlayIconClick,
  isAudioActive,
  canBePlayed,
}: Props) => {
  const {
    state: { user },
  } = useAuth();
  const [toolTipData, setToolTipData] = useState<{
    type: "copy" | "like" | "unlike";
    show: boolean;
    text?: string;
  }>();

  function replaceLinks(input: any, links: any) {
    const regex = /\[\[(\d+)\]\]/g;
    return input.replace(regex, (match: any, number: any) => {
      const index = parseInt(number) - 1;
      if (index >= 0 && index < links.length) {
        return `[${number}](${links[index]})`;
      } else {
        return match;
      }
    });
  }

  const formatWithReferences = () => {
    const textWithCitation = replaceLinks(
      data?.text,
      data?.references.map((item: any) => item.link)
    );
    let references = "";
    data?.references.forEach((reference: any, index: number) => {
      references = `${references} \n <li class="mb-2 text2">
       <a class="underline text-primary-01 mt-3 " target="_blank" href="${reference.link}">${reference.title}</a>\n\n<p class="w-[80%] text4 ml-2">${reference.snippet}</p></li>`;
    });
    return `${textWithCitation} \n \n <ol class="mt-4">${references}</ol>`;
  };

  const getContent = () => {
    if (typeof data?.text === "string") {
      let messageText = data.text;
      if (data.references && data.references.length > 0) {
        messageText = formatWithReferences();
      }
      return (
        <div className="chat-markdown-container">
          <article
            className="prose text2 prose-a:text-primary-01"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(marked.parse(messageText)),
            }}
          ></article>
        </div>
      );
    } else {
      return data?.text || "";
    }
  };

  const onCopy = async () => {
    try {
      await navigator.clipboard.writeText(data?.text as string);
      setToolTipData({
        type: "copy",
        show: true,
        text: "Copied!",
      });
      sendFeedBack("copied");
      setInterval(() => setToolTipData(undefined), 3000);
    } catch (error) {
      console.log("Copy failed:", error);
    }
  };

  const sendFeedBack = async (feedback: Feedback) => {
    try {
      const res = await sendChatFeedback({
        signal: abortController.signal,
        data: {
          output_id: data?.id,
          rating: feedback,
          channel: "webapp_rewrite_editor",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const triggerAction = async (
    action: "like" | "unlike" | "copy" | "voice"
  ) => {
    switch (action) {
      case "like":
        await sendFeedBack("thumbs_up");
        setToolTipData({
          type: "like",
          show: true,
          text: "Thanks for your feedback!",
        });
        setInterval(() => setToolTipData(undefined), 3000);
        return;
      case "unlike":
        sendFeedBack("thumbs_down");
        setToolTipData({
          type: "unlike",
          show: true,
          text: "Thanks for your feedback!",
        });
        setInterval(() => setToolTipData(undefined), 3000);
        return;
      case "voice":
        return;
      case "copy":
        onCopy();
        return;
    }
  };

  if (status?.status === "typing")
    return (
      <div className="bg-white rounded border flex items-center p-4">
        <LogoSmall />
        <div className="text2 text-primary-02 ml-2">
          <Typewriter
            options={{
              delay: 30,
              autoStart: true,
              loop: true,
              skipAddStyles: true,
              deleteSpeed: state.enableWebSearch ? 4 : 2,
              strings: state.enableWebSearch
                ? [
                    "Searching the web for finding good source of knowledge...",
                    "Found some interesting pages, Analyzing...",
                    "Compiling results for you...",
                    "Compiling results for you...",
                  ]
                : [
                    "Checking my facts for you...  ",
                    "Analyzing...  ",
                    "Compiling results for you",
                  ],
            }}
          />
        </div>
      </div>
    );

  if (data?.messageType === "sent")
    return (
      <div className="bg-white rounded border flex items-center p-4">
        <div>
          <UserIcon initial={user?.data.first_name[0] || ""} />
        </div>
        <p className="ml-2">{data.text}</p>
      </div>
    );
  else {
    return (
      <Tippy
        visible={true}
        interactive={true}
        offset={[0, 8]}
        content={
          <ActionButtons
            toolTipData={toolTipData}
            data={data}
            triggerAction={triggerAction}
            onPlayIconClick={onPlayIconClick}
            isAudioActive={isAudioActive}
            canBePlayed={canBePlayed}
          />
        }
        delay={[300, 0]}
        moveTransition={"none"}
        placement="top-end"
        zIndex={100}
      >
        <div className="bg-grey-08 hover:bg-primary-08 rounded border flex items-start p-4 overflow-x-auto">
          <div className="pt-2">
            <LogoSmall />
          </div>
          <p className="ml-2 pt-2">{getContent()}</p>
        </div>
      </Tippy>
    );
  }
};

export default MessageComponent;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { ChipDark } from "./chip";
import Tippy from "@tippyjs/react";
import { ReactComponent as LinkIcon } from "../assets/icons/link.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  errors?: { [name: string]: any };
  error?: FieldError;
  hasError?: boolean;
  label?: string | React.ReactNode;
  helperLink?: { text: string; link?: string };
  inputStyle?: React.CSSProperties | undefined;
  sizes?: "sm" | "lg";
  labelClassName?: string;
  helperText?: string | React.ReactNode;
};

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      helperText,
      errors,
      label,
      helperLink,
      inputStyle,
      labelClassName,
      sizes = "lg",
      error,
      hasError,
      ...props
    },
    ref
  ) => {
    const navigate = useNavigate();

    return (
      <div className={`mb-6 w-full max-w-[456px] ${props.className}`}>
        <div
          className={classNames({
            "flex items-center justify-between  label": true,
            "mb-2 text2": sizes === "lg",
            "mb-1 text5": sizes === "sm",
            "text-primary-02": labelClassName,
          })}
        >
          <h1
            className={classNames({
              text2: !labelClassName && sizes === "lg",
              text5: !labelClassName && sizes === "sm",
              [labelClassName || ""]: true,
            })}
          >
            {label}
          </h1>
          {helperLink && (
            <p
              className="cursor-pointer text2"
              onClick={() => navigate(`${helperLink.link}`)}
            >
              {helperLink.text}
            </p>
          )}
          {helperText && <p className="cursor-pointer text2">{helperText}</p>}
        </div>
        <input
          style={inputStyle}
          {...props}
          className={classNames({
            [`w-full rounded ${
              error || hasError
                ? "border-[1px] border-red-01 outline-0"
                : "outline-0 border-[1px] focus:border-[1px] focus:border-grey-02"
            }  text-primary-02 placeholder-grey-02 `]: true,
            "text2 px-4 py-3": sizes === "lg",
            "text5 px-3 py-1": sizes === "sm",
            "text-primary-02": true,
          })}
          ref={ref}
        />
        {errors && errors[props.name || ""] && (
          <p
            className={classNames({
              "mt-2 text2 text-red-01": true,
              text2: sizes === "lg",
              text5: sizes === "sm",
            })}
          >
            {errors[props.name || ""].message}
          </p>
        )}
      </div>
    );
  }
);

export default Input;

type Props2 = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  showLinkIcon?: boolean;
};

export const onCopy = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value);
  } catch (error) {
    console.log("Copy failed:", error);
  }
};

export const InputWithCopy = ({
  containerProps,
  showLinkIcon = true,
  ...props
}: Props2) => {
  const [copyToolTip, setCopyToolTip] = useState<{
    show: boolean;
    text?: string;
  }>();

  return (
    <Tippy
      visible={copyToolTip?.show}
      offset={[0, 8]}
      content={<ChipDark text={copyToolTip?.text || "Copy"} />}
      delay={[300, 0]}
      moveTransition={"none"}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          onCopy(String(props.value));
          setCopyToolTip({
            show: true,
            text: "Copied!",
          });
        }}
        onMouseEnter={() => {
          setCopyToolTip({
            show: true,
            text: "Copy",
          });
        }}
        onMouseLeave={() => {
          setCopyToolTip({
            show: false,
            text: copyToolTip?.text || "Copy",
          });
        }}
        className={`w-full mr-2 cursor-pointer ${containerProps?.className}`}
      >
        <div className="relative flex items-center justify-between px-4 border rounded cursor-pointer ">
          {showLinkIcon && <LinkIcon className="" />}
          <input
            {...props}
            disabled
            className={`flex-1 w-full  ${
              showLinkIcon ? "px-2" : "pr-2"
            } py-2 bg-white cursor-pointer outline-0 text2 text-grey-02 ${
              props.className
            }`}
          />
          <CopyIcon className="cursor-pointer" />
        </div>
      </div>
    </Tippy>
  );
};

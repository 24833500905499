import React from "react";
import { Chart as ChartJs, registerables } from "chart.js";
import gradient from "chartjs-plugin-gradient";
import { Line } from "react-chartjs-2";

ChartJs.register(...registerables);
ChartJs.register(gradient);

const options = {
  plugins: {
    legend: { display: false },
  },
  scales: {
    y: {
      ticks: { display: true, autoSkip: true, maxTicksLimit: 6 },
      grid: { drawOnChartArea: true, drawBorder: false, borderDash: [5, 8] },
    },
    x: {
      grid: { display: true, drawOnChartArea: false, drawBorder: false },
      gridLines: { borderDash: [8, 4], color: "red" },
      ticks: { display: true, autoSkip: true, maxTicksLimit: 6 },
    },
  },
};

type Props = {
  chartData: any;
};

const LineChart = ({ chartData }: Props) => {
  //configure line chart
  const getChartData = () => {
    return {
      labels: chartData?.labels,
      datasets: chartData?.datasets?.map((item: any) => ({
        label: item.label,
        data: item.data,
        fill: "start",
        borderColor: "#0A8BFE",
        borderWidth: 1,
        tension: 0.4,
        gradient: {
          backgroundColor: {
            axis: "y",
            colors: {
              30: "rgba(10, 139, 254, 0)",
              85: "rgba(10, 139, 254, 0.1)",
              90: "rgba(10, 139, 254, 0.08)",
            },
          },
        },
      })),
    };
  };

  return (
    <div className="">
      <Line data={getChartData()} options={options as any} />
    </div>
  );
};

export default LineChart;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ReactComponent as Logo } from "../../assets/icons/new-logo.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AUTH_LOADING,
  AUTH_SUCCESS,
  ENTER_DASHBOARD,
  fetchUser,
} from "./actions";
import { reducer, initialState, State } from "./reducer";
import { setAccessToken } from "../../utils/setAccessToken";
import { sendMessageToExtension } from "../../utils/extension";
import mixpanel from "mixpanel-browser";
import { isUserEligibleForFeature } from "../../utils/isUserEligableForFeature";
import { languageOptions } from "../../components/ApuForms/data";
import { planType } from "../../utils/helpers";
import { useMediaQuery } from "react-responsive";

type PlanType = "Free" | "Pro" | "Business";

type PricingModal = { isOpen: boolean; featureUserAttempted?: PlanType };

const AuthContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
  pricingModal: PricingModal;
  setPricingModalData: (data: PricingModal) => void;
  language: string;
  handleLanguageChange: (args: LangChangeArgs) => void;
  extensionBanner: boolean;
  toggleExtensionBanner: (args: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: (val: boolean) => void;
}>({
  state: initialState,
  dispatch: () => null,
  pricingModal: { isOpen: false, featureUserAttempted: planType.Pro },
  setPricingModalData: (data: any) => {},
  language: "auto",
  handleLanguageChange: (args: LangChangeArgs) => {},
  extensionBanner: true,
  toggleExtensionBanner: (args: boolean) => {},
  isSidebarOpen: true,
  toggleSidebar: (val) => {},
});

type Props = {
  children: React.ReactNode;
};

type LangChangeArgs = {
  lang: string;
  showPricingModalIfNec?: boolean;
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [language, setLanguage] = React.useState("auto");
  const [extensionBanner, toggleExtensionBanner] = useState(false);
  const [pricingModal, setPricingModal] = useState<PricingModal>({
    isOpen: false,
    featureUserAttempted: planType.Pro,
  });
  const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
  const [isSidebarOpen, toggleSidebar] = useState(isMobile ? false : true);

  const setPricingModalData = (data: PricingModal) => {
    setPricingModal(data);
  };

  /******/
  const getUser = useCallback(async () => {
    const queryParamAccessToken = searchParams.get("access_token");
    const queryParamRefreshToken = searchParams.get("refresh_token");
    if (queryParamAccessToken) {
      await setAccessToken({
        accessToken: queryParamAccessToken,
        refreshToken: queryParamRefreshToken as string,
      });
      const user = await fetchUser();
      analytics(user);
      await localStorage.removeItem("cortexUnverifiedUser");
      if (pathname.includes("onboarding")) {
        dispatch({ type: AUTH_SUCCESS, payload: { user } });
      } else {
        dispatch({
          type: ENTER_DASHBOARD,
          payload: { user: user },
        });
      }
      navigate(pathname);
      return;
    }

    dispatch({ type: AUTH_LOADING, payload: true });
    const unverifiedUser = await localStorage.getItem("cortexUnverifiedUser");

    /******user signed up and but not yet verified*****/
    if (unverifiedUser) {
      navigate("/user/verify-email");
      dispatch({
        type: AUTH_SUCCESS,
        payload: { user: JSON.parse(unverifiedUser) },
      });
      return;
    }

    const accessToken = await localStorage.getItem("cortexAccessToken");
    if (accessToken) {
      sendMessageToExtension({
        type: "SET_TOKEN",
        payload: {
          accessToken: accessToken.replace(/"/g, ""),
          token: accessToken.replace(/"/g, ""), //todo: remove when extension is updated
        },
      });
    }
    const currentUser = accessToken ? await fetchUser() : null;

    if (!currentUser) {
      dispatch({ type: AUTH_LOADING, payload: false });
      return;
    }

    /***user signed in and has access token***/

    if (currentUser && !currentUser.onboarded) {
      analytics(currentUser);
      checkRegistrationSourceAndRedirect(currentUser);
      return;
    }

    if (currentUser && currentUser.onboarded) {
      analytics(currentUser);
      dispatch({ type: ENTER_DASHBOARD, payload: { user: currentUser } });
      return;
    }
    //no current user or unverified user here.
  }, [navigate, dispatch]);

  useEffect(() => {
    getUser();
    const language = localStorage.getItem("default_language");
    setLanguage(language || "auto");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRegistrationSourceAndRedirect = (user: any) => {
    if (user.registration_source === "chrome_extension") {
      navigate("/extension/onboarding");
      dispatch({ type: AUTH_SUCCESS, payload: { user } });
    } else {
      dispatch({ type: AUTH_SUCCESS, payload: { user } });
      navigate("/user/onboarding");
    }
  };

  const analytics = async (user: any) => {
    try {
      mixpanel.identify(user.email);
      const { ...analyticsUser } = user;
      if (user.email) {
        mixpanel.people.set({
          ...analyticsUser,
          name: `${user.data?.first_name} ${user.data?.last_name}`,
        });
        mixpanel.people.set({
          $email: user.email,
        });
      } else {
        mixpanel.people.set({
          ...analyticsUser,
          email: user.email,
        });
      }
    } catch (error) {
      console.log("mixpanel error: ", error);
    }
  };

  const changeLang = (lang: string) => {
    setLanguage(lang);
  };

  const handleLanguageChange = async ({
    lang,
    showPricingModalIfNec,
  }: LangChangeArgs) => {
    const option = languageOptions.find((item: any) => item.value === lang);
    if (
      isUserEligibleForFeature({
        feature: option?.planTypeToUnlock as PlanType,
        user: state.user?.plan_type,
      })
    ) {
      changeLang(lang);
      try {
        await localStorage.setItem("default_language", lang);
      } catch (error) {
        console.log("error: ", error);
      }
    } else if (showPricingModalIfNec) {
      setPricingModalData({
        isOpen: true,
        featureUserAttempted: option?.planTypeToUnlock,
      });
    }
  };

  if (state.loading) {
    return (
      <div className="flex-col w-[100vw] h-[100vh] m-auto flex justify-center items-center">
        <Logo className="animate-pulse" width={100} height={100} />
        <p className="-mt-2 h3 text-grey-02">TextCortex</p>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        setPricingModalData,
        pricingModal,
        state,
        dispatch,
        language,
        toggleExtensionBanner,
        extensionBanner,
        handleLanguageChange,
        isSidebarOpen,
        toggleSidebar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);

import React from "react";
import { ReactComponent as ChromeInstall } from "../assets/icons/chrome_install.svg";
import { ReactComponent as EdgeInstall } from "../assets/icons/edge_install.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x-close.svg";
import { extensionLink, redirectBrowser } from "../utils/helpers";
import { browserName } from "react-device-detect";

const ExtensionBanner = () => {
  const [showBanner, toggleBanner] = React.useState(true);

  return !showBanner ? null : (
    <div className="flex w-[220px] flex-col p-6 bg-light-pink-gradient rounded border">
      <div className="flex justify-between items-start">
        {browserName === "Edge" ? <EdgeInstall /> : <ChromeInstall />}
        <CloseIcon
          className="cursor-pointer"
          onClick={() => toggleBanner(!showBanner)}
        />
      </div>
      <div className="flex flex-col">
        <p className="mt-2 h4">TextCortex Extension</p>
        <p className="mb-4">
          Hyper charge your writing wherever you need it in your browser.
        </p>
        <a href={redirectBrowser()} target="_blank">
          <button className="rounded border-none text-white w-[100px] h-9 outline-none flex items-center h5BoldRegular justify-center bg-brand-02">
            Install Now
          </button>
        </a>
      </div>
    </div>
  );
};

export default ExtensionBanner;

import React, { useState } from "react";
// import { ReactComponent as AlignIcon } from "../assets/icons/align_left.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/kebab_menu.svg";
import OutsideClickDetector from "../hooks/useOutsideClickDetecter";
import { addToFavorites } from "../screens/dashboard/creator/actions";
import { getDocumentCategory } from "../utils/getDocumentCategory";
import DocumentMenuDropdown from "./documentMenuDropdown";
import UserIcon from "./userIcon";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { defaultEditorRoute } from "../utils/helpers";
import { useAuth } from "../context/auth/authContext";

type Props = {
  title: string;
  description: string;
  category: string;
  cardType?: "type-1" | "type-2";
  document?: any;
  setDocumentToBeDeleted?: (id: string) => void;
  isLastCard?: boolean;
};

type TagProps = {
  category: { themeColor: string; themeBg: string; title: string };
};

const Tag = ({ category }: TagProps) => {
  if (category)
    return (
      <div
        style={{
          color: category.themeColor,
          backgroundColor: category.themeBg,
        }}
        className={`tag text2 rounded flex items-center px-2`}
      >
        {category.title}
      </div>
    );
  return null;
};

const DocumentCard = ({
  document,
  cardType,
  title,
  description,
  category,
  setDocumentToBeDeleted,
}: Props) => {
  const documentCategory = getDocumentCategory(category);
  const [showMenu, toggleMenu] = useState(false);
  let documentText = isJson(description)
    ? isJson(description)[0].text
    : description;
  documentText = documentText
    ? `${documentText.replace(/(<([^>]+)>)/gi, "").slice(0, 130)}...`
    : "";
  const { state } = useAuth();
  const navigate = useNavigate();

  function isJson(str: string) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return false;
    }
  }

  return (
    <div
      className={`relative rounded ${
        showMenu ? "min-h-[272px]" : "h-[252px]"
      } border w-[272px] md:w-[280px] px-4 pt-5 pb-3`}
    >
      <div className="flex items-center justify-between border-bottom">
        {cardType === "type-2" ? (
          <Tag category={documentCategory} />
        ) : (
          <div />
          // <AlignIcon className="cursor-pointer" />
        )}
        <Tippy
          offset={[0, 8]}
          interactive
          popperOptions={{
            strategy: "fixed",
          }}
          zIndex={99}
          content={
            <DocumentMenuDropdown
              onEdit={() => {
                navigate(
                  `${defaultEditorRoute}?documentId=${document.document_id}`
                );
              }}
              onFavorite={() => {
                addToFavorites({
                  prompt: "",
                  generated_text: document.document_data,
                  word_count: _.words(documentText || "").length,
                  language: document.language,
                  category: document.category,
                  parameters: "",
                });
              }}
              onDelete={() => {
                setDocumentToBeDeleted?.(document.document_id || "");
              }}
            />
          }
          delay={[100, 0]}
          moveTransition={"none"}
          trigger={"click"}
          placement={"right-start"}
        >
          <div className="rounded-md border-[1px] mb-3 p-[2px]">
            <MenuIcon className="cursor-pointer" />
          </div>
        </Tippy>
      </div>
      <Link
        to={`${defaultEditorRoute}?documentId=${document.document_id}`}
        className="flex flex-col h-full cursor-pointer"
      >
        <div className="h-[70%] break-all">
          <h2 className="mt-4 h2 text-primary-02">{title}</h2>
          <p className="mb-2 text2 text-grey-02">
            {documentText ? (
              documentText
            ) : (
              <span className="inline-block px-2 py-1 mt-6 border rounded bg-grey-07">
                Draft
              </span>
            )}
          </p>
        </div>
        {cardType !== "type-2" && (
          <div className="flex justify-between">
            <Tag category={documentCategory} />
            {state.user?.data.first_name[0] && (
              <UserIcon initial={state.user?.data.first_name[0]} />
            )}
          </div>
        )}
        {showMenu && (
          <div className="z-[1000] absolute top-2 right-[-240px]">
            <OutsideClickDetector onClickOutside={() => toggleMenu(false)}>
              <DocumentMenuDropdown
                onFavorite={() => {
                  addToFavorites({
                    prompt: "",
                    generated_text: document.document_data,
                    word_count: _.words(documentText || "").length,
                    language: document.language,
                    category: document.category,
                    parameters: "",
                  });
                }}
                onDelete={() => {
                  setDocumentToBeDeleted?.(document.document_id || "");
                }}
              />
            </OutsideClickDetector>
          </div>
        )}
      </Link>
    </div>
  );
};

export default DocumentCard;

import React, {
  DetailedHTMLProps,
  FormEventHandler,
  HTMLAttributes,
  LegacyRef,
  useState,
} from "react";
import { ReactComponent as SendMessage } from "../../assets/icons/send_message.svg";
import { ReactComponent as InfoCircle } from "../../assets/icons/info-circle-primary.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-2.svg";

import Tippy from "@tippyjs/react";
import { languageOptions } from "../ApuForms/data";
import { State } from "../../context/chat/reducer";
import { useMediaQuery } from "react-responsive";

import Switch from "react-switch";
import { useAuth } from "../../context/auth/authContext";
import { isUserEligibleForFeature } from "../../utils/isUserEligableForFeature";
import { PlanType } from "../../types";
import { ChipDark } from "../chip";

type LanguageProps = {
  onSelect: (item: any) => void;
};

const WebSearchInfo = () => {
  return (
    <div className="p-[1px] bg-pink-gradient-2 max-w-[450px] im:w-[450px] rounded-xl">
      <div className="p-5 px-6 bg-brand-25 rounded-xl">
        <h3 className="h3 flex items-center">
          <InfoCircle className="mr-2" />
          How does Web Search work?
        </h3>
        <p className="text2 mt-2 text-neutral-04">
          Web Search toggle was designed to give you the most recent information
          on the internet. When you type your message, Zeno will search for it
          on the internet, organise the information and present it to you in the
          best way possible. Therefore, expect some latencies when the toggle is
          on. (i.e. Ask Zeno “Who won the World Cup 2022?” and see for
          yourself.)
        </p>
      </div>
    </div>
  );
};

export const LanguageOptions = ({ onSelect }: LanguageProps) => {
  const languages = languageOptions;
  return (
    <div className="bg-white cursor-pointer relative z-[1000] flex items-center flex-col h-[200px] w-[130px] overflow-y-auto shadow border rounded">
      {languages.map((item) => (
        <p
          onClick={() => {
            onSelect(item);
          }}
          className=" px-2 py-2 border-bottom w-full"
        >
          {item.label}
        </p>
      ))}
    </div>
  );
};

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  setMessage: (val: string) => void;
  onSend?: () => void;
  inputRef: any;
  message: string;
  onLanguageChange: (item: any) => void;
  state: State;
  toggleWebSearch: () => void;
  hideLanguage?: boolean;
  clearConvo?: () => void;
}

const ChatInput = React.forwardRef((props: Props) => {
  const {
    setMessage,
    message,
    onSend,
    inputRef,
    toggleWebSearch,
    state,
    hideLanguage = false,
    clearConvo,
  } = props;
  const [showLanguageOptions, toggleLanguageOptions] = useState(false);
  const auth = useAuth();
  const handleInput: FormEventHandler<HTMLTextAreaElement> = (e) => {
    (e.target as any).style.height = "";
    (e.target as any).style.height = (e.target as any).scrollHeight + "px";
    setMessage(e.currentTarget.value);
  };
  const isMobile = useMediaQuery({ query: "(max-width: 925px)" });

  const currentLanguage = languageOptions.find(
    (item) => item.value === auth.language
  );

  const handleSwitch = () => {
    if (
      isUserEligibleForFeature({
        feature: "Business",
        user: auth.state.user?.plan_type as PlanType,
      })
    ) {
      toggleWebSearch();
    } else {
      auth.setPricingModalData({
        featureUserAttempted: "Business",
        isOpen: true,
      });
    }
  };

  return (
    <div className=" w-full">
      <div className="flex  flex-col items-end w-full mb-2">
        <div className="hidden sm:flex justify-between w-[99%] items-end h-[99%] flex-wrap">
          <div className="h-6 flex items-center">
            <span
              className="flex items-center cursor-pointer"
              onClick={handleSwitch}
            >
              <Switch
                handleDiameter={14}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.1)"
                className="react-switch h-2"
                id="material-switch"
                checked={state.enableWebSearch}
                width={27}
                height={12}
                onColor="#8043F9"
                offColor="#d3d5ff"
                onChange={() => {}}
              />
              <p className="text4 ml-2 mt-1 brand-gradient-text flex items-center">
                Enable web search{" "}
                {/* <PremiumSmall className="stroke-primary-09 ml-[5px] mt-[1px]" /> */}
              </p>
            </span>
            <div className="mt-1">
              <Tippy
                delay={[0, 0]}
                interactive={true}
                interactiveBorder={40}
                content={<WebSearchInfo />}
                placement="auto-start"
                appendTo={document.body}
                offset={[-30, 0]}
              >
                <InfoCircle className="w-3 h-3 ml-3 cursor-pointer" />
              </Tippy>
            </div>
          </div>
          <a
            className="relative max-w-fit"
            target={"_blank"}
            href={
              "https://textcortex.com/post/the-ultimate-guide-to-zenochat-the-best-chatgpt-alternative#10?utm_source=chat&utm_medium=webapp"
            }
          >
            <span className="text3 w-full  brand-gradient-text">
              Learn how to prompt Zeno{" ->"}
            </span>
            <div className="absolute w-full h-[1px] bottom-0 right-0 brand-gradient-2" />
          </a>
        </div>
      </div>
      <div className="flex border w-full max-h-[140px]  min-h-[40px]   rounded  px-3 items-center justify-between">
        <textarea
          className="w-[96%] resize-none  pr-2 overflow-y-auto max-h-[120px]  py-2  inline-block bg-white h-[40px] items-center text2 bg-transparent  outline-none"
          {...props}
          ref={inputRef}
          onInput={handleInput}
          placeholder={
            isMobile || hideLanguage
              ? "Tell me what you want to do"
              : "Tell me what you want to do"
          }
          onKeyDown={(e) => {
            if (e.shiftKey) {
              return;
            } else if (e.key === "Enter") {
              e.preventDefault();
              onSend && onSend();
            }
          }}
        />
        {/* <div
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSend && onSend();
              }
            }}
            ref={inputRef}
            contentEditable
            onInput={(e) => setMessage(e.currentTarget.textContent || "")}
          /> */}
        <div className="left-[75%] flex justify-center items-center">
          {/* <Microphone className="mr-2 cursor-pointer" /> */}
          <SendMessage onClick={onSend} className="mt-2 cursor-pointer" />
        </div>
      </div>
      <div className="flex justify-end mt-1 items-center">
        <div className="flex items-center mt-[-5px]">
          {/* <div className="mt-2 mr-4">
            <Tippy
              interactive={true}
              offset={[0, 8]}
              content={<LanguageOptions onSelect={props.onLanguageChange} />}
              delay={[300, 0]}
              moveTransition={"none"}
              trigger="click"
              popperOptions={{ strategy: "fixed" }}
              appendTo={document.body}
            >
              <LightBulb className="cursor-pointer shadow" />
            </Tippy>
          </div> */}
          {clearConvo && (
            <Tippy
              offset={[0, 8]}
              content={<ChipDark text={"Clear Conversation"} />}
              delay={[300, 0]}
              moveTransition={"none"}
            >
              <TrashIcon
                onClick={clearConvo}
                className="mr-4 mt-1 scale-105 outline-none cursor-pointer"
              />
            </Tippy>
          )}
          {!hideLanguage && (
            <div className="mr-4 mt-1">
              <Tippy
                interactive={true}
                visible={showLanguageOptions}
                offset={[0, 8]}
                content={
                  <LanguageOptions
                    onSelect={(val) => {
                      props.onLanguageChange(val);
                      toggleLanguageOptions(false);
                    }}
                  />
                }
                delay={[300, 0]}
                moveTransition={"none"}
                trigger="click"
                zIndex={10000}
                popperOptions={{ strategy: "fixed" }}
                appendTo={document.body}
                onClickOutside={() => toggleLanguageOptions(false)}
              >
                <p
                  onClick={() => toggleLanguageOptions(true)}
                  className="h1 cursor-pointer mt-[0px]"
                >
                  {currentLanguage?.emoji}
                </p>
              </Tippy>
            </div>
          )}
        </div>
        <p className="text3">{message.length}/100</p>
      </div>
    </div>
  );
});

export default ChatInput;

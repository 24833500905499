import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import Input from "../../components/input";
import SelectInput from "../../components/selectDropdown";

const industrySelectOptions = [
  { value: "ecommerce", label: "Ecommerce" },
  { value: "agency", label: "Agency" },
  { value: "software", label: "Software" },
  { value: "dropshipping", label: "Dropshipping" },
  { value: "freelance", label: "Freelance" },
];

const rolesSelectOptions = [
  { value: "entrepreneur", label: "Entrepreneur" },
  { value: "marketer", label: "Marketer" },
  { value: "copywriter", label: "Copywriter" },
  { value: "designer", label: "Designer" },
  { value: "developer", label: "Developer" },
  { value: "manager", label: "Manager" },
];

const SuggestTemplate = () => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    feature: "",
    topic: "",
    message: "",
    fullName: "",
    email: "",
  });

  const handleChange = (newOption: any) => {
    setFormData({ ...formData, [newOption.name]: newOption.value });
  };

  const submitForm = async () => {};

  return (
    <div className="w-full h-auto pr-6 page">
      <h1 className="h3 text-primary-02">Suggest Template</h1>
      <p className="text2 text-grey-02">
        What would make Text Cortex more useful for you?
      </p>
      <div className="text2 text-primary-02 border rounded max-w-[500px] px-6 py-5 mt-6">
        <div className="mb-6">
          <p className="mb-2">Topic</p>
          <SelectInput
            placeholder={"Feature request"}
            value={formData.role}
            onChange={handleChange}
            name="role"
            options={rolesSelectOptions}
          />
        </div>
        <div className="mb-6">
          <p className="mb-2">Category</p>
          <SelectInput
            placeholder={"Choose Category"}
            value={formData.industry}
            onChange={handleChange}
            name="category"
            options={industrySelectOptions}
          />
        </div>
        <div>
          <Input
            label="Message"
            placeholder={"I would like..."}
            value={formData.message}
            name={"message"}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            className="w-full  outline-none text-primary-02 text2"
          />
        </div>
        <div>
          <Input
            label="Full name"
            placeholder={"Michael Scott"}
            value={formData.fullName}
            name={"fullName"}
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
            className="w-full outline-none text-primary-02 text2"
          />
        </div>
        <div>
          <Input
            label="Email"
            placeholder={"person@textcortex.com"}
            value={formData.email}
            name={"email"}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            className="w-full outline-none text-primary-02 text2"
          />
        </div>
        <div className="my-8 max-w-[140px]">
          <Button onClick={submitForm}>Send</Button>
        </div>
      </div>
    </div>
  );
};

export default SuggestTemplate;

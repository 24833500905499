import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
// import { Input } from "../../../App";
import Creative from "../../../assets/icons/creative";
import Expand from "../../../assets/icons/expand";
import Fluency from "../../../assets/icons/fluency";
import Formal from "../../../assets/icons/formal";
import Smile from "../../../assets/icons/smile";
import Star from "../../../assets/icons/star";
import Summarize from "../../../assets/icons/summarize";
import _, { template } from "lodash";
import Lightning from "../../../assets/icons/lightning";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";

import Tippy from "@tippyjs/react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/chevron-down.svg";
import Button, { SaveButton } from "../../../components/button";
import Skeleton from "react-loading-skeleton";
import { getDiffWords } from "../../../utils/getWords";
import SelectInput from "../../../components/selectDropdown";
import useCreator from "./useCreator";
import { useAuth } from "../../../context/auth/authContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  languageOptions,
  templateOptions,
} from "../../../components/ApuForms/data";
import { copyOnSave, generateTextAI } from "./actions";
import axiosClient, { postRequest } from "../../../axios";
import { toast } from "react-toastify";
import { SET_USER } from "../../../context/auth/actions";
import { onCopy } from "../../../components/input";
import { ValueContainerProps, components } from "react-select";

import { isUserEligibleForFeature } from "../../../utils/isUserEligableForFeature";
import { PlanType } from "../../../types";
import PricingModal from "../../../components/pricingModal";

import { getRequestWordCount } from "../../../utils/sendParameterValues";
import { planType } from "../../../utils/helpers";
import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { fetchTokenStreams } from "../../../components/DPU/utils";

const N_GEN = 1;

type FormT = {
  text: string;
  language: any;
};

type Props = {
  submit: (data: any) => void;
  state: "loading" | "success" | "idle" | "error";
  results: any;
};

const rewriteOptions = [
  {
    icon: Star,
    title: "Rewrite",
    template_name: "paraphrases",
    planTypeToUnlock: planType.Free,
  },
  {
    icon: Fluency,
    title: "Fluency",
    template_name: "rewritings",
    mode: "style_fluent",
    planTypeToUnlock: planType.Free,
  },
  {
    icon: Formal,
    title: (
      <span className="flex items-center text-center">
        <p className="ml-3 text4 text-primary-02">Tone Changer</p>
      </span>
    ),
    type: "select",
    options: [
      { value: "tone_cheerful", label: "🤗   Cheerful" },
      { value: "tone_decisive", label: "🤔 Decisive" },
      { value: "tone_casual", label: "👕 Casual" },
      { value: "tone_encouraging", label: "💪 Encouraging" },
      { value: "tone_formal", label: "👨🏼‍💼 Formal" },
      { value: "tone_gentle", label: "😎 Gentle" },
      { value: "tone_friendly", label: "😀 Friendly" },
      { value: "style_detailed", label: "🛥 Detailed" },
      { value: "tone_respectful", label: "💂‍♀️ Respectful" },
      { value: "style_concise", label: "📜 Concise" },
      { value: "tone_warm", label: "🔥   Warm" },
      { value: "tone_blunt", label: "🗣 Blunt" },
      { value: "tone_urgent", label: "🚑 Urgent" },
    ],
    template_name: "rewritings",
    planTypeToUnlock: planType.Pro,
  },
  {
    icon: Smile,
    title: "Simple",
    template_name: "simplifications",
    planTypeToUnlock: planType.Free,
  },
  // {
  //   icon: Shorten,
  //   title: "Shorten",
  //   template_name: "shorten",
  // },
  {
    icon: Creative,
    title: "Creative",
    template_name: "rewritings",
    planTypeToUnlock: planType.Free,
    mode: "style_creative",
  },
  {
    icon: Expand,
    title: "Expand",
    template_name: "expansions",
    planTypeToUnlock: planType.Free,
  },
  {
    icon: Summarize,
    title: "Summarize",
    template_name: "summarizations",
    planTypeToUnlock: planType.Pro,
  },
];

type ParaphraseProps = {
  rewriteTemplates?: any;
  page?: "change_tone" | "rewrite";
};

const inputWordLimit = {
  Free: 100,
  Pro: 200,
  Business: 2000,
} as const;

const Paraphrase = ({
  rewriteTemplates = rewriteOptions,
  page = "rewrite",
}: ParaphraseProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
  const [searchParams, setSearchParams] = useSearchParams("");
  const [results, setResults] = useState<any[]>([]);
  const {
    state: { user },
    dispatch,
    setPricingModalData,
    pricingModal,
  } = useAuth();
  const [state, setState] = useState<
    | "idle"
    | "loading"
    | "success"
    | "error"
    | "streaming"
    | "loading_more"
    | "finished_streaming"
  >("idle");
  const { register, handleSubmit, setValue, watch, control } = useForm<FormT>();
  const [selectedOptions, setSelectedOption] = useState<{
    title: string;
    template_name: string;
    mode?: string;
  }>({
    title: "Rewrite",
    template_name: "paraphrases",
    mode: undefined,
  });
  const [tokenGeneratedResult, setTokenGeneratedResult] = useState("");
  const [copyToolTip, setCopyToolTip] = useState<{
    index: number;
    show: boolean;
    text?: string;
  }>();

  const [currentResult, setCurrentResult] = useState(0);

  const getWordCount = (text: string) => {
    return text ? text.trim().split(/[" ", \n]/).length : 0;
  };
  const words = getWordCount(watch("text"));

  useEffect(() => {
    const query = searchParams.get("editor_query_text");
    if (query) {
      setValue("text", query);
    }
  }, []);

  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
    submitQuery({ text: watch("text") }, option);
  }; // when pass nothing as argument, you are watching everything

  const getChunkedSentences = () => {
    const inputText = watch("text");
    const sentencesMarked = inputText
      .replaceAll('"', "'")
      .replace(/([.?!])\s*(?=[A-Z])/g, "$1|");

    const sentences = sentencesMarked.split("|");

    let hundredWordSentences: any[] = [];
    sentences.forEach((text) => {
      if (!hundredWordSentences.length) {
        hundredWordSentences.push(text.trim());
      } else {
        const currentText =
          hundredWordSentences[hundredWordSentences.length - 1];
        if (getWordCount(currentText) <= 100) {
          const updatedText = `${currentText} ${text}`;
          hundredWordSentences[hundredWordSentences.length - 1] =
            updatedText.trim();
        } else {
          hundredWordSentences.push(text.trim());
        }
      }
    });
    return hundredWordSentences;
  };

  const appendResult = (data: any, prevResult: any): any[] => {
    if (prevResult.length) {
      const updatedResult =
        prevResult && prevResult.length > 0
          ? prevResult.map((result: any, index: number) => ({
              ...result,
              text: `${result.text} \n \n ${data[index].text}`,
            }))
          : [];
      return updatedResult;
    }
    return data;
  };

  const getParaphraseResult = async (text: string, option: any) => {
    try {
      const commonAttributes = {
        n: N_GEN,
        source_lang: watch("language"),
        text: text.trim(),
        temperature: 0.65,
        max_tokens: 600,
      };
      let data = option.mode
        ? { ...commonAttributes, mode: option.mode }
        : { ...commonAttributes };

      const res = await axiosClient({
        method: "POST",
        url: `/internal/v1/texts/${option.template_name}`,
        data: { ...data },
      });
      if (res.data) return { text: res.data.data.outputs };
    } catch (err) {
      setState("error");
      console.log(err, "error here");
    }
  };

  const handleTokenStreaming = async (text: string, option: any) => {
    try {
      const commonAttributes = {
        n: N_GEN,
        source_lang: watch("language"),
        text: text.trim(),
        temperature: 0.65,
        max_tokens: 600,
      };
      let payload = option.mode
        ? { ...commonAttributes, mode: option.mode }
        : { ...commonAttributes };
      let result = {
        id: "",
        text: "",
      };
      await fetchTokenStreams(
        `/internal-stream/v1/texts/${option.template_name}`,
        payload,
        (chunk: any) => {
          // Split according to newline
          const parts = chunk.split("\n");
          if (parts[0] === "event: output") {
            setState(() => "streaming");
            const data = parts[1].substring(6);
            const parsedData = JSON.parse(data);
            const text = parsedData.outputs[0].text;
            setTokenGeneratedResult((prevResult) => prevResult + text);
            result = {
              id: parsedData.outputs[0].id,
              text: result.text + text,
            };
          }
          if (parts[0] === "event: end_of_stream") {
            setState(() => "finished_streaming");
            setTokenGeneratedResult((prevResult) => prevResult + "\n\n");
          }
          if (parts[0] === "event: error") {
            setState("error");
          }
        }
      );
      return result;
    } catch (err) {
      setState("error");
      console.log("error", err);
    }
  };

  const submitQuery = async (data: any, option?: any) => {
    try {
      if (watch("text").length) {
        const chunkedTexts = getChunkedSentences();
        let prevResult: any[] = [];
        let resultIndex = 0;
        setState("loading");
        if (canTokenStream()) {
          setTokenGeneratedResult("");
        }
        for (let text of chunkedTexts) {
          const res: any = canTokenStream()
            ? await handleTokenStreaming(text, option)
            : await getParaphraseResult(text, option);

          if (res?.text) {
            const updatedResult = appendResult(res?.text, prevResult);
            setResults(updatedResult);
            prevResult = [...prevResult, updatedResult];
            user?.plan_type.toLowerCase() === "free" &&
              dispatch({
                type: SET_USER,
                payload: { user: { ...user, credit: user.credit - 1 } },
              });
            resultIndex + 1 === chunkedTexts.length
              ? setState("success")
              : setState("loading_more");
            resultIndex++;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isParaphrasingPage = true;

  let result: any;
  if (results) {
    result = results[currentResult];
  }

  const wordCounterWidth = (planType: PlanType) => {
    const maxWordsLimit = (inputWordLimit as any)[planType];
    if (planType === "Business") {
      return 0;
    } else {
      return words > maxWordsLimit
        ? "100%"
        : `${(words / maxWordsLimit) * 100}%`;
    }
  };

  const hasPassedWordLimit = ({ planType }: { planType: PlanType }) => {
    return planType !== "Business" && words > inputWordLimit[planType];
  };

  const isButtonDisabled =
    state === "loading" ||
    state === "loading_more" ||
    state === "streaming" ||
    hasPassedWordLimit({ planType: user?.plan_type as PlanType });

  type Options = {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string | any;
    planTypeToUnlock: PlanType;
    template_name: string;
    mode?: string;
  };

  const rewriteOption: Array<Options> = rewriteOptions.filter(
    ({ title }) => typeof title === "string"
  );
  const [isOpen, setIsOpen] = useState(false);
  const [option, setOption] = useState<Options>(rewriteOption[0]);
  const { toggleSidebar, isSidebarOpen } = useAuth();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: Options) => {
    setOption(option);
    setIsOpen(false);
    if (
      isUserEligibleForFeature({
        feature: option.planTypeToUnlock as PlanType,
        user: user?.plan_type as PlanType,
      })
    ) {
      handleOptionChange({
        template_name: option.template_name,
        title: option.title,
        mode: option.mode,
      });
    } else {
      setPricingModalData({
        featureUserAttempted: option.planTypeToUnlock,
        isOpen: true,
      });
    }
  };
  const handleKeyPress = (e: any) => {
    if (e.keyCode === 40) {
      // Down arrow key
      // selectOption(rewriteOption.at(index + 1) as Options);
    }
  };
  useEffect(() => {
    setIsOpen(false);
    return setIsOpen(false);
  }, []);

  useEffect(() => {
    !isMobile && setIsOpen(false);
  }, [isMobile]);

  const canTokenStream = () => {
    return watch("language") === "en" || watch("language") === "auto";
  };

  return (
    <div
      className="relative page w-full min-h-full h-[calc(100vh-24px)] im:h-full z-[1000] overflow-y-auto"
      onClick={() => setIsOpen(false)}
    >
      <div
        className="flex  border-bottom h-16 bg-white  fixed top-0 w-screen  justify-between right-0  md:pl-[255px] md:hidden items-center z-[1000]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full px-5 relative flex">
          <div className="flex justify-between items-center w-full">
            <div
              className="flex items-center justify-center"
              onClick={() => {
                toggleSidebar(!isSidebarOpen);
              }}
            >
              <MdMenu />
            </div>
            <div
              className="flex items-center  text-primary-09"
              onClick={toggleDropdown}
            >
              <option.icon
                style={{ fill: "#F7F4FE", stroke: "#8043F9" }}
                className="stroke-inherit text-sm-SemiBold w-5 h-5"
                stroke="#8043F9"
              />
              <span className="pl-2 text-sm-SemiBold text-brand-02">
                {option.title}
              </span>
            </div>
            <div onClick={toggleDropdown}>
              <ArrowDown className="w-5 h-5" />
            </div>
          </div>
        </div>
        {isOpen && (
          <div
            className="absolute z-[5000] bottom-0 top-[55px] right-0 mr-4 px-2 bg-white border border-[#F0F3FC] p-2 w-[178px] h-[320px] flex justify-between mx-auto flex-col rounded shadow-lg "
            onKeyDown={handleKeyPress}
          >
            {rewriteOption.map((item: Options, index: number) => {
              return (
                <div
                  className={`flex pl-5 items-center justify-start w-[162px] h-[38px] text-sm-Medium  hover:bg-primary-25 ${
                    item === option
                      ? "bg-primary-25 text-brand-09"
                      : "text-[#5B6772] bg-white"
                  }`}
                  key={index}
                  onClick={() => selectOption(item)}
                  tabIndex={0}
                >
                  <item.icon stroke={item === option ? "#8043F9" : undefined} />
                  <span className="pl-2">{item.title}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex flex-col items-center h-full w-full bg-white  pt-10 md:pt-0  im:pb-0">
        <div className=" w-full pt-2  im:mt-6 im:mb-20 flex flex-col justify-center items-center h-full im:max-w-[77vw] overflow-y-auto ">
          <div className=" hidden mb-2 im:flex justify-between w-full items-center">
            <h1 className="h1 text-primary-02 self-center">
              {page === "change_tone" ? "Tone Change" : " Paraphrase"}
            </h1>
            <div className="flex items-center">
              <Controller
                name="language"
                control={control}
                defaultValue={
                  localStorage.getItem("default_language")
                    ? localStorage.getItem("default_language")
                    : "auto"
                }
                render={({ field: { onChange, value, name } }) => (
                  <SelectInput
                    label="Language"
                    name={name}
                    placeholder="Choose language"
                    value={value}
                    options={languageOptions}
                    onChange={(val: any) => {
                      if (
                        isUserEligibleForFeature({
                          feature: val.planTypeToUnlock,
                          user: user?.plan_type as PlanType,
                        })
                      ) {
                        onChange(val.value);
                        localStorage.setItem("default_language", val.value);
                      } else {
                        setPricingModalData({
                          featureUserAttempted: val.planTypeToUnlock,
                          isOpen: true,
                        });
                      }
                    }}
                    sizing={"sm"}
                    isSearchable
                  />
                )}
                rules={{ required: "This is a required field" }}
              />
            </div>
          </div>
          <div
            className={`bg-white im:border-[1px] relative im:border-grey-09  ml-0  pb-0 rounded-md w-full  h-full flex flex-col justify-between ${
              state === "error" && "py-6"
            } sm:p-0`}
          >
            {/* section 1 */}
            <div className="overflow-x-auto h-[70px] overflow-y-hidden hidden im:m-auto w-[90%] justify-between items-center md:flex  py-4 im:px-4 md:top-0">
              {rewriteOptions.map((item: any) => {
                const Icon = item.icon;

                if (item.type === "select") {
                  const isCurrentValue = item.options.some(
                    (item: any) => item.value === selectedOptions.title
                  );
                  return (
                    <div
                      className={`flex items-center ${
                        isCurrentValue && "bg-primary-07"
                      }  hover:rounded-md rounded-md pl-3 hover:border-[1px] hover:bg-primary-07  hover:border-grey-09`}
                    >
                      <span className="mr-3">
                        <Icon />
                      </span>
                      <SelectInput
                        placeholder={item.title}
                        name={item.template_name}
                        // placeholder="Choose language"
                        value={selectedOptions.title}
                        options={item.options}
                        onChange={(val: any) => {
                          if (
                            isUserEligibleForFeature({
                              feature: item.planTypeToUnlock as PlanType,
                              user: user?.plan_type as PlanType,
                            })
                          ) {
                            handleOptionChange({
                              template_name: item.template_name,
                              title: val.value,
                              mode: val.value,
                            });
                          } else {
                            setPricingModalData({
                              featureUserAttempted: item.planTypeToUnlock,
                              isOpen: true,
                            });
                            setSelectedOption({
                              template_name: "paraphrases",
                              title: "Rewrite",
                              mode: undefined,
                            });
                          }
                        }}
                        style={{
                          width: "auto",
                          minWidth: 140,
                          position: "relative",
                          border: "none",
                          backgroundColor: isCurrentValue
                            ? "#F0F3FC"
                            : "transparent",
                        }}
                        menuPortalTarget={document.body}
                        sizing={"md"}
                        className="group-hover:bg-primary-07"
                        hoverStyle={{
                          backgroundColor: "#F0F3FC",
                          border: "none",
                          color: "#0A8BFE",
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    onClick={() => selectOption(item)}
                    className={`${
                      selectedOptions.title === item.title &&
                      "bg-primary-07 shadow-sm rounded-md  isParaphrasingOptionSelected"
                    }  paraphrase-option-header  group hover:shadow-sm text-neutral-04  hover:bg-primary-07 p-2 px-4 cursor-pointer flex items-center hover:rounded-md`}
                  >
                    <span className="mr-3">
                      <Icon />
                    </span>
                    <p
                      className={`${
                        selectedOptions.title === item.title && "text-brand-02"
                      } text4 mb-0  group-hover:text-brand-02`}
                    >
                      {item.title}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* section 2 */}
            <div
              className={` h-[90%]   im:flex im:h-full  ${
                user?.plan_type === "Free" ? "im:h-[340px]" : "im:h-[500px]"
              } im:border-t-[1px] im:border-solid im:auto  w-auto border-none  h-full`}
            >
              <div className="shadow-sm w-auto im:w-[50%] max-h-[50%] im:max-h-full im:h-full im:border-solid im:border-[#F2F3F5] min-h-[50%] text-sm-Regular  flex flex-col ">
                <textarea
                  style={{ resize: "none" }}
                  {...register("text")}
                  placeholder="Let's rewrite your text. Start by writing or paste something here and click on the rephrase button."
                  className={`placeholder:text-neutral-02 pb-3 flex-1 flex-grow ${
                    user?.plan_type === "Free" ? "im:h-[280px]" : "im:h-[440px]"
                  }  max-h-full  text4 w-full p-4 bg-transparent outline-none im:border-none border border-[#DDDDDD] rounded "`}
                />
                <div className="relative w-full im:px-2 h-[50px] justify-between items-center im:border-t-[1px] flex im:border-solid im:border-[#F2F3F5]">
                  <div
                    style={{
                      width: wordCounterWidth(user?.plan_type as PlanType),
                    }}
                    className={`h-[3px] rounded-sm absolute ${
                      hasPassedWordLimit({
                        planType: user?.plan_type as PlanType,
                      })
                        ? "bg-red-400"
                        : "bg-brand"
                    } left-0 right-0 top-0 z-10 widget-paraphrase`}
                  />
                  <div
                    className={`flex w-full rounded-[5px] border-none justify-center im:w-auto bg-brand-02  im:mr-2  ${
                      isButtonDisabled &&
                      "bg-[#6466724d] w-full border-2 border-red-500"
                    }`}
                  >
                    <Button
                      disabled={isButtonDisabled}
                      onClick={handleSubmit((data) => {
                        submitQuery(data, {
                          title: selectedOptions.title,
                          template_name: selectedOptions.template_name,
                          mode: selectedOptions.mode,
                        });
                      })}
                      className={`flex justify-center items-center outline-none h5RegularBold  h-[34px] im:w-[134px]  bg-brand-02  min-w-full ${
                        isButtonDisabled && "bg-[#6466724d] w-full "
                      }`}
                    >
                      Paraphrase
                    </Button>
                  </div>

                  <div className=" w-[100vw] items-center bg-white flex justify-between bottom-0 left-0 fixed px-6 py-2 md:pl-[287px] im:relative im:p-0">
                    {isParaphrasingPage && user?.plan_type !== "Business" && (
                      <p
                        className="py-0 w-full text-center cursor-pointer pr-2 m-0 text4"
                        onClick={() => navigate("/pricing")}
                      >
                        <span className="text4  w-full flex-1 text-brand-09 im:text-brand-02">
                          Get Business
                        </span>{" "}
                        to rewrite more than 1000+ words.
                      </p>
                    )}
                    <p
                      className={`text5 ${
                        user?.plan_type === "Business" ? "w-full" : "w-[25%]"
                      } text-right ${
                        words >
                        (inputWordLimit as any)[user?.plan_type as PlanType]
                          ? "text-red-500"
                          : "text-neutral-05"
                      }  mb-0`}
                    >
                      {words}
                      {user?.plan_type !== "Business" &&
                        `/${
                          (inputWordLimit as any)[user?.plan_type as any]
                        }`}{" "}
                      words
                    </p>
                    {/* {user?.plan_type.toLowerCase() === "free" ? (
              ) : (
                <p className={`text4 text-neutral-05mb-0  pr-4`}>
                  {words} - words
                </p>
              )} */}
                  </div>
                </div>
              </div>
              <div
                className={`flex flex-col im:pb-0 w-full im:w-[50%] bg-[#FAFAFA] h-auto items-center overflow-y-auto  ${
                  user?.plan_type === "Free" ? "im:h-[340px]" : "im:h-[500px]"
                } im:overflow-y-auto min-h-[50%] h-[60%] `}
              >
                {state === "idle" && (
                  <div className="flex items-center justify-center w-full h-full p-3 ">
                    <div className="rounded-md shadow flex items-start bg-white max-w-[280px] m-auto p-3  ">
                      <Lightning className="w-5 mr-3" />
                      <p className="m-0 text-grey-004 text-sm-Regular btn2">
                        Answer the prompts on the left to see your AI content
                        show up here.
                      </p>
                    </div>
                  </div>
                )}
                {state !== "loading" && state !== "idle" && (
                  <div className="im:h-full w-full max-h-full paraphrase-result">
                    {(generateTextAI || (results && results.length > 0)) && (
                      <div className="h-full pt-5">
                        {Boolean(results && results.length) && (
                          <div className="flex px-4 pb-2  w-full justify-between items-center im:hidden">
                            <p className="text-grey-004">Result</p>
                            <CopyIcon
                              id="paraphrase-copy-icon"
                              onClick={() => {
                                onCopy(result.text);
                                setCopyToolTip({
                                  index: result.text,
                                  show: true,
                                  text: "Copied",
                                });
                              }}
                              className="w-4 h-4 copy-icon-blue"
                            />
                          </div>
                        )}
                        <div className="flex flex-col justify-between im:h-full max-h-full overflow-y-auto">
                          <div className="flex max-h-full justify-start im:max-h-full overflow-y-auto">
                            <p
                              className={`px-4 whitespace-pre-line text4 p-0 pb-5 m-0 w-full ${
                                user?.plan_type === "Free"
                                  ? "im:h-[280px]"
                                  : "im:h-[420px]"
                              }`}
                              style={{
                                wordBreak: "break-word",
                                paddingBottom: 12,
                              }}
                            >
                              {getDiffWords({
                                compareText: canTokenStream()
                                  ? tokenGeneratedResult
                                  : result.text || "",
                                baseText: watch("text") || "",
                                chunkByPhrase: false,
                                highlightClassName: "text-brand-02",
                              })}
                              {state === "loading_more" && (
                                <div className="w-[96%] mt-2">
                                  <Skeleton
                                    style={{
                                      marginBottom: 0,
                                      marginRight: 32,
                                      borderRadius: 10,
                                    }}
                                    containerClassName="overflow-hidden flex  w-full max-w-[100%] flex-wrap"
                                    width={"100%"}
                                    height={20}
                                    count={2}
                                  />
                                </div>
                              )}
                            </p>
                            <Tippy
                              zIndex={100}
                              delay={[300, 0]}
                              placement="top-start"
                              content={
                                copyToolTip?.show && (
                                  <p className="p-1 m-0 text-white rounded-md bg-neutral-800 btn4">
                                    {copyToolTip?.text || "Copy"}
                                  </p>
                                )
                              }
                            >
                              <div
                                onMouseEnter={() => {
                                  setCopyToolTip({
                                    index: result.id,
                                    show: true,
                                    text: "Copy",
                                  });
                                }}
                                onMouseLeave={() => {
                                  setCopyToolTip({
                                    index: result.id,
                                    show: false,
                                    text: copyToolTip?.text || "Copy",
                                  });
                                }}
                                className="z-10 pr-4 cursor-pointer hidden im:flex justify-start items-start"
                              >
                                <CopyIcon
                                  id="paraphrase-copy-icon"
                                  onClick={() => {
                                    onCopy(tokenGeneratedResult || result.text);
                                    setCopyToolTip({
                                      index:
                                        tokenGeneratedResult || result.text,
                                      show: true,
                                      text: "Copied",
                                    });
                                  }}
                                  className="w-4 h-4 copy-icon-blue"
                                />
                              </div>
                            </Tippy>
                          </div>
                          <div className="hidden px-4 py-[15px] h-[50px] w-full justify-between items-center border-y-[1px] bg-white im:flex border-solid border-[#F2F3F5] im:border-t-[1px] ">
                            <div className="flex">
                              {/* <p className="pr-1 mb-0 text4 text-neutral-05">
                                {_.words(result.text).length} words
                                
                              </p>
                              <p className="pr-4 mb-0 text4 text-neutral-05">
                                - {result.text && result.text.split(".").length}{" "}
                                Sentences
                              </p> */}
                            </div>
                            {/* <div className="flex items-center justify-center chevron-icons">
                        <div className="flex items-center justify-center  cursor-pointer border-solid border-[1px] rounded-md  border-[#A3AED0]">
                          <PrevIcon className={` w-4 h-4 `} onClick={onPrev} />
                        </div>
                        <p className="mx-3 my-0 text4 text-neutral-05">
                          {currentResult + 1}/{results.length}
                        </p>
                        <div className="flex items-center justify-center cursor-pointer border-solid border-[1px] rounded-md  border-[#A3AED0]">
                          <NextIcon className={`w-4 h-4`} onClick={onNext} />
                        </div>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {state === "loading" && (
                  <div className="w-[96%] pl-2 mt-2">
                    <Skeleton
                      style={{
                        marginBottom: 0,
                        marginRight: 32,
                        borderRadius: 10,
                      }}
                      containerClassName="overflow-hidden flex  w-full max-w-[100%] flex-wrap"
                      width={"100%"}
                      height={24}
                      count={4}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <PricingModal
            user={user}
            onClose={() => {
              setPricingModalData({ ...pricingModal, isOpen: false });
            }}
            featurePlanType={pricingModal.featureUserAttempted || "Free"}
            isOpen={pricingModal.isOpen}
          />
          {hasPassedWordLimit({ planType: user?.plan_type as PlanType }) && (
            <p className="mt-2 text-red-500 text4">
              You've exceeded the word limit for your{" "}
              <span
                onClick={() => navigate("/pricing")}
                className="underline cursor-pointer text-primary-01"
              >
                current plan
              </span>
            </p>
          )}
          {state === "error" && (
            <p className="mt-2 text-red-500 text4 flex flex-wrap z-[1000] bg-white">
              Oops! Something went wrong. Can you hit create again?
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Paraphrase;

import { useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../../axios";
import _ from "lodash";
import { toast } from "react-toastify";

export type Category =
  | "general"
  | "academic_life"
  | "ads_and_marketing"
  | "blogging"
  | "business"
  | "ecommerce"
  | "email_writing"
  | "website_copy"
  | "youtube_and_social_media";

export type CustomizeTab = "intro" | "step_1" | "step_2" | "step_3" | "results";

export const steps: { step: string; value: CustomizeTab; title: string }[] = [
  { step: "Intro", value: "intro", title: "Who Is Your Persona?" },
  {
    step: "Step 1",
    value: "step_1",
    title: "1. Add Your First Writing Example Below",
  },
  {
    step: "Step 2",
    value: "step_2",
    title: "2. Add Your Second Writing Example Below",
  },
  { step: "Step 3", value: "step_3", title: "3. Add Your Third Example Below" },
  {
    step: "Results",
    value: "results",
    title: "Your Customized AI Persona Library",
  },
];

export type FormValues = {
  customization_name: string;
  text_1: string;
  text_2: string;
  text_3: string;
  category: Category;
  description: string;
};

export type State = {
  currentTab: {
    tab: CustomizeTab;
    isOpen: boolean;
  };
  status: LoadingStatus;
  customizations: any;
  formValues: FormValues;
  currentPersona: any;
  completedSteps: {
    intro: boolean;
    step_1: boolean;
    step_2: boolean;
    step_3: boolean;
    results: boolean;
  };
};

type Action =
  | {
      type: "CHANGE_TAB";
      payload: { tab: CustomizeTab; isOpen: boolean };
    }
  | {
      type: "SET_STATUS";
      payload: LoadingStatus;
    }
  | {
      type: "SET_CUSTOMIZATIONS";
      payload: any;
    }
  | { type: "CHANGE_FORM_VALUES"; payload: FormValues }
  | { type: "SET_CURRENT_PERSONA"; payload: any }
  | {
      type: "SUBMIT_FORM";
      payload: {
        completedStep: CustomizeTab;
        formValues: FormValues;
        nextTab: CustomizeTab;
      };
    };

export type LoadingStatus =
  | "loading"
  | "submitting"
  | "idle"
  | "error"
  | "success";

const initialState: State = {
  currentTab: {
    tab: "intro",
    isOpen: true,
  },
  completedSteps: {
    intro: false,
    step_1: false,
    step_2: false,
    step_3: false,
    results: false,
  },
  formValues: {
    text_1: "",
    text_2: "",
    text_3: "",
    category: "general",
    description: "",
    customization_name: "",
  },
  currentPersona: null,
  status: "loading",
  customizations: [],
};

const submitPersonaForm = (data: any) => {
  return axiosClient({
    method: "post",
    url: "/internal/v1/text_analytics/customize_ai",
    data,
  });
};

const fetchPersonas = async () => {
  const res = await axiosClient({
    method: "get",
    url: `/internal/v1/text_analytics/customize_ai`,
  });
  return res;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CHANGE_TAB":
      return {
        ...state,
        currentTab: action.payload,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "CHANGE_FORM_VALUES":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload,
        },
      };
    case "SET_CUSTOMIZATIONS":
      return {
        ...state,
        customizations: [...action.payload],
      };
    case "SET_CURRENT_PERSONA":
      return {
        ...state,
        currentPersona: action.payload,
      };
    case "SUBMIT_FORM":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload.formValues,
        },
        currentTab: {
          ...state.currentTab,
          tab: action.payload.nextTab,
          isOpen: true,
        },
        completedSteps: {
          ...state.completedSteps,
          [action.payload.completedStep]: true,
        },
      };
    default:
      return state;
  }
};

const useCustomizeAI = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [deleteModal, toggleModal] = useState({
    id: null,
    show: false,
  });
  const pathname = useLocation().pathname;
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "SET_STATUS", payload: "loading" });
        const res = await fetchPersonas();
        const customizations = res.data.customizations;
        if (params.personaId) {
          const persona = res.data.customizations.find(
            (item: any) => item.customization_id === params.personaId
          );
          dispatch({ type: "SET_CURRENT_PERSONA", payload: persona });
        } else {
          dispatch({ type: "SET_CUSTOMIZATIONS", payload: customizations });
        }
        dispatch({ type: "SET_STATUS", payload: "success" });
      } catch (err) {
        toast.error(
          "Something went wrong. Please refresh again or check your network"
        );
        dispatch({
          type: "SET_STATUS",
          payload: "error",
        });
      }
    };
    fetchData();
  }, []);

  const submitForm = async (data: any) => {
    try {
      dispatch({ type: "SET_STATUS", payload: "submitting" });
      const res = await submitPersonaForm({
        category: data.category,
        default: false,
        persona_name: data.customization_name,
        persona_bio: data.description,
        text_samples: {
          text_1: data.text_1,
          text_2: data.text_2,
          text_3: data.text_3,
        },
      });
      dispatch({ type: "SET_STATUS", payload: "success" });
      navigate(
        `/user/dashboard/customize-your-ai/persona/${res.data.customization_results.customization_id}`
      );
    } catch (err) {
      dispatch({
        type: "CHANGE_TAB",
        payload: { isOpen: true, tab: "step_3" },
      });
      dispatch({ type: "SET_STATUS", payload: "error" });
      toast.error("Something went wrong. Please try again.");
      console.log(err, "error submitting persona form");
    }
  };

  const setAsDefault = async (id: string, isDefault: boolean) => {
    try {
      console.log(isDefault, "is default");
      toast.loading("Updating your default persona. Please wait...");
      const res = await axiosClient({
        method: "put",
        url: `/internal/v1/text_analytics/customize_ai`,
        data: { customization_id: id, default: !isDefault },
      });
      const updatedData = state.customizations.map((item: any) => {
        if (item.customization_id === id) {
          return { ...item, default: !isDefault };
        } else return item;
      });
      dispatch({ type: "SET_CUSTOMIZATIONS", payload: updatedData });
      dispatch({
        type: "SET_CURRENT_PERSONA",
        payload: state.currentPersona && {
          ...state.currentPersona,
          default: !isDefault,
        },
      });
      toast.dismiss();
      toast.success("Updated your default AI persona.");
    } catch (err) {
      toast.dismiss();
      toast.error("Something went wrong. Please try again.");
      console.log(err);
    }
  };

  const deletePersona = async () => {
    try {
      toast.loading("Deleting your persona. Please wait...");
      await axiosClient({
        method: "delete",
        url: `/internal/v1/text_analytics/customize_ai`,
        data: {
          customization_id: deleteModal.id,
        },
      });
      toggleModal({ id: null, show: false });
      dispatch({
        type: "SET_CUSTOMIZATIONS",
        payload: state.customizations.filter(
          (item: any) => item.customization_id !== deleteModal.id
        ),
      });
      if (pathname !== "/user/dashboard/customize-your-ai") {
        navigate("/user/dashboard/customize-your-ai");
      }
      toast.dismiss();
      toast.success("Your AI persona is deleted.");
    } catch (err) {
      toast.dismiss();
      toast.error("Something went wrong. Please try again.");
      console.log(err);
    }
  };

  return {
    state,
    dispatch,
    submitForm,
    fetchPersonas,
    deletePersona,
    setAsDefault,
    deleteModal,
    toggleModal,
  };
};

export default useCustomizeAI;

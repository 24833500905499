import React from "react";
import logo from "../assets/images/logo.png";

const Logo = () => {
  return (
    <img
      className="aspect-auto w-[134px] h-[24px]"
      alt="textcortex"
      src={logo}
    />
  );
};

export default Logo;

import React, { useEffect, useState } from "react";
import { ReactComponent as ChevronLeft } from "../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg";

export const months = [
  { name: "January", number: 1 },
  { name: "February", number: 2 },
  { name: "March", number: 3 },
  { name: "April", number: 4 },
  { name: "May", number: 5 },
  { name: "June", number: 6 },
  { name: "July", number: 7 },
  { name: "August", number: 8 },
  { name: "September", number: 9 },
  { name: "October", number: 10 },
  { name: "November", number: 11 },
  { name: "December", number: 12 },
];

type Props = {
  onChange: (value: any) => void;
  value: number;
};
const MonthSelector = ({ onChange, value }: Props) => {
  const [selectedMonth, setSelectedMonth] = useState<any>({
    currentMonth: {},
    months: months,
  });

  useEffect(() => {
    const currentMonth = value;
    setSelectedMonth({
      currentMonth: months[currentMonth - 1],
      months: months,
    });
  }, [value]);

  const onPrev = () => {
    const currentMonth = selectedMonth.currentMonth.number;
    setSelectedMonth({
      ...selectedMonth,
      currentMonth: selectedMonth.months[currentMonth - 2],
    });
    onChange(selectedMonth.months[currentMonth - 2]);
  };

  const onNext = () => {
    const currentMonth = selectedMonth.currentMonth.number;
    setSelectedMonth({
      ...selectedMonth,
      currentMonth: selectedMonth.months[currentMonth],
    });
    onChange(selectedMonth.months[currentMonth]);
  };

  // const isLastMonth =
  //   selectedMonth.currentMonth.number === selectedMonth.months.length;
  // const isFirstMonth = selectedMonth.currentMonth.number === 1;
  const thisMonth = new Date().getMonth() + 1;

  return (
    <div className="flex items-center">
      <ChevronLeft className={`cursor-pointer w-5 h-5 `} onClick={onPrev} />
      <p className="h3 mx-1">{selectedMonth.currentMonth.name}</p>
      <ChevronRight
        className={`cursor-pointer w-5 h-5 mt-[2px]`}
        onClick={() => {
          if (thisMonth !== selectedMonth?.currentMonth?.number) onNext();
        }}
      />
    </div>
  );
};

export default MonthSelector;

import React, { ReactNode, useEffect, useState } from "react";
import { ReactComponent as UpgradeIcon } from "../../assets/icons/upgrade.svg";
import { ReactComponent as ReferralIcon } from "../../assets/icons/referral.svg";
import { ReactComponent as GoogleChromeIcon } from "../../assets/icons/google-chrome.svg";
import { ReactComponent as VideoReviewIcon } from "../../assets/icons/video-review.svg";
import { ReactComponent as G2CrowdIcon } from "../../assets/icons/g2-crowd.svg";
import { ReactComponent as CapterraIcon } from "../../assets/icons/capterra.svg";
import { ReactComponent as TrustPilotIcon } from "../../assets/icons/trust-pilot.svg";
import { ReactComponent as TrustRadiusIcon } from "../../assets/icons/trust-radius.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";
import { ReactComponent as FacebookCommunityIcon } from "../../assets/icons/facebook-community.svg";
import { ReactComponent as CheersIcon } from "../../assets/icons/cheers.svg";
import { ReactComponent as FireIcon } from "../../assets/icons/fire.svg";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";
import { ReactComponent as GradIcon } from "../../assets/icons/grad.svg";
import { ReactComponent as GiftIcon } from "../../assets/icons/gift.svg";

import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import Checkbox from "../../components/checkbox";
import NotificationModal from "../../components/notificationModal";
import WriteReviewModal from "../../components/writeReviewModal";
import InviteFriendsModal from "../../components/InviteFriendsModal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axiosClient from "../../axios";
import { InviteHistory } from "../../hooks/useInviteFriends";
import { extensionLink } from "../../utils/helpers";
import Tippy from "@tippyjs/react";
import { useAuth } from "../../context/auth/authContext";
import { Helmet } from "react-helmet";
import { getShopifyCharge } from "../../context/auth/actions";
import { FaVideo } from "react-icons/fa";

type StepText = {
  title: string;
  buttonText: string;
};

export type ModalText = {
  mainTitle: string;
  stepOne: StepText;
};

export type EarnItem = {
  title: string;
  description: string | ReactNode;
  icon: any;
  buttonText: string;
  credits?: any;
  buttonAction?: string;
  earnType?: string;
  url?: string;
  buttonClassName?: string;
  modalText?: ModalText;
};

type GamificationStatus = {
  auth_id: string;
  capterra_review: boolean;
  created_date: string;
  g2_review: boolean;
  id: number;
  installed_extension: boolean;
  join_discord: boolean;
  join_facebook: boolean;
  trustpilot_review: boolean;
  trustradius_review: boolean;
  updated_date: string;
  video_review: boolean;
  invite_history: InviteHistory[];
};

const EarnMore = () => {
  const auth = useAuth();
  const [credits, setCredits] = useState(auth.state.user?.credit);
  const [showSuccessModal, toggleSuccessModal] = useState(false);
  const [showOutOfCreditModal, toggleOutOfCreditModal] = useState(false);
  const [showReviewModal, toggleReviewModal] = useState(false);
  const [activeItem, setActiveItem] = useState<EarnItem>();
  const [showInviteFriendsModal, toggleInviteFriendsModal] = useState(false);
  const [gamificationStatus, setGemificationStatus] =
    useState<GamificationStatus>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isShopify = auth?.state?.user?.shopify;
  const user = auth.state.user;

  const pageData: EarnItem[] = [
    {
      title: "Go unlimited with our plans",
      description:
        "Choose your plan and write marketing, sales and website copy in seconds.",
      icon: UpgradeIcon,
      buttonText: "View Plans",
      buttonClassName: "bg-brand-02 text-white",
      buttonAction: "upgradePlan",
    },
    {
      title: "Download the chrome extension & signup to text.cortex",
      description:
        "text.cortex superpowers on over 1000 webpages and textboxes",
      icon: GoogleChromeIcon,
      credits: (
        <span>
          unlocks global <b>superpowers</b>
        </span>
      ),
      buttonText: "Install extension",
      earnType: "installed_extension",
      url: "https://chrome.google.com/webstore/detail/textcortex-ai-writing-ass/hahkojdegblcccihngmgndhdfheheofe?utm_source=reward_center",
    },
    {
      title: "Refer your friends and get $50 off!",
      description:
        "Get $50 off on any plan for just referring your friends a Pro subscription for 14 days.",
      icon: GiftIcon,
      credits: (
        <span>
          become the <b>MVP</b> in your friend circle
        </span>
      ),
      buttonText: "Get $50 off",
      buttonAction: "redirectToExternalLink",
      earnType: "referral",
      url: `https://tell.textcortex.com/refer-your-friends?email=${user?.email}&plan_type=${user?.plan_type}`,
    },

    {
      title: "Send and post a video testimonial",
      description:
        "Get a free one month business subscription for your video testimonial",
      icon: VideoReviewIcon,
      credits: (
        <span>
          One-month <b>Business</b> for free ($79.99)
        </span>
      ),
      buttonText: "Send a video",
      url: `https://tell.textcortex.com/video-review?email=${user?.email}&plan_type=${user?.plan_type}`,
      earnType: "video_review",
    },
    {
      title: "Invite more friends to earn extra daily creations",
      description:
        "Invite friends to get 10 extra daily creations & create high-quality copies together.",
      icon: ReferralIcon,
      credits: 10,
      buttonText: "Invite friends",
      buttonAction: "openInviteFriendsModal",
      earnType: "referral",
    },
    {
      title: "Review us on G2, Capterra, TrustPilot or TrustRadius",
      description:
        "Get 5 daily creations for each platform by writing a review and then uploading a screenshot here.",
      icon: G2CrowdIcon,
      buttonText: "Write a review",
      credits: 5,
      buttonAction: "redirectToExternalLink",
      earnType: "g2_review",
      url: `https://tell.textcortex.com/get-daily-creations-with-reviews?email=${user?.email}&plan_type=${user?.plan_type}`,
    },
    {
      title: "Introduce yourself in Discord",
      description:
        "Get 5 extra daily creations by joining our Discord community, #introduce-yourself and upload a screenshot.",
      icon: DiscordIcon,
      buttonText: "Join Discord",
      credits: 5,
      buttonAction: "redirectToExternalLink",
      url: `https://tell.textcortex.com/introduce-yourself?email=${user?.email}&plan_type=${user?.plan_type}`,
    },
    {
      title: 'Join our Webinar “Beginner’s Belt - Copywriting excellence"',
      description:
        "Get 5 extra daily creations and learn how to use AI tools to your advantage",
      icon: () => <FaVideo color="#0A8BFE" size={32} />,
      credits: 5,
      buttonText: "Join Webinar",
      buttonAction: "redirectToExternalLink",
      url: `https://tell.textcortex.com/webinar-signup?email=${user?.email}&plan_type=${user?.plan_type}`,
    },
    {
      title: "Graduate from the Cortex Creator Academy",
      description: "Get 5 extra daily creations by finishing our (CCA)",
      icon: GradIcon,
      credits: 5,
      buttonText: "Start Academy",
      buttonAction: "redirectToExternalLink",
      url: `https://tell.textcortex.com/cortex-academy?email=${user?.email}&plan_type=${user?.plan_type}`,
    },
  ];

  const openUploadModal = (earnItem: EarnItem) => {
    setActiveItem(earnItem);
    toggleReviewModal(true);
  };

  const redirectToExternalLink = (item: EarnItem) => {
    window.open(item.url, "_blank");
  };

  const buttonActions: { [key: string]: (earnItem: EarnItem) => void } = {
    openInviteFriendsModal: () => toggleInviteFriendsModal(true),
    openUploadModal,
    redirectToExternalLink,
    upgradePlan: isShopify
      ? () => redirectToShopifyPayment()
      : () => navigate("/pricing"),
  };

  const redirectToShopifyPayment = async () => {
    try {
      getShopifyCharge();
    } catch (err) {
      console.log(err);
    }
  };

  // const fetchGamificationStatus = async () => {
  //   try {
  //     const res = await axiosClient("accounts/gamification_status");
  //     if (res.data.status === "success") {
  //       setGemificationStatus({
  //         invite_history: res.data.invite_history,
  //         ...res.data.gamification_data,
  //       });
  //     }
  //   } catch (error) {
  //     console.log("error: ", error);
  //   }
  // };

  useEffect(() => {
    const out_of_credit = searchParams.get("out_of_credit");
    if (out_of_credit) {
      toggleOutOfCreditModal(true);
    } else {
      toggleOutOfCreditModal(false);
    }
    // fetchGamificationStatus();
    // (window as any).chrome?.runtime?.sendMessage(
    //   "kaffbmdkkagajhknnbgkpgineiohpoph",
    //   { payload: {}, type: "KKKKKK" },
    //   function (response: any) {
    //     console.log("extension response", response);
    //   }
    // );
  }, []);

  const isItemLink = (item: EarnItem) => {
    return (
      item.earnType === "video_review" ||
      item.earnType === "installed_extension"
    );
  };

  const itemIsRedeemed = (
    item: EarnItem,
    gamificationStatus: GamificationStatus | undefined
  ) => {
    if (
      item.earnType === "referral" &&
      gamificationStatus?.invite_history &&
      gamificationStatus?.invite_history.length >= 10
    ) {
      return true;
    }
    if ((gamificationStatus as any)?.[item.earnType as any]) {
      return true;
    }
  };

  return (
    <div className="p-8 pt-0">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Earn Creations</title>
      </Helmet>
      <div className="w-full mt-5">
        <h1
          className={`h1 ${
            auth.isSidebarOpen ? "text-left" : "text-center"
          } w-full text-primary-02`}
        >
          Reward Center
        </h1>
        <p
          className={`text2 ${
            auth.isSidebarOpen ? "text-left" : "text-center"
          } text-grey-02`}
        >
          Upgrade your plan, invite friends, and install our chrome extension to
          rewrite your content and level up your writing.
        </p>
      </div>
      <div className="px-6 py-4 border rounded my-9">
        <div className="flex items-center border-bottom pb-5 justify-between mb-3">
          <h1 className="h2 text-primary-02">Reward Center Quests</h1>
          <div className="flex w-[200px]">
            <h1 className="h2 text-primary-02">Reward</h1>
          </div>
        </div>
        <div className="">
          {pageData.map((item, index) => {
            const isLastItem = index === pageData.length - 1;
            const Icon = item.icon;
            return (
              <div
                className={`mb-4 pb-3  flex justify-between items-center ${
                  !isLastItem && "border-bottom"
                }`}
              >
                <div className="flex items-center w-full">
                  <Icon className="w-8 h-8" />
                  <div className="flex flex-col ml-3">
                    <p className="text2 text-primary-02">{item.title}</p>
                    <p className="text3 text-grey-02 max-w-[500px] w-full">
                      {item.description}
                    </p>
                  </div>
                </div>
                <div className="w-full flex items-center justify-end">
                  {item.credits && (
                    <div className="flex items-center mr-6">
                      <span className="mr-2 text2 text-primary-02">
                        {item.credits}
                      </span>
                      {typeof item.credits === "number" && <CoinIcon />}
                      <Tippy
                        interactive={true}
                        interactiveBorder={40}
                        content={
                          <button className="py-[2px] cursor-default px-1 text4 text-grey-02 rounded bg-white border border-grey-09 shadow-[0px 4px 6px -2px rgba(0, 0, 0, 0.06)]">
                            Daily recurring creations
                          </button>
                        }
                        placement="left"
                      >
                        <RefreshIcon className="w-3 h-3 ml-3" />
                      </Tippy>
                      {/* <Checkbox
                        handleCheckbox={(val) => {
                          // console.log(val);
                        }}
                        name="checked"
                        className="mt-2 ml-3 checkbox-small"
                        value={itemIsRedeemed(item, gamificationStatus)}
                      /> */}
                    </div>
                  )}

                  <a
                    href={isItemLink(item) ? item.url : undefined}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <button
                      disabled={itemIsRedeemed(item, gamificationStatus)}
                      onClick={
                        item.buttonAction
                          ? () => buttonActions[item.buttonAction || ""](item)
                          : () => {}
                      }
                      className={`min-w-[148px] text2 text-grey-02 border rounded px-4 py-1 ${item?.buttonClassName}`}
                    >
                      {itemIsRedeemed(item, gamificationStatus)
                        ? "Earned!"
                        : item.buttonText}
                    </button>{" "}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <NotificationModal
        icon={() => <CheersIcon />}
        title={
          <div className="flex items-center">
            <p className="mr-1 h3">
              Congrats! You just won {activeItem?.credits}
            </p>
            <CoinIcon className="" />
          </div>
        }
        link={"/user/dashboard/earn-more"}
        description={"Your AI Partner is ready to write more."}
        buttonText={"Create text"}
        closeModal={() => toggleSuccessModal(false)}
        showModal={showSuccessModal}
        data={{
          creditsWon: activeItem?.credits,
        }}
      />
      <NotificationModal
        icon={() => <FireIcon />}
        titleClassName="flex w-full text-center h1 justify-center"
        title={"Daily creations limit reached"}
        description={
          "You're left with 0 creations for today. You'll get 10 more tomorrow"
        }
        link={"/pricing"}
        buttonText={"Go unlimited"}
        closeModal={() => {
          navigate("/user/dashboard/earn-more");
          toggleOutOfCreditModal(false);
        }}
        showModal={showOutOfCreditModal}
        buttonAction={() => navigate("/pricing")}
        footer={
          <span className="text2 mt-4 inline-block w-full text-center text-grey-02">
            Go unlimited with a $50 off{" "}
            <a
              target="_blank"
              href="https://tell.textcortex.com/refer-your-friends"
              className="underline"
            >
              by gifting your friends
            </a>
          </span>
        }
      />
      <WriteReviewModal
        showModal={showReviewModal}
        closeModal={() => toggleReviewModal(false)}
        item={activeItem}
        textData={activeItem?.modalText}
        onSuccess={() => {
          toggleReviewModal(false);
          toggleSuccessModal(true);
          if (activeItem) {
            setCredits((prev) => (prev || 0) + (activeItem?.credits || 0));
            setGemificationStatus((prev) => ({
              ...(prev as any),
              [activeItem.earnType as any]: true,
            }));
          }
        }}
      />
      <InviteFriendsModal
        closeModal={() => toggleInviteFriendsModal(false)}
        showModal={showInviteFriendsModal}
      />
    </div>
  );
};

export default EarnMore;

import { IconProps } from "../../types";
import React from "react";

const Expand = (props: IconProps) => {
  return (
    <svg
      width="22"
      height="9"
      viewBox="0 0 22 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.84623 0.5L1.00008 4.5L4.84623 8.5L4.84623 6.5L8.37187 6.5C8.45688 6.5 8.5384 6.46488 8.59851 6.40237C8.65861 6.33986 8.69238 6.25507 8.69238 6.16667L8.69238 2.83333C8.69238 2.74493 8.65861 2.66014 8.59851 2.59763C8.5384 2.53512 8.45688 2.5 8.37187 2.5L4.84623 2.5L4.84623 0.5Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.1538 8.5L20.9999 4.5L17.1538 0.5L17.1538 2.5L13.6281 2.5C13.5431 2.5 13.4616 2.53512 13.4015 2.59763C13.3414 2.66014 13.3076 2.74493 13.3076 2.83333L13.3076 6.16667C13.3076 6.25507 13.3414 6.33986 13.4015 6.40237C13.4616 6.46488 13.5431 6.5 13.6281 6.5L17.1538 6.5L17.1538 8.5Z"
        stroke={props.stroke ? props.stroke : "#5B6772"}
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Expand;

import React from "react";
import AuthProvider from "./context/auth/authContext";
import Router, { history } from "./router";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import mixpanel from "mixpanel-browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getLoginCred } from "./utils/getLoginCred";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
  debug: process.env.NODE_ENV !== "production",
});

const App = () => {
  return (
    <div className="max-h-[100dvh]">
      <Elements stripe={stripePromise}>
        <GoogleReCaptchaProvider
          scriptProps={{ async: true }}
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
        >
          <GoogleOAuthProvider clientId={getLoginCred("google")}>
            <HistoryRouter history={history}>
              <AuthProvider>
                <Router />
              </AuthProvider>
              <ToastContainer closeButton />
            </HistoryRouter>
          </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
      </Elements>
    </div>
  );
};

export default App;

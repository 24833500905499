import React, { useEffect, useRef, useState } from "react";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/toolbarPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { ReactComponent as ZenoIcon } from "../../assets/icons/zeno_logo.svg";
import { ReactComponent as KeyReturn } from "../../assets/icons/key_return.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";

import ListMaxIndentLevelPlugin from "./plugins/listMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/codeHighlightPlugin";
import AutoLinkPlugin from "./plugins/autoLinkPlugin";
import { useLexicalIsTextContentEmpty } from "@lexical/react/useLexicalIsTextContentEmpty";

import "./themes/styles.css";
import Header from "./header";
import { useMediaQuery } from "react-responsive";
import DraggableBlockPlugin from "./plugins/draggableBlockPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import useEditor from "./useEditor";
import { MyCustomAutoFocusPlugin } from "./plugins/customFocusPlugin";
import { StateHandlerPlugin } from "./plugins/stateHandlerPlugin";
import DPU from "../DPU";
import { useParams, useSearchParams } from "react-router-dom";
import { getDocument } from "../../screens/dashboard/creator/actions";
import { useAuth } from "../../context/auth/authContext";

function Placeholder() {
  return (
    <div className="rounded-md flex items-center cursor-pointer justify-between w-full">
      <div className="flex items-center">
        <ZenoIcon className="stroke-gray-400 mr-2" />
        <p className="h2 text-gray-400">Let's start writing here...</p>
      </div>
      {/* <div className="flex items-center">
        <KeyReturn className="mr-2" />
        <p className="text-gray-400">"Create"</p>
      </div> */}
    </div>
  );
}

function Editor() {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  useState<boolean>(false);
  const [editor] = useLexicalComposerContext();
  const {
    state,
    dispatch,
    saveEditorState,
    dumpTextToEditor,
    saveDocumentTitle,
    titleRef,
    resetDocument,
  } = useEditor(editor);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1300px)" });
  const { isSidebarOpen: isMainSidebarOpen, toggleSidebar: toggleMainSidebar } =
    useAuth();
  const isEmpty = useLexicalIsTextContentEmpty(editor);
  const isSmallWidthViewport = useMediaQuery({ query: "(max-width: 860px)" });
  const [showSidebar, toggleSidebar] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.get("ai-editor") ? toggleSidebar(false) : toggleSidebar(true);
    toggleMainSidebar(false);
  }, []);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onChange = () => {
    editor.getEditorState().read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS);
      saveEditorState(markdown);
    });
  };

  const onEditorKeyDown = (e: any) => {
    if ((e.key === "Backspace" || e.key === "Delete") && isEmpty) {
      e.preventDefault();
      (titleRef.current as any).focus();
      dispatch({
        type: "CHANGE_EDITOR_FOCUS",
        payload: "TITLE_EDITOR",
      });
    }
  };

  const onTitleKeyDown = (e: any) => {
    if (e.shiftKey) {
      return;
    } else if (e.key === "Enter") {
      e.preventDefault();
      dispatch({
        type: "CHANGE_EDITOR_FOCUS",
        payload: "MAIN_EDITOR",
      });
    }
  };

  return (
    <div className={`flex relative bg-white w-full min-h-[80vh] h-auto m-0`}>
      <div
        className={`relative  max-w-[850px] ${
          !isMainSidebarOpen ? "mx-auto pl-[3%]" : "px-[5.5%]"
        } w-full   lg:max-w-[1200px]`}
      >
        <div className={`h-full bg-white  w-full relative flex flex-col`}>
          <div
            className={`im:items-center ${
              !isMainSidebarOpen ? "px-[20%]" : "px-[23%]"
            }  w-full mt-[50px] im:mt-[0] bg-white sticky z-[10] top-0   im:mx-auto flex justify-start py-6`}
          >
            <Header />
            {state.saveStatus.editorStatus !== "idle" ? (
              <p className="text4 flex items-center w-[100px]">
                <DownloadIcon className="mr-1" />
                {state.saveStatus.editorStatus === "saving"
                  ? "Saving..."
                  : "Saved"}
              </p>
            ) : (
              <p
                onClick={() => saveEditorState(state.editorValue, true)}
                className="text4 cursor-pointer flex items-center w-[100px]"
              >
                <DownloadIcon className="mr-1" /> Save
              </p>
            )}
          </div>

          <div className={`h-full pl-[5%] w-full max-w-[650px]`}>
            <div className="editor-inner h-fit">
              <div className="flex flex-col relative ">
                <div className="relative w-full flex flex-col min-h-[80%] mt-10">
                  <div
                    id="editor_title"
                    ref={titleRef}
                    defaultValue={state.document.name}
                    contentEditable
                    className="h1Large w-[100%] min-h-[40px] pr-2 block bg-white items-center bg-transparent  outline-none"
                    onKeyDown={onTitleKeyDown}
                    placeholder={"Untitled"}
                    onInput={(e) => {
                      saveDocumentTitle(e.currentTarget.textContent || "");
                    }}
                  ></div>
                  <div className="relative mt-4">
                    {isEmpty && (
                      <div className="absolute left-[-25px] w-[80%]">
                        <Placeholder />
                      </div>
                    )}
                    <div className="">
                      <RichTextPlugin
                        contentEditable={
                          <div ref={onRef} onKeyDown={onEditorKeyDown}>
                            <ContentEditable className="editor-input" />
                          </div>
                        }
                        placeholder={<span />}
                        ErrorBoundary={LexicalErrorBoundary}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {state.currentEditorFocus === "MAIN_EDITOR" && (
                <MyCustomAutoFocusPlugin />
              )}
              <OnChangePlugin
                ignoreHistoryMergeTagChange={true}
                ignoreSelectionChange={true}
                onChange={onChange}
              />

              <HistoryPlugin />
              <AutoFocusPlugin />
              <CodeHighlightPlugin />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              {floatingAnchorElem && !isSmallWidthViewport && (
                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
              )}
              <StateHandlerPlugin state={state} dispatch={dispatch} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-fit fixed z-[1000] right-0  h-fit ${
          showSidebar && "h-screen min-h-screen"
        } bg-white rounded-[12px]`}
      >
        <DPU
          showSidebar={showSidebar}
          toggleSidebar={(val) => {
            toggleSidebar(val || !showSidebar);
          }}
          createNewDocument={resetDocument}
          dumpToEditor={(text) => dumpTextToEditor(text)}
        />
      </div>
      <div
        className={`fixed left-[24%]
        } w-full shadow-lg top-[95vh] pb-10`}
      >
        <ToolbarPlugin />
      </div>
    </div>
  );
}

export default React.memo(Editor);

import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postRequest } from "../../axios";
import Logo from "../../components/logo";
import Spinner from "../../components/spinner";
import { useAuth } from "../../context/auth/authContext";
import { PlanType } from "../../types";
import _ from "lodash";

const FastCheckout = () => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<
    "idle" | "success" | "error" | "loading"
  >("loading");

  const createStripeSession = async ({
    plan,
    coupon,
    planDuration,
    trial,
  }: {
    plan: PlanType;
    coupon?: string;
    planDuration?: string;
    trial?: any;
  }) => {
    let data: any = {
      product: plan,
      quantity: "1",
      payment: ["card"],
      frequency: planDuration,
      coupon: coupon ? coupon : "",
      trial,
    };
    if (!trial) {
      data = _.omit(data, "trial");
    }
    return await postRequest("payment/create_session", data);
  };

  const redirectToStripe = async (data: any) => {
    loadStripe(data.publicKey)
      .then((stripe: any) => {
        stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
      })
      .then(() => {
        toast.dismiss();
      })
      .catch(() => {
        toast.dismiss();
      });
  };

  useEffect(() => {
    const handleFastCheckout = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      let queryParam = "/user/signup?";
      Object.keys(params).forEach((param, index) => {
        if (params[param] && index + 1 === Object.keys(params).length) {
          queryParam = queryParam + `${param}=${params[param]}`;
        } else if (params[param])
          queryParam = queryParam + `${param}=${params[param]}&`;
      });

      if (!auth.state.user) {
        navigate(queryParam);
        return;
      }

      if (auth.state.user) {
        try {
          toast.loading("Redirecting you to Stripe");
          const res = await createStripeSession({
            plan: params.plan_type as PlanType,
            coupon: params.coupon ? params.coupon : "",
            planDuration: params.interval ? params.interval : "",
            trial: params.trial ? Number(params.trial) : "",
          });
          setStatus("success");
          redirectToStripe(res.data);
        } catch (err) {
          console.log(err);
          setStatus("error");
          // navigate(`/user/signup`);
          toast.dismiss();
          toast.error("Something went wrong. Please try again");
        }
      }
    };
    handleFastCheckout();
  }, []);

  return (
    <div className="p-[2%] w-screen h-screen bg-light-pink-gradient">
      <Logo />
      <div className="flex h-full w-full justify-center items-center">
        <div className="flex flex-col mt-[-5%] justify-center items-center">
          {status !== "error" && (
            <p className="text-primary-02 h3 mb-4">
              Hold tight! We are applying discount to your{" "}
              {searchParams.get("plan_type")} plan
            </p>
          )}
          {status === "error" && (
            <p className="text-red-400 h3 mb-4">
              Oops! Something went wrong. Please refresh the page.
            </p>
          )}
          {status === "loading" && <Spinner primaryColor />}
        </div>
      </div>
    </div>
  );
};

export default FastCheckout;

import React, { ReactNode } from "react";
import Modal from "react-modal";
import Input from "./input";
import { ReactComponent as CloseIcon } from "../assets/icons/close_button.svg";
import Button from "./button";

type Props = {
  showModal: boolean;
  onDelete?: (() => void) | ((id?: string) => Promise<void>);
  closeModal: () => void;
  title?: string;
  description?: string | ReactNode;
  buttonText?: string;
  disableDeleteButton?: boolean;
  buttonColor?: string;
};

const DeleteConfirmationModal = ({
  closeModal,
  showModal,
  onDelete,
  title,
  description,
  buttonText,
  disableDeleteButton,
  buttonColor,
}: Props) => {
  return (
    <Modal
      style={{ overlay: { zIndex: 1000 } }}
      isOpen={showModal}
      className="w-full relative flex items-center h-full m-auto bg-black-rgba"
    >
      <div className="w-[490px] p-6  rounded shadow flex flex-col m-auto bg-white ">
        <div className="mb-2 flex justify-between items-center">
          <h1 className="h3 text-primary-02">{title || "Delete document"}</h1>
          <CloseIcon onClick={closeModal} className="cursor-pointer" />
        </div>
        <p className="text2 text-grey-02">
          {description || "Are you sure you want to delete this document?"}
        </p>
        <div className="flex justify-end mt-6">
          <Button
            onClick={closeModal}
            className="bg-grey-06 text-grey-01 max-w-[100px] mr-6 h-11 flex items-center justify-center self-end"
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: buttonColor }}
            disabled={disableDeleteButton || false}
            onClick={onDelete as any}
            className={`max-w-[100px] ${
              disableDeleteButton ? "bg-gray-400" : "bg-red-01 "
            } h-11 flex items-center justify-center self-end`}
          >
            {buttonText || "Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;

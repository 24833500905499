import axiosClient from "../axios";

export const uploadToServer = async (rawFile: any) => {
  try {
    //TODO: change the formData keys to something else.
    const formData = new FormData();
    formData.append("image", rawFile);
    formData.append("image_name", rawFile.name);
    formData.append("image_tags", JSON.stringify(["cortex", "ai"]));
    formData.append("image_category", "document_image");
    formData.append("image_display_name", "document image");
    formData.append("image_description", "");

    const response = await axiosClient({
      method: "post",
      url: "/documents/upload_image",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    const imageUrl = response.data.data.image_url;
    return imageUrl;
  } catch (err) {
    console.log(err);
  }
};

import React from "react";
import { ReactComponent as CircleWavy } from "../../assets/icons/CircleWavyQuestion.svg";
import { ReactComponent as GraduationCap } from "../../assets/icons/GraduationCap.svg";
import { ReactComponent as Premium } from "../../assets/icons/premium_large.svg";
import { ReactComponent as ShareNetwork } from "../../assets/icons/ShareNetwork.svg";
import { ReactComponent as GiftIcon } from "../../assets/icons/Gift1.svg";
import Button from "../button";
import { useAuth } from "../../context/auth/authContext";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <div className="h-fit flex items-center justify-center w-fit">
      {auth.state.user?.plan_type === "Free" && (
        <div>
          <ShareNetwork
            onClick={() =>
              window.open(
                "https://tell.textcortex.com/refer-your-friends",
                "_blank"
              )
            }
            className="mr-6 cursor-pointer"
          />
        </div>
      )}

      <div>
        <GraduationCap
          onClick={() =>
            window.open("https://tell.textcortex.com/cortex-academy", "_blank")
          }
          className="mr-6 cursor-pointer"
        />
      </div>
      <div>
        <GiftIcon
          onClick={() => navigate("/user/dashboard/earn-more")}
          className="mr-6 cursor-pointer"
        />
      </div>
      <div id="cortex_onboarding_button">
        <CircleWavy className="mr-6 cursor-pointer" />
      </div>
      {auth.state.user?.plan_type === "Free" && (
        <div>
          <Premium
            onClick={() => navigate("/pricing")}
            className="mr-6 cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default Header;

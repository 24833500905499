type Data =
  | {
      type: "SET_TOKEN";
      payload?: {
        accessToken: string;
        refreshToken?: string;
        token: string;
      };
    }
  | {
      type: "REMOVE_TOKEN";
      payload?: any;
    };

const extensionIds = {
  ALL_BROWSERS_DEV: "kaffbmdkkagajhknnbgkpgineiohpoph",
  CHROME: "hahkojdegblcccihngmgndhdfheheofe",
  EDGE: "iaociiajffacjhhmleclkjdchjhdmjpb",
};

export const sendMessageToExtension = ({ payload, type }: Data) => {
  try {
    const sendMessage = (window as any).chrome?.runtime?.sendMessage;
    Object.values(extensionIds).forEach((id) => {
      sendMessage?.(id, { payload, type });
    });
  } catch (error) {
    console.log("send message error: ", error);
  }
};

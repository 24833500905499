import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import Editor from "../../../../components/editor";
import DPU from "../../../../components/DPU";
import Header from "../../../../components/editor/header";
import { useMediaQuery } from "react-responsive";
import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import ExampleTheme from "../../../../components/editor/themes";

const editorConfig: any = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  ignoreEmptyParagraph: true,
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

const EditorPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 860px)" });

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Editor />
    </LexicalComposer>
  );
};

export default EditorPage;

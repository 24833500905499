import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import AuthPagesContainer from "../../../components/authPageContainer";
import {
  AUTH_LOADING,
  googleAuth,
  REMOVE_ERROR,
  shopifyAuth,
  signupUser,
  SUBMITTING_FORM,
} from "../../../context/auth/actions";
import { useAuth } from "../../../context/auth/authContext";
import { AuthPageData } from "../../../types";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import useRecaptcha from "../../../hooks/useRecaptcha";

const signupPageData: AuthPageData = {
  type: "signup",
  title: "Sign up",
  subtitle: "Get your free TextCortex account to generate amazing content.",
  checkboxText: (
    <p>
      I agree to the{" "}
      <a
        className="underline"
        target="_blank"
        href="https://textcortex.com/terms-of-services"
        rel="noreferrer"
      >
        terms & conditions
      </a>{" "}
      and the{" "}
      <a
        className="underline"
        target="_blank"
        href="https://textcortex.com/privacy-policy"
        rel="noreferrer"
      >
        privacy policy
      </a>{" "}
      of text.cortex.
    </p>
  ),
  footer: "© 2022 TextCortex, All right Reserved",
  buttonText: "Sign in",
  changePage: {
    text: "Already have an account?",
    linkText: "Sign in now",
    link: "/user/login",
  },
  forms: [
    {
      type: "text",
      name: "first_name",
      label: "First name",
      placeholder: "First name",
      rules: { required: "This is a required field" },
    },
    {
      type: "text",
      name: "last_name",
      label: "Last name",
      placeholder: "Last name",
      rules: { required: "This is a required field" },
    },
    {
      type: "email",
      name: "email",
      label: "Email address",
      placeholder: "textcortex@gmail.com",
      rules: {
        required: "This is a required field",
        pattern:
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      },
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Enter 8 characters or more",
      rules: { required: "This is a required field" },
    },
  ],
};

type FormData = {
  first_name?: string;
  last_name?: string;
  email: string;
  password: string;
  terms_accepted: boolean;
};

const Signup = () => {
  const { dispatch, state } = useAuth();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<FormData>();
  const { affiliateCode } = useParams();
  const [searchParams] = useSearchParams();
  const { token: captchaToken, handleReCaptchaVerify } = useRecaptcha();

  const handleCheckbox = (value: boolean) => {
    setValue("terms_accepted", value);
  };

  const submitForm = async (data: FormData) => {
    dispatch({
      type: SUBMITTING_FORM,
      payload: true,
    });
    const token = await handleReCaptchaVerify();
    const registration_source = searchParams.get("registration_source");
    const affiliateCode = searchParams.get("reference_id");
    const formData = registration_source
      ? { ...data, registration_source, captcha_response: token }
      : data;
    if (affiliateCode) {
      signupUser(dispatch, {
        ..._.omit(formData, "terms_accepted"),
        reference_id: affiliateCode,
        captcha_response: token ? token : "placeholdertoken",
      });
    } else {
      signupUser(dispatch, {
        ..._.omit(formData, "terms_accepted"),
        captcha_response: token ? token : "placeholdertoken",
      });
    }
  };

  const googleAuthResponse = async (response: any) => {
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    try {
      const affiliateCode = searchParams.get("reference_id");
      if (affiliateCode) {
        await googleAuth(
          dispatch,
          {
            google_access_token: response.access_token.toString(),
            reference_id: affiliateCode,
          },
          "signup"
        );
      } else {
        await googleAuth(
          dispatch,
          {
            google_access_token: response.access_token.toString(),
          },
          "signup"
        );
      }
    } catch (err) {
      console.log(err, "here here here");
    }
  };

  const authWithShopify = async (storeUrl: string) => {
    await shopifyAuth(storeUrl);
  };

  return (
    <div className="w-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>TextCortex AI - Sign Up To Generate Amazing Text</title>
      </Helmet>
      <AuthPagesContainer
        googleAuth={googleAuthResponse}
        shopifyAuth={authWithShopify}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        pageData={signupPageData}
        submitForm={submitForm}
        handleCheckbox={handleCheckbox}
        watch={watch}
        loading={state.submittingForm}
        authError={state.error}
        control={control}
      />
    </div>
  );
};

export default React.memo(Signup);

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosClient, { getRequest, postRequest } from "../axios";
import { saveDocument } from "../screens/dashboard/creator/actions";

const useDocuments = (isFavorite?: boolean) => {
  const [documentType, setDocumentType] = useState("");
  const [state, setState] = useState<"loading" | "success" | "error" | "idle">(
    "idle"
  );
  const [showDeleteModal, toggleDeleteModal] = useState(false);

  const [documentToBeDeleted, setDocumentToBeDeleted] = useState<null | string>(
    null
  );
  const [showSaveDocumentModal, toggleSaveDocumentModal] = useState(false);
  const [newDocument, setNewDocument] = useState({
    documentName: "",
    documentType: "auto_complete",
  });
  const [documents, setDocuments] = useState<{
    filteredDocuments: null | any[];
    allDocuments: any;
  }>({
    filteredDocuments: null,
    allDocuments: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setState("loading");
        const options = isFavorite ? { favourite: true } : {};
        const res = await postRequest(
          "documents/retrieve_all_documents",
          options
        );
        const data = res.data.data.sort((a: any, b: any) => {
          if (a.created_date < b.created_date) return 1;
          return -1;
        });
        setDocuments({ ...documents, allDocuments: data });
        setState("success");
      } catch (error) {
        console.log("error:", error);
      }
    };
    fetchDocuments();
  }, []);

  const deleteDocument = async (id: string) => {
    try {
      toast.loading("Deleting document, please wait...`");
      await axiosClient({
        method: "delete",
        url: "/documents/update_document",
        data: {
          document_id: id,
        },
      });
      const updatedDocuments = documents.allDocuments.filter(
        (item: any) => item.document_id !== id
      );
      const updatedFiltered = documents.allDocuments.filter(
        (item: any) => item.document_id !== id
      );
      setDocuments({
        filteredDocuments: updatedFiltered,
        allDocuments: updatedDocuments,
      });
      toast.dismiss();
      toast.success("Document Deleted!");
    } catch (err) {
      toast.dismiss();
      toast.error("Failed to delete document!");
      console.log(err);
    }
  };

  useEffect(() => {
    if (documentType === "all") {
      setDocuments({
        ...documents,
        filteredDocuments: null,
      });
      return;
    }
    const filteredDocuments = documents.allDocuments.filter(
      (item: any) => item.category === documentType
    );
    setDocuments({
      ...documents,
      filteredDocuments,
    });
  }, [documentType]);

  const sortByAlphabet = () => {
    const sortedAll = documents.allDocuments.sort((a: any, b: any) => {
      if (a.document_name.toLowerCase() > b.document_name.toLowerCase())
        return 1;
      return -1;
    });
    const sortedFiltered = documents.filteredDocuments?.sort(
      (a: any, b: any) => {
        if (a.document_name.toLowerCase() > b.document_name.toLowerCase())
          return 1;
        return -1;
      }
    );
    setDocuments({
      allDocuments: sortedAll,
      filteredDocuments: sortedFiltered || null,
    });
  };

  const saveNewDocument = async (args?: any) => {
    const data = args
      ? args
      : {
          document_name: newDocument.documentName,
          category: newDocument.documentType,
          status: "Draft",
        };
    try {
      const res = await saveDocument(data);
      setDocuments({
        filteredDocuments: null,
        allDocuments: [
          {
            document_name: newDocument.documentName,
            category: newDocument.documentType,
            status: "Draft",
            document_data: "",
          },
          ...documents.allDocuments,
        ],
      });
      toggleSaveDocumentModal(false);
      if (res?.data)
        navigate(
          `/user/dashboard/creator/${newDocument.documentType}/${res?.data.document_id}`
        );
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteDocument = async () => {
    await deleteDocument(documentToBeDeleted || "");
    toggleDeleteModal(false);
  };

  return {
    documentType,
    setDocumentType,
    state,
    documents,
    deleteDocument,
    sortByAlphabet,
    setDocuments,
    newDocument,
    setNewDocument,
    saveNewDocument,
    showSaveDocumentModal,
    handleDeleteDocument,
    toggleSaveDocumentModal,
    documentToBeDeleted,
    setDocumentToBeDeleted,
    showDeleteModal,
    toggleDeleteModal,
  };
};

export default useDocuments;

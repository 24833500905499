import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENTER_DASHBOARD } from "../../../context/auth/actions";
import { useAuth } from "../../../context/auth/authContext";
import { OnboardingStep } from "../../../types";
import { defaultEditorRoute } from "../../../utils/helpers";
import OnboardingQuestions from "../onboardingQuestions";
import Demo from "./demo";
// import HowDidYouHearAboutUs from "./howDidYouHearAboutUs";
import OnboardingSuccess from "./onboardingSuccess";
import Pricing from "./pricing";

const ExtensionOnboarding = () => {
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(
    OnboardingStep.howDidYouHearAboutUs
  );
  switch (currentStep) {
    case OnboardingStep.howDidYouHearAboutUs:
      return (
        <OnboardingQuestions
          onNext={() => {
            setCurrentStep(OnboardingStep.demo);
          }}
        />
      );
    case OnboardingStep.demo:
      return <Demo onNext={() => setCurrentStep(OnboardingStep.pricing)} />;
    case OnboardingStep.pricing:
      return <Pricing onNext={() => setCurrentStep(OnboardingStep.success)} />;
    case OnboardingStep.success:
      return (
        <OnboardingSuccess
          onNext={() => {
            dispatch({
              type: ENTER_DASHBOARD,
              payload: state.user,
            });
            navigate(defaultEditorRoute);
          }}
        />
      );
    default:
      return <h1>default</h1>;
  }
};

export default ExtensionOnboarding;

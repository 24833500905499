import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdMenu } from "react-icons/md";
import { ReactComponent as ChatZenoLogo } from "../../assets/icons/ZenoChat.svg";
import PricingModal from "../../components/pricingModal";
import { ReactComponent as PlusCircle } from "../../assets/icons/plus_circle.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";

import Banners from "../../components/zenoChat/chatBanners";
import ChatHistory from "../../components/zenoChat/chatHistory";
import ChatInput from "../../components/zenoChat/chatInput";
import MessageComponent from "../../components/zenoChat/message";
import SampleMessagesBanner from "../../components/zenoChat/sampleMessagesBanner";
import { useAuth } from "../../context/auth/authContext";
import { BannerTypes, Message } from "../../context/chat/reducer";
import { useChat } from "../../context/chat/useChat";
import { PlanType } from "../../types";
import { isUserEligibleForFeature } from "../../utils/isUserEligableForFeature";
import { useMediaQuery } from "react-responsive";

const ZenoChat = () => {
  const {
    state,
    dispatch,
    submitForm,
    messageListRef,
    inputRef,
    setMessageAsCurrentlyPlaying,
    createNewChat,
  } = useChat();
  const auth = useAuth();

  const {
    state: { user },
    setPricingModalData,
    pricingModal,
    handleLanguageChange,
  } = auth;

  const onLanguageChange = (val: any) => {
    handleLanguageChange({ lang: val.value, showPricingModalIfNec: true });
  };
  const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
  const [showHistory, setShowHistory] = useState(false);
  const { isSidebarOpen, toggleSidebar } = useAuth();

  useEffect(() => {
    isMobile ? setShowHistory(false) : setShowHistory(true);
  }, [isMobile]);

  return (
    <div style={{ paddingBottom: 0 }} className=" py-0 page h-full max-h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Sign in and start creating well-crafted content 10x faster with your AI companion."
        />
        <title>TextCortex - AI Writing Assistant For All Your Content</title>
      </Helmet>

      <div className="relative flex flex-col max-w-screen h-full max-h-full">
        {/* Header */}
        <div className="flex px-5 z-[3000] border-bottom h-16 bg-white top-0 left-0 fixed  md:absolute md:top-0 w-screen justify-between right-0   md:hidden items-center ">
          <div
            className="flex items-center justify-center"
            onClick={(e) => {
              toggleSidebar(!isSidebarOpen);
              e.stopPropagation();
            }}
          >
            <MdMenu className="w-6 h-6" />
          </div>
          <div className="flex" onClick={createNewChat}>
            <PlusCircle
              className="stroke-brand-02 cursor-pointer"
              onClick={() => {}}
            />
            <span className="text-sm-SemiBold text-brand-02 items-center flex pl-3">
              New Chat
            </span>
          </div>
          <div className="" onClick={() => setShowHistory(!showHistory)}>
            <HistoryIcon className=" cursor-pointer" />
          </div>
        </div>

        {/* Body */}
        <div
          className="relative flex h-full max-h-full pt-10 md:pt-0 overflow-hidden bg-white z-[1000]"
          onClick={() => isMobile && setShowHistory(false)}
        >
          <div
            className={`w-full min-h-full max-h-full ${
              showHistory ? "md:max-w-[74%]" : "md:max-w-full"
            } justify-between`}
          >
            <div className="flex flex-col h-full max-h-full justify-between z-40">
              <div className="flex flex-col justify-between h-full max-h-[80%]">
                {/* Logo and Banner*/}
                <div className="flex  justify-between mt-3 min-h-min  w-full z-[1000]">
                  <div
                    className={`flex items-center justify-between ${
                      showHistory ? "w-full" : "w-[95%]"
                    }`}
                  >
                    <ChatZenoLogo className=" " />
                  </div>
                  <div
                    className={`px-4 mb-4 ${
                      !showHistory ? "rotate-180" : "rotate-0"
                    } absolute right-0 top-[1%]`}
                    onClick={() => setShowHistory(!showHistory)}
                  >
                    <HistoryIcon className=" cursor-pointer" />
                  </div>
                </div>
                <div className="overflow-y-auto  max-h-[50%]">
                  <Banners
                    toggleBanners={(val: BannerTypes) =>
                      dispatch({
                        type: "TOGGLE_BANNER",
                        payload: val,
                      })
                    }
                    showBanners={state.showBanners}
                  />
                </div>

                {/* Banner */}
                <div className="w-full max-h-[50%]  bottom-0 bg-white mt-3">
                  <div className=" h-full max-h-full overflow-auto ">
                    <SampleMessagesBanner
                      onSend={(text: string) => {
                        submitForm(text);
                      }}
                      toggleBanners={(val: BannerTypes) =>
                        dispatch({
                          type: "TOGGLE_BANNER",
                          payload: val,
                        })
                      }
                      showBanners={state.showBanners}
                    />
                  </div>
                </div>
                {/* Messages */}
                <div className="flex flex-col w-full max-h-full max-w-full overflow-y-auto  pb-5">
                  <div className="flex  flex-col justify-end">
                    {state.messages.map((message: Message) => (
                      <div className="my-4  ">
                        <MessageComponent
                          onPlayIconClick={() => {
                            setMessageAsCurrentlyPlaying({ message });
                          }}
                          isAudioActive={
                            state.currentlyPlayingMessage &&
                            state.currentlyPlayingMessage?.id === message.id
                          }
                          canBePlayed={Boolean(message.id)}
                          dispatch={dispatch}
                          data={message}
                        />
                      </div>
                    ))}

                    {state.loadingStatus.status === "typing" && (
                      <div className="mb-4">
                        <MessageComponent
                          state={state}
                          status={state.loadingStatus}
                        />
                      </div>
                    )}
                  </div>
                  <div ref={messageListRef} />
                </div>
              </div>
              {/* chat input */}
              <div
                className={`bottom-0  bg-white w-full h-auto max-h-full ${
                  showHistory ? "z-[0]" : "z-[3000]"
                }`}
              >
                <ChatInput
                  onLanguageChange={onLanguageChange}
                  inputRef={inputRef}
                  message={state.messageText}
                  onSend={() => {
                    submitForm(state.messageText);
                  }}
                  setMessage={(text: string) => {
                    dispatch({
                      type: "SET_MESSAGE_TEXT",
                      payload: text,
                    });
                  }}
                  toggleWebSearch={() => {
                    dispatch({ type: "TOGGLE_WEB_SEARCH" });
                  }}
                  state={state}
                  clearConvo={createNewChat}
                />
              </div>
            </div>
          </div>
          {/* History */}
          {showHistory && !isMobile && (
            <div
              className={`w-0 max-w-full mt-4 right-0 border-left bg-white  overflow-y-auto md:flex md:ml-4 md:max-w-full md:w-[26%]`}
              style={{
                transition: showHistory ? "0.3s" : "0.2s",
              }}
            >
              <ChatHistory
                dispatch={dispatch}
                status={state.loadingStatus}
                history={state.history}
              />
            </div>
          )}
          {isMobile && (
            <div
              className={`absolute right-0 bg-black-rgba top-10 h-full max-h-full  overflow-auto z-[10000] ${
                showHistory ? "right-0 w-[100vw] " : "right-0 w-0"
              }`}
              style={{
                transition: showHistory ? "0.3s" : "0.2s",
              }}
              onClick={() => {
                setShowHistory(false);
              }}
            >
              <div
                className="bg-white h-full max-h-full absolute top-0 w-[70vw] right-0 overflow-auto z-[3000]"
                onClick={(e) => e.stopPropagation()}
              >
                <ChatHistory
                  dispatch={dispatch}
                  status={state.loadingStatus}
                  history={state.history}
                />
              </div>
            </div>
          )}
        </div>

        <PricingModal
          user={user}
          onClose={() => {
            setPricingModalData({ ...pricingModal, isOpen: false });
          }}
          featurePlanType={pricingModal.featureUserAttempted || "Free"}
          isOpen={pricingModal.isOpen}
        />
      </div>
    </div>
  );
};

export default ZenoChat;

import React, { FormEventHandler, useEffect, useState } from "react";
import { Tab, TextSize, useDpu } from "./useDpu";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import axiosClient from "../../axios";
import SelectInput from "../selectDropdown";
import { ReactComponent as LogoSmall } from "../../assets/icons/logo-small.svg";
import { ReactComponent as LightBulb } from "../../assets/icons/Lightbulb.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as DumpToEditor } from "../../assets/icons/dump_to_editor.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-2.svg";

import _ from "lodash";

import {
  filterOption,
  getTemplateOptions,
  Group,
  GroupHeading,
  sendOutputFeedback,
  setDefaultValue,
  templateSelectStyles,
  ValueContainer,
} from "./utils";
import { GroupProps, components, GroupHeadingProps } from "react-select";
import Textarea from "../textarea";
import Input from "../input";
import Button from "../button";
import { useForm } from "react-hook-form";
import { isUserEligibleForFeature } from "../../utils/isUserEligableForFeature";
import { useAuth } from "../../context/auth/authContext";
import PricingModal from "../pricingModal";
import TypeWriterLoading from "./typeWriterLoading";
import { getDiffWords } from "../../utils/getWords";
import Copy from "../copy";
import Tippy from "@tippyjs/react";
import { LanguageOptions } from "../zenoChat/chatInput";
import {
  creativityOptions,
  languageOptions,
  textLengthOptions,
} from "../ApuForms/data";
import ParagraphRadio from "../paragraphRadio";
import { PlanType } from "../../types";
import ExtensionBanner from "../extensionBanner";
import { ChipDark } from "../chip";
import TagInput from "../tagInput";
import { useMediaQuery } from "react-responsive";
import ZenoSidebar from "./zenoSidebar";
import { useChat } from "../../context/chat/useChat";

const sidebarTabs: { title: string; value: Tab }[] = [
  {
    title: "Templates",
    value: "templates",
  },
  {
    title: "ZenoChat",
    value: "zeno",
  },
  // {
  //   title: "Developer mode",
  //   value: "developer_mode",
  // },
];

type Props = {
  dumpToEditor: (text: string) => void;
  createNewDocument: () => void;
  showSidebar: boolean;
  toggleSidebar: (val?: boolean) => void;
};

const DPU = ({
  dumpToEditor,
  createNewDocument,
  showSidebar,
  toggleSidebar,
}: Props) => {
  const { state, dispatch, getGenerations, onCopy, generatedText } = useDpu();
  const [showLanguageOptions, toggleLanguageOptions] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
  const auth = useAuth();
  const chat = useChat();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTabletOrMobile) {
      toggleSidebar(false);
    } else {
      toggleSidebar(true);
    }
  }, [isTabletOrMobile]);

  const templateOptions =
    state.tab === "developer_mode"
      ? getTemplateOptions({
          categories: { code: state.categories.code },
          templates: state.templates,
        })
      : getTemplateOptions({
          templates: state.templates,
          categories: _.omit(state.categories, "code"),
        });

  const handleFormSubmit = (data: any) => {
    getGenerations({ prompt: data, language: auth.language });
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit(handleFormSubmit)();
  };
  const values = watch();

  return (
    <div className="relative">
      <div
        className={`flex transition-delay flex-col w-full relative ${
          isTabletOrMobile && !showSidebar ? "h-fit" : "h-screen"
        } ${showSidebar ? "hidden" : "visible"} items-end`}
      >
        <div className="flex items-center h-fit">
          <Button
            className="text-brand-02 mt-3 h5BoldRegular flex items-center"
            onClick={createNewDocument}
            style={{ background: "transparent", paddingTop: 0 }}
          >
            <div className="flex mr-2 p-[2px] bg-white rounded-full border-[1px] items-center justify-center border-brand-02">
              <PlusIcon className="stroke-brand-02 w-3 h-3" />
            </div>
            New Doc
          </Button>
          <div className="bg-brand m-4 p-[1px] rounded">
            <Button
              onClick={() => toggleSidebar(true)}
              style={{ backgroundColor: "white", height: 40 }}
              className=" bg-white border-none p-0 flex items-center justify-center"
            >
              <span className="text-primary-02">Open Zeno Assistant</span>
              <ChevronRight />
            </Button>
          </div>
        </div>
        {!isTabletOrMobile && (
          <span className="sticky top-[30%] w-fit max-w-fit right-0">
            <ExtensionBanner />
          </span>
        )}
      </div>

      <div
        style={{
          transition: "0.5s",
        }}
        className={`w-[40%] absolute top-0 min-w-[400px] max-w-[400px] ${
          showSidebar ? "right-0" : "right-[-600px]"
        }
        h-screen z-[1000] items-start`}
      >
        <div className="h-fit absolute bg-white left-[-130px]">
          <Button
            className="text-brand-02 mt-6 h5BoldRegular flex items-center"
            onClick={createNewDocument}
            style={{ background: "transparent", paddingTop: 0 }}
          >
            <div className="flex mr-2 p-[2px] bg-white rounded-full border-[1px] items-center justify-center border-brand-02">
              <PlusIcon className="stroke-brand-02 w-3 h-3" />
            </div>
            New Doc
          </Button>
        </div>
        <div
          className={` relative brand-gradient-2 p-[2px]  rounded-r-[0px]   rounded-[12px] flex flex-col m-0  overflow-y-auto h-full drop-shadow-lg`}
        >
          <div
            className={` w-full relative overflow-y-auto h-full bg-white py-6 px-4 rounded-[8px]`}
          >
            <div className="flex w-[98%] ml-[1%] z-[100000] rounded-[8px] mt-[0.5%] px-[2%] items-center fixed top-0 right-[12px] shadow max-w-[380px] h-[80px] bg-white  justify-between">
              {/* <span
                  // onClick={() => {
                  //   dispatch({ type: "CHANGE_TAB", payload: item.value });
                  // }}
                  className={`h5BoldRegular ml-2 h-9 px-3 rounded flex items-center cursor-pointer ${
                    state.tab ? "bg-brand-01 text-primary-02" : "text-grey-10"
                  }`}
                >
                  Templates
                </span> */}
              {/* </div> */}
              <div className="flex  ml-4 w-[75%] justify-between items-center max-w-full">
                <div
                  onClick={() => toggleSidebar()}
                  className="flex ml-[-10px] item-center h-full cursor-pointer"
                >
                  <ChevronRight className="flex items-center h-auto" />
                </div>
                {sidebarTabs.map((item) => (
                  <span
                    onClick={() => {
                      dispatch({ type: "CHANGE_TAB", payload: item.value });
                    }}
                    className={`h5BoldRegular h-9 px-3 rounded flex items-center cursor-pointer ${
                      state.tab === item.value
                        ? "bg-brand-01 text-primary-02"
                        : "text-grey-10"
                    }`}
                  >
                    {item.title}
                  </span>
                ))}
              </div>
              <LogoSmall />
            </div>
            {(state.tab === "developer_mode" || state.tab === "templates") && (
              <div className="forms mt-[20%]">
                {state.status === "fetching_templates" && (
                  <SelectInput
                    name="template"
                    placeholder={"Fetching your templates..."}
                    isLoading={true}
                    style={templateSelectStyles}
                    value=""
                  />
                )}
                {state.status !== "fetching_templates" && (
                  <>
                    <SelectInput
                      name="template"
                      placeholder={"Select a template"}
                      style={templateSelectStyles}
                      defaultValue={state.currentTemplate}
                      onChange={(value: any) => {
                        if (
                          value.value === "paraphrase" ||
                          value.value === "change_tone"
                        ) {
                          navigate(
                            `/user/dashboard/creator/template/${value.value}`
                          );
                          return;
                        }
                        if (
                          isUserEligibleForFeature({
                            feature: value.plan_to_unlock,
                            user: auth.state.user?.plan_type || "Free",
                          })
                        ) {
                          dispatch({
                            type: "SET_TEMPLATE",
                            payload: value,
                          });
                          reset();
                        } else {
                          auth.setPricingModalData({
                            isOpen: true,
                            featureUserAttempted: value.plan_to_unlock,
                          });
                        }
                      }}
                      sizing={"md"}
                      options={templateOptions}
                      filterOption={(args: any, text: string) => {
                        return filterOption(args, text, templateOptions);
                      }}
                      menuStyles={{
                        boxShadow:
                          "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
                        border: "1px solid #F0E9FE",
                      }}
                      value={state.currentTemplate}
                      hoverStyle={{ border: "none" }}
                      isSearchable
                      components={{ Group, GroupHeading, ValueContainer }}
                    />

                    <form onSubmit={onSubmit} className="mt-8">
                      {state.currentTemplate &&
                        state.currentTemplate?.fields &&
                        state.currentTemplate?.fields.map((item: any) => {
                          if (
                            item.input_type === "textarea" ||
                            state.tab === "developer_mode"
                          ) {
                            return (
                              <div className="mb-4">
                                <label className="h5BoldRegular">
                                  {state.tab === "developer_mode"
                                    ? "Explain what you want to do"
                                    : item.description}
                                </label>
                                <Textarea
                                  className="h-[120px]"
                                  placeholder={
                                    state.tab === "developer_mode"
                                      ? "Write a function to multiply two numbers and print the result"
                                      : "Write something here..."
                                  }
                                  style={{ border: "1px solid #D0D5DD" }}
                                  {...register(item.name, {
                                    required: item.required,
                                  })}
                                />
                                <p className="text2 text-red-01 mt-2 mb-4">
                                  {errors?.[item.name] &&
                                    "This is a required field"}
                                </p>
                              </div>
                            );
                          } else if (item.input_type === "string") {
                            return (
                              <div className="mb-4">
                                <label className="h5BoldRegular mb-[-18px]">
                                  {item.description}
                                </label>
                                <Input
                                  {...register(item.name, {
                                    required: item.required,
                                  })}
                                  inputStyle={{
                                    height: 40,
                                    marginTop: 0,
                                    border: "1px solid #D0D5DD",
                                  }}
                                  placeholder={`${item.description.slice(
                                    0,
                                    30
                                  )}...`}
                                />
                                <p className="text2 text-red-01 mt-2 mb-4">
                                  {errors?.[item.name] &&
                                    "This is a required field"}
                                </p>
                              </div>
                            );
                          } else if (item.input_type === "tag") {
                            return (
                              <div className="mb-4">
                                <label className="h5BoldRegular mb-[-18px]">
                                  {item.description}
                                </label>
                                <TagInput
                                  {...register(item.name, {
                                    required: item.required,
                                  })}
                                  value={values[item.name]}
                                  onChange={(value) => {
                                    setValue(item.name, value);
                                  }}
                                />
                                <p className="text2 text-red-01 mt-2 mb-4">
                                  {errors?.[item.name] &&
                                    "This is a required field"}
                                </p>
                              </div>
                            );
                          } else if (item.input_type === "select") {
                            if (!watch(item.name)) {
                              setValue(item.name, item.options[0]);
                            }
                            return (
                              <div className="mb-4">
                                <label className="h5BoldRegular mb-[-18px]">
                                  {item.description}
                                </label>
                                <SelectInput
                                  name={item.name}
                                  placeholder={item.description}
                                  style={{
                                    minWidth: "200px",
                                    height: "24px",
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                  onChange={(value: any) => {
                                    setValue(item.name, value.value);
                                  }}
                                  defaultValue={{
                                    value: item.options[0],
                                    label: item.options[0],
                                  }}
                                  value={values[item.name]}
                                  options={item.options.map((option: any) => ({
                                    value: option,
                                    label: option,
                                  }))}
                                />
                                <p className="text2 text-red-01 mt-2 mb-4">
                                  {errors?.[item.name] &&
                                    "This is a required field"}
                                </p>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      <Button
                        type="submit"
                        disabled={state.status === "loading"}
                        className="bg-brand-02 h-10 flex items-center justify-center mt-8"
                      >
                        {state.tab === "developer_mode"
                          ? "Generate Code"
                          : "Create"}
                      </Button>
                      {state.status === "loading" && (
                        <div className="mt-10">
                          <TypeWriterLoading
                            showCtaButton={true}
                            userPlanType={auth.state.user?.plan_type}
                          />
                        </div>
                      )}
                      <div className="my-6 mb-[200px]">
                        {state.status !== "loading" &&
                          state.status !== "loading_more" &&
                          state.creations.length > 0 &&
                          state.creations.map((item, i) => (
                            <div
                              style={{ minHeight: 120 }}
                              className="flex flex-col justify-between w-full p-4 mb-6 rounded-md bg-primary-08"
                            >
                              <p
                                className=" body3 p-0 m-0"
                                style={{ wordBreak: "break-word" }}
                                onCopy={() => {
                                  sendOutputFeedback({
                                    channel: "webapp_rewrite_editor",
                                    output_id: item.id,
                                    rating: "ctrl_c",
                                    metadata: {
                                      selection: "",
                                    },
                                  });
                                }}
                              >
                                {state.currentTemplate.category === "general" &&
                                watch("text")
                                  ? getDiffWords({
                                      compareText: generatedText,
                                      baseText: watch("text"),
                                      chunkByPhrase: false,
                                      highlightClassName: "text-primary-01",
                                    })
                                  : generatedText}
                              </p>
                              <div className="flex justify-end w-full">
                                <div className="flex items-center">
                                  <div
                                    onClick={() => {
                                      dumpToEditor(generatedText);
                                    }}
                                  >
                                    <Tippy
                                      zIndex={1000}
                                      offset={[0, 8]}
                                      content={
                                        <ChipDark text={"Dump to editor"} />
                                      }
                                      delay={[300, 0]}
                                      moveTransition={"none"}
                                    >
                                      <DumpToEditor className="w-5 mr-2 cursor-pointer stroke-gray-600" />
                                    </Tippy>
                                  </div>
                                  <Copy
                                    onCopy={() => {
                                      onCopy(i, generatedText, item.id);
                                    }}
                                    text={generatedText}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </form>
                  </>
                )}
              </div>
            )}
            {state.tab === "zeno" && (
              <div className="h-full">
                <ZenoSidebar chatData={chat} />
              </div>
            )}

            <div className="fixed justify-end items-center m-1 rounded-[8px] bg-white flex w-[98%] right-0 max-w-[390px] bottom-0 border-top px-4">
              <div className="mr-0 flex items-center">
                {state.tab === "zeno" && (
                  <Tippy
                    offset={[0, 8]}
                    content={<ChipDark text={"Clear Conversation"} />}
                    delay={[300, 0]}
                    moveTransition={"none"}
                  >
                    <TrashIcon
                      onClick={chat.createNewChat}
                      className="mr-4 scale-105 outline-none cursor-pointer"
                    />
                  </Tippy>
                )}
                <Tippy
                  interactive={true}
                  visible={showLanguageOptions}
                  offset={[50, 0]}
                  content={
                    <LanguageOptions
                      onSelect={(val: any) => {
                        auth.handleLanguageChange({
                          lang: val.value,
                          showPricingModalIfNec: true,
                        });
                        toggleLanguageOptions(false);
                      }}
                    />
                  }
                  delay={[0, 0]}
                  moveTransition={"none"}
                  trigger="click"
                  zIndex={10000}
                  popperOptions={{ strategy: "fixed" }}
                  appendTo={document.body}
                  onClickOutside={() => toggleLanguageOptions(false)}
                >
                  <p
                    onClick={() => toggleLanguageOptions(true)}
                    className="h1 cursor-pointer mt-[0px]"
                  >
                    {
                      languageOptions.find(
                        (lang) => auth.language === lang.value
                      )?.emoji
                    }
                  </p>
                </Tippy>

                {state.tab !== "zeno" && (
                  <div className="mx-2">
                    <ParagraphRadio
                      items={creativityOptions}
                      showIcon={false}
                      value={state.creativity}
                      onChange={(value) => {
                        const itemPlan = creativityOptions.find(
                          (item) => item.value === value
                        )?.planTypeToUnlock;

                        if (
                          isUserEligibleForFeature({
                            feature: itemPlan as PlanType,
                            user: auth.state.user?.plan_type || "Free",
                          })
                        ) {
                          dispatch({
                            type: "SET_CREATIVITY",
                            payload: value as number,
                          });
                        } else {
                          auth.setPricingModalData({
                            isOpen: true,
                            featureUserAttempted: itemPlan,
                          });
                        }
                      }}
                      control={<LightBulb />}
                      showLabel={true}
                      hint={"Creativity"}
                    />
                  </div>
                )}

                {state.tab !== "zeno" && (
                  <ParagraphRadio
                    items={textLengthOptions}
                    showIcon={false}
                    value={state.textSize}
                    onChange={(size: any) => {
                      const option = textLengthOptions.find(
                        (item) => item.value === size
                      );
                      if (
                        isUserEligibleForFeature({
                          feature: option?.planTypeToUnlock as PlanType,
                          user: auth.state.user?.plan_type || "Free",
                        })
                      ) {
                        dispatch({ type: "SET_WORD_COUNT", payload: size });
                      } else {
                        auth.setPricingModalData({
                          isOpen: true,
                          featureUserAttempted: option?.planTypeToUnlock,
                        });
                      }
                    }}
                    control={<Medium />}
                    showLabel={true}
                    hint={"Output text length"}
                  />
                )}
                <PricingModal
                  user={auth.state.user}
                  onClose={() => {
                    auth.setPricingModalData({
                      ...auth.pricingModal,
                      isOpen: false,
                    });
                  }}
                  featurePlanType={
                    auth.pricingModal.featureUserAttempted || "Free"
                  }
                  isOpen={auth.pricingModal.isOpen}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DPU;

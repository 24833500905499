import axiosClient, { getRequest, postRequest } from "../../axios";
import axios from "axios";
import { history } from "../../router";
import { setAccessToken } from "../../utils/setAccessToken";
import { User } from "../../types";
import _ from "lodash";
import { toast } from "react-toastify";
import { sendMessageToExtension } from "../../utils/extension";
import mixpanel from "mixpanel-browser";
import { analytics } from "../../utils/analytics";
import { LOGIN, SIGNUP } from "../../constants/analytics";
import { storageKeys } from "../../utils/helpers";

export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const SUBMITTING_FORM = "SUBMITTING_FORM";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const ENTER_DASHBOARD = "ENTER_DASHBOARD";

const prepAnalyticsUser = (userId: string) => {
  try {
    mixpanel.identify(userId);
  } catch (error) {
    console.error("error: ", error);
  }
};

const analyticsAuth = (user: any) => {
  try {
    prepAnalyticsUser(user.data?.email);
    const { ...analyticsUser } = user;
    if (user.data) {
      mixpanel.people.set({
        ...analyticsUser,
        $name: `${user.data?.first_name} ${user.data?.last_name}`,
      });
      mixpanel.people.set({
        $email: user.data?.email || user.email,
      });
    } else {
      mixpanel.people.set({
        ...analyticsUser,
        $email: user.data?.email || user.email,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const checkIfGoingToFastCheckout = (): any => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.coupon || params.plan_type || params.interval || params.trial) {
    return params;
  }
  return null;
};

const getUrlParam = (params: any) => {
  let queryParam = "/fast-checkout?";
  Object.keys(params).forEach((param, index) => {
    if (params[param] && index + 1 === Object.keys(params).length) {
      queryParam = queryParam + `${param}=${params[param]}`;
    } else if (params[param])
      queryParam = queryParam + `${param}=${params[param]}&`;
  });
  return queryParam;
};

export const signupUser = async (
  dispatch: any,
  data: any,
  endPoint?: string
) => {
  try {
    dispatch({
      type: SUBMITTING_FORM,
      payload: true,
    });
    const res = await postRequest(endPoint ? endPoint : "auth/signup", data);
    if (endPoint) {
      dispatch({ type: SUBMITTING_FORM, payload: false });
      await setAccessToken({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });

      const user = await fetchUser();
      if (user.onboarded === true) {
        dispatch({ type: ENTER_DASHBOARD, payload: { user } });
        analyticsAuth(user);
        analytics(SIGNUP, {
          ...user,
          registration_source: data.registration_source || "web app",
        });
        const userFastCheckoutParams = checkIfGoingToFastCheckout();

        if (userFastCheckoutParams) {
          history.push(getUrlParam(userFastCheckoutParams));
          return;
        }
        history.push("/");
        return;
      }
      analyticsAuth(user);
      analytics(SIGNUP, {
        ...user,
        registration_source: data.registration_source || "web app",
      });
      dispatch({ type: AUTH_SUCCESS, payload: { user } });
      const userFastCheckoutParams = checkIfGoingToFastCheckout();
      if (userFastCheckoutParams) {
        history.push(getUrlParam(userFastCheckoutParams));
        return;
      }
      history.push("/user/onboarding");
      return;
    } else {
      const user = {
        email: data.email,
        status: "not-verified",
        data: { first_name: data.first_name, last_name: data.last_name },
      };
      dispatch({ type: AUTH_SUCCESS, payload: { user } });
      await localStorage.setItem("cortexUnverifiedUser", JSON.stringify(user));
      analyticsAuth(user);
      analytics(SIGNUP, {
        ...user.data,
        email: user.email,
        registration_source: data.registration_source || "web app",
      });
      const userFastCheckoutParams = checkIfGoingToFastCheckout();
      if (userFastCheckoutParams) {
        history.push(getUrlParam(userFastCheckoutParams));
        return;
      }
      history.push("/user/verify-email");
      return;
    }
  } catch (err) {
    const { status, message } = (err as any).response?.data;
    let errorMessage = message || "Wrong credentials. Please try again.";
    if (message.includes("Captcha")) {
      errorMessage = "Verification failed, turn off your VPN and try again.";
    }
    toast.error(errorMessage, {
      position: "top-left",
      className: "auth-error-toast",
    });
    dispatch({
      type: AUTH_FAILURE,
      payload: {
        error: {
          type: status,
          message: errorMessage,
        },
      },
    });
  }
};

export const login = async (dispatch: any, data: any, endPoint?: string) => {
  try {
    dispatch({
      type: SUBMITTING_FORM,
      payload: true,
    });
    const response = await postRequest(
      endPoint ? endPoint : "auth/login",
      data
    );
    await setAccessToken({
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    });

    if (!response.data.email_confirmed) {
      const user = { email: data.email, status: "not-verified" };
      await localStorage.setItem("cortexUnverifiedUser", JSON.stringify(user));
      analyticsAuth(user);
      analytics(LOGIN, { email: user.email });
      dispatch({
        type: AUTH_SUCCESS,
        payload: { user: user },
      });
      const userFastCheckoutParams = checkIfGoingToFastCheckout();
      if (userFastCheckoutParams) {
        history.push(getUrlParam(userFastCheckoutParams));
        return;
      }
      history.push("/user/verify-email");
      return;
    }

    //email-confirmed user: check if user have already passed the onboarding process or not if not redirect to /onboarding.
    const user = await fetchUser();
    const userFastCheckoutParams = checkIfGoingToFastCheckout();

    if (!user.onboarded) {
      analyticsAuth(user);
      analytics(LOGIN, { email: user.email });
      dispatch({ type: AUTH_SUCCESS, payload: { user } });
      if (userFastCheckoutParams) {
        history.push(getUrlParam(userFastCheckoutParams));
        return;
      }
      user.registration_source === "chrome_extension"
        ? history.push("/extension/onboarding")
        : history.push("/user/onboarding");
    } else {
      analyticsAuth(user);
      analytics(LOGIN, { ...user, email: user.email });
      dispatch({ type: ENTER_DASHBOARD, payload: { user } });
      if (userFastCheckoutParams) {
        history.push(getUrlParam(userFastCheckoutParams));
        return;
      }
      history.push("/");
      return;
    }
  } catch (err: any) {
    let errorMessage = err.response
      ? err.response.data.message
      : "Login failed. Please try again.";
    if (errorMessage.includes("Captcha")) {
      errorMessage = "Verification failed, turn off your VPN and try again.";
    }
    toast.error(errorMessage, {
      position: "top-left",
      className: "auth-error-toast",
    });
    dispatch({
      type: AUTH_FAILURE,
      payload: {
        error: {
          type: err?.response && err?.response.data.status,
          message: errorMessage,
        },
      },
    });
  }
};

export const sendVerificationEmail = async (dispatch: any, data: any) => {
  try {
    dispatch({
      type: SUBMITTING_FORM,
      payload: true,
    });
    await postRequest("auth/register/send_verification_email/", data);
    dispatch({
      type: SUBMITTING_FORM,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: SUBMITTING_FORM,
      payload: false,
    });
  }
};

export const submitOnboardingQuestions = async (data: any, dispatch: any) => {
  const formData = {
    industry: "",
    role: "",
    working_in_team: "",
    website: "",
    heard_us_from: [],
    ...data,
  };
  try {
    dispatch({ type: SUBMITTING_FORM, payload: true });
    const response = await postRequest(
      "accounts/save_onboarding_questions",
      formData
    );
    if (response.data) return true;
  } catch (err) {
    console.log(err);
    dispatch({
      type: SUBMITTING_FORM,
      payload: false,
    });
  }
  return false;
};

export const retrieveAccount = async (
  data: { email: string; token: string },
  dispatch: any
) => {
  const { email, token: captcha_response } = data;
  try {
    dispatch({
      type: SUBMITTING_FORM,
      payload: true,
    });
    await postRequest("auth/password_reset/", { email, captcha_response });
    dispatch({ type: SUBMITTING_FORM, payload: false });
    //to show email sent message on the component.
    return true;
  } catch (err) {
    toast.error("Something went wrong. Please try again.");
    dispatch({ type: SUBMITTING_FORM, payload: false });
    console.log(err);
  }
};

export const googleAuth = async (
  dispatch: any,
  data: any,
  authType: "login" | "signup"
) => {
  try {
    if (authType === "login") {
      await login(dispatch, data, "auth/google");
    } else if (authType === "signup") {
      await signupUser(dispatch, data, "auth/google");
    }
  } catch (err) {
    console.log(err);
  }
};

export const shopifyAuth = async (storeUrl: string) => {
  try {
    toast.loading("Redirecting you to shopify...");
    const response = await getRequest(`auth/install_shopify?shop=${storeUrl}`);
    window.open(response.data.redirect_link, "_blank");
    toast.dismiss();
  } catch (err) {
    toast.dismiss();
    toast.error("Failed to access your shopify store. Please try again");
    console.log(err);
  }
};

export const cleanUpAuthFromStorage = async () => {
  await localStorage.removeItem(storageKeys.ACCESS_TOKEN);
  sendMessageToExtension({
    type: "REMOVE_TOKEN",
  });
  await localStorage.removeItem("cortexUnverifiedUser");
  await localStorage.removeItem(storageKeys.REFRESH_TOKEN);
  await localStorage.removeItem("default_language");
};

export const logout = async (dispatch?: any) => {
  try {
    await postRequest("auth/logout");
    cleanUpAuthFromStorage();
    if (dispatch)
      dispatch({
        type: LOGOUT,
      });
    history.push("/");
  } catch (err) {
    cleanUpAuthFromStorage();
    if (dispatch)
      dispatch({
        type: LOGOUT,
      });
    console.error("logout error", err);
  }
};

export const fetchUser = async () => {
  try {
    const response = await getRequest("accounts/user_details");
    return response.data;
  } catch (err) {
    console.log("fetch user error: ", err);
  }
};

export const updateUser = (dispatch: any, data: User) => {
  dispatch({ type: AUTH_SUCCESS, payload: { user: data } });
};

export const confirmPasswordReset = async (dispatch: any, data: any) => {
  try {
    dispatch({ type: SUBMITTING_FORM, payload: true });
    const res = await postRequest(
      `auth/confirm_password_reset/${data.token}`,
      _.omit({ ...data }, "token")
    );
    toast.success("Password reset was successful.");
    history.push("/user/login");
    dispatch({ type: SUBMITTING_FORM, payload: false });
  } catch (err) {
    dispatch({ type: SUBMITTING_FORM, payload: false });
    console.log(err);
  }
};

export const getShopifyCharge = async () => {
  try {
    toast.loading("Redirecting you to shopify. Please wait...");
    const res = await getRequest("shopify/create_charge_retry/");
    if (res.data.redirectUri) {
      window.open(res.data.redirectUri);
    }
    toast.dismiss();
  } catch (err) {
    console.log(err);
  }
};

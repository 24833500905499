import React, { useEffect } from "react";
import useHorizontalScroll from "../hooks/useHorizontalScroll";
import { ReactComponent as LeftIcon } from "../assets/icons/chevron-left.svg";
import { ReactComponent as RightIcon } from "../assets/icons/chevron-right.svg";
import { getTemplateListData } from "../utils/getTemplateListData";
import TemplateCard from "./templateCard";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/authContext";
import PricingModal from "./pricingModal";
import { PlanType } from "../types";

type Props = {
  pagination?: boolean;
  title?: string;
  creatorPage?: boolean;
};

const TemplatesList = ({ pagination = true, title, creatorPage }: Props) => {
  const {
    state: { user },
  } = useAuth();
  const { onNext, onPrev, scrollRef } = useHorizontalScroll();
  const templates = getTemplateListData();

  const templateList = user?.shopify
    ? templates
    : templates.filter((item) => item.value !== "product_description_shopify");

  return (
    <section className="mt-5 w-full">
      <div className="flex items-center justify-between w-full">
        <div
          className={`flex items-start ${
            creatorPage && "items-center"
          } flex-col w-full`}
        >
          <h1 className={`${creatorPage ? "h1" : "h3"} text-primary-02`}>
            {title || "Templates"}
          </h1>
          <p className="text2 text-grey-02">
            Choose among 100+ AI templates and find your fit.{" "}
          </p>
        </div>
        {pagination && (
          <div className="flex item-center">
            {/* <button
            onClick={() => navigate("/user/dashboard/suggest-template")}
            className="h2 text-grey-02 px-3 h-[32px] mr-2 border rounded cursor-pinter"
          >
            Suggest template
          </button> */}
            <button
              onClick={onPrev}
              className="w-[32px] h-[32px] flex items-center justify-center mr-2 border rounded cursor-pinter"
            >
              <LeftIcon />
            </button>
            <button
              onClick={onNext}
              className="w-[32px] h-[32px] flex items-center justify-center mr-2 border rounded cursor-pinter"
            >
              <RightIcon />
            </button>
          </div>
        )}
      </div>
      <div className="flex mt-4">
        <div
          ref={scrollRef}
          className={`flex ${creatorPage && "justify-center"} ${
            pagination === false && "flex-wrap"
          }  overflow-y-scroll hide-scrollbar mx-auto flex space-evenly w-full`}
        >
          {templateList.map((item) => {
            const Icon = item.icon;
            return (
              <div className="cursor-pointer mr-8 mb-8">
                <TemplateCard
                  plan={item.plan_to_unlock as PlanType}
                  {...item}
                  icon={Icon}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TemplatesList;

import moment from "moment";

export const getDateFiller = (dates: any) => {
  let updatedDates = dates;
  let lastDate = dates[dates.length - 1];
  while (updatedDates.length < 5) {
    const newDate = moment(new Date(lastDate), "MMM DD").add(1, "day");
    updatedDates.push(moment(newDate).format("MMM DD"));
    lastDate = newDate;
  }
  return updatedDates.sort((a: any, b: any) => {
    if (a < b) return -1;
    return 1;
  });
};

export const getDatasetFiller = (datasets: any) => {
  let updatedData = [...datasets];
  let addedItems = 0;
  while (updatedData.length < 5) {
    updatedData.push(2);
    addedItems++;
  }

  return {
    data: updatedData,
    backgroundColors: [
      ...Array(datasets.length).fill("#0A8BFE"),
      ...Array(addedItems).fill("#E5E7EB"),
    ],
  };
};

export const getUsageDataWithFiller = (data: any) => {
  if (data.length < 6) {
    const newDates = getDateFiller(data.map((item: any) => item.day));
    return newDates.map((item: any, index: number) => {
      return {
        day: item,
        cost: data[index] ? data[index].cost : 0,
      };
    });
  }
};

import React, { ReactNode, useState } from "react";

import { ReactComponent as CoinIcon } from "../assets/icons/coin.svg";

import { ReactComponent as Logo } from "../assets/icons/logo_with_text.svg";
import { ReactComponent as LogoSmall } from "../assets/icons/logo-small.svg";
import { ReactComponent as SidebarOpenIcon } from "../assets/icons/sidebar_open.svg";
import { ReactComponent as SidebarCloseIcon } from "../assets/icons/sidebar_close.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/home_sidebar.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/plus_circle.svg";
import { ReactComponent as ImageIcon } from "../assets/icons/image_sidebar.svg";
import { ReactComponent as InfinityIcon } from "../assets/icons/infinity_sidebar.svg";
import { ReactComponent as RewriteIcon } from "../assets/icons/refresh.svg";
import { ReactComponent as FolderIcon } from "../assets/icons/folder_sidebar.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/settings_sidebar.svg";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "../assets/icons/chevron-up.svg";
import { ReactComponent as Trophy } from "../assets/icons/trophy.svg";
import { ReactComponent as ZenoIcon } from "../assets/icons/zeno_logo.svg";
import { ReactComponent as TemplatesIcon } from "../assets/icons/templates.svg";
import { ReactComponent as PremiumIcon } from "../assets/icons/premium_small.svg";
import { ReactComponent as EarnCoin } from "../assets/icons/earn_coin.svg";
import { ReactComponent as Customize } from "../assets/icons/customize.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/edit.svg";

import { ReactComponent as ShopifyIcon } from "../assets/icons/shopify.svg";
import { ReactComponent as Chrome } from "../assets/icons/chrome_dark.svg";
import { ReactComponent as MacIcon } from "../assets/icons/mac.svg";

import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from "./button";
import { useAuth } from "../context/auth/authContext";
import UserIcon from "./userIcon";
import { logout } from "../context/auth/actions";
import {
  defaultEditorRoute,
  edgeLink,
  extensionLink,
  isUserEdgeUser,
} from "../utils/helpers";
import { useMediaQuery } from "react-responsive";
import { SiMicrosoftedge } from "react-icons/si";

type Props = {
  isOpened: boolean;
  toggleSidebar: () => void;
};

interface LinkProps {
  collapsible?: boolean;
  name: string;
  icon?: JSX.Element;
  link: string;
  sideIcon?: {
    icon: JSX.Element;
  };
  subLinks?: {
    name: string;
    link: string;
    action?: () => void;
    external?: boolean;
    icon?: JSX.Element;
  }[];
}

type NavLinkProps = {
  item: LinkProps;
  customAction?: () => void;
  isExternal?: boolean;
};

const sidebarLinks: LinkProps[] = [
  {
    name: "Dashboard",
    icon: HomeIcon,
    link: "/user/dashboard",
  },
  {
    name: "AI Editor",
    icon: PencilIcon,
    link: `${defaultEditorRoute}?ai-editor=true`,
  },
  {
    name: "Templates",
    icon: TemplatesIcon,
    link: "/user/dashboard/creator",
  },
  {
    name: "Rewrite",
    icon: RewriteIcon,
    link: "/user/dashboard/creator/template/paraphrase",
  },
  {
    name: "ZenoChat",
    icon: ZenoIcon,
    link: "/user/dashboard/chat",
  },
  // {
  //   name: "Visual Cortex",
  //   icon: ImageIcon,
  //   link: "/user/dashboard/settings/account",
  //   sideIcon: {
  //     icon: (
  //       <span className="px-2 h-[16px] flex items-center rounded-md h6  bg-primary-07">
  //         <span className="brand-gradient-text-glow">New</span>
  //       </span>
  //     ),
  //   },
  // },
  // {
  //   name: "Integrated Apps",
  //   icon: InfinityIcon,
  //   link: "",
  //   collapsible: true,
  //   subLinks: [
  //     {
  //       name: "Shopify",
  //       icon: ShopifyIcon,
  //       external: true,
  //       link: "https://apps.shopify.com/text-cortex",
  //     },
  //     {
  //       name: "Extension",
  //       icon: Chrome,
  //       external: true,
  //       link: extensionLink,
  //     },
  //     {
  //       name: "API",
  //       icon: MacIcon,
  //       link: "/user/dashboard/settings/api-key",
  //     },
  //   ],
  // },
  // {
  //   name: "Gift 1-month to a friend",
  //   icon: ImageIcon,
  //   link: "/user/dashboard/earn-more",
  //   sideIcon: {
  //     icon: (
  //       <span className="px-2 h-[16px] flex items-center rounded-md h6  bg-primary-07">
  //         <span className="brand-gradient-text-glow">Reward</span>
  //       </span>
  //     ),
  //   },
  // },
  {
    name: "Customize your AI",
    icon: Customize,
    link: "/user/dashboard/customize-your-ai",
    // Add beta tag to Customize Your Ai Icon
    sideIcon: {
      icon: (
        <span className="px-2 h-[16px] flex items-center rounded-md h6  bg-primary-07">
          <span className="brand-gradient-text-glow">BETA</span>
        </span>
      ),
    },
  },
  {
    name: "Documents",
    icon: FolderIcon,
    link: "/user/dashboard/documents",
    sideIcon: {
      icon: <PlusIcon className="stroke-[#344054]" />,
    },
  },
];

const footerLinks: LinkProps[] = [
  {
    name: "Settings",
    icon: SettingsIcon,
    collapsible: true,
    link: "",
    subLinks: [
      {
        name: "Account",
        link: "/user/dashboard/settings/account",
      },
      {
        name: "Usage",
        link: "/user/dashboard/settings/usage",
      },
      {
        name: "Billing & Invoice",
        link: "/user/dashboard/settings/billing",
      },
      // {
      //   name: "Team Members",
      //   link: "/user/dashboard/settings/teams",
      // },
      {
        name: "Invite Friends",
        link: "/user/dashboard/settings/invite-friends",
      },
      {
        name: "API Key",
        link: "/user/dashboard/settings/api-key",
      },
    ],
  },
  {
    name: "Earn Creations",
    icon: EarnCoin,
    link: "/user/dashboard/earn-more",
  },
];

const SidebarNavLink = ({ item, customAction, isExternal }: NavLinkProps) => {
  const { pathname } = useLocation();
  const auth = useAuth();
  const Icon = item.icon as any;
  let SideIcon = () => item.sideIcon?.icon || <span></span>;

  if (item.name === "Earn Creations") {
    SideIcon = () =>
      (auth.state.user?.credit as any) === "Unlimited" ? (
        <p className="brand-gradient-text-glow h5">{auth.state.user?.credit}</p>
      ) : (
        <div className="bg-primary-07 w-6 h-6 rounded-full  flex items-center justify-center">
          <p className="h5 brand-gradient-text-glow p-2">
            {auth.state.user?.credit}
          </p>
        </div>
      );
  }

  const isActive = pathname === item.link;

  if (item.name === "Earn Creations" && auth.state.user?.plan_type !== "Free") {
    return (
      <a
        href=" https://tell.textcortex.com/gift-your-friends"
        target={"_blank"}
        className={`flex p-2 px-3 mb-2 hover:bg-primary-09 rounded-md w-full justify-between items-center `}
      >
        <div className="flex w-full items-center h-full">
          {item.icon && (
            <Icon
              className={`w-6 ${
                isActive ? "stroke-brand-02" : "stroke-[#667085]"
              }`}
            />
          )}
          <p className={`h5BoldRegular ml-3 ${isActive && "text-brand-02"}`}>
            Gift your Friends Pro
          </p>
        </div>
      </a>
    );
  }
  return (
    <NavLink
      to={(!customAction && item.link) || pathname}
      className={`flex p-2 px-3 mb-2 hover:bg-primary-09 rounded-md w-full justify-between items-center ${
        isActive && "bg-primary-08 rounded-md"
      }`}
      onClick={
        customAction
          ? customAction
          : () => (isExternal ? window.open(item.link, "_blank") : undefined)
      }
    >
      <div className="flex w-full items-center h-full">
        {item.icon && (
          <Icon
            className={`w-6 ${
              isActive ? "stroke-brand-02" : "stroke-[#667085]"
            }`}
          />
        )}
        <p className={`h5BoldRegular ml-3 ${isActive && "text-brand-02"}`}>
          {item.name}
        </p>
      </div>
      <SideIcon />
    </NavLink>
  );
};

type CollapsibleProps = {
  link: LinkProps;
};

const CollapsibleLink = ({ link }: CollapsibleProps) => {
  const [isOpen, toggle] = useState(false);
  const Icon = link.icon as any;
  const isCollapsible = link.collapsible && isOpen;
  return (
    <div className="">
      <div
        onClick={() => toggle(!isOpen)}
        className="flex justify-between items-center cursor-pointer p-2 mb-2  hover:bg-primary-09 rounded-md"
      >
        <div className={`flex  w-full items-center`}>
          <Icon />
          <p className="ml-3 h5BoldRegular">{link.name}</p>
        </div>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isCollapsible &&
        link.subLinks &&
        link.subLinks.map((item) => {
          return (
            <div className="ml-2">
              <SidebarNavLink
                isExternal={item.external}
                customAction={item.action}
                item={item}
              />
            </div>
          );
        })}
    </div>
  );
};

const Sidebar = ({ isOpened, toggleSidebar }: Props) => {
  const {
    state: { user },
    dispatch,
  } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1300px)" });
  const navigate = useNavigate();

  const User = () =>
    user?.data.profile_image ? (
      <img
        src={user?.data?.profile_image}
        alt=""
        className={`cursor-pointer flex items-center justify-center shadow w-6 rounded-[50%] h-6`}
      />
    ) : (
      <UserIcon initial={user?.data?.first_name[0] || ""} />
    );

  if (isOpened) {
    return (
      <div className="w-full h-screen bg-white sidebar-shadow px-4 py-6 overflow-y-auto">
        <div className="h-full flex flex-col justify-between ">
          <div className="">
            <div className="flex justify-between items-center mb-8">
              <Logo />

              <div className="cursor-pointer" onClick={() => toggleSidebar()}>
                <SidebarOpenIcon />
              </div>
            </div>
            <div className="flex flex-col">
              {sidebarLinks.map((item) => {
                return item.collapsible ? (
                  <CollapsibleLink link={item} />
                ) : (
                  <SidebarNavLink item={item} />
                );
              })}
            </div>
          </div>
          <div className="">
            {user?.plan_type === "Free" && (
              <Button
                onClick={() => navigate("/pricing")}
                style={{ padding: 1 }}
                className=" brand-gradient-2 h-9 p-1 flex items-center justify-center mt-6 "
              >
                {/* <span className="w-full  h-full rounded-md bg-primary-09 flex items-center justify-center"> */}
                <p className="text-white h5BoldRegular flex items-center">
                  Go Unlimited
                  <PremiumIcon className="ml-2 stroke-white" />
                </p>

                {/* </span> */}
              </Button>
            )}
            <Button
              onClick={() =>
                window.open(
                  isUserEdgeUser() ? edgeLink : extensionLink,
                  "_blank"
                )
              }
              style={{ padding: 1, background: "white" }}
              className=" h-9 p-1 flex items-center  border-2 rounded justify-center mt-4 "
            >
              {/* <span className="w-full  h-full rounded-md bg-primary-09 flex items-center justify-center"> */}
              <p className="text-primary-02 h5BoldRegular flex items-center">
                {isUserEdgeUser() ? "Add to Edge" : "Add to chrome"}
                {isUserEdgeUser() ? (
                  <SiMicrosoftedge color="#0E1227" className="ml-1" />
                ) : (
                  <Chrome className="ml-1 w-4 h-4" />
                )}
              </p>

              {/* </span> */}
            </Button>
            <div className="mt-6">
              {footerLinks.map((link) => {
                return link.collapsible ? (
                  <CollapsibleLink link={link} />
                ) : (
                  <div className="ml-[-4px]">
                    <SidebarNavLink
                      item={{
                        link: link.link,
                        name: link.name,
                        icon: link.icon,
                      }}
                    />
                  </div>
                );
              })}
              <div className="">
                <CollapsibleLink
                  link={{
                    icon: User as any,
                    name: `${user?.data.first_name} ${user?.data.last_name}`,
                    link: "",
                    collapsible: true,
                    subLinks: [
                      {
                        name: "Logout",
                        link: "",
                        action: () => logout(dispatch),
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={() => toggleSidebar()}
      className="flex cursor-pointer  relative z-[100] m-4 bg-white w-fit h-fit items-start p-3 shadow-lg border rounded"
    >
      <LogoSmall className="mr-4" /> <SidebarCloseIcon />
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Link } from "react-router-dom";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import Input from "../../../components/input";
import Logo from "../../../components/logo";
import GoogleLogin from "react-google-login";
import SocialAuthButton from "../../../components/socialAuthButton";
import {
  AUTH_LOADING,
  googleAuth,
  REMOVE_ERROR,
  signupUser,
} from "../../../context/auth/actions";
import { useAuth } from "../../../context/auth/authContext";
import { getLoginCred } from "../../../utils/getLoginCred";

type FormData = {
  first_name?: string;
  last_name?: string;
  email: string;
  password: string;
  terms_accepted: boolean;
};

const Signup = () => {
  const { dispatch, state } = useAuth();
  const [showEmailForm, toggleEmailForm] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    dispatch({
      type: REMOVE_ERROR,
    });
  }, [dispatch]);

  const handleCheckbox = (value: boolean) => {
    setValue("terms_accepted", value);
  };

  const submitForm = (data: FormData) => {
    signupUser(dispatch, {
      ..._.omit(data, "terms_accepted"),
      registration_source: "chrome_extension",
    });
  };

  const googleAuthResponse = async (response: any) => {
    dispatch({
      type: AUTH_LOADING,
      payload: true,
    });
    await googleAuth(
      dispatch,
      {
        id_token: response.tokenObj?.id_token,
        email: response.profileObj?.email,
        name: response.profileObj?.givenName,
        lastName: response.profileObj?.familyName,
      },
      "signup"
    );
  };

  return (
    <div className="flex w-full p-6 md:p-0">
      <div className="min-h-[100vh] pb-[80px] w-full relative">
        <div className="mt-12 max-w-[456px] m-auto">
          <Logo />
          <h1 className="mt-[60px] h1 text-primary-02">
            Take your writing to the next level
          </h1>
          <p className="mt-3 text2 text-grey-02">
            Get started by creating your account
          </p>
          <div className="flex flex-col mt-4 mb-5">
            {state.error && (
              <p className="mt-4 mb-4 text2 text-red-01">
                {state.error.message}
              </p>
            )}
            <GoogleLogin
              clientId={getLoginCred("google")}
              onSuccess={googleAuthResponse}
              onFailure={googleAuthResponse}
              cookiePolicy={"single_host_origin"}
              render={(props: any) => (
                <SocialAuthButton
                  onClick={props.onClick}
                  text={`Continue with Google`}
                  type="google"
                />
              )}
            />
            <SocialAuthButton
              onClick={() => {}}
              type="facebook"
              text={"Continue with Facebook"}
            />
            {!showEmailForm && (
              <SocialAuthButton
                onClick={() => {
                  toggleEmailForm(true);
                  dispatch({
                    type: REMOVE_ERROR,
                  });
                }}
                type="mail"
                text={"Continue with Mail"}
              />
            )}
          </div>

          {showEmailForm && (
            <form onSubmit={handleSubmit(submitForm)} className="mt-5">
              <Input
                {...register("first_name", {
                  required: "This is a required field",
                })}
                errors={errors}
                type="text"
                label="First name"
                name="first_name"
                placeholder={"First name"}
              />
              <Input
                errors={errors}
                type="text"
                label="Last name"
                placeholder={"Last name"}
                {...register("last_name", {
                  required: "This is a required field",
                })}
              />
              <Input
                {...register("email", {
                  required: "This is a required field",
                })}
                errors={errors}
                type="email"
                label="Email address"
                name="email"
                placeholder={"textcortex@gmail.com"}
              />
              <Input
                placeholder={"Enter 8 characters or more."}
                {...register("password", {
                  required: "This is a required field",
                })}
                errors={errors}
                type="password"
                label="Password"
                name="password"
              />
              {state.error && (
                <p className="mt-4 mb-4 text2 text-red-01">
                  {state.error.message}
                </p>
              )}
              <Checkbox
                value={watch("terms_accepted")}
                errors={errors}
                handleCheckbox={handleCheckbox}
                label={
                  <p className="text2 text-primary-02">
                    I agree to the
                    <span className="h2"> Terms & Conditions</span>
                  </p>
                }
                // name={"terms_accepted"}
                {...register("terms_accepted", {
                  required: "Please accept the terms & conditions.",
                })}
                // register={register}
                required={"Please accept the terms & conditions."}
              />
              <Button
                loading={state.submittingForm}
                type="submit"
                className="my-4"
              >
                Sign up
              </Button>
            </form>
          )}

          <p className="text2 text-primary-02">
            Already have an account?{" "}
            <Link to="/user/login" className="h2">
              Log in now
            </Link>
          </p>
          <p className="absolute bottom-[3vh] text2 text-grey-02 ">
            © 2022 TextCortex, All right Reserved
          </p>
        </div>
      </div>
      <div className="hidden md:flex w-full min-h-[100vh] bg-light-pink-gradient flex justify-center items-center">
        <div className="bg-white rounded w-[321px] h-[321px]" />
      </div>
    </div>
  );
};

export default Signup;

import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../../axios";
import { SET_USER } from "../../context/auth/actions";
import { useAuth } from "../../context/auth/authContext";
import { Creation, CreationStatus } from "../../types";
import { sizeToCount, storageKeys } from "../../utils/helpers";
import {
  fetchTokenStreams,
  getCategorizedTemplates,
  sendOutputFeedback,
  setDefaultValue,
} from "./utils";

let controller: AbortController = new AbortController();

export type Tab = "templates" | "zeno" | "developer_mode";

export type TextSize = "short" | "medium" | "long";

type Prompt = { text?: string };

export type Params = { prompt: Prompt; language?: string };

// write me a function that makes an axios post request to the backend and receives a response type of stream and get a json response back.
// the response should be a json object with a key of "data" and a value of a string.
type GenerateArgs = {
  prompt: any;
  controller: AbortController;
  creationType?: string;
  loadMore?: boolean;
  targetLanguage?: string;
  urlParam: string;
};

type State = {
  currentTemplate: any;
  tab: Tab;
  templates: any;
  categories: any;
  creations: Creation[];
  languages: any;
  status: CreationStatus;
  textSize: TextSize;
  generationCopied: boolean;
  error: null | { type: "creation_up_no_auth" | "creation_up" };
  creativity: number;
  showSidebar: boolean;
};

const generateDefaults: GenerateArgs = {
  prompt: {},
  controller: new AbortController(),
  urlParam: "",
};

export const initialState: State = {
  creations: [],
  currentTemplate: null,
  tab: "templates",
  templates: [],
  categories: [],
  languages: [],
  status: "idle",
  generationCopied: false,
  error: null,
  textSize: "short",
  creativity: 0.65,
  showSidebar: true,
};

type Action =
  | {
      type: "CHANGE_TAB";
      payload: Tab;
    }
  | {
      type: "SET_CATEGORIES__TEMPLATES_AND_LANGUAGES";
      payload: {
        categories: any;
        templates: any;
        languages: any;
        defaultValue: string;
        status: CreationStatus;
      };
    }
  | { type: "SET_TEMPLATE"; payload: any }
  | { type: "SET_STATUS"; payload: CreationStatus }
  | {
      type: "SET_COPIED";
      payload: boolean;
    }
  | {
      type: "SET_ERROR";
      payload: {
        type: "creation_up_no_auth" | "creation_up";
      };
    }
  | {
      type: "SET_WORD_COUNT";
      payload: TextSize;
    }
  | { type: "SET_CREATIVITY"; payload: number }
  | {
      type: "SET_CREATIONS";
      payload: Creation[];
    }
  | {
      type: "TOGGLE_SIDEBAR";
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CHANGE_TAB":
      console.log(state, "state o tab change");
      return {
        ...state,
        tab: action.payload,
      };
    case "SET_CREATIONS":
      return {
        ...state,
        creations: action.payload,
        status: "success",
        generationCopied: false,
      };

    case "SET_TEMPLATE":
      return {
        ...state,
        currentTemplate: action.payload,
      };
    case "SET_CATEGORIES__TEMPLATES_AND_LANGUAGES":
      const templateValue = setDefaultValue({
        templates: action.payload.templates,
        categories: action.payload.categories,
        tab: state.tab,
        defaultValue: action.payload.defaultValue,
      });
      return {
        ...state,
        categories: action.payload.categories,
        templates: action.payload.templates,
        languages: action.payload.languages,
        currentTemplate: { ...templateValue },
        status: action.payload.status,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "SET_COPIED":
      return { ...state, generationCopied: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload, status: "idle" };
    case "SET_WORD_COUNT":
      return { ...state, textSize: action.payload };
    case "SET_CREATIVITY":
      return { ...state, creativity: action.payload };
    case "TOGGLE_SIDEBAR":
      return { ...state, showSidebar: !state.showSidebar };
    default:
      return state;
  }
};

export const fetchTemplates = async () => {
  const res = await axiosClient({
    method: "get",
    url: `/internal/v1/available-templates`,
  });
  return res;
};

export const useDpu = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [generatedText, setGeneratedText] = useState("");
  const auth = useAuth();
  const params = useParams();

  useEffect(() => {
    const getTemplates = async () => {
      try {
        dispatch({
          type: "SET_STATUS",
          payload: "fetching_templates",
        });
        const res = await fetchTemplates();
        dispatch({
          type: "SET_CATEGORIES__TEMPLATES_AND_LANGUAGES",
          payload: {
            categories: res.data.categories,
            languages: res.data.languages,
            templates: res.data.templates,
            defaultValue: params.templateName || "",
            status: "idle",
          },
        });
      } catch (err) {
        console.log(err);
      }
    };
    getTemplates();
  }, []);

  const getGenerations = async ({ prompt, language }: Params) => {
    dispatch({
      type: "SET_STATUS",
      payload: "loading",
    });
    setGeneratedText("");
    try {
      const data = {
        ...prompt,
        source_lang: language ? language : "en",
        target_lang: language ? language : "en",
        max_tokens: sizeToCount[state.textSize],
        ...state.currentTemplate?.injected_fields,
      };

      if (language === "auto") delete data.target_lang;

      /*********** Token Streaming.... *******/
      if (language === "en" || language === "auto") {
        const data = {
          ...prompt,
          source_lang: language ? language : "en",
          target_lang: language ? language : "en",
          max_tokens: sizeToCount[state.textSize],
          ...state.currentTemplate?.injected_fields,
        };

        if (language === "auto") delete data.target_lang;

        const url = `${state.currentTemplate.path.replace(
          "internal",
          "internal-stream"
        )}`;
        // alert(`Path: ${path}\nJSON: ${json}`);
        // Call fetchBlogData async function here
        await fetchTokenStreams(url, data, (chunk: any) => {
          // Split according to newline
          const parts = chunk.split("\n");
          if (parts[0] === "event: output") {
            const data = parts[1].substring(6);
            const parsedData = JSON.parse(data);
            const text = parsedData.outputs[0].text;
            dispatch({
              type: "SET_CREATIONS",
              payload: parsedData.outputs,
            });
            setGeneratedText((prevText) => prevText + text);
          }
          if (parts[0] === "event: error") {
            dispatch({
              type: "SET_STATUS",
              payload: "error",
            });
          }
        });
      } else {
        /***************************************/
        const res = await axiosClient({
          url: state.currentTemplate.path,
          data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          signal: controller.signal,
        });

        if (res.status === 200 && res.data?.data?.outputs) {
          dispatch({
            type: "SET_CREATIONS",
            payload: res.data?.data?.outputs,
          });
          setGeneratedText(res.data?.data?.outputs[0].text);
        }
      }
    } catch (e: any) {
      const error = e;
      if (error.message === "canceled") {
        dispatch({ type: "SET_STATUS", payload: "idle" });
      } else if (error.response) {
        if (error.response.data.error === 10) {
          dispatch({ type: "SET_STATUS", payload: "creation_up_no_auth" });
          dispatch({
            type: "SET_ERROR",
            payload: { type: "creation_up_no_auth" },
          });

          auth.dispatch({
            type: SET_USER,
            payload: { user: { ...auth.state.user, credit: 0 } },
          });
        } else if (error.response.data.error === 402) {
          dispatch({
            type: "SET_ERROR",
            payload: { type: "creation_up" },
          });
          auth.dispatch({
            type: SET_USER,
            payload: { user: { ...auth.state.user, credit: 0 } },
          });
        } else {
          dispatch({ type: "SET_STATUS", payload: "error" });
        }
      } else {
        console.error("error: ", error);
        dispatch({ type: "SET_STATUS", payload: "error" });
      }
    }
  };

  function processChunk(chunk: any) {
    console.log("Processed chunk:", chunk);
  }

  const onCopy = async (i: any, text: string, id: string) => {
    try {
      await navigator.clipboard.writeText(text || "");
      sendOutputFeedback({
        channel: "webapp_rewrite_editor",
        output_id: id,
        rating: "copied",
      });
      // analytics(COPY, {
      //   template_name: state.template,
      //   source: document.domain,
      //   generation: { id, text },
      //   allGenerations: state.creations,
      // });
    } catch (error) {
      console.error("copy failed: ", error);
    }
  };

  return {
    state,
    dispatch,
    fetchTemplates,
    getGenerations,
    onCopy,
    generatedText,
  };
};

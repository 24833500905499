import React, { useState } from "react";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { AuthPageData, PlanType } from "../types";
import investLaunchPadLogo from "../assets/images/InvestLaunchpad.png";
import euLogo from "../assets/images/EuLogo.png";
import Logo from "./logo";
import Button from "./button";
import SocialAuthButton from "./socialAuthButton";
import Input from "./input";
import { useNavigate, useSearchParams } from "react-router-dom";
import Checkbox from "./checkbox";
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  Controller,
} from "react-hook-form";
import { getLoginCred } from "../utils/getLoginCred";
import ReCAPTCHA from "react-google-recaptcha";
import { login } from "../context/auth/actions";
import { Base64 } from "js-base64";
import { ReactComponent as Checkmark } from "../assets/icons/checkmark.svg";
import { ReactComponent as FiveStars } from "../assets/icons/5-stars.svg";
import { ReactComponent as ReviewPlats } from "../assets/icons/review_plats.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

type Props = {
  pageData: AuthPageData;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitForm: (data: any) => void;
  register: UseFormRegister<any>;
  handleSubmit?: UseFormHandleSubmit<any>;
  errors: FieldErrors<any>;
  handleCheckbox?: (val: boolean) => void;
  watch: any;
  loading?: boolean;
  authError: any;
  googleAuth?: (response: unknown) => void;
  shopifyAuth: (url: string) => void;
  control: any;
};

const isProduction = process.env.NODE_ENV === "production";
const isStaging = process.env.NODE_ENV === "staging";

let redirect_uri = process.env.REACT_APP_DISCORD_REDIRECT;

const discordLink = `https://discord.com/api/oauth2/authorize?client_id=1037379175708885053&redirect_uri=${redirect_uri}&response_type=code&scope=identify%20email`;

const ctaContent = {
  title: "Join the writing revolution.",
  features: [
    "Upgrade for unlimited creations & more features",
    "No professional experiences required",
    "Your writing assistant saving 70% of your time",
  ],
  reviews: [
    {
      avatar:
        "https://uploads-ssl.webflow.com/622611a2b23d0e1ab20fba78/637363ab9f24c161da2cccfb_image%201.png",
      name: "Jeff Barnette",
      stars: 5,
      reviews:
        "This tool is amazing and makes overcoming writers block a breeze. (...) This is certainly an extension worth trying out because it may just save you hours of work and frustration.",
    },
    {
      avatar:
        "https://uploads-ssl.webflow.com/622611a2b23d0e1ab20fba78/637375d16f6c19274fde2ac7_Group%204.png",
      name: "Cyphas Solutions LTD",
      stars: 5,
      reviews:
        "Initially I thought it was like the others tested, Jasper, CopyAI,GaryAi etc. But the way Dominik explained it and testing things one can truly see the approach is much better...",
    },
    {
      avatar:
        "https://uploads-ssl.webflow.com/622611a2b23d0e1ab20fba78/637375d16f6c19274fde2ac7_Group%204.png",
      name: "Cristine Lee",
      stars: 5,
      reviews:
        "Impressive tool! Love the rewrite feature. Easy to use. Great support from the team. Thank you team TextCortex!",
    },
  ],
};

const AuthPagesContainer: React.FC<Props> = ({
  pageData,
  submitForm,
  onChange,
  register,
  handleSubmit,
  errors,
  handleCheckbox,
  watch,
  loading,
  authError,
  googleAuth,
  control,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useGoogleOneTapLogin({
    onSuccess: (tokenResponse) => {
      setSocialLoading(false);
      googleAuth &&
        googleAuth({
          ...tokenResponse,
          access_token: tokenResponse.credential,
        });
    },
    onError: () => {
      setSocialLoading(false);
      console.log("err");
    },
  });

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setSocialLoading(false);
      googleAuth && googleAuth(tokenResponse);
    },
    onError: () => {
      setSocialLoading(false);
      console.log("err");
    },
  });
  const [socialLoading, setSocialLoading] = useState(false);
  const navigate = useNavigate();
  const authType = pageData.type === "signIn" ? "Login" : "Sign up";

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit && handleSubmit(submitForm)();
  };

  const handleDiscordLogin = async () => {
    try {
      const reference_id = searchParams.get("reference_id");
      const registration_source = searchParams.get("registration_source");
      const data = {
        reference_id: reference_id || "",
        registration_source: registration_source || "",
      };
      const encoded = Base64.encode(JSON.stringify(data));
      window.open(`${discordLink}&state=${encoded}`, "_self");
    } catch (err) {
      console.log(err, "error");
    }
  };

  const checkIfUserCameFromFastCheckout = () => {
    const coupon = searchParams.get("coupon");
    const plan = searchParams.get("plan_type") as PlanType;
    const planDuration = searchParams.get("interval");
    const trial = searchParams.get("trial");

    if (coupon || plan || planDuration || trial) {
      return true;
    } else {
      return false;
    }
  };

  const checkFastCheckoutAndRedirect = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let queryParam = authType === "Sign up" ? "/user/login?" : "/user/signup?";
    Object.keys(params).forEach((param, index) => {
      if (params[param] && index + 1 === Object.keys(params).length) {
        queryParam = queryParam + `${param}=${params[param]}`;
      } else if (params[param])
        queryParam = queryParam + `${param}=${params[param]}&`;
    });

    if (
      params.coupon ||
      params.plan_type ||
      params.planDuration ||
      params.trial
    ) {
      navigate(queryParam);
    } else {
      navigate(pageData.changePage?.link || "/");
    }
  };

  const userCameFromFastCheckout = checkIfUserCameFromFastCheckout();

  let customPageTitle;

  if (userCameFromFastCheckout) {
    customPageTitle =
      authType === "Login"
        ? "Login to redeem your discount"
        : "Signup to redeem your discount";
  }

  return (
    <div
      id="form_container"
      className="flex items-stretch w-full h-auto max-h-full overflow-y-auto b bg-white"
    >
      <div className="flex-1 w-6/12 h-full">
        <div className="w-[80%] max-w-[500px]  h-full m-auto flex flex-col justify-center">
          {/* <div className="mb-[20px]">
            <Logo />
          </div> */}
          <div className="form-content h-auto max-h-full py-20">
            <h1 className="mb-4 h1 text-primary-02">{pageData.title}</h1>
            {pageData.changePage && (
              <div className="flex items-center mb-2">
                <p className="text2 text-primary-02">
                  {pageData.changePage.text}
                </p>
                <p
                  className="ml-2 cursor-pointer h2 text-primary-02"
                  onClick={() => {
                    checkFastCheckoutAndRedirect();
                  }}
                >
                  {pageData.changePage.linkText}
                </p>
              </div>
            )}
            {pageData.subtitle && (
              <p className=" text2 text-grey-02 mb-6">{pageData.subtitle}</p>
            )}

            {/* {pageData.footer && (
              <p className="mt-16 text2 text-grey-02">{pageData.footer}</p>
            )} */}

            <SocialAuthButton
              onClick={() => {
                login();
              }}
              text={`${authType} with Google`}
              type="google"
            />

            <SocialAuthButton
              text={`${authType} with Discord`}
              type="discord"
              onClick={handleDiscordLogin}
            />
            <div className="divider my-4 max-w-[456px] flex items-center">
              <span className="h-[1px] w-[46%] bg-grey-03 mr-2" />
              <p className="text2 text-grey-02">or</p>
              <span className="h-[1px] w-[46%] bg-grey-03 ml-2" />
            </div>

            <form onSubmit={onSubmit}>
              {pageData.forms &&
                pageData.forms.map((field) => (
                  <Controller
                    control={control}
                    name={field.name}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => {
                      return (
                        <Input
                          {...register(field.name, { ...field.rules })}
                          key={field.name}
                          {...field}
                          value={value}
                          name={name}
                          onChange={onChange}
                          errors={errors}
                        />
                      );
                    }}
                  />
                ))}
              {authError && (
                <p className="mt-4 mb-4 text2 text-red-01">
                  {authError.message}
                </p>
              )}
              {pageData.checkboxText && (
                <Checkbox
                  required={
                    authType === "Sign up" &&
                    "Please accept the terms and conditions"
                  }
                  register={register}
                  errors={errors}
                  handleCheckbox={handleCheckbox}
                  label={pageData.checkboxText}
                  value={
                    authType === "Sign up"
                      ? watch("terms_accepted")
                      : watch("remember_me")
                  }
                  name={
                    authType === "Sign up" ? "terms_accepted" : "remember_me"
                  }
                />
              )}
              <Button id="submit_button" loading={loading} className="mt-6">
                <p>{authType}</p>
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div className="w-6/12 hidden min-h-screen p-10 overflow-y-auto im:block bg-brand">
        <div className="bg-light-pink-gradient px-20  w-full h-full rounded-md">
          <div className="h-[80%] justify-center flex flex-col">
            <h1 className="large-text mb-6">
              {customPageTitle || ctaContent.title}
            </h1>
            <div className="">
              {ctaContent.features.map((item) => (
                <div className="flex items-center mb-2">
                  <Checkmark />
                  <p className="text4 ml-6">{item}</p>
                </div>
              ))}
            </div>
            <div className="mt-16">
              <Carousel
                autoPlay
                showThumbs={false}
                renderIndicator={(
                  clickHandler: (
                    e: React.MouseEvent | React.KeyboardEvent
                  ) => void,
                  isSelected: boolean,
                  index: number,
                  label: string
                ) => (
                  <div
                    onClick={clickHandler}
                    className={`w-5 h-1 cursor-pointer rounded-md ${
                      isSelected ? "bg-primary-01" : "bg-primary-06"
                    }`}
                  ></div>
                )}
                showArrows={false}
                showStatus={false}
                className="review-carousel max-w-[360px]"
              >
                {ctaContent.reviews.map((item) => (
                  <div className="flex flex-col">
                    <div className="flex mb-5">
                      <img
                        className="rounded-full relative h-9 w-9"
                        src={item.avatar}
                      />
                      <div className="ml-2">
                        <p className="h5">{item.name}</p>
                        <FiveStars />
                      </div>
                    </div>
                    <p className="max-w-[320px] h5Italic text-left">
                      "{item.reviews}"
                    </p>
                  </div>
                ))}
              </Carousel>
              <div className="flex">
                <p className="h5Bold w-[170px] mr-6">
                  4.9 out of 5 stars in 150+ Reviews
                </p>
                <ReviewPlats />
              </div>
            </div>
          </div>
        </div>
        {/* <h1 className="text1 text-primary-02 max-w-[340px]">
          Recognized as one of the Top 200 AI Companies in Europe.
        </h1>
        <div className="flex w-full mt-4 logos">
          <img
            alt="partner-logo"
            className="w-[145px] h-[35px]"
            src={investLaunchPadLogo}
          />
          <img alt="partner-logo" src={euLogo} />
        </div>
        <div className="border-[10px] border-b-0 border-r-0 border-primary-02 mt-12 w-full h-full bg-white rounded-tl-[32px]">
          <img
            src={require("../assets/images/signup_image.png")}
            alt=""
            className="rounded-tl-[32px]"
          />
        </div> */}
      </div>
    </div>
  );
};

export default AuthPagesContainer;
